/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
import { Modal } from "@mui/material";
import classNames from "classnames";
import { warningAlert } from "components/alert";
import { TextLinked } from "components/buttons";
import CountryItem from "components/countryItem";
import EmptyNotice from "components/emptyNotice";
import { XSvg } from "components/icons";
import SubHeaderMobile from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import { SearchBar } from "components/textField";
import SearchCountryBarPC from "components/textField/searchBarPC/searchCountry";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { getListCountry112 } from "services/HeatMapService/HeatMapService";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import { isIOS } from "util/CommonUtil";
import { ModalStyled, SelfScrollDiv } from "views/HeatMap/styled";
import WorldSvg from "../../assets/img/mobile/world.svg";
import FooterResetSubmit from "./components/FooterResetSubmit";
import { SubCategory48px } from "./styled";

const world = {
  mkCountryUid: 0,
  labelEng: "World",
  labelKor: "전세계",
  imageUrl: WorldSvg,
  countryCodeShort: "ALL"
};
let timeout;
export default function ModalFilterCountry({
  open,
  onClose,
  listSelectedCountry,
  setListSelectedCountry,
  value,
  pc
}) {
  const [keyword, setKeyword] = useState("");
  const [listSelected, setListSelected] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (open) {
      getListContry();
      setListSelected(listSelectedCountry);
    }
  }, [open]);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (open) getListContry();
    }, 300);
  }, [keyword]);

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const getListContry = async () => {
    try {
      setIsLoading(true);
      const res = await getListCountry112(
        keyword
          ? {
              language: localStorage.getItem("i18nextLng").toUpperCase(),
              keyword
            }
          : {
              language: localStorage.getItem("i18nextLng").toUpperCase()
            }
      );
      if (res?.code === "200") {
        setListCountry(res?.data?.list || []);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onReset = () => {
    if (i18n.language === "ko") {
      setListSelectedCountry([
        {
          countryCodeShort: "KR",
          countryCode: "KOR",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
          labelKor: "대한민국",
          labelEng: "Republic of Korea",
          mkCountryUid: "19d0417c147811ee9382d8bbc199b2cd"
        }
      ]);
      setListSelected([
        {
          countryCodeShort: "KR",
          countryCode: "KOR",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
          labelKor: "대한민국",
          labelEng: "Republic of Korea",
          mkCountryUid: "19d0417c147811ee9382d8bbc199b2cd"
        }
      ]);
    } else {
      setListSelectedCountry([
        {
          countryCodeShort: "US",
          countryCode: "USA",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
          labelKor: "미국",
          labelEng: "United States",
          mkCountryUid: "19d05cfb147811ee9382d8bbc199b2cd"
        }
      ]);
      setListSelected([
        {
          countryCodeShort: "US",
          countryCode: "USA",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
          labelKor: "미국",
          labelEng: "United States",
          mkCountryUid: "19d05cfb147811ee9382d8bbc199b2cd"
        }
      ]);
    }
  };

  const handleSelect = (item) => {
    const { mkCountryUid } = item;
    if (
      listSelected.find((obj) => obj?.mkCountryUid === mkCountryUid) !==
      undefined
    ) {
      setListSelected((e) => e.filter((a) => a?.mkCountryUid !== mkCountryUid));
    } else if (mkCountryUid === 0) {
      setListSelected([item]);
    } else if (
      listSelected.find((obj) => obj?.mkCountryUid === 0) !== undefined
    ) {
      setListSelected([item]);
    } else setListSelected((prevSelected) => [...prevSelected, item]);
  };

  const selectAll = () => setListSelected(listCountry);

  const resetAll = () => setListSelected([]);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled
        modalType={pc ? "pc-large" : "mobile"}
        className="hidden-scrollbar">
        {!pc ? (
          <SubHeaderMobile
            headerTitle={<h1>{t("info.country")}</h1>}
            borderBottom
            customAction={
              <div style={{ display: "flex", gap: "16px" }}>
                {/* {value === "1B0275B8A7AF4F6BBB2F5F387AC86AD8" && ( */}
                <TextLinked
                  onClick={selectAll}
                  btnText={t("button.selectAll")}
                />
                {/* )} */}
                <TextLinked onClick={resetAll} btnText={t("button.deSelect")} />
              </div>
            }
            onBack={onClose}
          />
        ) : (
          <div className="pd-8-24 flex-center gap-32 w-100 border-1px-bot-neutral-8">
            <SearchCountryBarPC onInputChange={setKeyword} />
            <div className="flex">
              <TextLinked onClick={resetAll} btnText={t("button.deSelect")} />
              <button type="button" className="btn-40px-end" onClick={onClose}>
                <SvgIcons name="XSvg" color="#11131A" />
              </button>
            </div>
          </div>
        )}
        <SelfScrollDiv className="hidden-scrollbar h-100vh">
          {!pc && (
            <div style={{ padding: "8px 20px" }}>
              <SearchBar onInputChange={setKeyword} />
            </div>
          )}
          <div>
            {!isEmpty(listSelected) && isArray(listSelected) && (
              <Swiper
                className="wishListSlider"
                spaceBetween={16}
                slidesPerView="auto"
                slidesOffsetBefore={!pc ? 20 : 24}
                slidesOffsetAfter={20}>
                {listSelected.map((item, index) => {
                  return (
                    <SwiperSlide
                      style={{
                        width: "auto"
                      }}
                      key={`country_selected_${index}`}>
                      <button
                        type="button"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "4px 8px 4px 12px",
                          borderRadius: "16px",
                          backgroundColor: "var(--neutral-color-8,#F4F4F4)"
                        }}
                        onClick={() => handleSelect(item)}>
                        <img
                          src={item?.fileName || item?.imageUrl}
                          alt=""
                          style={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            objectFit: "cover"
                          }}
                        />
                        <XSvg
                          width={!pc ? undefined : 8.93}
                          height={!pc ? undefined : 8.93}
                          fillColor={
                            !pc ? undefined : "var(--neutral-color-1, #11131a)"
                          }
                        />
                      </button>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
          {!keyword ? (
            <div
              style={{ padding: !pc ? "8px 0 16px 0" : undefined }}
              className="flex-col gap-24">
              {value !== "1B0275B8A7AF4F6BBB2F5F387AC86AD8" &&
                (!pc ? (
                  <CountryItem
                    item={world}
                    key="world"
                    noneBorder
                    active={
                      listSelected.find(
                        (obj) => obj?.mkCountryUid === world?.mkCountryUid
                      ) !== undefined
                    }
                    onClick={() => handleSelect(world)}
                  />
                ) : (
                  <div className="mr-24 ml-24 pt-24">
                    <CountryItem
                      pc={pc}
                      item={world}
                      key="world"
                      noneBorder
                      active={
                        listSelected.find(
                          (obj) => obj?.mkCountryUid === world?.mkCountryUid
                        ) !== undefined
                      }
                      onClick={() => handleSelect(world)}
                    />
                  </div>
                ))}
              <div className="flex-col">
                <SubCategory48px pc={pc}>
                  <h5 className="fw-500">{t("info.popularMarket")}</h5>
                </SubCategory48px>
                <div
                  className={classNames({
                    "grid layout-2-col mr-24 ml-24 grid-col-gap-24 grid-row-gap-4":
                      pc
                  })}>
                  {!isEmpty(listCountry) &&
                    isArray(listCountry) &&
                    listCountry
                      .filter((item) => item?.mainYn === "Y")
                      .map((item, index) => {
                        return (
                          <CountryItem
                            pc={pc}
                            item={item}
                            key={index}
                            active={
                              listSelected.find(
                                (obj) =>
                                  obj?.mkCountryUid === item?.mkCountryUid
                              ) !== undefined
                            }
                            onClick={() => handleSelect(item)}
                          />
                        );
                      })}
                </div>
              </div>
              <SelfScrollDiv>
                <SubCategory48px pc={pc}>
                  <h5 className="fw-500">{t("info.allMarket")}</h5>
                </SubCategory48px>
                <SelfScrollDiv>
                  <div
                    className={classNames({
                      "grid layout-2-col mr-24 ml-24 grid-col-gap-24 grid-row-gap-4":
                        pc
                    })}>
                    {!isEmpty(listCountry) &&
                      isArray(listCountry) &&
                      listCountry
                        // .filter((item) => item?.mainYn !== "Y")
                        .map((item, index) => {
                          return (
                            <CountryItem
                              pc={pc}
                              item={item}
                              key={index}
                              active={
                                listSelected.find(
                                  (obj) =>
                                    obj?.mkCountryUid === item?.mkCountryUid
                                ) !== undefined
                              }
                              onClick={() => handleSelect(item)}
                            />
                          );
                        })}
                  </div>
                </SelfScrollDiv>
              </SelfScrollDiv>
            </div>
          ) : (
            <SelfScrollDiv className="pt-8 pb-16">
              <SubCategory48px pc={pc}>
                <h5 className="fw-500">{t("info.allMarket")}</h5>
              </SubCategory48px>
              <SelfScrollDiv>
                {!isEmpty(listCountry) && isArray(listCountry) ? (
                  <div
                    className={classNames({
                      "grid layout-2-col mr-24 ml-24 grid-col-gap-24 grid-row-gap-4":
                        pc
                    })}>
                    {listCountry
                      // .filter((item) => item?.mainYn !== "Y")
                      .map((item, index) => {
                        return (
                          <CountryItem
                            pc={pc}
                            item={item}
                            key={index}
                            active={
                              listSelected.find(
                                (obj) =>
                                  obj?.mkCountryUid === item?.mkCountryUid
                              ) !== undefined
                            }
                            onClick={() => handleSelect(item)}
                          />
                        );
                      })}
                  </div>
                ) : (
                  <EmptyNotice noticeText={t("notice.noMatchResult")} />
                )}
              </SelfScrollDiv>
            </SelfScrollDiv>
          )}
        </SelfScrollDiv>
        <div className={classNames({ "pb-16": isIOS() })}>
          <FooterResetSubmit
            pc={pc}
            onReset={onReset}
            onCancle={() => {
              setListSelected(listSelectedCountry);
              onClose();
            }}
            onSubmit={() => {
              if (listSelected.length === 0) {
                warningAlert(t("notice.warningAlertCountry"));
              } else {
                setListSelectedCountry(listSelected);
                onClose();
              }
            }}
          />
        </div>
      </ModalStyled>
    </Modal>
  );
}
