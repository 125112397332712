import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Naver() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      window.opener.postMessage(
        {
          source: {
            sns: "naver",
            type: new URLSearchParams(window.location.hash.substr(1)).get(
              "state"
            )
          },
          access_token: new URLSearchParams(window.location.hash.substr(1)).get(
            "access_token"
          )
        },
        "*"
      );
      window.close();
    }
  }, [location]);

  return null;
}

export default Naver;
