export default function ChangeSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1997 5.91545C15.5863 5.91545 15.8997 6.22885 15.8997 6.61545V20.1628L19.4772 16.1497C19.7344 15.8611 20.1769 15.8357 20.4655 16.0929C20.7541 16.3502 20.7795 16.7927 20.5222 17.0812L15.7222 22.4659C15.529 22.6827 15.2219 22.7576 14.9505 22.6542C14.6791 22.5508 14.4997 22.2905 14.4997 22.0001V6.61545C14.4997 6.22885 14.8131 5.91545 15.1997 5.91545Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79981 18.0847C8.41321 18.0847 8.09981 17.7713 8.09981 17.3847L8.09981 3.8373L4.52234 7.85049C4.26509 8.13907 3.8226 8.16447 3.53402 7.90722C3.24543 7.64997 3.22003 7.20748 3.47728 6.9189L8.27729 1.53428C8.47055 1.31748 8.77763 1.24254 9.04904 1.34595C9.32045 1.44936 9.49981 1.70963 9.49981 2.00008L9.49981 17.3847C9.49981 17.7713 9.18641 18.0847 8.79981 18.0847Z"
        fill={fillColor}
      />
    </svg>
  );
}
