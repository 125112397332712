/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
import moment from "moment";
import JSSoup from "jssoup";
import { TYPE_ALARM } from "../contexts/Constants";

export const getUrlVars = (url) => {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
};

export const getPgUrl = (name) => {
  const url = window.location.href;
  const trdPrnrCd = getUrlVars(url)[name] || "";
  return trdPrnrCd;
};

export const setDataAfterLogin = (user, token, refreshToken) => {
  window.localStorage.setItem("user", JSON.stringify(user));
  window.localStorage.setItem("token", token);
  window.localStorage.setItem("refresh_token", refreshToken);
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("LoginListener", user.mail);
  }
};

export function relativeURLtoAbsoluteURL(relativePath = "") {
  if (typeof relativePath !== "string") return "";
  const anchor = document.createElement("a");
  anchor.href = relativePath;
  return anchor.href;
}
export const newTime = (time) =>
  new Date(time).toISOString().slice(0, 19).replace("T", " ");

export const getUserProfile = () => {
  try {
    const userProfile = JSON.parse(localStorage.getItem("user"));
    return userProfile || {};
  } catch {
    return {};
  }
};

export const updateUserProfile = (keyValue) => {
  let userProfile = JSON.parse(localStorage.getItem("user"));
  // fix case when logout but async is not finish
  if (userProfile) {
    userProfile = { ...userProfile, ...keyValue };
    window.localStorage.setItem("user", JSON.stringify(userProfile));
  }
};

export const formatDate = (date, format = "YYYY.MM.DD") => {
  const day = new Date(date);
  return moment(day).format(format);
};

export const datesOfWeek = () => {
  const week = [];
  const current = new Date();
  const day = current.getDay();
  // Starting Monday not Sunday
  current.setDate(current.getDate() - day + (day === 0 ? -6 : 1));
  for (let i = 0; i < 7; i++) {
    week.push(moment(new Date(current)).format("YYYY.MM.DD"));
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  );

export const handlePostCalender = (posts = []) => {
  const newPosts = posts.flatMap((item) => {
    if (!Number.isNaN(item.subTitles)) {
      return {
        ...item,
        subTitles: moment(new Date(item.subTitles)).format("YYYY.MM.DD")
      };
    }
    return [];
  });
  return newPosts.length ? groupBy(newPosts, "subTitles") : {};
};

export const handleCalender = (defaultCalender = [], week = []) => {
  return defaultCalender.map((item, index) => ({
    ...item,
    date: week[index].slice(-2),
    day: week[index]
  }));
};

// export function createNestedComments(
//   comments,
//   idCommentParent = null,
//   level = 0
// ) {
//   const result = [];

//   for (const comment of comments) {
//     if (comment.idCommentParent === idCommentParent) {
//       const commentLevel = level + 1;
//       const children = createNestedComments(comments, comment.id);
//       if (children.length > 0) {
//         comment.children = children;
//       }
//       result.push(comment);
//     }
//   }

//   return result;
// }

export function createNestedComments(
  comments,
  idCommentParent = null,
  level = 0
) {
  const nestedComments = [];

  for (const comment of comments) {
    if (comment.idCommentParent === idCommentParent) {
      // Calculate the comment's level based on its parent's level
      const commentLevel = level + 1;

      // Create a new object with the comment data and level
      const nestedComment = {
        ...comment,
        level: commentLevel,
        children: createNestedComments(comments, comment.id, commentLevel)
      };
      nestedComments.push(nestedComment);
    }
  }

  return nestedComments;
}

export function sortCommentsByTime(comments) {
  comments.sort((a, b) => a.createDate - b.createDate);
  comments.forEach((comment) => {
    if (comment.children) {
      sortCommentsByTime(comment.children);
    }
  });
  return comments;
}

export function postIsNew(time, diff = 48) {
  const duration = moment().diff(new Date(time), "hours");
  return duration < diff;
}

export function splitString(string, limit = 50) {
  return string && string.length > limit
    ? `${string.slice(0, limit)} ...`
    : string || "";
}

export function splitNickname(string, limit = 8, isWidth320px = false) {
  return string && string.length > limit && isWidth320px
    ? `${string.slice(0, limit)}...`
    : string || "";
}

export function formatDurationComm(duration) {
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  const hours = parseInt(match[1]) || 0;
  const minutes = parseInt(match[2]) || 0;
  const seconds = parseInt(match[3]) || 0;
  return `${hours ? `${hours}:` : ""}${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
}

export const setPreviousUrl = () => {
  const currentUrl =
    window.location.pathname + window.location.search + window.location.hash;
  window.localStorage.setItem("previousUrl", currentUrl);
};

export const getPreviousUrl = () => {
  const url = window.localStorage.getItem("previousUrl");
  return url;
};

export const trimString = (string, length) => {
  return string.length > length ? `${string.substring(0, length)}...` : string;
};

export const getThumbnail = (detail, fileUrls) => {
  const content = new JSSoup(detail);
  const imgArrContent = content.findAll("img");
  const imgContent = imgArrContent.length > 0 ? imgArrContent[0].attrs.src : "";
  let imgArrFile = fileUrls || [];
  imgArrFile = imgArrFile
    .map((item) => item.url)
    .filter(
      (filename) =>
        filename.endsWith(".png") ||
        filename.endsWith(".jpg") ||
        filename.endsWith(".jpeg") ||
        filename.endsWith(".gif")
    );

  const imgFile = imgArrFile.length > 0 ? imgArrFile?.[0] : "";
  return imgContent || imgFile || "";
};

export const getImageArr = (detail, fileUrls) => {
  const content = new JSSoup(detail);
  const imgArrContent = content.findAll("img");
  let imgArrFile = fileUrls || [];
  imgArrFile = imgArrFile
    .map((item) => item.url)
    .filter(
      (filename) =>
        filename.endsWith(".png") ||
        filename.endsWith(".jpg") ||
        filename.endsWith(".jpeg") ||
        filename.endsWith(".gif")
    );
  return imgArrContent.length ? imgArrContent : imgArrFile || [];
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0B";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const checkIsNumber = (number) => {
  return typeof number === "number" && !Number.isNaN(number);
};

export const numByteFromContent = (content) => {
  return new TextEncoder()?.encode(content)?.length || 0;
};

export const isExceedNumBytes = (content, numBytes = 4000) => {
  return (new TextEncoder()?.encode(content)?.length || 0) > numBytes;
};

export const isNumBytes = (content, numBytes = 4000) => {
  return (new TextEncoder()?.encode(content)?.length || 0) === numBytes;
};

export const isImage = (fileName) =>
  fileName.endsWith(".png") ||
  fileName.endsWith(".jpg") ||
  fileName.endsWith(".jpeg") ||
  fileName.endsWith(".gif") ||
  false;

export const formatHashtags = (hashtags) => {
  if (!hashtags) {
    return "";
  }

  const hashtagArr = hashtags.trim().split("#").filter(Boolean);
  const formattedHashtags = hashtagArr
    .map((hashtag) => `#${hashtag.trim()}`)
    .join(" ");

  return formattedHashtags;
};

export const handleText = (str, start, end) => {
  const first = str.substring(0, start); // 41
  const last = str.substring(str.length - end); // 35
  const middle = "...";
  const shortened = first + middle + last;
  return shortened;
};

// Remove HTML tags from content and return length
export const removeHtmlTagsLength = (content) => {
  if (content?.includes("img") || content?.includes("iframe")) {
    return -1;
  }
  const div = document.createElement("div");
  div.innerHTML = content;
  const plainText = div.textContent || div.innerText || "";

  // eslint-disable-next-line prefer-destructuring
  const length = plainText.length;

  return length;
};

export function validateNickName(content) {
  const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  const englishRegex = /[a-zA-Z]/g;

  const koreanCount = (content.match(koreanRegex) || []).length;
  const englishCount = (content.match(englishRegex) || []).length;

  return koreanCount >= 2 || englishCount >= 4;
}

export function checkNickName(content) {
  // const regex = /^[a-zA-Z0-9가-힣]{4,}$/;
  const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]{4,}$/;
  return regex.test(content);
}

export function replaceTagPBR(content) {
  const pattern = /(<p><br><\/p>\s*)+(?!.*<p><br><\/p>\s*)/;
  const modifiedString = content.replace(pattern, "");

  return modifiedString;
}

export function checkDevice() {
  const { userAgent } = navigator;
  if (/Android/i.test(userAgent)) {
    // Android devices
    return "AD";
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    // iOS devices
    return "IOS";
  }
  return "WD";
}

export function checkVideoLink(url) {
  const videoRegex =
    /(youtube\.com\/embed\/|youtu\.be\/|vimeo\.com\/|dailymotion\.com\/video\/|facebook\.com\/video\/)/;
  return videoRegex.test(url);
}

export function getTypeAlarm(alarm) {
  const typeAlarm =
    alarm.typeNotice === "PUSH_ADMIN"
      ? alarm.title
      : TYPE_ALARM[alarm.typeNotice];
  return typeAlarm;
}

export function converTime(d) {
  d = d.search(/PT/i) > -1 ? d.slice(2) : d;
  let m;
  let s;
  const hIndex = d.search(/h/i);
  const mIndex = d.search(/m/i);
  const sIndex = d.search(/s/i);
  const dContainsH = hIndex > -1;
  const dContainsM = mIndex > -1;
  const dContainsS = sIndex > -1;
  const h = dContainsH ? `${d.slice(0, hIndex)}:` : "";
  m = dContainsM
    ? d.slice(dContainsH ? hIndex + 1 : 0, mIndex)
    : dContainsH
    ? "0"
    : "0";
  s = dContainsS ? d.slice(dContainsM ? mIndex + 1 : hIndex + 1, sIndex) : "0";
  s = (dContainsM || dContainsS) && s < 10 ? `0${s}` : s;
  m = (dContainsH || dContainsM) && m < 10 ? `0${m}:` : `${m}:`;
  return d !== "0S" ? h + m + s : "LIVE";
}

export const sumTotalPostFromBoard = (listBoard) => {
  return listBoard.reduce(
    (preValue, curValue) => preValue + curValue.totalPost,
    0
  );
};
