/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import NewsItem from "components/NewsItem";
import { MorePagging } from "components/buttons";
import { CountryName } from "components/countryName";
import EmptyNotice from "components/emptyNotice";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { searchNews } from "services/NewsAnalysisService";

export default function News({ keywords }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listNew, setListNew] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const setIsLoading = (value) => dispatch(commonActions.setLoading(value));
  const isLoading = useSelector((state) => state?.common.loading);

  useEffect(() => {
    getNewSearch();
  }, [currentPage, keywords]);

  const getNewSearch = async () => {
    try {
      setIsLoading(true);
      const res = await searchNews({
        currentPage,
        pageSize: 20,
        keyWord: keywords,
        idBulltinBoard: "2115917b36138454e2b9e72298d975d2b2a"
      });
      if (res.code === "200") {
        const listData = res?.data?.list || [];
        setListNew(currentPage === 1 ? listData : [...listNew, ...listData]);
        setTotalItems(res?.data?.pagination?.totalItems);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const onClose = () =>
    dispatch(commonActions.onCloseModalComprehensiveSearch());

  const onClickViewMore = () => setCurrentPage((e) => e + 1);

  return (
    <div className="pt-8 pl-20 pr-20 pb-40 full-height flow-y-auto flex-col flex-1 flex-start hidden-scrollbar">
      {
        isArray(listNew) && !isEmpty(listNew) ? (
          <>
            <CountryName name={t("info.popularSearch")} />
            <div className="flex-col">
              {listNew?.map((item, index) => (
                <NewsItem
                  item={item}
                  key={`News_item_${index}`}
                  index={index + 1}
                  total={listNew?.length}
                  onClose={onClose}
                />
              ))}
            </div>
          </>
        ) : (
          !isLoading && (
            <EmptyNotice noticeText={t("notice.noMatchResult")} borderTop />
          )
        ) // add empty notice here
      }
      {isLoading ? null : (
        <MorePagging
          status={listNew?.length}
          limit={totalItems || 0}
          onClick={onClickViewMore}
        />
      )}
    </div>
  );
}
