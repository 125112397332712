import { CheckboxCheckedBlueSvg } from "components/icons";
import { memo } from "react";
import { useTranslation } from "react-i18next";

function CountryItem({ item, active, onClick, noneBorder, pc }) {
  const isImage =
    item?.imageUrl ||
    item?.fileName ||
    item?.baseFileName ||
    item?.filePath ||
    item?.countryFileName;
  const { i18n } = useTranslation();

  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: !pc ? "8px 20px" : "0 12px",
        borderBottom:
          noneBorder || pc ? "" : "1px solid var(--neutral-color-8, #F4F4F4)",
        backgroundColor: active ? "#5E86F40F" : ""
      }}>
      <div style={{ display: "flex", gap: "12px" }}>
        {isImage ? (
          <img
            src={`${isImage}`}
            className="block-24px img-cover border-rounded img-box-shadow"
            alt=""
          />
        ) : null}

        <h4 className="text-align-left">
          {i18n.language === "ko"
            ? item?.countryLabelKor ||
              item?.labelKor ||
              item?.countryLabelEng ||
              item?.labelEng
            : item?.countryLabelEng ||
              item?.labelEng ||
              item?.countryLabelKor ||
              item?.labelKor}
        </h4>
      </div>

      <div
        style={{
          padding: "8px 0 8px 16px",
          width: "40px",
          height: "40px"
        }}>
        {active ? <CheckboxCheckedBlueSvg /> : " "}
      </div>
    </button>
  );
}
export default memo(CountryItem);
