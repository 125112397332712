import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModalLogin: false,
  openModalRegister: false,
  openModalForgetPasswords: false,
  alertSuccessContinue: null,
  userName: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onOpenModalLogin: (state) => {
      state.openModalLogin = true;
    },
    onCloseModalLogin: (state) => {
      state.openModalLogin = false;
    },
    onOpenModalRegister: (state) => {
      state.openModalRegister = true;
    },
    onCloseModalRegister: (state) => {
      state.openModalRegister = false;
    },
    onOpenModalForgetPasswords: (state) => {
      state.openModalForgetPasswords = true;
    },
    onCloseModalForgetPasswords: (state) => {
      state.openModalForgetPasswords = false;
    },
    onRegisterSuccess: (state, action) => {
      state.alertSuccessContinue = action.payload;
    },
    onCloseModalSuccess: (state) => {
      state.alertSuccessContinue = null;
    },
    onUpdateUserName: (state, action) => {
      state.userName = action.payload;
    },
    onClearUserName: (state) => {
      state.userName = null;
    }
  }
});

// Actions
export const authActions = authSlice.actions;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
