/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { successAlert } from "components/alert";
import Flag from "components/flag";
import { StarFillSvg, StarPlusSvg } from "components/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { deleteInterest } from "services/InterestService";
import { ContentItem } from "./styled";

export function SearchItem({ item, onClick, PC = false }) {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const handleAddtoFavorite = () => {
    const token = localStorage.getItem("token");
    if (token) dispatch(commonActions.onAddInterest(item));
    else dispatch(commonActions.onOpenModalRequireLogin());
  };

  const handleDeleteFromFavorite = async () => {
    try {
      const res = await deleteInterest(item?.mkMyInterestDetailUid);
      if (res && res.code === "200") {
        successAlert(t("notice.deleteSuccess"));
        dispatch(
          portfolioActions.setLastModified(res?.data?.mkMyInterestMasterUid)
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ContentItem isPC={PC}>
      <div className="left" onClick={onClick}>
        <Flag item={item} />
        <div className="label">
          <h4 className="lh-16">
            {i18n.language === "ko"
              ? item?.labelKor || item?.labelEng
              : item?.labelEng || item?.labelKor}
          </h4>
          <h6 className="fw-500 lh-14 color-info">
            {item?.symbolType === "FRX"
              ? `${item?.symbol || item?.labelEng} | ${
                  i18n.language === "ko"
                    ? item?.codeLabelKor || item?.codeLabelEng
                    : item?.codeLabelEng || item?.codeLabelKor
                }${item?.exchange && ` - ${item?.exchange}`}`
              : item?.symbolType === "CRP"
              ? `${item?.symbol || item?.baseCurrency} | ${
                  i18n.language === "ko"
                    ? item?.codeLabelKor || item?.codeLabelEng
                    : item?.codeLabelEng || item?.codeLabelKor
                }${item?.exchange && ` - ${item?.exchange}`}`
              : item?.symbolType === "STK"
              ? item?.uidCode || item?.symbol || item?.labelEng // uidCode = symbol as value, some old api still using uidCode
              : item?.symbol || item?.labelEng}
          </h6>
        </div>
      </div>
      <div className="right">
        {item?.interestYn === "N" ? (
          <button type="button" onClick={handleAddtoFavorite}>
            <StarPlusSvg />
          </button>
        ) : (
          <button type="button" onClick={handleDeleteFromFavorite}>
            <StarFillSvg />
          </button>
        )}
      </div>
    </ContentItem>
  );
}
