export default function Calendar3Svg({ fillColor = "#11131A", width, height }) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9998 3.70005C4.28183 3.70005 3.6998 4.28208 3.6998 5.00005V19C3.6998 19.718 4.28183 20.3 4.9998 20.3H18.9998C19.7178 20.3 20.2998 19.718 20.2998 19V5.00005C20.2998 4.28208 19.7178 3.70005 18.9998 3.70005H4.9998ZM2.2998 5.00005C2.2998 3.50888 3.50864 2.30005 4.9998 2.30005H18.9998C20.491 2.30005 21.6998 3.50888 21.6998 5.00005V19C21.6998 20.4912 20.491 21.7001 18.9998 21.7001H4.9998C3.50864 21.7001 2.2998 20.4912 2.2998 19V5.00005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9998 1.30005C16.3864 1.30005 16.6998 1.61345 16.6998 2.00005V6.00005C16.6998 6.38665 16.3864 6.70005 15.9998 6.70005C15.6132 6.70005 15.2998 6.38665 15.2998 6.00005V2.00005C15.2998 1.61345 15.6132 1.30005 15.9998 1.30005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9998 1.30005C8.3864 1.30005 8.6998 1.61345 8.6998 2.00005V6.00005C8.6998 6.38665 8.3864 6.70005 7.9998 6.70005C7.61321 6.70005 7.2998 6.38665 7.2998 6.00005V2.00005C7.2998 1.61345 7.61321 1.30005 7.9998 1.30005Z"
        fill={fillColor}
      />
    </svg>
  );
}
