export default function RefreshSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666665 1.2C0.924398 1.2 1.13333 1.40893 1.13333 1.66667V5.2H4.66666C4.9244 5.2 5.13333 5.40893 5.13333 5.66667C5.13333 5.9244 4.9244 6.13333 4.66666 6.13333H0.666665C0.408932 6.13333 0.199998 5.9244 0.199998 5.66667V1.66667C0.199998 1.40893 0.408932 1.2 0.666665 1.2Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8667 8.33333C10.8667 8.0756 11.0756 7.86667 11.3333 7.86667H15.3333C15.5911 7.86667 15.8 8.0756 15.8 8.33333V12.3333C15.8 12.5911 15.5911 12.8 15.3333 12.8C15.0756 12.8 14.8667 12.5911 14.8667 12.3333V8.8H11.3333C11.0756 8.8 10.8667 8.59107 10.8667 8.33333Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20687 0.789559C7.25622 0.486033 8.36536 0.453276 9.43079 0.694344C10.4962 0.935412 11.4832 1.44245 12.2997 2.16814C13.1162 2.89383 13.7355 3.81453 14.0999 4.84432C14.1859 5.08729 14.0586 5.35395 13.8157 5.43993C13.5727 5.52591 13.306 5.39865 13.2201 5.15568C12.9082 4.27452 12.3783 3.4867 11.6797 2.86575C10.981 2.2448 10.1365 1.81094 9.22482 1.60467C8.31316 1.39839 7.3641 1.42642 6.46621 1.68614C5.56831 1.94586 4.75083 2.4288 4.09006 3.0899C4.08662 3.09335 4.08311 3.09674 4.07956 3.10008L0.986227 6.00675C0.798404 6.18324 0.50307 6.17405 0.32658 5.98623C0.150091 5.7984 0.159279 5.50307 0.347103 5.32658L3.4352 2.42483C4.2065 1.65492 5.1599 1.09239 6.20687 0.789559ZM15.6734 8.01377C15.8499 8.20159 15.8407 8.49693 15.6529 8.67342L12.5648 11.5752C11.7935 12.3451 10.8401 12.9076 9.79313 13.2104C8.74378 13.514 7.63464 13.5467 6.5692 13.3057C5.50377 13.0646 4.51676 12.5576 3.70029 11.8319C2.88381 11.1062 2.26447 10.1855 1.90006 9.15568C1.81409 8.91271 1.94135 8.64604 2.18432 8.56007C2.42729 8.47409 2.69395 8.60135 2.77993 8.84432C3.09175 9.72548 3.6217 10.5133 4.32033 11.1342C5.01897 11.7552 5.86351 12.1891 6.77517 12.3953C7.68684 12.6016 8.63589 12.5736 9.53379 12.3139C10.4317 12.0541 11.2492 11.5712 11.9099 10.9101C11.9134 10.9067 11.9169 10.9033 11.9204 10.8999L15.0138 7.99325C15.2016 7.81676 15.4969 7.82595 15.6734 8.01377Z"
        fill={fillColor}
      />
    </svg>
  );
}
