/* eslint-disable no-unused-vars */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getListInterest = (params) => {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return newAxiosInstance.get(URL.INTEREST + qs.stringify(validParams, true));
};

export const addInterest = (body) => newAxiosInstance.post(URL.INTEREST, body);

export const getMyPopularSearch = (params) =>
  newAxiosInstance.get(URL.GET_POPULAR_SEARCH + qs.stringify(params, true));

export const getSearchResults = (params) =>
  newAxiosInstance.get(URL.GET_SEARCH + qs.stringify(params, true));

export const getCategoryList = (params) =>
  newAxiosInstance.get(URL.GET_CATEGORY_LIST + qs.stringify(params, true));
export const deleteInterest = (id) =>
  newAxiosInstance.delete(`${URL.INTEREST}/${id}`);

export const getChart = (params) =>
  newAxiosInstance.get(URL.GET_CHART + qs.stringify(params, true));
export const getDetail = (params) =>
  newAxiosInstance.get(URL.GET_DETAIL + qs.stringify(params, true));
