const URL = Object.freeze({
  GET_CRYPTO_ALL: "/api/user/crypto/all",
  GET_CRYPTO_REALTIME: "/api/user/crypto/realtime",
  GET_CRYPTO_MAIN: "/api/user/crypto/main",
  GET_CURRENCY_LIST: "/api/user/calcu/currency/list",
  GET_EXCHANGE_LIST: "/api/user/forex/exchangeRateTableSymbol",
  GET_CALCULATOR_RESULT: "/api/user/calcu/result",
  // indices
  GET_INDICES_MAIN: "/api/user/indices/main",
  GET_INDICES_ALL: "/api/user/indices/all",
  GET_INDICES_REALTIME_MAIN: "/api/user/indices/realtime-main",
  GET_INDICES_REALTIME: "/api/user/indices/realtime",

  // etf
  GET_ETF_MAIN: "/api/user/etf/main",
  GET_ETF_ALL: "/api/user/etf/all",
  GET_ETF_REALTIME_MAIN: "/api/user/etf/realtime-main",
  GET_ETF_REALTIME: "/api/user/etf/realtime",

  // future
  GET_FUTURE_MAIN: "/api/user/futures/main",
  GET_FUTURE_ALL: "/api/user/futures/all",
  GET_FUTURE_REALTIME_MAIN: "/api/user/futures/realtime-main",
  GET_FUTURE_REALTIME: "/api/user/futures/realtime",

  // stock
  GET_STOCK_INDICES_GROUP: "/api/user/stock/indices-group",
  GET_STOCK_INDICES: "/api/user/stock/indices",
  GET_STOCK_REALTIME: "/api/user/stock/realtime",
  GET_STOCK_UNDERVALUE: "/api/user/stock/underValue",
  GET_STOCK_THEMA: "/api/user/stock/thema",
  GET_STOCK_THEMA_STOCK: "/api/user/stock/thema/stock",
  GET_STOCK_INVESTOR: "/api/user/stock/investor",
  GET_STOCK_SECTOR: "/api/user/stock/sector",
  GET_STOCK_SECTOR_DETAIL: "/api/user/stock/sector/detail",
  GET_STOCK_SECTOR_STOCK: "/api/user/stock/sector/stock",
  GET_STOCK_FAIR: "/api/user/stock/fair-value/",
  GET_SUMMARY_STOCK: "/api/user/stock/summary/",
  GET_STOCK_ABOUT: "/api/user/stock/about/",
  GET_STOCK_DIVIDEND: "/api/user/stock/dividend",
  GET_STOCK_INCOME: "/api/user/stock/income-statement/summary",
  GET_STOCK_BALANCE: "/api/user/stock/balance-sheet/summary",
  GET_STOCK_CASHFLOW: "/api/user/stock/cash-flow/summary",
  GET_STOCK_EARNING: "/api/user/stock/earning",
  GET_EXCHANGE_SYMBOL: "/api/user/crypto/exchangeSymbol",

  // stock pc

  GET_STOCK_PC_REALTIME: "/api/user/stock/pc/realtime",
  // PC api

  // stock PC

  GET_STOCK_INVESTOR_LIST: "/api/user/stock/pc/investor", // PC-03-06
  GET_INVESTOR_TREND: "/api/user/stock/pc/investor/trend", // PC-03-06-01
  GET_DAILY_NET_VALUE: "/api/user/stock/pc/investor/daily/bid", // PC-03-06-02
  GET_SECTOR_STATUS: "/api/user/stock/pc/sector", // PC-03-07
  GET_SECTOR_DETAIL: "/api/user/stock/pc/sector/detail", // PC-03-08
  GET_MAIN_STOCKS_BY_SECTOR: "/api/user/stock/pc/sector/stock", // PC-03-09
  GET_STOCK_PC_CAPACITYVALUE: "/api/user/stock/pc/capacity-value", // PC-03-10
  GET_INVESTOR_WARNING: "/api/user/stock/pc/investor/warning", // PC-03-53
  GET_RECENT_LIST: "api/user/thema/thema-recent-list", // 15-07,
  GET_POPULAR_LIST: "api/user/thema/thema-popular-list", // 15-08
  GET_SEARCH_THEMA_RESULT: "api/user/thema/search-thema", // 15-05
  GET_SEARCH_THEMA_SYMBOL: "api/user/thema/search-thema-symbol" // 15-06
});
export default URL;
