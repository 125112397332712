import { InputAdornment } from "@mui/material";
import classNames from "classnames";
import SvgIcons from "components/svgIcons";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { postActions } from "redux/reducers/postReducer";
import { StyledTextField } from "./styled";

export default function SearchBarPC({ placeholder, onSearch, currentValue }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(currentValue || "");
  const onEnter = (event) => {
    if (event.key === "Enter") {
      onSearch(inputValue);
      dispatch(postActions.onCloseModalThemaSearch());
      event.preventDefault();
    }
  };

  return (
    <StyledTextField
      variant="outlined"
      placeholder={placeholder}
      value={inputValue}
      onKeyDown={onEnter}
      onChange={(event) => setInputValue(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="center">
            <button
              aria-label="Clear search input"
              type="button"
              className={classNames("btn-40px-end", {
                hidden: isEmpty(inputValue)
              })}
              onClick={() => setInputValue("")}>
              <SvgIcons name="close" />
            </button>
            <button
              type="button"
              aria-label="Search"
              className={classNames("btn-40px-end", {
                "svg-sell": !isEmpty(inputValue),
                "svg-neutral-5": isEmpty(inputValue)
              })}
              onClick={() => onSearch(inputValue)}>
              <SvgIcons name="SearchSvg" />
            </button>
          </InputAdornment>
        )
      }}
    />
  );
}
SearchBarPC.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  currentValue: PropTypes.string
};

SearchBarPC.defaultProps = {
  currentValue: ""
};
