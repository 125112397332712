/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
import { AppBar, List, ListItemButton, Toolbar } from "@mui/material";

import classNames from "classnames";
import { successAlert } from "components/alert";
import { GoToPage15px } from "components/buttons";
import { CheckboxCheckedBlueSvg, CheckboxUncheckedSvg } from "components/icons";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { getMyListPortfolio, updatePortfolio } from "services/PofolioService";
import { isIOS } from "util/CommonUtil";
import {
  Button40,
  ButtonSubmit,
  Drawer,
  FormBtnSubmit,
  ListItemText,
  Puller
} from "./styled";

function ModalChangeDefaultPortfolio() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listPortfolio = useSelector((state) => state.portfolio.listPortfolio);
  const currentActive = useSelector((state) => state.portfolio.currentActive);
  const openModalChangeDefault = useSelector(
    (state) => state.portfolio.openModalChangeDefault
  );
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (currentActive) setSelected(currentActive);
  }, [currentActive]);

  useEffect(() => {
    if (openModalChangeDefault) getListPortfolio();
  }, [openModalChangeDefault]);

  const getListPortfolio = async () => {
    try {
      setLoading(true);
      const res = await getMyListPortfolio({
        currentPage: 1,
        pageSize: 10
      });
      if (res.code === "200") {
        dispatch(portfolioActions.setListPortfolio(res?.data?.list || []));
        if (!currentActive?.mkMyInterestMasterUid)
          dispatch(
            portfolioActions.updateCurrentPortfolio(
              res?.data?.list?.filter(
                (portfolio) => portfolio?.defaultYn === "Y"
              )[0]
            )
          );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  const onClose = () => {
    dispatch(portfolioActions.onCloseModalChangeDefault());
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const bodyUpdate = {
        mkMyInterestMasterUid: selected?.mkMyInterestMasterUid,
        interestName: selected?.interestName,
        defaultYn: "Y"
      };
      const res = await updatePortfolio(bodyUpdate);
      if (res.code === "200") {
        successAlert(t("notice.removeInterest"));
        dispatch(portfolioActions.updateCurrentPortfolio(bodyUpdate));
        const ress = await getMyListPortfolio({
          currentPage: 1,
          pageSize: 10
        });
        if (ress.code === "200")
          dispatch(portfolioActions.setListPortfolio(ress?.data?.list || []));
        dispatch(portfolioActions.onCloseModalChangeDefault());
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Drawer
      onOpen={() => {}}
      anchor="bottom"
      open={openModalChangeDefault}
      onClose={onClose}
      sx={{
        display: openModalChangeDefault ? "block" : "none",
        "& .MuiPaper-root": {
          borderRadius: "16px 16px 0 0"
        }
      }}>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar
          sx={{
            justifyContent: "flex-start",
            padding: "8px 24px",
            gap: "12px"
          }}>
          <div className="flex-start-center gap-12">
            <h2>{t("info.portfolio")}</h2>
            <div className="flex-centered gap-2 fit-height">
              <p className="fw-400 font-2 color-info fs-14 lh-18">
                {listPortfolio?.length || 0}
              </p>
              <p className="fw-400 font-2 color-info fs-12 lh-18 pb-2">/</p>
              <p className="fw-400 font-2 color-info fs-14 lh-18">10</p>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <List
        style={{
          maxHeight: "60vh",
          overflow: "auto",
          padding: "8px 0"
        }}>
        <div style={{ maxHeight: "60vh" }}>
          {listPortfolio?.map((item, index) => (
            <ListItemButton
              onClick={() => setSelected(item)}
              sx={{
                justifyContent: "space-between",
                padding: "16px 24px"
              }}
              key={index}>
              <ListItemText>
                <div className="portfolio_title">
                  {item?.defaultYn === "Y" ? (
                    <GoToPage15px
                      btnText={item?.interestName}
                      color="var(--neutral-color-1, #11131A)"
                      bold
                      style={{ overflow: "hidden", width: "calc(100% - 16px)" }}
                    />
                  ) : (
                    <GoToPage15px
                      btnText={item?.interestName}
                      color="var(--neutral-color-1, #11131A)"
                    />
                  )}
                </div>
                <div className="interest_status">
                  {item?.defaultYn === "Y" && (
                    <h5
                      style={{ color: "var(--primary-color-1)" }}
                      className="lh-14 fw-700">
                      {t("info.myDefaultPortfolio")}
                    </h5>
                  )}
                  <h5
                    style={{ color: "var(--neutral-color-4)" }}
                    className="lh-14 fw-500">
                    {t("info.countInterest", { interest: item.rows })}
                  </h5>
                </div>
              </ListItemText>
              <Button40>
                {selected?.mkMyInterestMasterUid ===
                item?.mkMyInterestMasterUid ? (
                  <CheckboxCheckedBlueSvg />
                ) : (
                  <CheckboxUncheckedSvg
                    fillColor="var( --neutral-color-7)"
                    width={24}
                    height={24}
                  />
                )}
              </Button40>
            </ListItemButton>
          ))}
        </div>
      </List>
      <div className={classNames({ "pb-16": isIOS() })}>
        <FormBtnSubmit>
          <ButtonSubmit submitType="cancel" onClick={onClose}>
            {t("button.close")}
          </ButtonSubmit>
          <ButtonSubmit submitType="save" onClick={onSave}>
            {t("button.save")}
          </ButtonSubmit>
        </FormBtnSubmit>
      </div>
    </Drawer>
  );
}

export default memo(ModalChangeDefaultPortfolio);
