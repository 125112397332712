export default function ShareSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.214 7.4349C15.8923 8.2103 16.889 8.70005 18 8.70005C20.0435 8.70005 21.7 7.0435 21.7 5.00005C21.7 2.9566 20.0435 1.30005 18 1.30005C15.9566 1.30005 14.3 2.9566 14.3 5.00005C14.3 5.42973 14.3733 5.84231 14.508 6.22598L8.78604 9.56517C8.10782 8.78979 7.11113 8.30005 6.00005 8.30005C3.9566 8.30005 2.30005 9.9566 2.30005 12C2.30005 14.0435 3.9566 15.7 6.00005 15.7C7.11127 15.7 8.10808 15.2102 8.78631 14.4346L14.5095 17.7697C14.3739 18.1546 14.3 18.5687 14.3 19C14.3 21.0435 15.9566 22.7 18 22.7C20.0435 22.7 21.7 21.0435 21.7 19C21.7 16.9566 20.0435 15.3 18 15.3C16.8907 15.3 15.8953 15.7883 15.2171 16.5617L9.49224 13.2256C9.62685 12.8421 9.70005 12.4296 9.70005 12C9.70005 11.5704 9.6268 11.1578 9.49211 10.7741L15.214 7.4349ZM18 2.70005C16.7298 2.70005 15.7 3.72979 15.7 5.00005C15.7 5.40449 15.8044 5.78455 15.9877 6.11475C15.9971 6.12848 16.0061 6.14265 16.0146 6.15723C16.023 6.1717 16.0309 6.18634 16.0382 6.20113C16.4426 6.86033 17.17 7.30005 18 7.30005C19.2703 7.30005 20.3 6.2703 20.3 5.00005C20.3 3.72979 19.2703 2.70005 18 2.70005ZM7.96149 10.7983C7.96888 10.8133 7.97685 10.8282 7.98543 10.8429C7.99408 10.8577 8.00319 10.8721 8.01273 10.886C8.1958 11.2161 8.30005 11.5959 8.30005 12C8.30005 12.4042 8.1958 12.784 8.01272 13.1141C8.0031 13.1281 7.99392 13.1427 7.9852 13.1576C7.97671 13.1722 7.96881 13.1869 7.96149 13.2018C7.55696 13.8606 6.82982 14.3 6.00005 14.3C4.72979 14.3 3.70005 13.2703 3.70005 12C3.70005 10.7298 4.72979 9.70005 6.00005 9.70005C6.82982 9.70005 7.55697 10.1395 7.96149 10.7983ZM15.7 19C15.7 18.6151 15.7946 18.2523 15.9618 17.9335C15.9848 17.9053 16.0059 17.8749 16.0248 17.8425C16.0431 17.8111 16.0587 17.7789 16.0716 17.7462C16.4819 17.1164 17.1924 16.7 18 16.7C19.2703 16.7 20.3 17.7298 20.3 19C20.3 20.2703 19.2703 21.3 18 21.3C16.7298 21.3 15.7 20.2703 15.7 19Z"
        fill={fillColor}
      />
    </svg>
  );
}
