import { ChevronDownSvg, ChevronRightSvg, PlusSvg } from "components/icons";

import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { memo } from "react";
import { idRouter } from "routes/idRouter";
import SvgIcons from "components/svgIcons";
import { localeAndFixed } from "util/common";

const StyledComponent = styled.button`
  display: flex;
  align-items: center;
`;
const GoToPageStyled = styled(StyledComponent)`
  width: fit-content;
  column-gap: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .label-interest-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const TextAddStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export function GoToPage14px({ btnText, onClick, color, bold }) {
  return (
    <GoToPageStyled onClick={onClick}>
      <h5
        className={bold ? "fw-700" : "fw-500"}
        style={{ color: color || "var(--accent-neutral, #45495a)" }}>
        {btnText}
      </h5>
      <ChevronRightSvg fillColor={color || "var(--accent-neutral, #45495a)"} />
    </GoToPageStyled>
  );
}

export function GoToPage15px({ btnText, onClick, color, bold }) {
  return (
    <GoToPageStyled onClick={onClick}>
      <h4
        className={
          bold ? "fw-700 label-interest-item" : "fw-500 label-interest-item"
        }
        style={{ color: color || "var(--accent-neutral, #45495a)" }}>
        {btnText}
      </h4>
      <ChevronRightSvg fillColor={color || "var(--accent-neutral, #45495a)"} />
    </GoToPageStyled>
  );
}
export function TextLinked({ btnText, onClick }) {
  const location = useLocation();
  return (
    <StyledComponent onClick={onClick}>
      <h5
        className="fw-500 text-underline"
        style={{
          color:
            location.pathname === `/${idRouter.recentViewDetail}` ||
            location.pathname === `/${idRouter.economicCalendar}`
              ? "var(--neutral-color-4,#9497A0)"
              : "var(--neutral-color-3, #45495a)",
          flexShrink: 0
        }}>
        {btnText}
      </h5>
    </StyledComponent>
  );
}
export function TextAdd({ btnText }) {
  return (
    <TextAddStyled>
      <h3 className="fw-500">{btnText}</h3>
      <PlusSvg />
    </TextAddStyled>
  );
}

// button icon 40px icon 20px
export function CircleButton40px({ icon }) {
  return (
    <button
      type="button"
      className="flex-centered block-40px border-rounded border-1px-neutral-7">
      {icon}
    </button>
  );
}
export function CircleButton56px({ icon, cursor = "pointer" }) {
  return (
    <button
      type="button"
      className="block-56px border-rounded flex-centered bg-neutral-8 border-1px-neutral-7"
      style={{
        cursor
      }}>
      {icon}
    </button>
  );
}

export function CircleImage({ img }) {
  return <img src={img} alt="" className="block-20px border-rounded" />;
}

function MorePaggingButton({
  status,
  limit,
  onClick,
  hiddenCircle,
  showFullPageSize = false,
  noneMarginTop,
  className
}) {
  if (!showFullPageSize) {
    if (+status >= +limit || +limit === 0) return null;
  }
  return (
    <div
      className={className}
      style={{
        marginTop: noneMarginTop ? "0" : "20px"
      }}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
        onClick={onClick}
        className="flex-col flex-between-center gap-8">
        {hiddenCircle ? null : <CircleButton40px icon={<ChevronDownSvg />} />}
        <p className="fw-400 font-2 caption">
          {localeAndFixed(status, 0)} / {localeAndFixed(limit, 0)}
        </p>
      </div>
    </div>
  );
}

export const MorePagging = memo(MorePaggingButton);
function DropdownSelectButton({ btnText, imgUrl, onClick }) {
  return (
    <button
      className="flex-centered gap-12 border-1px-neutral-7 br-20 pr-13 pb-8 pt-8 pl-17 fit-width fit-height"
      type="button"
      onClick={onClick}>
      {imgUrl && (
        <img
          className="block-18px border-rounded img-cover img-box-shadow"
          alt=""
          src={imgUrl}
        />
      )}
      <div className="flex-1 text-align-left flex-centered gap-4 svg-pd-5-3">
        <h4 className="text-ellipsis-1">{btnText}</h4>
        <ChevronDownSvg />
      </div>
    </button>
  );
}

export const DropdownButton = memo(DropdownSelectButton);

function WritePostBtn({ onClick, hasNavBar }) {
  return (
    <button
      type="button"
      className="bg-neutral-2 border-rounded flex-centered block-56px"
      onClick={onClick}
      style={{
        position: "absolute",
        bottom: hasNavBar ? "76px" : "56px",
        right: "20px"
      }}>
      <SvgIcons name="edit2" className="h-24px" />
    </button>
  );
}

export const WritePostButton = memo(WritePostBtn);
