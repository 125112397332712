import { createSlice } from "@reduxjs/toolkit";
import KRsvg from "assets/img/KR.svg";

const initialState = {
  stockTab: {
    highestTab: {
      baseCurrency: {
        countryFileName:
          "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
        mkIndicesMasterUid: "B880C6B36C144022BD614506FAA3C92B",
        labelKor: "코스피",
        labelEng: "KOSPI"
      },
      activeTab: 0
    },
    top5SubjectTab: {
      activeTab: 0,
      switchTab: "순매수"
    },
    top5ThemeTab: {
      mkThemaMasterUid: "",
      stagnation: 0,
      count: 0,
      rise: 0,
      labelKor: "",
      labelEng: "",
      volatilityPercent: "",
      decrease: 0
    },
    majorTab: {
      countryFileName:
        "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
      mkIndicesMasterUid: "B880C6B36C144022BD614506FAA3C92B",
      labelKor: "코스피",
      labelEng: "KOSPI"
    }
  },
  etfTab: {
    baseCurrency: {
      countryCodeShort: "KR",
      countryCode: "KOR",
      mainYn: "Y",
      imageUrl: KRsvg,
      labelKor: "대한민국",
      labelEng: "Republic of Korea",
      mkCountryUid: "19d0417c147811ee9382d8bbc199b2cd"
    },
    activeTab: 0
  }
};

const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    updateStockTab: (state, action) => {
      if (action?.payload?.highestTab !== undefined) {
        state.stockTab.highestTab = action?.payload?.highestTab;
      }
      if (action?.payload?.top5ThemeTab !== undefined) {
        state.stockTab.top5ThemeTab = action?.payload?.top5ThemeTab;
      }
      if (action?.payload?.top5SubjectTab !== undefined)
        state.stockTab.top5SubjectTab = action?.payload?.top5SubjectTab;
      if (action?.payload?.majorTab !== undefined) {
        state.stockTab.majorTab = action?.payload?.majorTab;
      }
    },
    updateEtfTab: (state, action) => {
      if (action?.payload?.baseCurrency !== undefined) {
        state.etfTab.baseCurrency = action?.payload?.baseCurrency;
      }
      if (action?.payload?.activeTab !== undefined) {
        state.etfTab.activeTab = action?.payload?.activeTab;
      }
    }
  }
});

export const marketActions = marketSlice.actions;

// Reducer
const marketReducer = marketSlice.reducer;
export default marketReducer;
