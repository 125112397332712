import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listRecent: [],
  listHistory: [],
  loading: true
};

const recentSlice = createSlice({
  name: "recent",
  initialState,
  reducers: {
    setListRecent: (state, action) => {
      state.listRecent = action?.payload;
    },
    setListHistory: (state, action) => {
      state.listHistory = action?.payload;
    },
    clearRecent: (state) => {
      state.listRecent = [];
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    }
  }
});

// Actions
export const recentActions = recentSlice.actions;

// Reducer
const recentReducer = recentSlice.reducer;
export default recentReducer;
