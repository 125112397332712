/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import Header from "components/subHeaderMobile";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { getPrivacy } from "services/SettingService";
import { SelfScrollDiv } from "views/HeatMap/styled";
import { ModalStyled } from "./styled";

const fakePrivacy1 = [
  {
    title: "수집하는 개인정보의 항목 및 수집방법",
    contents: [
      {
        contentNumber: {
          index: "1",
          content: "회원가입 시 개인정보 수집항목"
        },
        contentWithNumberCircle: [
          {
            content:
              "회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 시 아래와 같은 개인정보를 수집하고 있습니다. 단, 개인정보 수집항목은 서비스에 따라 상이할 수 있습니다."
          }
        ],
        content1: "- 필수 및 선택 항목 : 아이디, 비밀번호, 이름, 닉네임, 이메일"
      },
      {
        contentNumber: {
          index: "2",
          content: "개인정보 수집방법"
        },
        contentWithNumberCircle: [
          {
            content:
              "홈페이지 및 모바일 APP, 이메일, 전화, 팩스, 상담 게시판, 이벤트 응모"
          },
          {
            content: "협력회사로부터의 제공"
          },
          {
            content: "생성정보 수집 툴을 통한 수집"
          }
        ],
        content: "회사는 다음과 같은 방법으로 개인정보를 수집합니다."
      }
    ]
  },
  {
    title: "개인정보의 수집 및 이용목적",
    content: "회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.",
    contents: [
      {
        contentNumber: {
          index: "1",
          content: "서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산"
        }
        // content1:
        //   "콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 등 발송, 금융거래 본인 인증 및 금융 서비스, 요금 추심 등"
      },
      {
        contentNumber: {
          index: "2",
          content: "회원 관리"
        },
        content1:
          "회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 불만처리 등 민원처리, 고지사항 전달, 분쟁 조정을 위한 기록보존, 회원탈퇴 의사 확인 등"
      },
      {
        contentNumber: {
          index: "3",
          content: "마케팅 및 광고에의 활용"
        },
        content1:
          "신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 주요 뉴스 및 투자 정보 제공, 이벤트 등 광고성 정보 제공, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등"
      },
      {
        contentNumber: {
          index: "4",
          content: "모바일 서비스 기능 제공"
        },
        content1: "앱 푸시 알림, 이벤트 등 광고성 정보 제공 등"
      }
    ]
  },
  {
    title: "개인정보의 보유 및 이용기간",
    content:
      "회사는 회원이 탈퇴의사를 밝히기 전까지 회원정보를 보유합니다. 그러나 회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.",
    contents: [
      {
        contentNumber: {
          index: "1",
          content: "회사 내부 방침에 의한 정보보호 사유"
        },
        content1:
          "부정 이용 기록 / 보존 이유 : 부정 이용 방지 / 보존 기간 : 1년"
      },
      {
        contentNumber: {
          index: "2",
          content: "관계법령에 의한 정보보호 사유"
        },
        contentWithNumberCircle: [
          {
            content:
              "표시.광고에 관한 기록 / 보존 이유 : 전자상거래 등에서의 소비자보호에 관현 법률 / 보존 기간 : 6개월"
          },
          {
            content:
              "계약 또는 청약철회 등에 관한 기록 / 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 / 보존 기간 : 5년"
          },
          {
            content:
              "대금결제 및 재화 등의 공급에 관한 기록 / 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 / 보존 기간 : 5년"
          },
          {
            content:
              "전자 금융 거래에 관한 기록 / 보존 이유 : 전자금융거래법 / 보존 기간 : 5년"
          },
          {
            content:
              "소비자의 불만 또는 분쟁처리에 관한 기록 / 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 / 보존 기간 : 3년"
          },
          {
            content:
              "웹사이트 방문기록 / 보존 이유 : 통신비밀보호법 / 보존 기간 : 3개월"
          }
        ],
        content:
          "상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다."
      }
    ]
  },
  {
    title: "개인정보의 파기 절차 및 방법",
    content:
      "이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.",
    contents: [
      {
        contentNumber: {
          index: "1",
          content: "파기절차"
        },
        content1:
          "회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유 목적(보유 및 이용기간 참조) 이외의 다른 목적으로 이용되지 않습니다."
      },
      {
        contentNumber: {
          index: "2",
          content: "파기방법"
        },
        contentWithNumberCircle: [
          {
            content:
              "종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기합니다."
          },
          {
            content:
              "전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다."
          }
        ]
      }
    ]
  }
];

export function ContentWithNumber({ index, content }) {
  return (
    <h5 className="color-neutral">
      {index}) {content}
    </h5>
  );
}

export function TitleWithSquare({ content }) {
  return (
    <h4 className="color-neutral-1 flex-start-center gap-6">
      <div className="square" /> {content}
    </h4>
  );
}

export function ContentMemberWithNumberCircle({ index, content }) {
  return (
    <div className="flex gap-4">
      <div className="circle-number">
        <p className="txt">{index}</p>
      </div>
      <p className="flex fw-500 fs-14 lh-22 color-neutral">{content}</p>
    </div>
  );
}

export function ContentPrivacy({ listData }) {
  return (
    <div>
      {listData?.map((item, index) => (
        <div className={index === 0 ? "" : "pt-56"} key={index}>
          <TitleWithSquare content={item.title} />
          {item?.content ? (
            <div className="pt-16">
              <p className="flex fw-500 fs-14 lh-22 color-neutral">
                {item.content}
              </p>
            </div>
          ) : null}
          <div className="pt-16 flex-col gap-16">
            {item.contents.map((contentItem, idx) => (
              <div className="flex-col gap-8" key={idx}>
                <ContentWithNumber
                  index={contentItem.contentNumber.index}
                  content={contentItem.contentNumber.content}
                />
                {contentItem.content ? (
                  <p className="flex fw-500 fs-14 lh-22 color-neutral">
                    {contentItem.content}
                  </p>
                ) : null}

                {contentItem.contentWithNumberCircle?.map(
                  (itemContentCircle, idx3) => (
                    <ContentMemberWithNumberCircle
                      key={idx3}
                      index={idx3 + 1}
                      content={itemContentCircle.content}
                    />
                  )
                )}
                <p className="flex fw-500 fs-14 lh-22 color-neutral">
                  {contentItem.content1 || null}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function ModalPrivacyPolicy() {
  const dispatch = useDispatch();
  const openModalPrivacy = useSelector(
    (state) => state.common.openModalPrivacy
  );
  const onClose = () => {
    dispatch(commonActions.onCloseModalPrivacy());
  };

  const [content, setContent] = useState("");

  useEffect(() => {
    getContentMember();
  }, []);

  const getContentMember = async () => {
    try {
      // setIsLoading(true);
      const res = await getPrivacy();
      if (res?.code === "200") {
        setContent(res?.data[0]?.detail);
      }
    } catch (e) {
      console.error(e);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Modal
      open={openModalPrivacy}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          logo={false}
          borderBottom
          onBack={onClose}
          headerTitle={<h1>개인정보처리방침</h1>}
        />

        <SelfScrollDiv className="pt-32 pl-pr-20 pb-64 gap-32">
          {/* <h2>최신 수정일: 2023년 03월 15일</h2> */}
          <div className="flex-col gap-24">
            <p className="fw-500 fs-14 lh-22 color-neutral">
              핀카이브(주)(이하 “회사”라 함)는 정보통신망 이용촉진 및 정보보호
              등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등
              정보통신서비스제공자가 준수해야할 관련 법령상의 개인정보보호
              규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여
              이용자 권익 보호에 최선을 다하고 있습니다.
            </p>
            <p className="fw-500 fs-14 lh-22 color-neutral">
              회사의 개인정보취급방침은 법령 및 고시 등의 변경과 회사의 약관 및
              내부 정책에 따라 변경 될 수 있으며 이를 개정하는 경우 회사는
              변경사항에 대하여 사전에 웹사이트에 게시하거나 이용자에게 별도
              통지합니다.
            </p>
          </div>
          <div className="pt-24">
            <ContentPrivacy listData={fakePrivacy1} />
          </div>
        </SelfScrollDiv>

        {/* <SelfScrollDiv>
          <pre className="pt-32 pl-pr-20 pb-64">{content}</pre>
        </SelfScrollDiv> */}
      </ModalStyled>
    </Modal>
  );
}
