/* eslint-disable default-param-last */
import {
  SET_AGORA_CLIENT,
  SET_AGORA_LOGGED_IN,
  ADD_AGORA_CHANNEL,
  REMOVE_AGORA_CHANNEL,
  REMOVE_ALL_AGORA_CHANNELS
} from "../type/agoraType";

const initialState = {
  client: null,
  isLoggedIn: false,
  channels: []
};

export const agoraReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGORA_CLIENT: {
      state.client = action.payload;
      return { ...state };
    }
    case SET_AGORA_LOGGED_IN: {
      state.isLoggedIn = action.payload;
      return { ...state };
    }
    case ADD_AGORA_CHANNEL: {
      state.channels.push(action.payload);
      return { ...state };
    }
    case REMOVE_AGORA_CHANNEL: {
      state.channels = state.channels.filter(
        (channel) => channel.channelId !== action.payload
      );
      return { ...state };
    }
    case REMOVE_ALL_AGORA_CHANNELS: {
      state.channels = [];
      return { ...state };
    }
    default:
      return state;
  }
};
