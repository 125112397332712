/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import MainPageService from "../services/MainPage/MainPageService";
import { BOARD_ID } from "../constants";
import PostService from "../services/PostService/PostService";
import { MENU_ID, BOARD_ID as BOARD_ID_2 } from "../contexts/Constants";
import SNSURL from "../services/SnsBoardService/SNSURL";
import SnsBoardService from "../services/SnsBoardService/SnsBoardService";
import VideoBoardService from "../services/VideoBoardService/VideoBoardService";
import VIDEO_URL from "../services/VideoBoardService/url";
import ChatService from "../services/ChatService/ChatService";

const urlLikeUnLike = (idPost, type, url) => {
  url += `/${type}?idPost=${idPost}`;
  return url;
};

const useMain = () => {
  const [menuList, setMenuList] = useState([]);
  const [stock5PostList, setStock5PostList] = useState([]);
  const [marketAnalys5PostList, setMarketAnalys5PostList] = useState([]);
  const [dailyReport5PostList, setDailyReport5PostList] = useState([]);
  const [investStrategy5PostList, setInvestStrategy5PostList] = useState([]);
  const [generalNews5PostList, setGeneralNews5PostList] = useState([]);
  const [currentTab5PostList, setCurrentTab5PostList] = useState([]);
  const [sns5PostList, setSns5PostList] = useState([]);
  const [allAlarmList, setAllAlarmList] = useState([]);
  const [notReadAlarmList, setNotReadAlarmList] = useState([]);
  const [listPostByBoardVideoFX, setListPostByBoardVideoFX] = useState([]);
  const [infoUser, setInfoUser] = useState({
    countMessage: 0,
    countNotice: 0,
    keyword: 0,
    chat: 0,
    count: 0,
    point: 0,
    countNote: 0
  });
  const [detailBoard, setDetailBoard] = useState({});
  const [analysTabIndex, setAnalysTabIndex] = useState(0);

  const [new5Post, setNew5Post] = useState([]);
  const [hot5Post, setHot5Post] = useState([]);
  // by menu
  const [stock5Post, setStock5Post] = useState([]);
  const [community5Post, setCommunity5Post] = useState([]);
  // post detail
  const [detailPost, setDetailPost] = useState({});
  const navigate = useNavigate();
  const recallNotice = useSelector((state) => state.user.recallNotice);

  const fetchMenu = useCallback(async () => {
    try {
      const res = await MainPageService.getMenu();
      const data = res?.data?.data ?? [];
      setMenuList(data);
    } catch (err) {
      setMenuList([]);
      console.log(err);
    }
  }, []);

  const fetchStocks5Posts = useCallback(async (id) => {
    try {
      const res = await MainPageService.get5LatestPost(id);
      let data = res?.data?.data ?? [];
      data = data?.map((post) => {
        const { bulltinBoardDto } = post || {};
        const { read: readAuthority, secretMessage: isSecret = false } =
          bulltinBoardDto || {};
        return {
          ...post,
          readAuthority,
          isSecret
        };
      });
      setStock5PostList(data);
    } catch (err) {
      setStock5PostList([]);
      console.log(err);
    }
  }, []);

  const fetchPostsByBoardVideoFX = useCallback(async () => {
    try {
      const url = VIDEO_URL.GET_LIST_VIDEO(BOARD_ID_2.FX_ESSENTIAL_LECTURE);
      const res = await VideoBoardService.getListVideo(url, {});
      const data = res?.data?.data ?? [];
      setListPostByBoardVideoFX(data);
    } catch (err) {
      setListPostByBoardVideoFX([]);
      console.log(err);
    }
  }, []);

  const fetchStock5Posts = useCallback(async (id) => {
    try {
      const res = await PostService.getPostByMenu(id);
      const data = res?.data?.data ?? [];
      setStock5Post(data);
    } catch (err) {
      setStock5Post([]);
      console.log(err);
    }
  }, []);

  const fetchCommunity5Posts = useCallback(async (id) => {
    try {
      const res = await PostService.getPostByMenu(id);
      const data = res?.data?.data ?? [];
      setCommunity5Post(data);
    } catch (err) {
      setCommunity5Post([]);
      console.log(err);
    }
  }, []);

  const fetchNew5Posts = useCallback(async () => {
    try {
      const res = await PostService.getNewPost(false);
      const data = res?.data?.data ?? [];
      setNew5Post(data);
    } catch (err) {
      setNew5Post([]);
      console.log(err);
    }
  }, []);

  const fetchHot5Posts = useCallback(async () => {
    try {
      const res = await PostService.getNewPost(true);
      const data = res?.data?.data ?? [];
      setHot5Post(data);
    } catch (err) {
      setHot5Post([]);
      console.log(err);
    }
  }, []);

  const fetchMarketAnalys5Posts = useCallback(async (id) => {
    try {
      const res = await MainPageService.get5LatestPost(id);
      let data = res?.data?.data ?? [];
      data = data?.map((post) => {
        const { bulltinBoardDto } = post || {};
        const { read: readAuthority, secretMessage: isSecret = false } =
          bulltinBoardDto || {};
        return {
          ...post,
          readAuthority,
          isSecret
        };
      });
      setMarketAnalys5PostList(data);
    } catch (err) {
      setMarketAnalys5PostList([]);
      console.log(err);
    }
  }, []);

  const fetchDailyReport5Posts = useCallback(async (id) => {
    try {
      const res = await MainPageService.get5LatestPost(id);
      let data = res?.data?.data ?? [];
      data = data?.map((post) => {
        const { bulltinBoardDto } = post || {};
        const { read: readAuthority, secretMessage: isSecret = false } =
          bulltinBoardDto || {};
        return {
          ...post,
          readAuthority,
          isSecret
        };
      });
      setDailyReport5PostList(data);
    } catch (err) {
      setDailyReport5PostList([]);
      console.log(err);
    }
  }, []);

  const fetchInvestStrategy5Posts = useCallback(async (id) => {
    try {
      const res = await MainPageService.get5LatestPost(id);
      let data = res?.data?.data ?? [];
      data = data?.map((post) => {
        const { bulltinBoardDto } = post || {};
        const { read: readAuthority, secretMessage: isSecret = false } =
          bulltinBoardDto || {};
        return {
          ...post,
          readAuthority,
          isSecret
        };
      });
      setInvestStrategy5PostList(data);
    } catch (err) {
      setInvestStrategy5PostList([]);
      console.log(err);
    }
  }, []);

  const fetchGeneralNews5Posts = useCallback(async (id) => {
    try {
      const res = await MainPageService.get5LatestPost(id);
      let data = res?.data?.data ?? [];
      data = data?.map((post) => {
        const { bulltinBoardDto } = post || {};
        const { read: readAuthority, secretMessage: isSecret = false } =
          bulltinBoardDto || {};
        return {
          ...post,
          readAuthority,
          isSecret
        };
      });
      setGeneralNews5PostList(data);
    } catch (err) {
      setGeneralNews5PostList([]);
      console.log(err);
    }
  }, []);

  const fetchSns5Posts = useCallback(async (id) => {
    try {
      const res = await MainPageService.get5LatestPost(id);
      const data = res?.data?.data ?? [];
      if (data.length > 0) {
        const dataNew = data.map((item) => {
          if (
            JSON.parse(localStorage.getItem("user")) !== null &&
            item.userLike.length > 0
          ) {
            const flag = item.userLike.filter(
              (itm) =>
                itm.email === JSON.parse(localStorage.getItem("user")).username
            );
            item.flagLike = flag.length > 0;
          } else {
            item.flagLike = false;
          }

          return item;
        });
        setSns5PostList(dataNew);
      } else {
        setSns5PostList(data);
      }
    } catch (err) {
      setSns5PostList([]);
      console.log(err);
    }
  }, []);

  const fetchDetailBoard = useCallback(async (id) => {
    try {
      const res = await MainPageService.getDetailBoard(id);
      const data = res?.data?.data ?? [];
      setDetailBoard(data);
    } catch (err) {
      setDetailBoard({});
      console.log(err);
    }
  }, []);

  const fetchInfoUser = useCallback(async () => {
    try {
      const res = await MainPageService.getCountAllNoti();
      const data = res?.data?.data ?? {};
      const resChat = await ChatService.getListRoom();
      const dataChat = resChat?.data?.data ?? [];
      if (!isEmpty(data)) {
        const {
          countMessage,
          countNotice,
          keyword,
          count,
          point,
          countNote,
          nickname,
          imgPath
        } = data;
        let numUnView = 0;
        dataChat.forEach((item) => {
          numUnView = item.totalViewFalse + numUnView;
        });
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("AlarmListener", countNotice);
        }
        setInfoUser({
          ...infoUser,
          countMessage,
          countNotice,
          keyword,
          chat: numUnView,
          count,
          point,
          countNote,
          nickname,
          imgPath
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangeAnalysTabIndex = useCallback(
    async (index) => {
      setAnalysTabIndex(index);
      switch (index) {
        case 0:
          setCurrentTab5PostList(marketAnalys5PostList);
          break;
        case 1:
          setCurrentTab5PostList(dailyReport5PostList);
          break;
        case 2:
          setCurrentTab5PostList(investStrategy5PostList);
          break;
        default:
          setCurrentTab5PostList(marketAnalys5PostList);
          break;
      }
    },
    [
      analysTabIndex,
      marketAnalys5PostList,
      dailyReport5PostList,
      investStrategy5PostList
    ]
  );

  const fetchPostDetail = useCallback(async (id, idBoard) => {
    try {
      const res = await MainPageService.getPostDetail(id, idBoard);
      if (res?.data?.code === "404") {
        navigate("/404");
      }
      const data = res?.data?.data ?? [];
      setDetailPost(data);
    } catch (err) {
      setDetailPost({});
      console.log(err);
    }
  }, []);

  const handleLike = useCallback(
    async (idPost, type) => {
      if (isEmpty(localStorage.getItem("token"))) {
        return;
      }
      try {
        const url = urlLikeUnLike(idPost, type, SNSURL.SET_POST_LIKE());
        const res = await SnsBoardService.setPostLike(url);
        if (res.data.code === "200") {
          fetchSns5Posts(BOARD_ID.SNS_NEWS);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [urlLikeUnLike]
  );

  useEffect(() => {
    fetchMenu();
    // fetchStocks5Posts(BOARD_ID.FEATURE_INTEREST_THEME_STOCKS);
    // fetchMarketAnalys5Posts(BOARD_ID.MARKET_ANALYST);
    // fetchDailyReport5Posts(BOARD_ID.DAILY_REPORT);
    // fetchInvestStrategy5Posts(BOARD_ID.INVESTMENT_STRATEGY_ANALYST);
    // fetchGeneralNews5Posts(BOARD_ID.GENERAL_NEWS);
    // fetchSns5Posts(BOARD_ID.SNS_NEWS);
    // fetchStock5Posts(MENU_ID.STOCK_MARKET);
    // fetchCommunity5Posts(MENU_ID.COMMUNITY);
    fetchNew5Posts();
    fetchHot5Posts();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchInfoUser();
    }
  }, [recallNotice]);

  useEffect(() => {
    if (marketAnalys5PostList?.length > 0) {
      handleChangeAnalysTabIndex(0);
    }
  }, [marketAnalys5PostList]);

  return {
    menuList,
    stock5PostList,
    generalNews5PostList,
    currentTab5PostList,
    detailBoard,
    analysTabIndex,
    infoUser,
    allAlarmList,
    notReadAlarmList,
    stock5Post,
    community5Post,
    new5Post,
    hot5Post,
    detailPost,
    sns5PostList,
    listPostByBoardVideoFX,
    fetchDetailBoard,
    handleChangeAnalysTabIndex,
    fetchPostDetail,
    fetchPostsByBoardVideoFX,
    handleLike,
    fetchInfoUser
  };
};

export default useMain;
