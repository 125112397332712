const URL = Object.freeze({
  GET_MENU: "/api/user/menu/get-all",
  GET_5_LATEST_POST: (id) => `/api/user/post/list-by-board/new?idBoard=${id}`,
  GET_DETAIL_BOARD: (id) => `/api/user/bulltin-board/detail?id=${id}`,
  GET_COUNT_ALL_NOTI: "/api/user/all",
  GET_ALL_ALARM_LIST: "/api/user/alarm/list/all?all=true",
  GET_NOT_READ_ALARM_LIST: "/api/user/alarm/list/all?all=false",
  GET_POST_DETAIL: (id, idBoard) =>
    `/api/user/post/detail?id=${id}&idBoard=${idBoard}`,
  GET_SETTING_PAGE: "/api/user/setting-page/detail",
  GET_BLOCK_IP: "/api/user/setting-page/ip",
  GET_GENERAL_POST: "/api/user/post/list-post/new?hot=false",
  GET_SLIDER_POST: (id) => `/api/user/post/list-by-menu/new?idMenu=${id}`,
  CHECK_VISIT_PAGE: "/api/user/request-page/request"
});
export default URL;
