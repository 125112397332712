export default function Edit3Svg({ fillColor = "#45495A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={fillColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5001 7.08754C15.2802 7.08754 15.0694 7.17489 14.9139 7.33038L7.70218 14.5421L7.31134 16.1054L8.87471 15.7146L16.0864 8.50291C16.1634 8.42592 16.2245 8.33452 16.2661 8.23393C16.3078 8.13334 16.3292 8.02552 16.3292 7.91664C16.3292 7.80777 16.3078 7.69995 16.2661 7.59936C16.2245 7.49877 16.1634 7.40737 16.0864 7.33038C16.0094 7.25339 15.918 7.19232 15.8174 7.15065C15.7168 7.10899 15.609 7.08754 15.5001 7.08754ZM14.3364 6.75291C14.645 6.44427 15.0637 6.27087 15.5001 6.27087C15.7163 6.27087 15.9303 6.31344 16.1299 6.39615C16.3296 6.47886 16.5111 6.60008 16.6639 6.75291C16.8167 6.90573 16.9379 7.08716 17.0206 7.28684C17.1033 7.48651 17.1459 7.70052 17.1459 7.91664C17.1459 8.13277 17.1033 8.34678 17.0206 8.54645C16.9379 8.74613 16.8167 8.92756 16.6639 9.08038L9.37221 16.372C9.31988 16.4244 9.25431 16.4615 9.18251 16.4795L6.84917 17.0628C6.71002 17.0976 6.56283 17.0568 6.4614 16.9554C6.35998 16.854 6.31921 16.7068 6.354 16.5676L6.93733 14.2343C6.95528 14.1625 6.99241 14.0969 7.04474 14.0446L14.3364 6.75291ZM11.5918 16.6667C11.5918 16.4411 11.7746 16.2583 12.0001 16.2583H17.2501C17.4757 16.2583 17.6585 16.4411 17.6585 16.6667C17.6585 16.8922 17.4757 17.075 17.2501 17.075H12.0001C11.7746 17.075 11.5918 16.8922 11.5918 16.6667Z"
        fill="white"
      />
    </svg>
  );
}
