/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";
import URL from "./url";

class ChatService {
  createRoom(body) {
    const url = URL.CREATE_ROOM;
    return axiosClient.post(url, body);
  }

  getListRoom() {
    const url = URL.GET_LIST_ROOM;
    return axiosClient.get(url);
  }

  deleteRoom(id) {
    const url = `${URL.DELETE_MESSAGE}?roomId=${id}`;
    return axiosClient.delete(url);
  }

  getRoom(id) {
    const url = `${URL.GET_ROOM}?roomId=${id}`;
    return axiosClient.get(url);
  }

  getRoomCheck(id) {
    const url = `${URL.CHECK_ROOM}?idUser=${id}`;
    return axiosClient.get(url);
  }
}

export default new ChatService();
