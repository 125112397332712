import styled from "styled-components";

export const Styled = styled.div`
  padding: 0 20px;
  .iconText {
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    align-items: center;
    align-content: center;
    align-self: stretch;
    flex-wrap: wrap;
    cursor: pointer;
    border-bottom: ${(props) =>
      props.noneBorder ? "none" : "1px solid var(--F4F4F4, #f4f4f4)"};
    .icon {
      display: flex;
      align-items: center;
    }
    .text {
      display: flex;
      align-items: center;
      gap: 2px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  ._2dep {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 16px 0 16px 28px;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;

    ._txt {
      flex: 1 0 0;
      cursor: pointer;
    }
  }
`;

export const StyledMenuToken = styled.div`
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: var(--F9F9F9, #f9f9f9);
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      a {
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid var(--E4E5E7, #e4e5e7);
        }
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      gap: 8px;
      & > button {
        position: relative;
        margin-left: 18px;
        svg {
          width: 24px;
          height: 24px;
        }
        span {
          width: 5.34px;
          height: 5.34px;
          background: #f4cb5d;
          border-radius: 50%;
          font-weight: 400;
          font-size: 10px;
          color: #45495a;
          line-height: 18px;
          text-align: center;
          position: absolute;
          right: 1px;
          top: 0px;
        }

        &.bell {
          span {
            background: #1554f6;
          }
        }

        &.chat {
          span {
            background: #f20013;
            color: #ffffff;
          }
        }
      }
    }
  }
  .bot {
    width: 100%;
    .button-user-infor {
      background: #1554f6;
      color: white;
      display: flex;
      height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 24px;
      width: 100%;
    }
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: var(--F9F9F9, #f9f9f9);
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    mark {
      background-color: transparent;
      color: #5e86f4;
    }
  }
  .bot {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    list-style: none;
    box-sizing: border-box;
    .button-user-infor {
      background: #1554f6;
      color: white;
      display: flex;
      height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 24px;
    }
    .register {
      background: var(--373D3F, #373d3f);
    }
  }
`;
