import moment from "moment";
import { createSlice } from "@reduxjs/toolkit";
import { isKorean } from "util/common";

const defaultValue = {
  enableFilter: true,
  country: [
    isKorean
      ? {
          countryCodeShort: "KR",
          countryCode: "KOR",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
          labelKor: "대한민국",
          labelEng: "Republic of Korea",
          mkCountryUid: "19d0417c147811ee9382d8bbc199b2cd"
        }
      : {
          countryCodeShort: "US",
          countryCode: "USA",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
          labelKor: "미국",
          labelEng: "United States",
          mkCountryUid: "19d05cfb147811ee9382d8bbc199b2cd"
        }
  ],
  searchDate: moment().format("YYYY-MM-DD"),
  kind: [
    {
      viewOrder: 0,
      defaultValue: "Y",
      labelKor: "전체",
      labelEng: "All",
      codeValue: "ALL"
    }
  ]
};

const initialState = {
  searchDataIndicator: {
    ...defaultValue,
    importance: [
      {
        id: 0,
        labelKor: "전체",
        labelEng: "ALL"
      }
    ]
  },
  searchDataDisclosure: {
    kind: [
      {
        viewOrder: 0,
        defaultValue: "Y",
        labelKor: "전체",
        labelEng: "All",
        codeValue: "ALL"
      }
    ], // kind
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    activeTab: 0
  },
  searchDataEarning: { ...defaultValue },
  searchDataDividend: { ...defaultValue },
  searchDataSplit: { ...defaultValue },
  searchDataMaturity: { ...defaultValue }
};

const economicCalendarSlice = createSlice({
  name: "economicCalendar",
  initialState,
  reducers: {
    updateSearchDataIndicator: (state, action) => {
      if (action?.payload?.country !== undefined)
        state.searchDataIndicator.country = action?.payload?.country;
      if (action?.payload?.searchDate !== undefined) {
        state.searchDataIndicator.searchDate = moment(
          action?.payload?.searchDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.importance !== undefined)
        state.searchDataIndicator.importance = action?.payload?.importance;
      if (action?.payload?.kind !== undefined)
        state.searchDataIndicator.kind = action?.payload?.kind;
      if (action?.payload?.enableFilter !== undefined)
        state.searchDataIndicator.enableFilter = action?.payload?.enableFilter;
    },
    updateSearchDataDisclosure: (state, action) => {
      if (action?.payload?.startDate !== undefined) {
        state.searchDataDisclosure.startDate = moment(
          action?.payload?.startDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.endDate !== undefined) {
        state.searchDataDisclosure.endDate = moment(
          action?.payload?.endDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.kind !== undefined)
        state.searchDataDisclosure.kind = action?.payload?.kind;
      if (action?.payload?.activeTab !== undefined) {
        state.searchDataDisclosure.activeTab = action?.payload?.activeTab;
      }
    },
    updateSearchDataEarning: (state, action) => {
      if (action?.payload?.country !== undefined)
        state.searchDataEarning.country = action?.payload?.country || "ALL";
      if (action?.payload?.searchDate !== undefined) {
        state.searchDataEarning.searchDate = moment(
          action?.payload?.searchDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.kind !== undefined)
        state.searchDataEarning.kind = action?.payload?.kind || "ALL";
      if (action?.payload?.enableFilter !== undefined)
        state.searchDataEarning.enableFilter = action?.payload?.enableFilter;
    },
    updateSearchDataDividend: (state, action) => {
      if (action?.payload?.country !== undefined)
        state.searchDataDividend.country = action?.payload?.country || "ALL";
      if (action?.payload?.searchDate !== undefined) {
        state.searchDataDividend.searchDate = moment(
          action?.payload?.searchDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.kind !== undefined)
        state.searchDataDividend.kind = action?.payload?.kind || "ALL";
      if (action?.payload?.enableFilter !== undefined)
        state.searchDataDividend.enableFilter = action?.payload?.enableFilter;
    },
    updateSearchDataSplit: (state, action) => {
      if (action?.payload?.country !== undefined)
        state.searchDataSplit.country = action?.payload?.country;
      if (action?.payload?.searchDate !== undefined) {
        state.searchDataSplit.searchDate = moment(
          action?.payload?.searchDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.kind !== undefined)
        state.searchDataSplit.kind = action?.payload?.kind;
      if (action?.payload?.enableFilter !== undefined)
        state.searchDataSplit.enableFilter = action?.payload?.enableFilter;
    },
    updateSearchDataMaturity: (state, action) => {
      if (action?.payload?.country !== undefined)
        state.searchDataMaturity.country = action?.payload?.country;
      if (action?.payload?.searchDate !== undefined) {
        state.searchDataMaturity.searchDate = moment(
          action?.payload?.searchDate
        ).format("YYYY-MM-DD");
      }
      if (action?.payload?.kind !== undefined)
        state.searchDataMaturity.kind = action?.payload?.kind;
      if (action?.payload?.enableFilter !== undefined)
        state.searchDataMaturity.enableFilter = action?.payload?.enableFilter;
    }
  }
});

// Actions
export const economicCalendarActions = economicCalendarSlice.actions;

// Reducer
const economicCalendarReducer = economicCalendarSlice.reducer;
export default economicCalendarReducer;
