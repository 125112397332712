import { Modal } from "@mui/material";
import CountryItem from "components/countryItem";
import Header from "components/subHeaderMobile";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appSettingActions } from "redux/reducers/appSettingReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { getValueSetting, updateSetting } from "services/SettingService";
import { ModalStyled } from "./styled";

function ModalSelectSetting() {
  const dispatch = useDispatch();
  const [listOptions, setListOptions] = useState([]);
  const kindOfSelect = useSelector((state) => state?.appSetting?.kindOfSelect);
  const currentSetting = useSelector(
    (state) => state?.appSetting?.currentSetting
  );
  const { i18n } = useTranslation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (kindOfSelect?.optionKind) getListSelect();
  }, [kindOfSelect]);

  const onClose = () => dispatch(appSettingActions.clearKindOfSelect());

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const getListSelect = async () => {
    try {
      setIsLoading(true);
      const res = await getValueSetting({ kind: kindOfSelect?.optionKind });
      if (res?.code === "200") {
        const option = isArray(res?.data?.list) ? res?.data?.list || [] : [];
        setListOptions(option);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSettings = async (newSettingItem) => {
    const { labelEng, labelKor, optionValue, filePath } = newSettingItem;
    try {
      setIsLoading(true);
      const oldSettingIndex = currentSetting?.findIndex(
        (obj) => obj?.optionKind === kindOfSelect?.optionKind
      );
      // find that the old setting was exist or not
      let newSetting;
      if (oldSettingIndex !== -1) {
        newSetting = [...currentSetting];
        newSetting[oldSettingIndex] = {
          optionKind: kindOfSelect?.optionKind,
          labelEng,
          labelKor,
          optionValue,
          filePath
        };
      } else
        newSetting = [
          ...currentSetting,
          {
            optionKind: kindOfSelect?.optionKind,
            labelEng,
            labelKor,
            optionValue,
            filePath
          }
        ];
      if (newSetting !== null) {
        if (token) {
          const res = await updateSetting({
            createYn: "N",
            setItems: newSetting
          });
          if (res?.code === "200") {
            dispatch(appSettingActions.updateCurrentSetting(newSetting));
          }
        } else {
          dispatch(appSettingActions.updateCurrentSetting(newSetting));
        }
        if (kindOfSelect?.optionKind === "Language") {
          switch (optionValue) {
            case "KOR":
              if (i18n.language !== "ko") i18n.changeLanguage("ko");
              if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("handleMessage", "ko");
              }
              break;
            case "USA":
              if (i18n.language !== "en") i18n.changeLanguage("en");
              if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("handleMessage", "en");
              }
              break;
            // case "VNM":
            //   if (i18n.language !== "vi") i18n.changeLanguage("vi");
            //   if (window.flutter_inappwebview) {
            //     window.flutter_inappwebview.callHandler("handleMessage", "vi");
            //   }
            //   break;
            default:
              if (i18n.language !== "en") i18n.changeLanguage("en");
              if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("handleMessage", "en");
              }
          }
        }
        localStorage.setItem("userSettings", JSON.stringify(newSetting)); // still save on local to recheck language
        onClose();
      }
      // else {
      // localStorage.removeItem("userSettings");
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={kindOfSelect !== null}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          borderBottom
          onBack={onClose}
          headerTitle={
            isArray(listOptions) &&
            !isEmpty(listOptions) && (
              <h1>
                {i18n.language === "ko"
                  ? listOptions[0]?.description ||
                    listOptions[0]?.parentOptionKind
                  : listOptions[0]?.parentOptionKind ||
                    listOptions[0]?.description}
              </h1>
            )
          }
        />
        <div className="flex-col full-height flex-1 pt-8 flow-y-auto">
          {isArray(listOptions) &&
            !isEmpty(listOptions) &&
            listOptions.map((item, index) => (
              <CountryItem
                item={item}
                key={index}
                active={item?.optionValue === kindOfSelect?.optionValue}
                onClick={() => updateSettings(item)}
              />
            ))}
        </div>
      </ModalStyled>
    </Modal>
  );
}
export default memo(ModalSelectSetting);
