export default function ChartSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4728 8.516C20.7578 8.25477 20.7771 7.81197 20.5158 7.52698C20.2546 7.242 19.8118 7.22275 19.5268 7.48398L13.0907 13.3837L9.92736 10.484C9.65974 10.2387 9.24897 10.2387 8.98135 10.484L3.5268 15.484C3.24182 15.7452 3.22257 16.188 3.4838 16.473C3.74504 16.758 4.18783 16.7772 4.47282 16.516L9.45435 11.9496L12.6177 14.8493C12.8853 15.0947 13.2961 15.0947 13.5637 14.8493L20.4728 8.516Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 3H2.66667C1.19391 3 0 3.89543 0 5V19C0 20.1046 1.19391 21 2.66667 21H21.3333C22.8061 21 24 20.1046 24 19V5C24 3.89543 22.8061 3 21.3333 3ZM2.66667 4.4H21.3333C21.7961 4.4 22.1611 4.54243 22.379 4.70579C22.5963 4.86882 22.6 4.98717 22.6 5V19C22.6 19.0128 22.5963 19.1312 22.379 19.2942C22.1611 19.4576 21.7961 19.6 21.3333 19.6H2.66667C2.20391 19.6 1.83885 19.4576 1.62105 19.2942C1.40367 19.1312 1.4 19.0128 1.4 19V5C1.4 4.98717 1.40367 4.86882 1.62105 4.70579C1.83886 4.54243 2.20391 4.4 2.66667 4.4Z"
        fill={fillColor}
      />
    </svg>
  );
}
