import { createSlice } from "@reduxjs/toolkit";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

const initialState = {
  listCountry: [],
  sortState: {
    sortBy: null,
    orderBy: null
  }
};

const heatmapSlice = createSlice({
  name: "heatmap",
  initialState,
  reducers: {
    updateSortState: (state, action) => {
      state.sortState = action?.payload; // array of setting get from api 14-02
    },
    updateListCountry: (state, action) => {
      if (!isEmpty(action?.payload) && isArray(action?.payload))
        state.listCountry = action?.payload;
      // array of country get from api 14-02
      else {
        state.listCountry = [];
      }
    }
  }
});

// Actions
export const heatmapActions = heatmapSlice.actions;

// Reducer
const heatmapReducer = heatmapSlice.reducer;
export default heatmapReducer;
