import { useMediaQuery } from "react-responsive";

function useResponsive() {
  const isTabletOrMobile =
    !!localStorage.getItem("mobile_view") ||
    useMediaQuery({ query: "(max-width: 1256px)" });

  const isMobile =
    !!localStorage.getItem("mobile_view") ||
    useMediaQuery({ query: "(max-width: 1023px)" });

  return { isTabletOrMobile, isMobile };
}

export default useResponsive;
