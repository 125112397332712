import { Box, Container, Divider, Grid } from "@mui/material";
import Footer from "assets/img/footer.png";

import { LogoFinchiveSvg } from "components/icons";
import SvgIcons from "components/svgIcons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { idRouter } from "routes/idRouter";
import { StyledBoxFooter } from "./styled";

export default function FooterPC() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const footerLinks = [
    {
      title: t("info.morePage.market.title"),
      links: [
        {
          title: t("info.thema"),
          onClick: () => {
            navigate("/?tab=0");
          }
        },
        {
          title: t("info.morePage.market.children.stock"),
          onClick: () => {
            navigate("/?tab=1");
          }
        },
        {
          title: t("info.morePage.market.children.market"),
          onClick: () => {
            navigate("/?tab=2");
          }
        },
        {
          title: t("info.morePage.market.children.crypto"),
          onClick: () => {
            navigate("/?tab=3");
          }
        },
        {
          title: t("info.morePage.market.children.foreign"),
          onClick: () => {
            navigate("/?tab=4");
          }
        },
        {
          title: t("info.morePage.market.children.futures"),
          onClick: () => {
            navigate("/?tab=5");
          }
        },
        {
          title: t("info.morePage.market.children.ETF"),
          onClick: () => {
            navigate("/?tab=6");
          }
        }
      ]
    },
    {
      title: t("info.morePage.heatMap.title"),
      links: [
        {
          title: t("info.morePage.heatMap.children.thema"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=0`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.stock"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=1`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.indices"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=2`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.forex"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=3`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.crypto"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=4`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.future"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=5`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.ETF"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=6`);
          }
        }
      ]
    },
    {
      title: t("info.economicCalendar"),
      links: [
        {
          title: t("info.morePage.economic.children.thema"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=0`);
          }
        },
        {
          title: t("info.morePage.economic.children.disclosure"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=1`);
          }
        },
        {
          title: t("info.morePage.economic.children.indicator"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=2`);
          }
        },
        {
          title: t("info.morePage.economic.children.earnings"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=3`);
          }
        },
        {
          title: t("info.morePage.economic.children.dividend"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=4`);
          }
        },
        {
          title: t("info.morePage.economic.children.split"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=5`);
          }
        },
        {
          title: t("info.morePage.economic.children.IPO"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=6`);
          }
        },
        {
          title: t("info.morePage.economic.children.holiday"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=7`);
          }
        }
        // {
        //   title: t("info.morePage.economic.children.maturity"),
        //   onClick: () => {
        //     navigate(`/${idRouter.communityApp}?tab=8`);
        //   }
        // }
      ]
    },
    {
      title: t("info.newsAndAnalysis"),
      links: [
        {
          title: t("info.news"),
          onClick: () => {
            navigate(`/${idRouter.newsAndAnalysis}?tab=0`);
          }
        },
        {
          title: t("info.analysis"),
          onClick: () => {
            navigate(`/${idRouter.newsAndAnalysis}?tab=1`);
          }
        },
        {
          title: t("info.report"),
          onClick: () => {
            navigate(`/${idRouter.newsAndAnalysis}?tab=2`);
          }
        }
      ]
    },
    {
      title: t("info.menu.community"),
      links: [
        {
          title: t("info.footerPC.community.takeTheQuiz")
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=0`);
          // }
        },
        {
          title: t("info.footerPC.community.investorForecast")
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=1`);
          // }
        },
        {
          title: t("info.footerPC.community.popularDiscussionRoom")
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=2`);
          // }
        },
        {
          title: t("info.footerPC.community.freeBulletinBoard")
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=2`);
          // }
        }
      ]
    },
    {
      title: t("info.customerService"),
      links: [
        {
          title: "FAQ"
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=0`);
          // }
        },
        {
          title: t("info.notice")
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=1`);
          // }
        },
        {
          title: "Q&A"
          // onClick: () => {
          //   navigate(`/${idRouter.newsAndAnalysis}?tab=2`);
          // }
        }
      ]
    }
  ];
  return (
    <StyledBoxFooter className="bg-neutral-1 flex-col flex-centered">
      <div className="flex-col flex-start-center gap-56 w-1256px">
        <Container
          disableGutters
          className="flex-start-center gap-32 border-1px-bot-neutral-3"
          style={{
            maxWidth: "1256px" // override default
          }}>
          <Box className="flex-center gap-32">
            <img src={Footer} alt="finchive_footer_img" className="image" />
          </Box>
          <Box className="flex-1">
            <h2 className="lh-26 color-neutral-10">
              글로벌 No.1 금융 플랫폼 핀카이브 앱 다운로드
            </h2>
            <h3 className="fw-500 color-neutral-10">
              Nunc pulvinar sapien et ligula ullamcorper malesuada proin.
            </h3>
          </Box>
          <Box className="flex-center gap-16">
            <button type="button">
              <SvgIcons name="getOnGGPlay" />
            </button>
            <button type="button">
              <SvgIcons name="downloadAppStore" />
            </button>
          </Box>
        </Container>

        <Container
          disableGutters
          style={{
            maxWidth: "1256px" // override default
          }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Box className="flex-col gap-40 fit-width">
                <LogoFinchiveSvg fillColor="#ffffff" />
                <Box className="flex-col gap-16 fit-width">
                  <button type="button" className="fit-width">
                    <h5 className="fw-300 color-neutral-7 text-align-left">
                      {t("info.footerPC.membersOnly")}
                    </h5>
                  </button>
                  <button type="button" className="fit-width">
                    <h5 className="fw-300 color-neutral-7 text-align-left">
                      {t("info.footerPC.privacyPolicy")}
                    </h5>
                  </button>
                  <button type="button" className="fit-width">
                    <h5 className="fw-300 color-neutral-7 text-align-left">
                      {t("info.footerPC.partnershipInquiry")}
                    </h5>
                  </button>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <SvgIcons name="faceBookSvg" className="pointer" />
                  <SvgIcons name="twitterSvg" className="pointer" />
                  <SvgIcons name="telegramSvg" className="pointer" />
                  <SvgIcons name="linkInSvg" className="pointer" />
                  <SvgIcons name="youTubeSvg" className="pointer" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8} justifyContent="center">
              <Box className="footer-links-container" language={i18n.language}>
                {footerLinks.map((column, index) => (
                  <Box key={index} className="footer-column">
                    <button type="button" className="fit-width">
                      <h5 className="fw-300 color-neutral-10 pointer">
                        {column.title}
                      </h5>
                    </button>
                    <Box className="column-links">
                      {column.links.map((link, i) => (
                        <button type="button" key={i} onClick={link?.onClick}>
                          <h5 className="fw-300 color-neutral-7 pointer text-align-left">
                            {link?.title}
                          </h5>
                        </button>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Divider
          style={{
            width: "100%",
            margin: "8px 0",
            borderColor: "var(--neutral-color-3, #45495a)"
          }}
        />
        <Container
          disableGutters
          className="flex-col gap-8"
          style={{
            maxWidth: "1256px" // override default
          }}>
          <div className="flex-start-center gap-8">
            <h5 className="color-neutral-7">투자 위험 고지</h5>
            <h5 className="fw-300 color-neutral-7">
              게시된 정보는 단순히 정보제공을 목적으로 하며 투자 추천이
              아닙니다. 핀카이브에서 제공된 정보에 의한 투자 결과에 대한 법적인
              책임을 지지 않습니다.
            </h5>
          </div>
          <p className="caption color-neutral-5">
            ㈜ 데일리에이치 대표 | 황병체 사업자 등록번호 | 292-88-02357 경기도
            성남시 분당구 운중로 142 판교메디컬타워 9층 전화 | 070-7701-8200
            팩스 | 031-707-7736 Copyright DailyH. All rights reserved
          </p>
        </Container>
      </div>
    </StyledBoxFooter>
  );
}
