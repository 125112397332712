/* eslint-disable no-unused-vars */
import { Backdrop, CircularProgress } from "@mui/material";
import { successAlert } from "components/alert";
import CenterConfirmModal from "components/centerConfirmModal";
import {
  AddOver10Portfolio,
  ModalActionComment,
  ModalComprehensiveSearch,
  ModalEditPortfolio,
  ModalLoginMobile,
  ModalMyPortfolio,
  ModalNewPortfolio,
  ModalRegisterMobile,
  ModalRequireLogin,
  ModalSelectActionUser,
  ModalSelectSetting,
  ModalSetting
} from "components/modal";
import ModalExchangeCalculator from "components/modal/ModalExchangeCalculator";
import ModalMemberShip from "components/modal/ModalMemberShip";
import ModalPrivacyPolicy from "components/modal/ModalPrivacyPolicy";
import DialogAvatar from "components/modal/avatarUser/DialogAvatar";
import DrawerActionAvatar from "components/modal/avatarUser/DrawerActionAvatar";
import NavBarMobile from "components/navBarMobile";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { appSettingActions } from "redux/reducers/appSettingReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { postActions } from "redux/reducers/postReducer";
import ModalSettingMyPage from "views/MyPage/component/modal/ModalSettingMyPage";
import { updateUserProfile } from "../common/FuncComm";
import useClearEditData from "../hook/useClearDataEdit";
import { getSetting } from "../redux/actions/settingAction";
import { recallNotice } from "../redux/actions/userAction";
import UserProfileService from "../services/UserProfileService/UserProfileService";
import "./_mainLayout.scss";

function MainLayout({ children, socket }) {
  const pathUrl = window.location.href.replace(window.location.origin, "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector((state) => state.common.loading);
  const checkRd = useSelector((state) => state.loginReducer.isOpenLogin);
  const unfollowUser = useSelector((state) => state.post.unfollowUser);
  const submitLogout = useSelector((state) => state.common.submitLogout);
  const openModalLogin = useSelector((state) => state.auth.openModalLogin);
  const openModalRegister = useSelector(
    (state) => state.auth.openModalRegister
  );
  const TOKEN = localStorage.getItem("token");

  // useEffect(() => {
  //   const handleServerError = () => {
  //     navigate(`${idRouter.apiError500}`);
  //   };

  //   // Add an event listener for the custom event
  //   window.addEventListener("serverError", handleServerError);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener("serverError", handleServerError);
  //   };
  // }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    socket.off("newAlarm");
    socket.on("newAlarm", (message) => {
      if (!localStorage.getItem("token")) {
        return;
      }
      dispatch(recallNotice());
    });
  }, [socket]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await UserProfileService.getUserDetail();
        const data = res?.data?.data ?? {};
        const authority = data?.authority ?? 0;
        const userRole = data?.userRole ?? "";
        const loginMethod = data?.loginMethod ?? null;
        updateUserProfile({ authority, userRole, loginMethod });
      } catch (err) {
        console.error(err);
      }
    }
    if (window.localStorage.getItem("token")) {
      fetchData();
    }
  }, [checkRd]);

  useEffect(() => {
    dispatch(getSetting());
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathUrl]);

  const onLogout = () => {
    if (window.flutter_inappwebview) {
      const { mail } = JSON.parse(localStorage.getItem("user"));
      window.flutter_inappwebview.callHandler("LogoutListener", mail);
    }
    localStorage.clear();
    dispatch(appSettingActions.onCloseModalSetting()); // close modal if logout from setting page
    dispatch(commonActions.onSubmitLogout());
    navigate("/");
    window.location.reload(); // clear reducers
  };

  useClearEditData();

  function handleMessage(event) {
    if (event.origin !== window.location.origin) return;

    if (event.data.type === "SEARCH_RESULT") {
      const newUrl = event.data.url;
      navigate(newUrl);
    }
  }

  return (
    <Suspense fallback={<> </>}>
      <Backdrop
        sx={{
          "& .MuiCircularProgress-circle": {
            stroke: "white"
          },
          color: "#fff",
          zIndex: 2000
        }}
        open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      {children}
      <NavBarMobile />
      {TOKEN && (
        <>
          <ModalMyPortfolio />
          <ModalNewPortfolio />
          <ModalEditPortfolio />
          <AddOver10Portfolio />
          <ModalSelectActionUser />
          <ModalActionComment />
          <CenterConfirmModal
            type="confirm"
            open={unfollowUser !== null}
            textNotice={
              <>
                <h4>{t("info.askedUnfollow")}</h4>
                <h6 className="fw-500">
                  {t("info.submitUnfollow", { unfollowUser })}
                </h6>
              </>
            }
            onCancel={() => dispatch(postActions.finishUnfollow())}
            onSubmit={() => {
              // call api unfollow user
              dispatch(postActions.finishUnfollow());
              successAlert(t("notice.successUnfollow"));
            }}
          />
          <CenterConfirmModal
            open={submitLogout}
            btnText={t("button.yes")}
            btnText2={t("button.no")}
            textNotice={
              <>
                <h4>{t("info.logOut")}</h4>
                <h6 className="fw-500">{t("info.confirmLogout")}</h6>
              </>
            }
            onCancel={() => dispatch(commonActions.onSubmitLogout())}
            onSubmit={onLogout}
          />
        </>
      )}
      <ModalSetting />
      <ModalSelectSetting />
      {openModalLogin && <ModalLoginMobile />}
      {openModalRegister && <ModalRegisterMobile />}
      <ModalRequireLogin />
      <ModalMemberShip />
      <ModalPrivacyPolicy />
      <DrawerActionAvatar />
      <DialogAvatar />
      <ToastContainer limit="3" />
      <ModalSettingMyPage />
      <ModalComprehensiveSearch />
      <ModalExchangeCalculator />
    </Suspense>
  );
}

export default MainLayout;
