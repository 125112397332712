import { XCircle } from "components/icons";
import SvgIcons from "components/svgIcons";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { DeleteButton, Input, InputBarWrap, InputBox } from "./styled";

function InputField({ onInputChange, onEnter }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const isOpen = useSelector((state) => state.common.openNewPortfolio);

  useEffect(() => {
    setInputValue("");
  }, [isOpen]);

  const handleInputChange = (setFieldValue) => (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
    setFieldValue("inputValue", newValue);
  };

  const handleDeleteClick = (setFieldValue) => () => {
    setInputValue("");
    onInputChange(null);
    setFieldValue("inputValue", "");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <InputBarWrap>
      <Formik
        initialValues={{ inputValue: "" }}
        validationSchema={Yup.object({
          inputValue: Yup.string().max(49, t("info.max50length"))
        })}>
        {({ errors, setFieldValue }) => (
          <Form style={{ width: "100%" }} className="flex-col gap-8">
            <InputBox className="_input_box">
              <Input
                type="text"
                placeholder={t("info.portfolioNameRequired")}
                value={inputValue}
                onChange={handleInputChange(setFieldValue)}
                maxLength={50}
                onKeyDown={handleKeyDown}
              />
              <DeleteButton
                onClick={handleDeleteClick(setFieldValue)}
                style={{ display: inputValue ? "flex" : "none" }}>
                <XCircle fillColor="var(--neutral-color-1)" />
              </DeleteButton>
            </InputBox>
            {errors.inputValue && (
              <div className="flex gap-8">
                <SvgIcons name="redAlertCircle" className="svg-24px" />
                <div className="color-neutral">{errors.inputValue}</div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </InputBarWrap>
  );
}

export default InputField;
