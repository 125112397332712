/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChevronRightSvg } from "components/icons";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { myPageActions } from "redux/reducers/myPageReducer";
import { newsActions } from "redux/reducers/newsReducer";
import { idRouter } from "routes/idRouter";
import { Styled } from "./styles";

export default function MenuContainer({
  fakeMenu,
  icon,
  text,
  router,
  noneBorder = false
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleMenuClick = (item) => {
    if (router === "my-page") {
      if (item.id === "내 관심 목록") {
        navigate(`/${idRouter.watchList}`);
      } else if (item.id === "퀴즈결과") {
        navigate("/*");
      } else {
        dispatch(myPageActions.setTabIndex(item.id.toString()));
        dispatch(myPageActions.onOpenSettingModal());
      }
    } else if (router === "community-app") {
      if (item.id === "HOT") {
        dispatch(commonActions.setTabIndexCommunity("hot"));
        navigate(`/${idRouter.communityApp}`);
      } else if (item.id === "LIKE") {
        if (token) {
          navigate(`/${idRouter.communityApp}`);
          dispatch(commonActions.setTabIndexCommunity("like"));
        } else {
          navigate(`/${idRouter.communityApp}`);
          dispatch(commonActions.setTabIndexCommunity("like"));
          dispatch(commonActions.onOpenModalRequireLogin());
        }
      } else {
        navigate(`/${idRouter.freeBoard}`);
      }
    } else if (router === "calculator") {
      dispatch(commonActions.onOpenModalExchangeCalculator());
    } else {
      dispatch(commonActions.setTabIndex(item.id));
      dispatch(newsActions.setTabIndexNews(item.id));
      dispatch(commonActions.setTabIndexCustomerService(item.id));
      dispatch(commonActions.setTabIndexEconomic(item.id));
      navigate(router ? `/${router}` : "/");
    }
  };

  const handleTextClick = () => {
    dispatch(commonActions.setTabIndex(0));
    dispatch(commonActions.setTabIndexEconomic(0));
    dispatch(myPageActions.setTabIndex(0));
    if (router === "calculator") {
      dispatch(commonActions.onOpenModalExchangeCalculator());
    } else {
      navigate(router ? `/${router}` : "/");
    }
  };

  return (
    <Styled noneBorder={noneBorder}>
      <div className="iconText">
        <div className="icon">{icon}</div>
        <div className="text" onClick={() => handleTextClick()}>
          <h4>{text}</h4>
          <ChevronRightSvg />
        </div>
      </div>
      {!isEmpty(fakeMenu) && (
        <div className="_2dep">
          {fakeMenu?.map((item, index) => (
            <div
              className="_txt"
              key={`${item.id}_${index}`}
              onClick={() => handleMenuClick(item)}>
              <h5 className="fw-500">{item.field || item.id}</h5>
            </div>
          ))}
        </div>
      )}
    </Styled>
  );
}
