export default function UserXSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6764 15.6766C2.55782 14.7952 3.75329 14.3 4.9998 14.3H11.9998C13.2463 14.3 14.4418 14.7952 15.3232 15.6766C16.2046 16.5581 16.6998 17.7535 16.6998 19V21C16.6998 21.3866 16.3864 21.7 15.9998 21.7C15.6132 21.7 15.2998 21.3866 15.2998 21V19C15.2998 18.1248 14.9521 17.2855 14.3333 16.6666C13.7144 16.0477 12.875 15.7 11.9998 15.7H4.9998C4.12459 15.7 3.28522 16.0477 2.66635 16.6666C2.04748 17.2855 1.6998 18.1248 1.6998 19V21C1.6998 21.3866 1.3864 21.7 0.999805 21.7C0.613205 21.7 0.299805 21.3866 0.299805 21V19C0.299805 17.7535 0.794982 16.5581 1.6764 15.6766Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4998 3.70005C6.67726 3.70005 5.1998 5.17751 5.1998 7.00005C5.1998 8.82259 6.67726 10.3 8.4998 10.3C10.3223 10.3 11.7998 8.82259 11.7998 7.00005C11.7998 5.17751 10.3223 3.70005 8.4998 3.70005ZM3.7998 7.00005C3.7998 4.40431 5.90407 2.30005 8.4998 2.30005C11.0955 2.30005 13.1998 4.40431 13.1998 7.00005C13.1998 9.59579 11.0955 11.7 8.4998 11.7C5.90407 11.7 3.7998 9.59579 3.7998 7.00005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5048 7.50507C17.7782 7.23171 18.2214 7.23171 18.4948 7.50507L23.4948 12.5051C23.7681 12.7784 23.7681 13.2217 23.4948 13.495C23.2214 13.7684 22.7782 13.7684 22.5048 13.495L17.5048 8.49502C17.2315 8.22166 17.2315 7.77844 17.5048 7.50507Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4948 7.50507C23.7681 7.77844 23.7681 8.22166 23.4948 8.49502L18.4948 13.495C18.2214 13.7684 17.7782 13.7684 17.5048 13.495C17.2315 13.2217 17.2315 12.7784 17.5048 12.5051L22.5048 7.50507C22.7782 7.23171 23.2214 7.23171 23.4948 7.50507Z"
        fill={fillColor}
      />
    </svg>
  );
}
