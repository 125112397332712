/* eslint-disable no-unused-vars */
import { CircleButton56px } from "components/buttons";
import { AlertCircleSvg } from "components/icons";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { EmptyHistoryPage } from "./styled";

function EmptyNotice({ noticeText, borderTop, border, noticeText2 }) {
  const { t } = useTranslation();
  return (
    <EmptyHistoryPage
      borderTop={borderTop}
      border={border}
      className="text-centered">
      <CircleButton56px icon={<AlertCircleSvg />} cursor="default" />
      <h4 style={{ color: "#9497A0" }}>{noticeText || t("info.emptyData")}</h4>
      {noticeText2 ? (
        <h6 className="fw-500 color-info">{noticeText2}</h6>
      ) : null}
    </EmptyHistoryPage>
  );
}
export default memo(EmptyNotice);
