import styled from "styled-components";

export const HeatMapStyled = styled.div`
  display: flex;
  flex-direction: column;
  .scroll {
    flex: 1;
    overflow: auto;
    padding-top: 32px;
  }
  /* .MuiTabs-flexContainer {
    padding-top: 56px;
  } */
  .content_stock {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
  .back-heatmap {
    margin: 12px auto 0px auto;
    padding: 4.5px 16px 4.5px 12px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .flag_time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .date_filter {
        display: flex;
        gap: 8px;
        align-items: center;
        h6 {
          color: #9497a0;
          font-size: 12px;
        }
        .filter {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #373d3f;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .flag {
        width: 32px;
        height: 32px;
        position: relative;
        flex-shrink: 0;
        border-radius: 50%;
        overflow: hidden;
        img {
          display: block;
          width: 32px;
          height: 32px;
          object-fit: cover;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .container_data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      width: calc(100% - 60px);
    }
    .list_data {
      &:first-of-type {
        padding-top: ${(props) => (props.nonePadding ? 0 : "16px")};
        border-top: ${(props) =>
          props.nonePadding ? "none" : "1px solid #f4f4f4"};
      }
    }
  }
`;

export const ButtonContent = styled.button`
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  border-bottom: ${(props) =>
    props.active ? "2px solid var(--neutral-color-1, #11131A)" : "none"};
  h3 {
    color: ${(props) =>
      props.active
        ? "var(--neutral-color-1, #11131A)"
        : "var(--neutral-color-4, #9497A0)"};
  }
`;
export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: ${(props) => (props.pc ? "24px" : 0)}; */
  gap: ${(props) => (props.pc ? "24px" : 0)};
  /* width: 100vw; */
  width: ${(props) => {
    if (props.modalType === "mobile") return "100vw";
    if (props.modalType === "pc-large") return "720px";
    if (props.modalType === "pc-small") return "420px";
    return "100vw";
  }};

  height: ${(props) => {
    if (props.modalType === "mobile") return "100vh";
    if (props.modalType === "pc-large") return "640px";
    if (props.modalType === "pc-small") return "auto";
    return "100vh";
  }};

  background-color: var(--neutral-color-10, #ffffff);
  .mt-16 {
    margin-top: 16px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .body-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -32px;
  }

  .justify-item-center {
    justify-content: center;
  }

  .caption-text p {
    color: var(--neutral-color-4, #9497a0);
  }

  .min-width-40 {
    min-width: 40px;
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
    .close-modal__button {
      width: 40px;
      height: 40px;
      padding: 8px;
      display: block;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;
export const SelfScrollDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SelfScrollRestrictHeight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;
