const URL = Object.freeze({
  CREATE_ROOM: "/api/user/chat/create-room",
  GET_LIST_ROOM: "/api/user/chat/listRoom",
  DELETE_MESSAGE: "/api/user/chat/delete-room",
  GET_ROOM: "/api/user/chat/get-room",
  CHECK_ROOM: "/api/user/chat/check-room",
  SEARCH_NICK_NAME: "/api/user/search-nick-name",
  ATTACHMENT_CHAT: "/api/storage/attachments/chat"
});
export default URL;
