import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Kakao() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      window.opener.postMessage(
        {
          source: {
            sns: "kakao",
            type: new URLSearchParams(location.search).get("state")
          },
          code: new URLSearchParams(location.search).get("code")
        },
        "*"
      );
      window.close();
    }
  }, [location]);

  return null;
}

export default Kakao;
