import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import Header from "components/subHeaderMobile";
import { ModalStyled, SelfScrollDiv } from "views/HeatMap/styled";
import CategoryItem from "components/categoryItem";
import { commonActions } from "redux/reducers/commonReducer";
import { getSelectorList } from "services/HeatMapService/HeatMapService";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";

const ALL = {
  mkThemaMasterUid: "",
  themaLabelEng: "All",
  themaLabelKor: "전체"
};
export default function ModalSelectCategory({
  open,
  onClose,
  searchData,
  setSearchData
}) {
  const [selectorList, setSelectorList] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) getList();
  }, [open]);
  const setLoading = (value) => dispatch(commonActions.setLoading(value));
  const getList = async () => {
    try {
      setLoading(true);
      const res = await getSelectorList({
        country: searchData?.country?.country
      });
      if (res?.code === "200") {
        setSelectorList(res?.data?.sector || []);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header logo={false} borderBottom={false} onBack={onClose} />
        <SelfScrollDiv className="h-100vh hidden-scrollbar">
          <SelfScrollDiv className="hidden-scrollbar">
            <CategoryItem
              item={ALL}
              key="CategoryItemALL"
              active={
                searchData?.mkThemaMasterUid?.themaLabelEng ===
                ALL?.themaLabelEng
              }
              onClick={() => {
                setSearchData((prevState) => ({
                  ...prevState,
                  mkThemaMasterUid: ALL
                }));
                onClose();
              }}
            />
            {isArray(selectorList) &&
              !isEmpty(selectorList) &&
              selectorList?.map((item, index) => (
                <CategoryItem
                  item={item}
                  key={`CategoryItem${index}`}
                  active={
                    searchData?.mkThemaMasterUid?.themaLabelEng ===
                    item?.themaLabelEng
                  }
                  onClick={() => {
                    setSearchData((prevState) => ({
                      ...prevState,
                      mkThemaMasterUid: item
                    }));
                    onClose();
                  }}
                />
              ))}
          </SelfScrollDiv>
        </SelfScrollDiv>
        <ButtonFixedCancel onBack={onClose} />
      </ModalStyled>
    </Modal>
  );
}
