export default function SendSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2998 4.00005C3.2998 3.61345 3.61321 3.30005 3.9998 3.30005H9.9998C10.3864 3.30005 10.6998 3.61345 10.6998 4.00005V10C10.6998 10.3866 10.3864 10.7 9.9998 10.7H3.9998C3.61321 10.7 3.2998 10.3866 3.2998 10V4.00005ZM4.6998 4.70005V9.30005H9.2998V4.70005H4.6998Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2998 4.00005C13.2998 3.61345 13.6132 3.30005 13.9998 3.30005H19.9998C20.3864 3.30005 20.6998 3.61345 20.6998 4.00005V10C20.6998 10.3866 20.3864 10.7 19.9998 10.7H13.9998C13.6132 10.7 13.2998 10.3866 13.2998 10V4.00005ZM14.6998 4.70005V9.30005H19.2998V4.70005H14.6998Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2998 14C13.2998 13.6134 13.6132 13.3 13.9998 13.3H19.9998C20.3864 13.3 20.6998 13.6134 20.6998 14V20C20.6998 20.3867 20.3864 20.7 19.9998 20.7H13.9998C13.6132 20.7 13.2998 20.3867 13.2998 20V14ZM14.6998 14.7V19.3H19.2998V14.7H14.6998Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2998 14C3.2998 13.6134 3.61321 13.3 3.9998 13.3H9.9998C10.3864 13.3 10.6998 13.6134 10.6998 14V20C10.6998 20.3867 10.3864 20.7 9.9998 20.7H3.9998C3.61321 20.7 3.2998 20.3867 3.2998 20V14ZM4.6998 14.7V19.3H9.2998V14.7H4.6998Z"
        fill={fillColor}
      />
    </svg>
  );
}
