import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listPortfolio: [],
  loading: true,
  flag: false,
  currentActive: {
    interestName: null,
    mkMyInterestMasterUid: null,
    defaultYn: "Y"
  },
  lastModified: undefined,
  openModalChangeDefault: false
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setListPortfolio: (state, action) => {
      state.listPortfolio = action?.payload;
    },
    clearPortfolio: (state) => {
      state.listPortfolio = [];
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    updateCurrentPortfolio: (state, action) => {
      state.currentActive.interestName = action?.payload?.interestName;
      state.currentActive.mkMyInterestMasterUid =
        action?.payload?.mkMyInterestMasterUid;
      state.currentActive.defaultYn = action?.payload?.defaultYn;
    },
    setLastModified: (state, action) => {
      state.lastModified = action?.payload;
    },
    onOpenModalChangeDefault: (state) => {
      state.openModalChangeDefault = true;
    },
    onCloseModalChangeDefault: (state) => {
      state.openModalChangeDefault = false;
    },
    onUpdateFlag: (state) => {
      state.flag = !state.flag;
    }
  }
});

// Actions
export const portfolioActions = portfolioSlice.actions;

// Reducer
const portfolioReducer = portfolioSlice.reducer;
export default portfolioReducer;
