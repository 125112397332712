export default function ChevronDownSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.50483 0.505074C0.778197 0.231707 1.22141 0.231707 1.49478 0.505074L6.9998 6.0101L12.5048 0.505074C12.7782 0.231707 13.2214 0.231707 13.4948 0.505074C13.7681 0.778441 13.7681 1.22166 13.4948 1.49502L7.49478 7.49502C7.22141 7.76839 6.7782 7.76839 6.50483 7.49502L0.50483 1.49502C0.231463 1.22166 0.231463 0.778441 0.50483 0.505074Z"
        fill={fillColor}
      />
    </svg>
  );
}
