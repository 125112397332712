export default function AlarmFillSvg({ fillColor = "#1554F6" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26271 4.16234C8.51921 2.90584 10.2234 2.19995 12.0003 2.19995C13.7773 2.19995 15.4814 2.90584 16.7379 4.16234C17.9944 5.41883 18.7003 7.123 18.7003 8.89995C18.7003 13.5454 20.0593 15.8319 20.8323 16.7624C21.1456 17.1395 21.1007 17.6092 20.9839 17.9007C20.8683 18.1893 20.5553 18.6 20.0095 18.6H3.99116C3.4454 18.6 3.13232 18.1893 3.01674 17.9007C2.89997 17.6092 2.85505 17.1395 3.16834 16.7624C3.94131 15.8319 5.30033 13.5454 5.30033 8.89995C5.30033 7.123 6.00622 5.41883 7.26271 4.16234Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9187 20.1947C10.2531 20.0007 10.6815 20.1146 10.8754 20.449C10.9897 20.646 11.1537 20.8095 11.3511 20.9232C11.5484 21.0368 11.7722 21.0967 11.9999 21.0967C12.2277 21.0967 12.4514 21.0368 12.6488 20.9232C12.8461 20.8095 13.0102 20.646 13.1244 20.449C13.3184 20.1146 13.7468 20.0007 14.0812 20.1947C14.4156 20.3887 14.5294 20.817 14.3354 21.1515C14.0981 21.5606 13.7574 21.9002 13.3475 22.1363C12.9377 22.3724 12.473 22.4967 11.9999 22.4967C11.5269 22.4967 11.0622 22.3724 10.6523 22.1363C10.2425 21.9002 9.90178 21.5606 9.66444 21.1515C9.47046 20.817 9.58429 20.3887 9.9187 20.1947Z"
        fill={fillColor}
      />
    </svg>
  );
}
