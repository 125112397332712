export default function NoteSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.7001 16.2L17.5 11.6V1.8C17.5 1.7 17.5 1.6 17.5 1.6C17.5 1.6 17.5 1.6 17.5 1.5C17.5 1.4 17.4 1.4 17.4 1.3C17.3 1.2 17.3 1.2 17.2 1.2C17.1 1.2 17.1 1.2 17 1.2H5.20005C5.10005 1.2 5.10005 1.2 5.00005 1.2C4.90005 1.2 4.90005 1.3 4.80005 1.3C4.80005 1.3 4.70005 1.4 4.70005 1.5C4.70005 1.5 4.70005 1.5 4.70005 1.6C4.70005 1.7 4.70005 1.8 4.70005 1.8V11.5L1.50005 14.2C1.40005 14.3 1.30005 14.5 1.30005 14.7C1.30005 14.9 1.40005 15.1 1.50005 15.2L6.90005 20.6C7.20005 20.9 7.60005 20.9 7.90005 20.6L10.8 17.7L16.4 22.7C16.7 22.9 17.1 22.9 17.4 22.7L22.8 17.3C22.9 17.2 23 17 23 16.8C22.9 16.5 22.8001 16.4 22.7001 16.2ZM12 6.8L16.1 3.3V10.4L12 6.8ZM14.9 2.5L11 5.9L7.10005 2.5H14.9ZM7.20005 19.2L2.80005 14.8L5.00005 12.9L9.50005 16.9L7.20005 19.2ZM16.7 21.2L11.2 16.3C11.2 16.3 11.2 16.2 11.1 16.2C11 16.1 11 16.1 10.9 16.1L6.10005 11.8L5.90005 11.6V10.2V3.3L9.90005 6.8L11 7.7L16.3 12.4L16.5 12.5L21.3 16.7L16.7 21.2Z"
        fill={fillColor}
      />
    </svg>
  );
}
