export default function FilterSvg({
  width = "24",
  height = "24",
  fillColor = "#11131A"
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.36508 4.00548C1.47978 3.75827 1.72753 3.6001 2.00006 3.6001H22.0001C22.2726 3.6001 22.5203 3.75827 22.635 4.00548C22.7497 4.2527 22.7105 4.54401 22.5346 4.75211L14.7001 14.0164V22.3001C14.7001 22.5427 14.5744 22.768 14.3681 22.8956C14.1617 23.0231 13.904 23.0347 13.687 22.9262L9.68701 20.9262C9.44986 20.8076 9.30006 20.5652 9.30006 20.3001V14.0164L1.46556 4.75211C1.28958 4.54401 1.25037 4.2527 1.36508 4.00548ZM3.50877 5.0001L10.5346 13.3081C10.6414 13.4345 10.7001 13.5946 10.7001 13.7601V19.8675L13.3001 21.1675V13.7601C13.3001 13.5946 13.3587 13.4345 13.4656 13.3081L20.4913 5.0001H3.50877Z"
        fill={fillColor}
      />
    </svg>
  );
}
