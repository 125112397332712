export default function MaximizeSvg({ fillColor = "#ffffff" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.725 2.25001C10.725 1.96006 10.96 1.72501 11.25 1.72501H15.75C16.0399 1.72501 16.275 1.96006 16.275 2.25001V6.75001C16.275 7.03996 16.0399 7.27501 15.75 7.27501C15.46 7.27501 15.225 7.03996 15.225 6.75001V2.77501H11.25C10.96 2.77501 10.725 2.53996 10.725 2.25001Z"
        fill={fillColor || "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24998 10.725C2.53993 10.725 2.77498 10.9601 2.77498 11.25V15.225H6.74998C7.03993 15.225 7.27498 15.4601 7.27498 15.75C7.27498 16.04 7.03993 16.275 6.74998 16.275H2.24998C1.96003 16.275 1.72498 16.04 1.72498 15.75V11.25C1.72498 10.9601 1.96003 10.725 2.24998 10.725Z"
        fill={fillColor || "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1212 1.87878C16.3262 2.0838 16.3262 2.41621 16.1212 2.62124L10.8712 7.87124C10.6662 8.07626 10.3338 8.07626 10.1287 7.87124C9.92372 7.66621 9.92372 7.3338 10.1287 7.12878L15.3787 1.87878C15.5838 1.67375 15.9162 1.67375 16.1212 1.87878Z"
        fill={fillColor || "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87121 10.1288C8.07623 10.3338 8.07623 10.6662 7.87121 10.8712L2.62121 16.1212C2.41618 16.3263 2.08377 16.3263 1.87874 16.1212C1.67372 15.9162 1.67372 15.5838 1.87874 15.3788L7.12874 10.1288C7.33377 9.92375 7.66618 9.92375 7.87121 10.1288Z"
        fill={fillColor || "white"}
      />
    </svg>
  );
}
