import {
  EDIT_PROFILE,
  WITHDRAW,
  CHANGE_PASSWORD,
  CHANGE_INFORMATION,
  RESET_ALL
} from "../type/editType";

export const editProfileAction = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_PROFILE, payload });
};

export const withdrawAction = (payload) => async (dispatch) => {
  dispatch({ type: WITHDRAW, payload });
};

export const changePasswordAction = (payload) => async (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD, payload });
};

export const changeInformationAction = (payload) => async (dispatch) => {
  dispatch({ type: CHANGE_INFORMATION, payload });
};

export const resetAll = () => async (dispatch) => {
  dispatch({ type: RESET_ALL });
};
