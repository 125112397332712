export default function AlarmSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26271 4.16234C8.51921 2.90584 10.2234 2.19995 12.0003 2.19995C13.7773 2.19995 15.4814 2.90584 16.7379 4.16234C17.9944 5.41883 18.7003 7.123 18.7003 8.89995C18.7003 13.5454 20.0593 15.8319 20.8323 16.7624C21.1456 17.1395 21.1007 17.6092 20.9839 17.9007C20.8683 18.1893 20.5553 18.6 20.0095 18.6H3.99116C3.4454 18.6 3.13232 18.1893 3.01674 17.9007C2.89997 17.6092 2.85505 17.1395 3.16834 16.7624C3.94131 15.8319 5.30033 13.5454 5.30033 8.89995C5.30033 7.123 6.00622 5.41883 7.26271 4.16234ZM12.0003 3.59995C10.5947 3.59995 9.2466 4.15834 8.25266 5.15229C7.25872 6.14623 6.70033 7.4943 6.70033 8.89995C6.70033 13.2898 5.55243 15.8406 4.59448 17.2H19.4062C18.4482 15.8406 17.3003 13.2898 17.3003 8.89995C17.3003 7.4943 16.7419 6.14623 15.748 5.15229C14.7541 4.15834 13.406 3.59995 12.0003 3.59995Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91919 20.1947C10.2536 20.0007 10.6819 20.1146 10.8759 20.449C10.9902 20.646 11.1542 20.8095 11.3516 20.9232C11.5489 21.0368 11.7727 21.0967 12.0004 21.0967C12.2282 21.0967 12.4519 21.0368 12.6493 20.9232C12.8466 20.8095 13.0106 20.646 13.1249 20.449C13.3189 20.1146 13.7473 20.0007 14.0817 20.1947C14.4161 20.3887 14.5299 20.817 14.3359 21.1515C14.0986 21.5606 13.7579 21.9002 13.348 22.1363C12.9382 22.3724 12.4734 22.4967 12.0004 22.4967C11.5274 22.4967 11.0627 22.3724 10.6528 22.1363C10.2429 21.9002 9.90227 21.5606 9.66493 21.1515C9.47094 20.817 9.58478 20.3887 9.91919 20.1947Z"
        fill={fillColor}
      />
    </svg>
  );
}
