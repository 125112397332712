import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  changeInformationAction,
  changePasswordAction,
  editProfileAction,
  withdrawAction
} from "../redux/actions/editAction";

function useClearEditData() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      window.location.pathname !== "/profile" &&
      window.location.pathname !== "/my_page/userInfo"
    ) {
      dispatch(editProfileAction(null));
    }
    if (
      window.location.pathname !== "/edit_infomation" &&
      window.location.pathname !== "/my_page/edit_information"
    ) {
      dispatch(changeInformationAction(null));
    }
    if (
      window.location.pathname !== "/edit_infomation/change_password" &&
      window.location.pathname !== "/my_page/change_password"
    ) {
      dispatch(changePasswordAction(null));
    }

    if (
      window.location.pathname !== "/edit_infomation/membership_withdrawal" &&
      window.location.pathname !== "/my_page/membership_withdrawal"
    ) {
      dispatch(withdrawAction(null));
    }
  }, [window.location.pathname]);

  return null;
}

export default useClearEditData;
