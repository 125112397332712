export const idRouter = {
  watchList: "watch-list",
  recentViewDetail: "recent-view-detail",
  searchInterest: "search-interest",
  communityApp: "community-app",
  discussion: "discussion",
  portfolioDetail: "portfolio-detail",
  interestDetail: "interest-detail",
  tiny: "tiny",
  heatMap: "heat-map",
  economicCalendar: "economic-calendar",
  economicIndicatorDetail: "economic-indicator-detail",
  economicThemaDetail: "economic-thema-detail",
  morePage: "more-page",
  majorCryptoCurrency: "major-crypto-currency",
  majorIndices: "major-indices",
  top100Crypto: "top-100-crypto",
  top100HighestVolume: "top-100-trading-volume",
  topLowestPointStock: "top-lowest-point-stock",
  top100Thema: "top-thema",
  top100Etf: "top-100-etf",
  top100Subject: "top-subject",
  top20DiscussionRoom: "top-discussion-room",
  technicalDetail: "technical-detail",
  dailyPrice: "daily-price",
  components: "components",
  viewChartImage: "view-chart-image",
  realtimeExchangeRate: "realtime-exchange-rate",
  detailPrediction: "detail-prediction",
  themeDetail: "theme-detail",
  themeDebate: "theme-debate",
  top100Industry: "top-industry",
  majorEtf: "major-etf",
  topTrader: "top-traders",
  traderDetail: "trader-detail",
  topPopular: "top-popular",
  realTime: "real-time",
  sendNote: "message-test",
  chatting: "chatting",
  chatList: "chat-list",
  reportingList: "your-block",
  profile: "user",
  follow: "follow",
  svgIcon: "svg-icon", // svg icon
  writePost: "write",
  freeBoard: "free-board",
  apiError500: "error-500",
  notifications: "notifications",
  myPage: "my-page",
  messageBox: "message-box",
  writeANote: "write-a-note",
  customerService: "customer-service",
  appNoticeDetail: "app-notice-detail",
  newsAndAnalysis: "news-and-analysis",
  news: "news",
  postDetail: "post",
  overseaCompanies: "oversea-companies",
  privacy: "privacy-user",
  calendar: "calendar",
  themeDetailOverview: "theme-detail-overview",
  dailyQuotes: "daily-quotes"
};
