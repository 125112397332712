/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import { AppBar, Dialog } from "@mui/material";
import classNames from "classnames";
import SvgIcons from "components/svgIcons";
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { DatePickerStyled2, FooterCalendarStyled } from "./styled";

export default function ModalDatePicker({
  currentDate,
  isOpen,
  closeModal,
  setTime
}) {
  const dateRef = useRef();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(currentDate || new Date());

  useEffect(() => {
    if (currentDate && isOpen) setValue(currentDate);
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "4px",
          padding: "8px 4px 16px 4px",
          width: "min-content",
          minWidth: 311
        },
        "& .MuiPaper-root header": {
          padding: "0 !important",
          width: "unset",
          minWidth: "unset"
        }
      }}>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none"
        }}
        color="inherit">
        <DatePickerStyled2>
          <Calendar
            prevLabel={<SvgIcons name="prev2Icon" />}
            nextLabel={<SvgIcons name="next2Icon" />}
            formatDay={(_, date) => (
              <div
                className={classNames("date-format", {
                  "disable-date":
                    new Date(date).getTime() > new Date().getTime()
                })}>
                {date.getDate()}
              </div>
            )}
            onChange={setValue}
            value={value}
            locale={i18n.language}
            inputRef={dateRef}
            next2Label={null}
            prev2Label={null}
            showNeighboringMonth={false}
            calendarType="iso8601"
          />
        </DatePickerStyled2>
        <FooterCalendarStyled>
          <button type="button" onClick={closeModal}>
            {t("button.cancel")}
          </button>
          <button
            type="button"
            onClick={() => {
              setTime(value);
              closeModal();
            }}>
            {t("button.confirm")}
          </button>
        </FooterCalendarStyled>
      </AppBar>
    </Dialog>
  );
}
