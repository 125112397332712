/* eslint-disable no-lonely-if */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import { successAlert } from "components/alert";
import CenterConfirmModal from "components/centerConfirmModal";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appSettingActions } from "redux/reducers/appSettingReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { getListSetting, resetListSetting } from "services/SettingService";
import { updateArrayWithMissingData } from "util/common";
import SettingItem from "../../views/MorePage/component/SettingItem";
import ModalChangeDefaultPortfolio from "./ModalChangeDefaultPortfolio";

export default function ModalSetting() {
  const dispatch = useDispatch();
  const [listSetting, setListSetting] = useState([]);
  // const [modalSelectSetting, setModalSelectSetting] = useState(false);
  const [openSubmitReset, setSubmitReset] = useState(false);
  const kindOfSelect = useSelector((state) => state?.appSetting?.kindOfSelect);
  const open = useSelector((state) => state?.appSetting?.openModalSetting);
  const defaultSetting = useSelector(
    (state) => state?.appSetting?.defaultSetting
  );
  const currentSetting = useSelector(
    (state) => state?.appSetting?.currentSetting
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (open || kindOfSelect === null) {
      getListCurrentSetting();
    }
  }, [open, kindOfSelect, localStorage.getItem("token")]);

  const onClose = () => dispatch(appSettingActions.onCloseModalSetting());

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const getListCurrentSetting = async () => {
    try {
      setIsLoading(true);
      const res = await getListSetting();
      if (res?.code === "200" && res?.data?.list !== "no data") {
        const newListSetting =
          !isEmpty(res?.data?.list) && isArray(res?.data?.list)
            ? res?.data?.list
            : [];
        setListSetting(newListSetting);
        const defineSetting = await getDefaultSetting(newListSetting); // setting default by api
        const localSettings = JSON.parse(localStorage.getItem("userSettings"));
        const currentDataSetting =
          !isEmpty(res?.data?.userSetting) && isArray(res?.data?.userSetting)
            ? res?.data?.userSetting
            : localSettings;

        const finalSetting = updateArrayWithMissingData(
          updateArrayWithMissingData(currentDataSetting, currentSetting || []),
          defineSetting
        );
        dispatch(appSettingActions.updateCurrentSetting(finalSetting));
        localStorage.setItem("userSettings", JSON.stringify(finalSetting)); // save local to easy language
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getDefaultSetting = async (allSetting) => {
    const newDefaultList =
      allSetting?.flatMap((obj) =>
        obj.listSetting
          .filter(
            (itemSetting) =>
              itemSetting?.optionType === "switch" ||
              itemSetting?.optionType === "select"
          )
          .map(({ currentValue, optionKind }) => {
            if (optionKind === "Language") {
              const { languages } = navigator;
              const isKorean =
                languages.includes("ko") || languages.includes("ko-KR");
              return isKorean
                ? {
                    filePath:
                      "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
                    labelEng: "Korea",
                    labelKor: "한국어",
                    optionKind: "Language",
                    optionValue: "KOR" // only need this, can remove the other param
                  }
                : {
                    filePath:
                      "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
                    labelEng: "English",
                    labelKor: "영어",
                    optionKind: "Language",
                    optionValue: "USA"
                  };
            }
            return {
              labelKor: currentValue[0]?.labelKor,
              labelEng: currentValue[0]?.labelEng,
              optionKind,
              filePath: currentValue[0]?.filePath,
              optionValue: currentValue[0]?.optionValue
            };
          })
      ) || [];
    dispatch(appSettingActions.updateDefaultSettings(newDefaultList));
    return newDefaultList;
  };

  const resetSetting = async () => {
    try {
      setIsLoading(true);
      if (localStorage.getItem("token")) {
        const res = await resetListSetting();
        if (res?.code === "200") {
          successAlert(t("notice.resetSuccess"));
          // await getListCurrentSetting();
          dispatch(appSettingActions.updateCurrentSetting(defaultSetting));
          // have default so dont need recall kind
        }
      } else if (defaultSetting !== null) {
        localStorage.setItem("userSettings", JSON.stringify(defaultSetting));
        dispatch(appSettingActions.updateCurrentSetting(defaultSetting));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const afterUpdate = useCallback(() => getListCurrentSetting(), []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex-col h-100vh hidden-scrollbar bg-neutral-10">
        <Header
          borderBottom
          onBack={onClose}
          headerTitle={<h1>{t("button.setting")}</h1>}
          customAction={
            <button
              type="button"
              className="btn-40px-end"
              onClick={() => setSubmitReset(true)}>
              <SvgIcons name="refresh" className="h-24px" />
            </button>
          }
        />
        <div className="pt-8 hidden-scrollbar flex-col flex-1 full-height flow-y-auto gap-24">
          {!isEmpty(listSetting) &&
            isArray(listSetting) &&
            listSetting?.map((groupSetting, groupIndex) => (
              <div
                className="flex-col"
                key={`${groupSetting?.labelEng}_${groupIndex}`}>
                <h6 className="fw-500 color-info pd-12-20">
                  {i18n.language === "ko"
                    ? groupSetting?.labelKor || groupSetting?.labelEng
                    : groupSetting?.labelEng || groupSetting?.labelKor}
                </h6>
                {isArray(groupSetting?.listSetting) &&
                  !isEmpty(groupSetting?.listSetting) &&
                  groupSetting?.listSetting.map((itemSetting, itemIndex) => (
                    <SettingItem
                      item={itemSetting}
                      key={`${groupSetting?.labelEng}_${groupIndex}0_${itemSetting?.labelEng}_${itemIndex}`}
                      afterUpdate={afterUpdate}
                    />
                  ))}
              </div>
            ))}
        </div>
        <CenterConfirmModal
          open={openSubmitReset}
          btnText={t("button.yes")}
          btnText2={t("button.no")}
          textNotice={
            <>
              <h4>{t("notice.noticeModalSetting")}</h4>
              <h6 className="fw-500">{t("notice.noticeModalSetting1")}</h6>
            </>
          }
          onCancel={() => setSubmitReset(false)}
          onSubmit={async () => {
            await resetSetting();
            setSubmitReset(false);
          }}
        />
        <ModalChangeDefaultPortfolio />
      </div>
    </Modal>
  );
}
