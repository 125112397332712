export default function ChartBaseLine() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7594_6009)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6148 2.95991C19.821 3.2074 19.7876 3.57523 19.5401 3.78148L11.6234 10.3787C11.4071 10.559 11.0929 10.559 10.8766 10.3787L7.08333 7.21768L1.20677 12.1148C0.959279 12.3211 0.591449 12.2876 0.385204 12.0401C0.178958 11.7926 0.212397 11.4248 0.459892 11.2186L6.70989 6.01022C6.92622 5.82995 7.24045 5.82995 7.45677 6.01022L11.25 9.17124L18.7932 2.88522C19.0407 2.67897 19.4086 2.71241 19.6148 2.95991Z"
          fill="#F20013"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.7 15.8333C-1.7 15.5112 -1.3866 15.25 -1 15.25H21C21.3866 15.25 21.7 15.5112 21.7 15.8333C21.7 16.1555 21.3866 16.4167 21 16.4167H-1C-1.3866 16.4167 -1.7 16.1555 -1.7 15.8333Z"
          fill="#45495A"
        />
      </g>
      <defs>
        <clipPath id="clip0_7594_6009">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
