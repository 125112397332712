/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { RefreshSvg } from "components/icons";
import { useTranslation } from "react-i18next";
import { ButtonRefresh, ButtonSubmit, FormBtnSubmit } from "../styled";

export default function FooterResetSubmit({
  textCancle,
  textSubmit,
  onReset,
  onCancle,
  onSubmit,
  pc
}) {
  const { t } = useTranslation();

  return (
    <FormBtnSubmit pc={pc}>
      <div className="flex-centered">
        {onReset && !pc && (
          <ButtonRefresh onClick={onReset}>
            <RefreshSvg />
          </ButtonRefresh>
        )}
        {onReset && pc && (
          <button
            type="button"
            className="btn-40px-center border-1px-neutral-7 br-20"
            onClick={onReset}>
            <RefreshSvg />
          </button>
        )}
      </div>
      <div className={classNames("flex gap-8")}>
        <ButtonSubmit submitType="cancel" onClick={onCancle}>
          {textCancle || t("button.cancel")}
        </ButtonSubmit>
        <ButtonSubmit submitType="save" onClick={onSubmit}>
          {textSubmit || t("button.save")}
        </ButtonSubmit>
      </div>
    </FormBtnSubmit>
  );
}
