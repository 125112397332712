export default function WhiteFilter({ open }) {
  return (
    <div
      style={{
        display: open ? "block" : "none",
        background: "#fff",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 800,
        opacity: 0.8
      }}>
      {" "}
    </div>
  );
}
