/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { warningAlert } from "components/alert";
import SvgIcons from "components/svgIcons";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { myPageActions } from "redux/reducers/myPageReducer";
import AvatarImage from "./AvatarImage";

function AvatarUser({
  size = "80px",
  iconName = "camera",
  position = "2px",
  onClick
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOpenDrawerAvatar = () =>
    dispatch(myPageActions.onOpenDrawerAvatar());

  const handleCloseDrawerAvatar = () =>
    dispatch(myPageActions.onCloseDrawerAvatar());

  const handleSettingAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        // limit 20MB - Mr.K
        warningAlert(t("notice.avatarLimit20"));
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          dispatch(
            myPageActions.updateAvatar({
              avatarFile: file,
              avatarUrl: reader.result
            })
          );
        };
        reader.readAsDataURL(file);
      }
    }
    handleCloseDrawerAvatar();
  };

  return (
    <div className="flex-centered">
      <button
        type="button"
        onClick={onClick || handleOpenDrawerAvatar}
        className="pos-relative fit-width fit-height">
        <AvatarImage size={size} />
        <div
          style={{
            position: "absolute",
            bottom: position,
            right: position
          }}>
          <input
            type="file"
            accept="image/*"
            name="input_avatar"
            id="input_avatar"
            onChange={(e) => handleSettingAvatar(e)}
            style={{
              display: "none"
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <SvgIcons name={iconName} />
        </div>
      </button>
    </div>
  );
}
export default memo(AvatarUser);
