export default function HelpCircleSvg({
  fillColor = "#11131A",
  width = "24",
  height = "24"
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 2.70005C6.86356 2.70005 2.6998 6.8638 2.6998 12C2.6998 17.1363 6.86356 21.3 11.9998 21.3C17.1361 21.3 21.2998 17.1363 21.2998 12C21.2998 6.8638 17.1361 2.70005 11.9998 2.70005ZM1.2998 12C1.2998 6.0906 6.09036 1.30005 11.9998 1.30005C17.9093 1.30005 22.6998 6.0906 22.6998 12C22.6998 17.9095 17.9093 22.7001 11.9998 22.7001C6.09036 22.7001 1.2998 17.9095 1.2998 12Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3087 7.72852C11.7733 7.6367 11.2228 7.7373 10.7545 8.01251C10.2862 8.28773 9.93042 8.71979 9.75017 9.23218C9.62188 9.59687 9.22224 9.78851 8.85755 9.66022C8.49286 9.53193 8.30121 9.13229 8.42951 8.7676C8.71947 7.94332 9.29179 7.24826 10.0451 6.80553C10.7984 6.36279 11.6841 6.20095 12.5454 6.34868C13.4066 6.4964 14.1877 6.94414 14.7504 7.61261C15.3131 8.28102 15.6211 9.12698 15.6198 10.0007C15.6194 11.3719 14.6003 12.3042 13.8081 12.8323C13.3907 13.1106 12.9782 13.3163 12.6729 13.4521C12.5192 13.5204 12.3902 13.572 12.2979 13.6072C12.2518 13.6247 12.2146 13.6383 12.188 13.6477L12.1562 13.6589L12.1465 13.6622L12.1433 13.6633L12.1421 13.6637C12.1419 13.6637 12.1412 13.664 11.9198 12.9999L12.1421 13.6637C11.7754 13.7859 11.378 13.588 11.2558 13.2212C11.1336 12.8548 11.3314 12.4588 11.6975 12.3361C11.6974 12.3362 11.6976 12.3361 11.6975 12.3361C11.6976 12.3361 11.6984 12.3358 11.6985 12.3358L11.7007 12.335L11.719 12.3286C11.7364 12.3224 11.7637 12.3125 11.7995 12.2989C11.8714 12.2715 11.9767 12.2294 12.1043 12.1727C12.3615 12.0584 12.699 11.8892 13.0315 11.6675C13.7393 11.1956 14.2198 10.6283 14.2198 9.99989L14.2198 9.99884C14.2206 9.45568 14.0292 8.92975 13.6794 8.51422C13.3296 8.09868 12.844 7.82035 12.3087 7.72852Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2998 17C11.2998 16.6134 11.6132 16.3 11.9998 16.3H12.0098C12.3964 16.3 12.7098 16.6134 12.7098 17C12.7098 17.3866 12.3964 17.7 12.0098 17.7H11.9998C11.6132 17.7 11.2998 17.3866 11.2998 17Z"
        fill={fillColor}
      />
    </svg>
  );
}
