import { createSlice } from "@reduxjs/toolkit";
import { warningAlert } from "components/alert";
import { isKorean } from "util/common";

const initialState = {
  unfollowUser: null,
  modalOpen: false,
  memberData: "", // this is the user that your action is towards,
  reportPostList: "",
  reportId: null,
  blockId: null,
  followId: null,
  commentOpen: false,
  commentData: null,
  postList: [],
  userDeletePostList: [],
  commentIdEdit: "",
  flag: false,
  sharedObject: null,
  blockFlag: false,
  modalAccessAlbum: false,
  blockType: null,
  discussionRoomLst: [],
  isEditUrl: false,
  previousPage: null,
  countAllowOpenAlbum: 0,
  anchorEl: null,
  idPostEdit: null,
  openModalThemaSearch: false
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    onUnfollow: (state, action) => {
      state.unfollowUser = action?.payload;
    },
    finishUnfollow: (state) => {
      state.unfollowUser = null;
    },
    onOpenModalAction: (state, action) => {
      const token = localStorage.getItem("token");
      if (!token) {
        warningAlert(
          isKorean
            ? "로그인이 필요한 서비스입니다."
            : "This service requires login."
        );
        return;
      }
      state.memberData = action?.payload;
      state.modalOpen = true;
    },
    onOpenCommentModal: (state, action) => {
      state.commentData = action?.payload;
      state.commentOpen = true;
    },
    onSelectTarget: (state, action) => {
      state.commentData = action?.payload;
    },
    onAnchorEl: (state, action) => {
      state.anchorEl = action?.payload;
    },
    onCloseCommentModal: (state) => {
      state.commentOpen = false;
    },
    onCloseModalAction: (state) => {
      state.modalOpen = false;
    },
    onEditPost: (state, action) => {
      state.idPostEdit = action?.payload;
    },
    onReportPost: (state, action) => {
      state.reportPostList = action?.payload;
    },
    onChangeFollowList: (state, action) => {
      state.postList = action.payload;
    },
    onChangeFollowStatus: (state, action) => {
      const { id = "", followYn = "" } = action.payload;
      state.postList = state.postList.map((item) => {
        const listUserId = [
          item?.id,
          item?.userId,
          item?.followingUserId,
          item?.followUserId
        ];
        if (listUserId.includes(id)) {
          return {
            ...item,
            followYn
          };
        }
        return item;
      });
    },
    onDeletePost: (state, action) => {
      state.userDeletePostList = [...state.userDeletePostList, action?.payload];
    },
    onEditComment: (state, action) => {
      state.commentIdEdit = action?.payload;
    },
    onChangeFlag: (state) => {
      state.flag = !state.flag;
    },
    onChangeSearchKeyFreeBoard: (state, action) => {
      state.searchKeys = action?.payload;
    },
    onSetShareObject: (state, action) => {
      state.sharedObject = action?.payload;
    },
    onBlockUser: (state, action) => {
      state.blockFlag = action?.payload;
    },
    onChangeBlockType: (state, action) => {
      state.blockType = action?.payload;
    },
    onOpenModalAccesAlbum: (state) => {
      state.modalAccessAlbum = true;
    },
    onCloseModalAccesAlbum: (state) => {
      state.modalAccessAlbum = false;
    },
    updateDiscussionRoomLst: (state, action) => {
      state.discussionRoomLst = action?.payload;
    },
    onChangeEditUrl: (state, action) => {
      state.isEditUrl = action.payload;
    },
    onChangePreviousPage: (state, action) => {
      state.previousPage = action.payload;
    },
    onAllowOpenAlbum: (state) => {
      state.countAllowOpenAlbum += 1;
    },
    onOpenModalThemaSearch: (state) => {
      state.openModalThemaSearch = true;
    },
    onCloseModalThemaSearch: (state) => {
      state.openModalThemaSearch = false;
    }
  }
});

// Actions
export const postActions = postSlice.actions;

// Reducer
const postReducer = postSlice.reducer;
export default postReducer;
