/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from "styled-components";
import { Button, Menu, MenuItem } from "@mui/material";
import classNames from "classnames";
import { successAlert } from "components/alert";
import {
  AlarmActiveSvg,
  AlarmPlusSvg,
  AlarmSvg,
  AlignLeftSvg,
  ArrowLeft,
  BookMarkSvg,
  Calendar3Svg,
  ChartSvg,
  CheckboxUncheckedSvg,
  FilterSvg,
  GridSvg,
  LoginSvg,
  LogoFinchiveSvg,
  MoreNavBarIconSvg,
  PlusSvg,
  SearchSvg,
  SettingSvg,
  ShareSvg
} from "components/icons";
import SvgIcons from "components/svgIcons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { appSettingActions } from "redux/reducers/appSettingReducer";
import { authActions } from "redux/reducers/authReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { idRouter } from "routes/idRouter";
import { getMyListPortfolio, updatePortfolio } from "services/PofolioService";

const HeaderMobileStyled = styled.div`
  width: 100%;
  height: fit-content;
  padding: ${(props) =>
    props?.logo
      ? props.borderBottom
        ? "12px 20px 11px 20px"
        : "12px 20px"
      : props.borderBottom
      ? "8px 20px 7px 20px"
      : "8px 20px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => {
    if (props.borderBottom) return "1px solid var(--neutral-color-8, #f4f4f4)";
    return "none";
  }};
  background-color: var(--neutral-color-10);

  .header-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h1 {
      margin-top: 3px;
    }
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 8px;
    .icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0 8px 16px;
    }
    .flag {
      width: 40px;
      height: 40px;
      padding: 8px 0 8px 16px;
      flex-shrink: 0;
      /* border-radius: 50%; */
      overflow: hidden;
      img {
        display: block;
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .header-right-v1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0px;
    button {
      padding: 0;
    }
  }
`;
const MenuDropDownStyled = styled(Menu)`
  border-radius: 2px;
  .MuiPopover-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: var(--neutral-color-2, #373d3f);
  }
  ul {
    padding: 12px 40px 12px 24px;
  }
  li {
    padding: 0;
  }
  h4 {
    color: var(--neutral-color-10, #ffffff);
  }
  h4.current {
    color: var(--secondary-color-1, #f4cb5d);
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`;

function DropDownMore() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentPortfolio = useSelector(
    (state) => state.portfolio.currentActive
  );
  const listPortfolio = useSelector((state) => state.portfolio.listPortfolio);

  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  const changePortfolioDefault = async () => {
    try {
      setLoading(true);
      const bodyUpdate = {
        mkMyInterestMasterUid: currentPortfolio?.mkMyInterestMasterUid,
        interestName: currentPortfolio?.interestName,
        defaultYn: "Y"
      };
      const res = await updatePortfolio(bodyUpdate);
      if (res.code === "200") {
        successAlert(t("notice.updateDefaultPortfolio"));
        dispatch(portfolioActions.updateCurrentPortfolio(bodyUpdate));
        const ress = await getMyListPortfolio({
          currentPage: 1,
          pageSize: 10
        });
        if (ress.code === "200")
          dispatch(portfolioActions.setListPortfolio(ress?.data?.list || []));
      }
    } catch (e) {
      console.error(e);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const onOpenNew = () => {
    if (listPortfolio?.length >= 10)
      dispatch(commonActions.onOpenAddOver10Portfolio());
    else dispatch(commonActions.onOpenNewPortfolio());
    setAnchorEl(null);
  };

  const onOpenUpdate = useCallback(() => {
    dispatch(commonActions.onOpenUpdatePortfolio());
    setAnchorEl(null);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="btn-show-more"
        aria-controls={open ? "dropdown-more-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        sx={{ minWidth: "40px" }} // override minwidth 64px default
      >
        <MoreNavBarIconSvg fillColor="#11131A" />
      </Button>
      <MenuDropDownStyled
        id="dropdown-more-menu"
        MenuListProps={{
          "aria-labelledby": "btn-show-more"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}>
        {!(
          !listPortfolio ||
          !isArray(listPortfolio) ||
          isEmpty(listPortfolio)
        ) ? (
          <>
            <MenuItem disableRipple>
              {currentPortfolio?.defaultYn === "Y" ? (
                <h4 className="lh-48 fw-500 current">
                  {t("info.myDefaultPortfolio")}
                  <CheckboxUncheckedSvg fillColor="var(--secondary-color-1)" />
                </h4>
              ) : (
                <h4 className="lh-48 fw-500" onClick={changePortfolioDefault}>
                  {t("info.setAsMyDefaultPortfolio")}
                </h4>
              )}
            </MenuItem>
            <MenuItem onClick={onOpenUpdate} disableRipple>
              <h4 className="lh-48 fw-500">{t("button.editPortfolio")}</h4>
            </MenuItem>
          </>
        ) : null}
        <MenuItem onClick={onOpenNew} disableRipple>
          <h4 className="lh-48 fw-500">{t("info.createNewPortfolio")}</h4>
        </MenuItem>
      </MenuDropDownStyled>
    </div>
  );
}

function HeaderMobile({
  logo,
  borderBottom,
  onBack,
  headerTitle,
  onNotice,
  scrapYn,
  onScrap,
  onSearch,
  onAlarmPlus,
  onProfile,
  onCalendar,
  onShare,
  onChart,
  onList,
  display,
  onFilter,
  hiddenFilter,
  onPlus,
  onPlusChat,
  disable,
  onMore,
  flag,
  onSetting,
  customAction,
  centerContent,
  status
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const national = useSelector(
    (state) =>
      state?.appSetting?.currentSetting?.find(
        (obj) => obj?.optionKind === "Language"
      ) || {
        filePath:
          "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
        labelEng: "Korea",
        labelKor: "한국어",
        optionKind: "Language",
        optionValue: "KOR"
      }
  );

  const token = localStorage.getItem("token");

  const backPrevious = () => {
    navigate(location.key !== "default" ? -1 : "/");
  };

  const handleClickBack = onBack || backPrevious;

  // const handleShare = async () => {
  //   const url = window.location.href;
  //   try {
  //     await navigator.share({
  //       title: "Finchive",
  //       text: "Finchive",
  //       url
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  return (
    <HeaderMobileStyled borderBottom={borderBottom} logo={logo}>
      {logo ? (
        <button
          type="button"
          className="header-left"
          onClick={() => navigate("/")}>
          <LogoFinchiveSvg />
        </button>
      ) : (
        <div className="header-left">
          <button
            type="button"
            className="btn-40px-start"
            onClick={onBack || handleClickBack}>
            <ArrowLeft />
          </button>
          {headerTitle}
        </div>
      )}

      {centerContent && <div className="header-center">{centerContent}</div>}

      <div
        className={classNames("header-right", {
          "header-right-v1": customAction
        })}>
        {customAction}
        {/* pop up for notification  */}
        {onNotice &&
          (token ? (
            <button
              type="button"
              className="icon-button"
              onClick={() => navigate(`/${idRouter.notifications}`)}>
              {status ? <AlarmActiveSvg /> : <AlarmSvg />}
            </button>
          ) : (
            <button
              type="button"
              className="icon-button"
              onClick={() => dispatch(authActions.onOpenModalLogin())}>
              <SvgIcons name="LoginSvg" className="h-24px" />
            </button>
          ))}
        {onProfile && (
          <button type="button" className="icon-button">
            <LoginSvg />
          </button>
        )}
        {onAlarmPlus && (
          <button type="button" className="icon-button">
            <AlarmPlusSvg />
          </button>
        )}
        {onChart && (
          <button onClick={onChart} type="button" className="icon-button">
            <ChartSvg />
          </button>
        )}
        {onScrap && (
          <button onClick={onScrap} type="button" className="icon-button">
            {scrapYn ? (
              <SvgIcons name="bookmarkIconFill" className="h-24px" />
            ) : (
              <BookMarkSvg />
            )}
          </button>
        )}
        {onShare && (
          <button type="button" className="icon-button" onClick={onShare}>
            <ShareSvg />
          </button>
        )}
        {onFilter && !hiddenFilter && (
          <button type="button" onClick={onFilter} className="icon-button">
            <FilterSvg />
          </button>
        )}
        {/* search menu */}
        {onSearch && (
          <button type="button" className="icon-button" onClick={onSearch}>
            <SearchSvg />
          </button>
        )}
        {onCalendar && (
          <button type="button" className="icon-button">
            <Calendar3Svg />
          </button>
        )}
        {onList && (
          <button type="button" className="icon-button" onClick={onList}>
            {display ? <GridSvg /> : <AlignLeftSvg />}
          </button>
        )}
        {onPlus && (
          <button
            type="button"
            className="icon-button"
            onClick={() => navigate(`/${idRouter.searchInterest}`)}>
            <PlusSvg />
          </button>
        )}
        {onMore && <DropDownMore />}
        {flag && (
          <button
            type="button"
            className="flag"
            onClick={() =>
              dispatch(appSettingActions.setKindOfSelect(national))
            }>
            <img src={national?.filePath} alt="national" />
          </button>
        )}
        {onSetting && (
          <button type="button" className="icon-button" onClick={onSetting}>
            <SettingSvg />
          </button>
        )}
        {onPlusChat &&
          (disable ? (
            <button type="button" className="icon-button">
              <SvgIcons name="chatPlusSvgDisable" className="flex" />
            </button>
          ) : (
            <button type="button" className="icon-button" onClick={onPlusChat}>
              <SvgIcons name="chatPlusSvg" className="flex" />
            </button>
          ))}
      </div>
    </HeaderMobileStyled>
  );
}

export default memo(HeaderMobile);
