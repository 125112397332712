import styled from "styled-components";

export const FlagBox = styled.div`
  width: 24px;
  height: 24px;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }
  .flags_box {
    width: 24px;
    height: 24px;
    position: relative;
    ._flag_two_front,
    ._flag_two_back {
      object-fit: cover;
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 1),
        0px 0px 1px 0px rgba(255, 255, 255, 1);
    }
    ._flag_two_front {
      left: 0;
      top: 0;
      z-index: 2;
    }
    ._flag_two_back {
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    ._flag_one {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
    }
  }
`;
