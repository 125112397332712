export default function Edit3Svg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9999 3.57871C17.623 3.57871 17.2615 3.72846 16.9949 3.99501L4.63202 16.3579L3.962 19.038L6.64207 18.368L19.005 6.00506C19.1369 5.87307 19.2416 5.71639 19.3131 5.54395C19.3845 5.3715 19.4213 5.18668 19.4213 5.00003C19.4213 4.81338 19.3845 4.62856 19.3131 4.45612C19.2416 4.28367 19.1369 4.12699 19.005 3.99501C18.873 3.86302 18.7163 3.75833 18.5439 3.6869C18.3714 3.61547 18.1866 3.57871 17.9999 3.57871ZM16.005 3.00506C16.5341 2.47596 17.2517 2.17871 17.9999 2.17871C18.3704 2.17871 18.7373 2.25169 19.0796 2.39347C19.4219 2.53526 19.7329 2.74307 19.9949 3.00506C20.2569 3.26704 20.4647 3.57806 20.6065 3.92036C20.7483 4.26266 20.8213 4.62953 20.8213 5.00003C20.8213 5.37053 20.7483 5.73741 20.6065 6.0797C20.4647 6.422 20.2569 6.73302 19.9949 6.99501L7.49492 19.495C7.40521 19.5847 7.2928 19.6484 7.16972 19.6791L3.16972 20.6791C2.93117 20.7388 2.67883 20.6689 2.50497 20.495C2.3311 20.3211 2.26121 20.0688 2.32084 19.8303L3.32084 15.8303C3.35161 15.7072 3.41526 15.5948 3.50497 15.5051L16.005 3.00506ZM11.2999 20C11.2999 19.6135 11.6133 19.3 11.9999 19.3H20.9999C21.3865 19.3 21.6999 19.6135 21.6999 20C21.6999 20.3866 21.3865 20.7001 20.9999 20.7001H11.9999C11.6133 20.7001 11.2999 20.3866 11.2999 20Z"
        fill={fillColor}
      />
    </svg>
  );
}
