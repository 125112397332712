export default function MoreNavBarIconSvg({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 12.9999C12.5522 12.9999 12.9999 12.5522 12.9999 11.9999C12.9999 11.4476 12.5522 10.9999 11.9999 10.9999C11.4476 10.9999 10.9999 11.4476 10.9999 11.9999C10.9999 12.5522 11.4476 12.9999 11.9999 12.9999Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 11.5999C11.779 11.5999 11.5999 11.779 11.5999 11.9999C11.5999 12.2208 11.779 12.3999 11.9999 12.3999C12.2208 12.3999 12.3999 12.2208 12.3999 11.9999C12.3999 11.779 12.2208 11.5999 11.9999 11.5999ZM10.3999 11.9999C10.3999 11.1162 11.1162 10.3999 11.9999 10.3999C12.8836 10.3999 13.5999 11.1162 13.5999 11.9999C13.5999 12.8836 12.8836 13.5999 11.9999 13.5999C11.1162 13.5999 10.3999 12.8836 10.3999 11.9999Z"
        fill={fillColor}
      />
      <path
        d="M18.9999 12.9999C19.5522 12.9999 19.9999 12.5522 19.9999 11.9999C19.9999 11.4476 19.5522 10.9999 18.9999 10.9999C18.4476 10.9999 17.9999 11.4476 17.9999 11.9999C17.9999 12.5522 18.4476 12.9999 18.9999 12.9999Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9999 11.5999C18.779 11.5999 18.5999 11.779 18.5999 11.9999C18.5999 12.2208 18.779 12.3999 18.9999 12.3999C19.2208 12.3999 19.3999 12.2208 19.3999 11.9999C19.3999 11.779 19.2208 11.5999 18.9999 11.5999ZM17.3999 11.9999C17.3999 11.1162 18.1162 10.3999 18.9999 10.3999C19.8836 10.3999 20.5999 11.1162 20.5999 11.9999C20.5999 12.8836 19.8836 13.5999 18.9999 13.5999C18.1162 13.5999 17.3999 12.8836 17.3999 11.9999Z"
        fill={fillColor}
      />
      <path
        d="M4.9999 12.9999C5.55219 12.9999 5.9999 12.5522 5.9999 11.9999C5.9999 11.4476 5.55219 10.9999 4.9999 10.9999C4.44762 10.9999 3.9999 11.4476 3.9999 11.9999C3.9999 12.5522 4.44762 12.9999 4.9999 12.9999Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9999 11.5999C4.77899 11.5999 4.5999 11.779 4.5999 11.9999C4.5999 12.2208 4.77899 12.3999 4.9999 12.3999C5.22082 12.3999 5.3999 12.2208 5.3999 11.9999C5.3999 11.779 5.22082 11.5999 4.9999 11.5999ZM3.3999 11.9999C3.3999 11.1162 4.11625 10.3999 4.9999 10.3999C5.88356 10.3999 6.5999 11.1162 6.5999 11.9999C6.5999 12.8836 5.88356 13.5999 4.9999 13.5999C4.11625 13.5999 3.3999 12.8836 3.3999 11.9999Z"
        fill={fillColor}
      />
    </svg>
  );
}
