export default function LogoFinchiveSvg({ fillColor }) {
  return (
    <svg
      width="114"
      height="24"
      viewBox="0 0 114 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.9715 7.28564C27.4544 7.28564 26.0658 7.81707 24.9687 8.69136V7.59421L20.6487 8.2885V23.6828H24.9687V14.6485C24.9687 12.9599 26.3315 11.5971 28.0201 11.5971C29.7087 11.5971 31.0715 12.9599 31.0715 14.6485V23.6828H35.3915V13.6971C35.3915 10.1571 32.5201 7.28564 28.9801 7.28564H28.9715Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M13.7656 8.28853V23.6828H18.0856V7.59424L13.7656 8.28853Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M8.58857 3.82286C8.58857 3.82286 8.61429 3.82286 8.62286 3.81429C9.00857 3.66857 9.43715 3.58286 9.87429 3.58286C10.1143 3.58286 10.3543 3.60857 10.5771 3.65143C10.6114 3.65143 10.6371 3.66857 10.6714 3.67714L11.5629 0.497144C10.6971 0.180001 9.77143 0 8.79429 0C5.09143 0 2.09143 2.98286 2.05714 6.67714C2.05714 6.70286 2.05714 6.72 2.05714 6.74572V8.60572H0V11.5714H2.05714V23.6914H6.37714V11.5714H11.1943V8.60572H6.37714V7.08857C6.37714 5.61429 7.29429 4.35429 8.58857 3.84V3.82286Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M49.1657 18.1371C48.4286 19.0714 47.2886 19.6714 46.0029 19.6714C43.7743 19.6714 41.9658 17.8628 41.9658 15.6342C41.9658 13.4057 43.7743 11.5971 46.0029 11.5971C47.28 11.5971 48.4286 12.1971 49.1657 13.1314L53.0658 11.1771C51.5829 8.8371 48.9772 7.2771 46.0029 7.2771C41.3915 7.2771 37.6458 11.0142 37.6458 15.6342C37.6458 20.2542 41.3829 23.9914 46.0029 23.9914C48.9772 23.9914 51.5915 22.4314 53.0658 20.0914L49.1657 18.1371Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M104.717 19.6713C103.252 19.6713 101.966 18.8828 101.254 17.7085L108.72 16.1228L113.049 15.2056C113.023 14.6228 112.929 14.057 112.783 13.517C112.706 13.2256 112.612 12.9342 112.509 12.6513C111.3 9.51419 108.274 7.29419 104.717 7.29419C100.106 7.29419 96.3601 11.0313 96.3601 15.6513C96.3601 15.797 96.3772 15.9428 96.3858 16.0885C96.4287 16.9885 96.6173 17.8456 96.9258 18.6428C98.1258 21.7799 101.16 23.9999 104.717 23.9999C107.452 23.9999 109.869 22.6799 111.394 20.6571L107.606 18.4713C106.869 19.217 105.849 19.6885 104.726 19.6885L104.717 19.6713ZM104.717 11.597C106.183 11.597 107.469 12.3856 108.18 13.5599L100.714 15.1456C100.954 13.1485 102.66 11.597 104.717 11.597Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M76.9457 23.6828V7.59424L72.6343 8.28853V23.6828H76.9457Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M74.7857 5.74281C76.1491 5.74281 77.2543 4.63759 77.2543 3.27424C77.2543 1.91088 76.1491 0.805664 74.7857 0.805664C73.4224 0.805664 72.3171 1.91088 72.3171 3.27424C72.3171 4.63759 73.4224 5.74281 74.7857 5.74281Z"
        fill="url(#paint0_radial_13887_5755)"
      />
      <path
        d="M63.6515 7.28577C62.1343 7.28577 60.7458 7.81719 59.6486 8.69148V0.300049L55.3286 0.994335V23.6829H59.6486V14.6486C59.6486 12.9601 61.0115 11.5972 62.7 11.5972C64.3886 11.5972 65.7515 12.9601 65.7515 14.6486V23.6829H70.0715V13.6972C70.0715 10.1572 67.2 7.28577 63.66 7.28577H63.6515Z"
        fill={fillColor || "#11131A"}
      />
      <path
        d="M96.3599 7.51709L91.2428 8.33995L87.6256 17.4942L83.7171 7.61995L79.2085 8.33995L85.2771 23.6828H89.9742L96.3599 7.51709Z"
        fill={fillColor || "#11131A"}
      />
      <defs>
        <radialGradient
          id="paint0_radial_13887_5755"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(73.0733 5.41717) scale(7.94383 7.94382)">
          <stop stopColor="#00D3F7" />
          <stop offset="1" stopColor="#1554F6" />
        </radialGradient>
      </defs>
    </svg>
  );
}
