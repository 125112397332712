/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { ChevronRightSvg } from "components/icons";
import { AntSwitch } from "components/modal/ModalEconomicCalendarFilter";
import isArray from "lodash/isArray";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appSettingActions } from "redux/reducers/appSettingReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { myPageActions } from "redux/reducers/myPageReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { getValueSetting, updateSetting } from "services/SettingService";

function SettingItem({ item, afterUpdate }) {
  const dispatch = useDispatch();
  const currentSetting = useSelector(
    (state) => state?.appSetting?.currentSetting
  );
  const { i18n } = useTranslation();
  const token = localStorage.getItem("token");

  const handleClick = () => {
    switch (item?.optionType) {
      case "switch":
        break;
      case "select":
        dispatch(
          appSettingActions.setKindOfSelect(
            currentSetting?.find(
              (obj) => obj?.optionKind === item?.optionKind
            ) || {
              labelEng: item?.labelEng,
              labelKor: item?.labelKor,
              optionKind: item?.optionKind || item?.parentOptionKind,
              optionValue: item?.currentValue[0]?.optionValue
            }
          )
        );
        break;
      case "link":
        onLink();
        break;
      default:
    }
  };

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const onSwitch = async () => {
    try {
      setIsLoading(true);
      const res = await getValueSetting({ kind: item?.optionKind });
      if (res?.code === "200") {
        const option = isArray(res?.data?.list) ? res?.data?.list || [] : [];
        const oldSettingIndex = currentSetting.findIndex(
          (obj) => obj?.optionKind === item?.optionKind
        ); // find the object of old setting
        // switch just have two type for select
        // find the first option differ oldSetting to make sure only one return

        if (oldSettingIndex !== -1) {
          const newSettingItem = option.find(
            (obj) =>
              obj.optionValue !== currentSetting[oldSettingIndex]?.optionValue
          );
          const newSetting = [...currentSetting];
          newSetting[oldSettingIndex] = {
            optionKind:
              newSettingItem?.optionKind || newSettingItem?.parentOptionKind,
            optionValue: newSettingItem?.optionValue
          };
          if (newSetting !== null) {
            if (token) {
              const resp = await updateSetting({
                createYn: "N",
                setItems: newSetting
              });
              if (resp?.code === "200") {
                dispatch(appSettingActions.updateCurrentSetting(newSetting));
              }
            } else {
              dispatch(appSettingActions.updateCurrentSetting(newSetting));
            }
            localStorage.setItem("userSettings", JSON.stringify(newSetting));
          }
          // else {
          // localStorage.removeItem("userSettings");
          // }
        } else {
          const newSettingItem = option.find(
            (obj) => obj.optionValue !== item?.currentValue[0]?.optionValue
          );
          const newSetting = [
            ...currentSetting,
            {
              optionKind:
                newSettingItem?.optionKind || newSettingItem?.parentOptionKind,
              optionValue: newSettingItem?.optionValue
            }
          ];
          if (newSetting !== null) {
            if (token) {
              const resp = await updateSetting({
                createYn: "N",
                setItems: newSetting
              });
              if (resp?.code === "200") {
                dispatch(appSettingActions.updateCurrentSetting(newSetting));
              }
            } else {
              dispatch(appSettingActions.updateCurrentSetting(newSetting));
            }
            localStorage.setItem("userSettings", JSON.stringify(newSetting));
          }
          // else {
          // localStorage.removeItem("userSettings");
          // }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      afterUpdate();
      setIsLoading(false);
    }
  };

  const onLink = () => {
    switch (item?.action) {
      case "portfolio();":
        dispatch(portfolioActions.onOpenModalChangeDefault());
        break;
      case "secession();":
        // dispatch(appSettingActions.onCloseModalSetting());
        dispatch(myPageActions.onOpenSettingModal());
        dispatch(myPageActions.setTabIndex("8"));
        break;
      case "logout();":
        dispatch(commonActions.onLogout());
        break;
      default:
    }
  };

  const currentValue = useMemo(
    () =>
      item?.optionType !== "link"
        ? currentSetting?.find((obj) => obj?.optionKind === item?.optionKind) ||
          item?.currentValue[0]
        : null,
    [item, currentSetting]
  );

  return (
    <button
      type="button"
      className="flex-between-center pd-16-20 border-1px-bot-neutral-8 svg-neutral-6"
      onClick={() => handleClick()}>
      <h4>
        {i18n.language === "ko"
          ? item?.labelKor || item?.labelEng
          : item?.labelEng || item?.labelKor}
      </h4>
      {item?.optionType === "switch" && (
        <AntSwitch
          inputProps={{ "aria-label": "ant design" }}
          checked={currentValue?.optionValue === "On"}
          onChange={() => onSwitch()}
        />
      )}
      {item?.optionType === "select" && currentValue && (
        <div className="flex-centered">
          <h5 className="fw-500 color-info">
            {i18n.language === "ko"
              ? currentValue?.labelKor || currentValue?.labelEng
              : currentValue?.labelEng || currentValue?.labelKor}
          </h5>
          <ChevronRightSvg className="fill" />
        </div>
      )}
      {item?.optionType === "link" && <ChevronRightSvg className="fill" />}
      {item?.optionType === "text" && currentValue && (
        <h5 className="fw-500 color-info">{currentValue?.optionValue}</h5>
      )}
    </button>
  );
}
export default memo(SettingItem);
