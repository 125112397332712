import { useState, useEffect, useRef } from "react";
import { detectDeviceType } from "../util/CommonUtil";
import MainPageService from "../services/MainPage/MainPageService";

const device = detectDeviceType();

const useSettingPage = () => {
  const [settingPage, setSettingPage] = useState({});
  const [infoBlock, setInfoBlock] = useState({
    code: "",
    message: "",
    data: {}
  });
  const abortVisitController = useRef();
  const abortBlockController = useRef();

  const handleFetchSettingPage = async () => {
    try {
      const res = await MainPageService.getSettingPage();
      const data = res?.data?.data ?? {};
      setSettingPage(data);
    } catch (err) {
      setSettingPage([]);
      console.log(err);
    }
  };

  const handleFetchBlockIp = async () => {
    try {
      if (abortBlockController.current) {
        abortBlockController?.current?.abort();
      }
      abortBlockController.current = new AbortController();
      const { signal } = abortBlockController.current;
      const res = await MainPageService.getBlockIp(signal);
      const { code, message, data } = res.data;
      if (code !== infoBlock?.code || message !== infoBlock?.message)
        setInfoBlock({ code, message, data });
    } catch (err) {
      console.log(err);
    }
  };

  const checkVisitPage = async () => {
    try {
      if (abortVisitController.current) {
        abortVisitController?.current?.abort();
      }
      abortVisitController.current = new AbortController();
      const { signal } = abortVisitController.current;
      await MainPageService.checkVisitPage({ device }, signal);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleFetchSettingPage();
    handleFetchBlockIp();
  }, []);

  return {
    settingPage,
    infoBlock,
    checkVisitPage,
    handleFetchBlockIp
  };
};

export default useSettingPage;
