/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";
import URL from "./url";

class AuthService {
  loginSns(idToken, loginMethod) {
    const encodedIdToken = encodeURIComponent(idToken);
    const query = `?idToken=${encodedIdToken}&loginMethod=${loginMethod}`;
    const url = URL.LOGIN_SNS + query;
    return axiosClient.get(url);
  }

  signUpSns(idToken, loginMethod, isSignUp = false) {
    const query = `?idToken=${idToken}&loginMethod=${loginMethod}&signUp=${isSignUp}`;
    const url = URL.SIGN_UP_SNS + query;
    return axiosClient.get(url);
  }

  signUpSns2(idToken, loginMethod, body = {}, isSignUp = false) {
    const encodedIdToken = encodeURIComponent(idToken);
    const query = `?idToken=${encodedIdToken}&loginMethod=${loginMethod}&signUp=${isSignUp}`;
    const url = URL.SIGN_UP_SNS + query;
    return axiosClient.post(url, body);
  }

  changePass(body = {}) {
    const url = URL.CHANGE_PASS;
    return axiosClient.post(url, body);
  }
}

export default new AuthService();
