/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHelperText } from "@mui/material";
import { updateUserProfile } from "common/FuncComm";
import { successAlert, warningAlert } from "components/alert";
import { XCircle } from "components/icons";
import { OutlinedInputBorder24 } from "components/modal/components/OutlinedInputBorder24";
import AvatarUser from "components/userAvatar";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { myPageActions } from "redux/reducers/myPageReducer";
import {
  checkNickname,
  getDetail,
  saveAttachments,
  updateProfile
} from "services/MyPageService/MyPageService";
import * as Yup from "yup";
import { ButtonStyled, NumberTab3Styled } from "./styled";

let timeout;

export default function MyPageChangeProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const avatarFile = useSelector((state) => state?.myPage?.avatarFile);

  const setLoading = (value) => dispatch(commonActions.setLoading(value));
  useEffect(() => {
    getUserDetail();
  }, []);

  const onSubmit = async () => {
    if (!formik.values.nameUser.trim()) {
      warningAlert(t("info.requiredUserName"));
    } else {
      try {
        let res;
        if (avatarFile) {
          const form = new FormData();
          form.append("attachments", avatarFile);
          const resAtt = await saveAttachments(form);
          res = await updateProfile({
            nickname: formik.values.nameUser.trim(),
            about: formik.values.aboutMe,
            img: resAtt?.data[0]?.url
          });
          if (res.code === "200")
            updateUserProfile({ imgPath: resAtt?.data[0]?.url });
        } else {
          res = await updateProfile({
            nickname: formik.values.nameUser.trim(),
            about: formik.values.aboutMe,
            img: JSON.parse(localStorage.getItem("user")).imgPath
          });
        }
        if (res.code === "200") {
          successAlert(t("notice.saved"));
          dispatch(myPageActions.onCloseSettingModal());
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      nameUser: "",
      aboutMe: ""
    },
    validationSchema: Yup.object({
      nameUser: Yup.string().max(12, "닉네임은 12자 이내로 작성해 주세요"), // text from mr.paul
      aboutMe: Yup.string().max(100, "100자 이내로 작성해 주세요!").nullable()
    }),
    onSubmit
  });

  const handleChange = (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (e.target.value !== "") getCheckName(e.target.value);
    }, 500);
    formik.setFieldValue("nameUser", e.target.value);
  };

  const getCheckName = async (name) => {
    try {
      // setIsLoading(true);
      const res = await checkNickname({ nickName: name });
      if (res.code === "400") {
        formik.setFieldError("nameUser", "현재 사용 중인 닉네임입니다.");
      }
    } catch (e) {
      console.error(e);
    } finally {
      // setIsLoading(false);
    }
  };

  const getUserDetail = async () => {
    try {
      setLoading(true);
      const res = await getDetail();
      if (res.code === "200") {
        formik.setFieldValue("nameUser", res?.data?.nickname);
        formik.setFieldValue("aboutMe", res?.data?.about);
        localStorage.setItem("user", JSON.stringify(res?.data));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const helperText = (name) =>
    formik.errors[name] ? (
      <FormHelperText sx={{ color: "#F20013" }}>
        {formik.errors[name]}
      </FormHelperText>
    ) : null;

  return (
    <NumberTab3Styled className="flex-1 full-height flow-y-auto pl-20 pr-20 pb-64">
      <AvatarUser />
      <form onSubmit={formik.handleSubmit} className="flex-col gap-24">
        <div className="flex-col gap-12">
          <div className="flex-between">
            <label htmlFor="outlined-input-authenCode">
              <h5 className="fw-500 color-info">{t("info.nickName")}</h5>
            </label>
            <h6 className="fw-500 color-neutral">
              {formik.values.nameUser?.length || 0}
              <span className="fw-300 fs-10 lh-20 color-neutral">/</span>
              12
            </h6>
          </div>
          <OutlinedInputBorder24
            name="nameUser"
            value={formik.values.nameUser}
            error={Boolean(formik.errors.nameUser) && formik.errors.nameUser}
            onChange={handleChange}
            placeholder={t("info.requiredNickName")}
            endAdornment={
              formik.values.nameUser ? (
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() => formik.setFieldValue("nameUser", "")}>
                  <XCircle />
                </button>
              ) : null
            }
          />
          {helperText("nameUser")}
        </div>
        <div className="flex-col gap-12">
          <div className="flex-between">
            <label htmlFor="outlined-input-authenCode">
              <h5 className="fw-500 color-info">{t("info.aboutMe")}</h5>
            </label>
            <h6 className="fw-500 color-neutral">
              {formik.values.aboutMe?.length || 0}
              <span className="fw-300 fs-10 lh-20 color-neutral">/</span>
              100
            </h6>
          </div>
          <OutlinedInputBorder24
            name="aboutMe"
            onChange={formik.handleChange}
            value={formik.values.aboutMe}
            error={Boolean(formik.errors.aboutMe) && formik.errors.aboutMe}
            placeholder={t("info.requiredIntroduce", {
              userName: formik.values.nameUser
            })}
            endAdornment={
              formik.values.aboutMe ? (
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() => formik.setFieldValue("aboutMe", "")}>
                  <XCircle />
                </button>
              ) : null
            }
          />
          {helperText("aboutMe")}
        </div>
        <ButtonStyled
          type="submit"
          disabled={
            !!(
              formik.errors.aboutMe ||
              formik.errors.nameUser ||
              formik.values.nameUser?.length === 0
            )
          }>
          <h5>{t("button.save")}</h5>
        </ButtonStyled>
      </form>
      {/* <CenterConfirmModal
        open={openSubmitCancel}
        onSubmit={() => {
          if (avatarFile)
            dispatch(
              myPageActions.updateAvatar({
                avatarFile: null,
                avatarUrl: JSON.parse(localStorage.getItem("user")).imgPath
              })
            );
          dispatch(myPageActions.onCloseSettingModal());
          setOpenSubmitCancel(false);
        }}
        onCancel={() => {
          setOpenSubmitCancel(false);
        }}
        textNotice={
          <>
            <h4>{t("info.confirmDelete.title")}</h4>
            <h6 className="fw-500">{t("info.confirmDelete.content")}</h6>
          </>
        }
      /> */}
    </NumberTab3Styled>
  );
}
