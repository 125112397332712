export default function MinusSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22501 9.00001C3.22501 8.71006 3.46006 8.47501 3.75001 8.47501H14.25C14.54 8.47501 14.775 8.71006 14.775 9.00001C14.775 9.28996 14.54 9.52501 14.25 9.52501H3.75001C3.46006 9.52501 3.22501 9.28996 3.22501 9.00001Z"
        fill={fillColor}
      />
    </svg>
  );
}
