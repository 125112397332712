/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import { AppBar, Dialog, Toolbar } from "@mui/material";
import MuiInput from "@mui/material/Input";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { MinusSvg, PlusSvg } from "components/icons";
import useResponsive from "hook/useResponsive";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import { idRouter } from "routes/idRouter";
import { isIOS } from "util/CommonUtil";
import FooterResetSubmit from "./components/FooterResetSubmit";
import { Drawer, ModalContent, Puller } from "./styled";

const Input = styled(MuiInput)`
  width: 64px;
  height: 24px;
`;
function ModalFilterSlider({
  isOpen,
  closeModal,
  valueSlider,
  setSearchData,
  PC = false
}) {
  const { isTabletOrMobile } = useResponsive();
  const location = useLocation();
  const [valuePercent, setValuePercent] = useState(0);
  const [valuePrice, setValuePrice] = useState(0);
  const { t, i18n } = useTranslation();

  const handleSliderPercent = (_, newValue) => {
    setValuePercent(newValue);
  };

  const handleSliderPrice = (_, newValue) => {
    setValuePrice(newValue);
  };

  const handleAddPercent = () => {
    if (valuePercent < 30) {
      setValuePercent(valuePercent + 1);
    }
  };

  const handleRemovePercent = () => {
    if (valuePercent > -30) {
      setValuePercent(valuePercent - 1);
    }
  };

  const handleAddPrice = () => {
    if (valuePrice < valueSlider) {
      setValuePrice(valuePrice + 1);
    }
  };

  const handleRemovePrice = () => {
    if (valuePrice > 0) {
      setValuePrice(valuePrice - 1);
    }
  };

  const handleInputChangePercent = (values) => {
    const { floatValue } = values;
    setValuePercent(floatValue);
  };

  const handleInputChangePrice = (values) => {
    const { floatValue } = values;
    setValuePrice(floatValue);
  };

  const listHidenModal = [idRouter.recentViewDetail];

  const handleResetClick = () => {
    setValuePercent(null);
    setValuePrice(null);
  };

  const formatNumber = (number) => {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  if (listHidenModal.includes(location.pathname) || location.pathname === "/")
    return null;

  const contentComponent = (
    <>
      {isTabletOrMobile && (
        <AppBar
          sx={{
            position: "relative",
            boxShadow: "none",
            borderBottom: "1px solid var(--neutral-color-8)"
          }}
          color="inherit">
          {PC ? null : <Puller />}
          <Toolbar
            sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
            <h2>{t("info.filter")}</h2>
          </Toolbar>
        </AppBar>
      )}
      <ModalContent PC>
        <div className="container">
          <div className="wrap">
            <h2>
              {t("info.changePercent")}
              {!isTabletOrMobile && "(%)"}
            </h2>
            <div className="button">
              <button
                onClick={handleAddPercent}
                type="button"
                className="flex-centered">
                <PlusSvg width={18} height={18} />
              </button>
              <button
                onClick={handleRemovePercent}
                type="button"
                className="flex-centered">
                <MinusSvg />
              </button>
            </div>
          </div>

          <div className="space_top">
            <Slider
              aria-label="Default"
              valueLabelDisplay="on"
              value={valuePercent || 0}
              onChange={handleSliderPercent}
              min={-30}
              max={30}
              sx={{
                width: isTabletOrMobile ? "" : "240px !important",
                position: "relative",
                left: "12px",
                padding: isTabletOrMobile ? "" : "21px 0 !important"
              }}
            />
            <NumericFormat
              sx={{
                ...(!isTabletOrMobile && { width: "120px !important" })
              }}
              value={valuePercent || ""}
              onValueChange={(values) => handleInputChangePercent(values)}
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= -30 && floatValue <= 30)
                );
              }}
              customInput={Input}
              type="tel"
              decimalScale={4}
              thousandSeparator
              valueIsNumericString
            />
          </div>
        </div>

        <div className="container">
          <div className="wrap">
            <h2>
              {t("info.tradingVolume")}
              {!isTabletOrMobile && "(억)"}
            </h2>
            <div className="button">
              <button
                onClick={handleAddPrice}
                type="button"
                className="flex-centered">
                <PlusSvg width={18} height={18} />
              </button>
              <button
                onClick={handleRemovePrice}
                type="button"
                className="flex-centered">
                <MinusSvg />
              </button>
            </div>
          </div>

          <div className="space_top">
            <Slider
              aria-label="Default"
              valueLabelDisplay="on"
              value={valuePrice || 0}
              onChange={handleSliderPrice}
              min={0}
              max={valueSlider}
              sx={{
                width: isTabletOrMobile ? "" : "240px !important",
                position: "relative",
                left: "12px",
                padding: isTabletOrMobile ? "" : "21px 0 !important"
              }}
              valueLabelFormat={(x) => formatNumber(x)}
            />
            <NumericFormat
              sx={{
                ...(!isTabletOrMobile && { width: "120px !important" })
              }}
              value={valuePrice || ""}
              onValueChange={(values) => handleInputChangePrice(values)}
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= 0 && floatValue <= valueSlider)
                );
              }}
              customInput={Input}
              type="tel"
              decimalScale={4}
              thousandSeparator
              valueIsNumericString
            />
          </div>
        </div>
      </ModalContent>
      <div className={classNames({ "pb-16": isIOS() })}>
        <FooterResetSubmit
          onReset={handleResetClick}
          onCancle={closeModal}
          onSubmit={() => {
            setSearchData((prevState) => ({
              ...prevState,
              changePercent: valuePercent,
              value: valuePrice
            }));
            closeModal();
          }}
        />
      </div>
    </>
  );

  if (isTabletOrMobile) {
    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={closeModal}
        onOpen={() => {}}
        sx={{
          display: isOpen ? "block" : "none",
          "& .MuiPaper-root": {
            borderRadius: "16px 16px 0 0"
          }
        }}>
        {contentComponent}
      </Drawer>
    );
  }
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperProps={{
        sx: {
          maxWidth: "480px",
          width: "100%"
        }
      }}>
      {contentComponent}
    </Dialog>
  );
}

export default memo(ModalFilterSlider);
