export default function DefaultProfilePictureSvg({ fillColor = "#E4E5E7" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19987 8.99995C8.19987 6.89037 9.89029 5.19995 11.9999 5.19995C14.1095 5.19995 15.7999 6.89037 15.7999 8.99995C15.7999 11.1095 14.1095 12.8 11.9999 12.8C9.89029 12.8 8.19987 11.1095 8.19987 8.99995Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.54777 14.1434C8.69749 13.591 10.267 13.2 11.9999 13.2C13.7327 13.2 15.3023 13.591 16.452 14.1434C17.0257 14.4191 17.5117 14.743 17.8615 15.0938C18.2025 15.4356 18.4665 15.8575 18.4665 16.323C18.4665 16.8076 18.1831 17.197 17.8231 17.4858C17.4611 17.7761 16.9662 18.0132 16.3967 18.2019C15.2529 18.5809 13.7005 18.8 11.9999 18.8C10.2952 18.8 8.74245 18.562 7.59979 18.1739C7.03086 17.9806 6.53866 17.7419 6.17911 17.4575C5.82651 17.1786 5.5332 16.7982 5.5332 16.323C5.5332 15.8575 5.79727 15.4356 6.1382 15.0938C6.48809 14.743 6.97404 14.4191 7.54777 14.1434Z"
        fill={fillColor}
      />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        stroke={fillColor}
      />
    </svg>
  );
}
