export default function DownLoadSvg({ fillColor = "#AFB1B8" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 11.9167C2.82217 11.9167 3.08333 12.1778 3.08333 12.5V15.8333C3.08333 16.1207 3.19747 16.3962 3.40064 16.5994C3.6038 16.8025 3.87935 16.9167 4.16667 16.9167H15.8333C16.1207 16.9167 16.3962 16.8025 16.5994 16.5994C16.8025 16.3962 16.9167 16.1207 16.9167 15.8333V12.5C16.9167 12.1778 17.1778 11.9167 17.5 11.9167C17.8222 11.9167 18.0833 12.1778 18.0833 12.5V15.8333C18.0833 16.4301 17.8463 17.0024 17.4243 17.4243C17.0024 17.8463 16.4301 18.0833 15.8333 18.0833H4.16667C3.56993 18.0833 2.99763 17.8463 2.57568 17.4243C2.15372 17.0024 1.91667 16.4301 1.91667 15.8333V12.5C1.91667 12.1778 2.17784 11.9167 2.5 11.9167Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42085 7.92085C5.64866 7.69305 6.01801 7.69305 6.24581 7.92085L10 11.675L13.7542 7.92085C13.982 7.69305 14.3513 7.69305 14.5791 7.92085C14.807 8.14866 14.807 8.51801 14.5791 8.74581L10.4125 12.9125C10.1847 13.1403 9.81533 13.1403 9.58752 12.9125L5.42085 8.74581C5.19305 8.51801 5.19305 8.14866 5.42085 7.92085Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.91667C10.3222 1.91667 10.5833 2.17784 10.5833 2.50001V12.5C10.5833 12.8222 10.3222 13.0833 10 13.0833C9.67783 13.0833 9.41666 12.8222 9.41666 12.5V2.50001C9.41666 2.17784 9.67783 1.91667 10 1.91667Z"
        fill={fillColor}
      />
    </svg>
  );
}
