export default function FaviconASvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2825_13475)">
        <path
          d="M20.0833 0H3.91667C1.75355 0 0 1.75355 0 3.91667V20.0833C0 22.2464 1.75355 24 3.91667 24H20.0833C22.2464 24 24 22.2464 24 20.0833V3.91667C24 1.75355 22.2464 0 20.0833 0Z"
          fill="#45495A"
        />
        <path
          d="M7.28001 11.1899C7.02952 11.1898 6.78634 11.2744 6.59001 11.4299V11.1899L5.84668 11.3966V14.0166H6.59001V12.4599C6.59001 12.3203 6.64549 12.1863 6.74426 12.0875C6.84303 11.9888 6.977 11.9333 7.11668 11.9333C7.25636 11.9333 7.39032 11.9888 7.48908 12.0875C7.58785 12.1863 7.64334 12.3203 7.64334 12.4599V14.0166H8.38668V12.2966C8.38712 12.1512 8.35879 12.0071 8.30333 11.8726C8.24788 11.7381 8.16637 11.616 8.06352 11.5131C7.96067 11.4103 7.8385 11.3287 7.70403 11.2733C7.56956 11.2178 7.42546 11.1895 7.28001 11.1899Z"
          fill="white"
        />
        <path
          d="M4.62646 11.3966V11.92V12.4133V14.0166H5.3698V11.1866L4.62646 11.3966Z"
          fill="white"
        />
        <path
          d="M3.53662 11.5899V12.1433H4.27662V11.3966H3.53662V11.5899Z"
          fill="white"
        />
        <path d="M2.7935 11.3966H2.3335V12.1399H2.7935V11.3966Z" fill="white" />
        <path
          d="M3.5498 11.1533C3.54898 11.0325 3.58487 10.9142 3.65273 10.8142C3.72059 10.7142 3.81721 10.6372 3.92981 10.5933C3.99884 10.5663 4.07237 10.5527 4.14647 10.5533H4.26647H4.28314L4.43647 10.0033C4.28379 9.94706 4.12251 9.91773 3.95981 9.91663C3.65331 9.91662 3.35926 10.0379 3.14191 10.254C2.92456 10.4701 2.80157 10.7635 2.7998 11.07V11.3833H3.5498V11.1533Z"
          fill="white"
        />
        <path
          d="M2.79346 11.3966V12.1433V12.5899V14.0166H3.53679V12.1433V11.5899V11.3966H2.79346Z"
          fill="white"
        />
        <path
          d="M15.2432 10.8501C15.4973 10.8501 15.7032 10.6441 15.7032 10.3901C15.7032 10.136 15.4973 9.93005 15.2432 9.93005C14.9892 9.93005 14.7832 10.136 14.7832 10.3901C14.7832 10.6441 14.9892 10.8501 15.2432 10.8501Z"
          fill="#1554F6"
        />
        <path
          d="M12.5969 14.0167V12.4601C12.5969 12.3204 12.6523 12.1864 12.7511 12.0876C12.8499 11.9889 12.9838 11.9334 13.1235 11.9334C13.2632 11.9334 13.3972 11.9889 13.4959 12.0876C13.5947 12.1864 13.6502 12.3204 13.6502 12.4601V14.0167H14.3935V12.2967C14.3938 12.0883 14.3352 11.884 14.2245 11.7074C14.1137 11.5308 13.9554 11.3891 13.7677 11.2985C13.5799 11.208 13.3704 11.1723 13.1633 11.1955C12.9562 11.2188 12.7598 11.3001 12.5969 11.4301V9.93005L11.8535 10.1401V14.0167H12.5969Z"
          fill="white"
        />
        <path
          d="M15.6169 14.0166V11.1866L14.8735 11.3966V11.92V12.4333V14.0166H15.6169Z"
          fill="white"
        />
        <path
          d="M16.9401 13.8234L16.9801 13.9234L17.0067 13.9901L17.0167 14.0167H17.6867L17.6967 13.9901L17.7234 13.9234L17.7667 13.8167L17.8367 13.6401L18.8067 11.1801L18.0334 11.3967L17.7934 12.0101L17.4234 12.9501L17.3501 12.7701L16.9801 11.8301L16.7267 11.1867L15.9834 11.3967V11.4001L16.1934 11.9301L16.7967 13.4567L16.9401 13.8234Z"
          fill="white"
        />
        <path
          d="M10.7368 13.06C10.6723 13.1435 10.5894 13.2109 10.4946 13.2572C10.3998 13.3034 10.2956 13.3272 10.1901 13.3267C10.0722 13.3265 9.95633 13.2964 9.85325 13.2392C9.75018 13.182 9.6633 13.0996 9.60076 12.9996C9.53822 12.8997 9.50205 12.7856 9.49565 12.6679C9.48924 12.5502 9.51281 12.4327 9.56415 12.3266C9.61548 12.2205 9.69291 12.1292 9.78917 12.0611C9.88544 11.9931 9.99739 11.9506 10.1146 11.9376C10.2317 11.9246 10.3503 11.9416 10.4591 11.9869C10.5679 12.0322 10.6635 12.1044 10.7368 12.1967L11.4034 11.8634C11.2368 11.5983 10.9885 11.3947 10.696 11.2831C10.4035 11.1715 10.0826 11.1581 9.7818 11.2448C9.48099 11.3316 9.21653 11.5137 9.0283 11.7639C8.84007 12.014 8.73828 12.3186 8.73828 12.6317C8.73828 12.9448 8.84007 13.2493 9.0283 13.4995C9.21653 13.7496 9.48099 13.9318 9.7818 14.0185C10.0826 14.1053 10.4035 14.0918 10.696 13.9803C10.9885 13.8687 11.2368 13.665 11.4034 13.4L10.7368 13.06Z"
          fill="white"
        />
        <path
          d="M21.6399 12.3567C21.5769 12.0266 21.4007 11.7288 21.1417 11.5147C20.8827 11.3006 20.557 11.1836 20.221 11.1838C19.8849 11.1841 19.5595 11.3016 19.3008 11.5162C19.0421 11.7307 18.8664 12.0288 18.8039 12.359C18.7415 12.6892 18.7962 13.0309 18.9587 13.325C19.1212 13.6192 19.3813 13.8475 19.694 13.9704C20.0068 14.0934 20.3527 14.1034 20.672 13.9986C20.9913 13.8939 21.2641 13.681 21.4432 13.3967L20.7766 13.0633C20.7121 13.1468 20.6292 13.2143 20.5344 13.2605C20.4396 13.3068 20.3354 13.3305 20.2299 13.33C20.08 13.3286 19.9345 13.2793 19.8145 13.1894C19.6946 13.0994 19.6066 12.9735 19.5632 12.83H21.6566C21.6566 12.7934 21.6566 12.7567 21.6566 12.72C21.6566 12.6833 21.6566 12.6634 21.6566 12.6334C21.6596 12.5408 21.654 12.4482 21.6399 12.3567ZM20.2166 11.9334C20.3573 11.9291 20.4961 11.9671 20.6149 12.0426C20.7338 12.1181 20.8273 12.2275 20.8832 12.3567H19.5832C19.6367 12.2325 19.7249 12.1264 19.8373 12.0513C19.9497 11.9762 20.0814 11.9352 20.2166 11.9334Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2825_13475">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
