export default function CheckboxCheckedBlackSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4948 6.00507C20.7681 6.27844 20.7681 6.72166 20.4948 6.99502L9.49478 17.995C9.22141 18.2684 8.7782 18.2684 8.50483 17.995L3.50483 12.995C3.23146 12.7217 3.23146 12.2784 3.50483 12.0051C3.7782 11.7317 4.22141 11.7317 4.49478 12.0051L8.9998 16.5101L19.5048 6.00507C19.7782 5.73171 20.2214 5.73171 20.4948 6.00507Z"
        fill={fillColor}
      />
    </svg>
  );
}
