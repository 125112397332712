export default function XCircle({ fillColor = "#9497A0" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.70005C6.86374 2.70005 2.69999 6.8638 2.69999 12C2.69999 17.1363 6.86374 21.3 12 21.3C17.1362 21.3 21.3 17.1363 21.3 12C21.3 6.8638 17.1362 2.70005 12 2.70005ZM1.29999 12C1.29999 6.0906 6.09054 1.30005 12 1.30005C17.9094 1.30005 22.7 6.0906 22.7 12C22.7 17.9095 17.9094 22.7001 12 22.7001C6.09054 22.7001 1.29999 17.9095 1.29999 12Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.495 8.50507C15.7683 8.77844 15.7683 9.22166 15.495 9.49502L9.49496 15.495C9.2216 15.7684 8.77838 15.7684 8.50501 15.495C8.23165 15.2217 8.23165 14.7784 8.50501 14.5051L14.505 8.50507C14.7784 8.23171 15.2216 8.23171 15.495 8.50507Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50501 8.50507C8.77838 8.23171 9.2216 8.23171 9.49496 8.50507L15.495 14.5051C15.7683 14.7784 15.7683 15.2217 15.495 15.495C15.2216 15.7684 14.7784 15.7684 14.505 15.495L8.50501 9.49502C8.23165 9.22166 8.23165 8.77844 8.50501 8.50507Z"
        fill={fillColor}
      />
    </svg>
  );
}
