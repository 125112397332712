/* eslint-disable default-param-last */
import {
  GET_CHECK_AUTO_LOGIN,
  GET_CHECK_CLOSE,
  GET_CHECK_DEFAULT_AUTO_LOGIN,
  GET_CHECK_OPEN
} from "../type/loginType";

const initialState = {
  isOpenLogin: false,
  isAutoLogin: false
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECK_OPEN: {
      state.isOpenLogin = true;
      return { ...state };
    }
    case GET_CHECK_CLOSE: {
      state.isOpenLogin = false;
      return { ...state };
    }
    case GET_CHECK_AUTO_LOGIN: {
      state.isAutoLogin = true;
      return { ...state };
    }
    case GET_CHECK_DEFAULT_AUTO_LOGIN: {
      state.isAutoLogin = false;
      return { ...state };
    }
    default:
      return state;
  }
};

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   isOpenLogin: false,
//   isAutoLogin: false
// };

// const loginSlice = createSlice({
//   name: "login",
//   initialState,
//   reducers: {
//     onOpenNewPortfolio: (state) => {
//       state.openNewPortfolio = true;
//     },
//     onCloseNewPortfolio: (state) => {
//       state.openNewPortfolio = false;
//     }
//   }
// });

// // Actions
// export const loginActions = loginSlice.actions;

// // Reducer
// const loginReducer = loginSlice.reducer;
// export default loginReducer;
