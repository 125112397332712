/* eslint-disable no-unused-vars */
import { FormHelperText } from "@mui/material";
import { errorAPI, successAlert } from "components/alert";
import { OutlinedInputBorder24 } from "components/modal/components/OutlinedInputBorder24";
import SvgIcons from "components/svgIcons";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { myPageActions } from "redux/reducers/myPageReducer";
import { checkPassword } from "services/AuthenService";
import { changePassword } from "services/MyPageService/MyPageService";
import * as Yup from "yup";
import { ButtonStyled } from "../MyPageChangeProfile/styled";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState(true);
  const [visible, setVisible] = useState({
    passwordOld: false,
    passwordNew: false,
    confirmPassword: false
  });
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    try {
      const res = await changePassword({ ...values });
      if (res.code === "200") {
        successAlert(t("notice.passwordChanged"));
        dispatch(myPageActions.onCloseSettingModal());
      }
      if (res.code === "400") {
        setPassword(true);
        formik.setFieldValue("passOld", "");
        formik.setFieldValue("passwordNew", "");
        formik.setFieldValue("passwordNeww", "");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickCheckPass = async () => {
    try {
      const res = await checkPassword({
        email: JSON.parse(localStorage.getItem("user"))?.mail,
        password: formik.values.passOld
      });
      if (res?.data?.code === "200") {
        setPassword((e) => !e);
      }
      if (res?.data?.code === "400") {
        setPassword(true);
        errorAPI(t("notice.wrongPassWord"));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      passOld: "",
      passwordNew: "",
      passwordNeww: ""
    },
    validationSchema: Yup.object({
      passwordNew: Yup.string()
        .min(8, t("notice.requireLongPass"))
        .max(16, t("notice.requireLongPass")),
      passwordNeww: Yup.string().oneOf(
        [Yup.ref("passwordNew"), null],
        t("notice.requirePassNotMatch")
      )
    }),
    onSubmit
  });

  const helperText = (name) =>
    formik.errors[name] ? (
      <FormHelperText sx={{ color: "#F20013" }}>
        {formik.errors[name]}
        <span className="color-buy rotate-8deg inline-block">!</span>
      </FormHelperText>
    ) : null;

  return (
    <div className="flex-col gap-12 pl-20 pr-20 pb-64">
      {password ? (
        <>
          <h5 className="fw-500 color-info">{t("info.oldPassword")}</h5>
          <OutlinedInputBorder24
            name="passOld"
            value={formik.values.passOld}
            onChange={formik.handleChange}
            placeholder={t("info.requiredOldPassword")}
            type={visible?.passwordOld ? "text" : "password"}
            endAdornment={
              <button
                type="button"
                className="btn-40px-end"
                onClick={() =>
                  setVisible((e) => ({ ...e, passwordOld: !e.passwordOld }))
                }>
                <SvgIcons name={visible.passwordOld ? "eyeOff" : "eye"} />
              </button>
            }
          />
          <div className="pt-12">
            <ButtonStyled
              type="button"
              onClick={handleClickCheckPass}
              disabled={!!(formik.values.passOld?.length === 0)}>
              <h5>{t("button.save")}</h5>
            </ButtonStyled>
          </div>
        </>
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex-col gap-24">
          <div className="flex-col gap-12">
            <h5 className="fw-500 color-info">{t("info.newPassword")}</h5>
            <OutlinedInputBorder24
              fullWidth
              name="passwordNew"
              value={formik.values.passwordNew}
              onChange={formik.handleChange}
              placeholder={t("info.newPasswordRequired")}
              type={visible?.passwordNew ? "text" : "password"}
              endAdornment={
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() =>
                    setVisible((e) => ({ ...e, passwordNew: !e.passwordNew }))
                  }>
                  <SvgIcons name={visible.passwordNew ? "eyeOff" : "eye"} />
                </button>
              }
            />
            {helperText("passwordNew")}
          </div>
          <div className="flex-col gap-12">
            <h5 className="fw-500 color-info">{t("info.verifyNewPassword")}</h5>
            <OutlinedInputBorder24
              fullWidth
              name="passwordNeww"
              value={formik.values.passwordNeww}
              onChange={formik.handleChange}
              placeholder={t("info.reEnterPassword")}
              type={visible?.confirmPassword ? "text" : "password"}
              endAdornment={
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() =>
                    setVisible((e) => ({
                      ...e,
                      confirmPassword: !e.confirmPassword
                    }))
                  }>
                  <SvgIcons name={visible.confirmPassword ? "eyeOff" : "eye"} />
                </button>
              }
            />
            {helperText("passwordNeww")}
          </div>
          <div className="pt-12">
            <ButtonStyled
              type="submit"
              onClick={() => {}}
              disabled={
                !!(
                  formik.errors.passwordNew ||
                  formik.errors.passwordNeww ||
                  formik.values.passwordNew?.length === 0 ||
                  formik.values.passwordNeww?.length === 0
                )
              }>
              <h5>{t("button.save")}</h5>
            </ButtonStyled>
          </div>
        </form>
      )}
    </div>
  );
}
