import styled from "styled-components";

export const RankingListItemStyled = styled.button`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
  background-color: ${(props) =>
    props.active
      ? "rgba(94, 134, 244, 0.06)"
      : "var(--neutral-color-10, #FFFFFF)"};
  .ranking-list-item {
    width: ${(props) => (props.active ? "calc(100% - 40px)" : "100%")};
    display: flex;
    justify-content: start;
    h3 {
      width: 30px;
      font-style: italic;
      text-align: left;
    }
    .ranking-list-content {
      width: calc(100% - 30px);
      gap: 8px;
      display: flex;
      justify-content: start;
      align-items: center;
      h4 {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h5 {
        width: fit-content;
      }
    }
  }
`;
