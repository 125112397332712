export default function ChartCandleOutlineSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08349 17.5L6.08349 2.5L7.25016 2.5L7.25016 17.5L6.08349 17.5Z"
        fill="#F20013"
      />
      <rect x="4.16666" y="5" width="5" height="10" fill="#F20013" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7502 15.8333L12.7502 4.16666L13.9168 4.16666L13.9168 15.8333L12.7502 15.8333Z"
        fill="#1554F6"
      />
      <rect x="10.8333" y="6.11111" width="5" height="7.77778" fill="#1554F6" />
    </svg>
  );
}
