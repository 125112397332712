import CenterConfirmModal from "components/centerConfirmModal";
import useCheckPermission from "hook/useCheckPermission";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";

export default function ModalRequireLogin() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleCheckPermisionMobile } = useCheckPermission();
  const open = useSelector((state) => state.common.openModalRequireLogin);

  const onClose = () => dispatch(commonActions.onCloseModalRequireLogin());

  const onOpen = () => {
    dispatch(commonActions.onCloseModalRequireLogin());
    handleCheckPermisionMobile(() => navigate(`${location.pathname}`));
  };

  return (
    <CenterConfirmModal
      open={open}
      type="confirm"
      onCancel={onClose}
      onSubmit={onOpen}
      textNotice={
        <>
          <h4>{t("notice.noticeRequireLogin")}</h4>
          <h6 className="fw-500">{t("notice.noticeRequireLogin1")}</h6>
        </>
      }
    />
  );
}
