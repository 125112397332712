import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listInterest: [],
  loading: true
};

const interestSlice = createSlice({
  name: "interest",
  initialState,
  reducers: {
    setListInterest: (state, action) => {
      state.listPortfolio = action?.payload;
    },
    clearInterest: (state) => {
      state.listPortfolio = [];
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    }
  }
});

// Actions
export const interestActions = interestSlice.actions;

// Reducer
const portfolioReducer = interestSlice.reducer;
export default portfolioReducer;
