/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appSettingActions } from "redux/reducers/appSettingReducer";
import { commonActions } from "redux/reducers/commonReducer";
import {
  getListSetting,
  getValueSetting,
  updateSetting
} from "services/SettingService";
import { updateArrayWithMissingData } from "util/common";

function ButtonSelectLanguage() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [listContry, setListContry] = useState([]);
  const anchorRef = useRef(null);
  const currentSetting = useSelector(
    (state) => state?.appSetting?.currentSetting
  );
  const national = useMemo(
    () =>
      currentSetting?.find((obj) => obj?.optionKind === "Language") || {
        filePath:
          "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
        labelEng: "Korea",
        labelKor: "한국어",
        optionKind: "Language",
        optionValue: "KOR"
      },
    [currentSetting]
  );
  const token = localStorage.getItem("token");

  useEffect(() => {
    getListCurrentSetting();
    if (open) {
      getListSelect();
    }
  }, [open, localStorage.getItem("token")]);

  const getListCurrentSetting = async () => {
    try {
      setIsLoading(true);
      const res = await getListSetting();
      if (res?.code === "200" && res?.data?.list !== "no data") {
        const newListSetting =
          !isEmpty(res?.data?.list) && isArray(res?.data?.list)
            ? res?.data?.list
            : [];
        const defineSetting = await getDefaultSetting(newListSetting); // setting default by api
        const localSettings = JSON.parse(localStorage.getItem("userSettings"));
        const currentDataSetting =
          !isEmpty(res?.data?.userSetting) && isArray(res?.data?.userSetting)
            ? res?.data?.userSetting
            : localSettings;

        const finalSetting = updateArrayWithMissingData(
          updateArrayWithMissingData(currentDataSetting, currentSetting || []),
          defineSetting
        );
        dispatch(appSettingActions.updateCurrentSetting(finalSetting));
        localStorage.setItem("userSettings", JSON.stringify(finalSetting)); // save local to easy language
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getDefaultSetting = async (allSetting) => {
    const newDefaultList =
      allSetting?.flatMap((obj) =>
        obj.listSetting
          .filter(
            (itemSetting) =>
              itemSetting?.optionType === "switch" ||
              itemSetting?.optionType === "select"
          )
          .map(({ currentValue, optionKind }) => {
            if (optionKind === "Language") {
              const { languages } = navigator;
              const isKorean =
                languages.includes("ko") || languages.includes("ko-KR");
              return isKorean
                ? {
                    filePath:
                      "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
                    labelEng: "Korea",
                    labelKor: "한국어",
                    optionKind: "Language",
                    optionValue: "KOR" // only need this, can remove the other param
                  }
                : {
                    filePath:
                      "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
                    labelEng: "English",
                    labelKor: "영어",
                    optionKind: "Language",
                    optionValue: "USA"
                  };
            }
            return {
              labelKor: currentValue[0]?.labelKor,
              labelEng: currentValue[0]?.labelEng,
              optionKind,
              filePath: currentValue[0]?.filePath,
              optionValue: currentValue[0]?.optionValue
            };
          })
      ) || [];
    dispatch(appSettingActions.updateDefaultSettings(newDefaultList));
    return newDefaultList;
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const getListSelect = async () => {
    try {
      setIsLoading(true);
      const res = await getValueSetting({ kind: "Language" });
      if (res?.code === "200") {
        const option = isArray(res?.data?.list) ? res?.data?.list || [] : [];
        setListContry(option);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSettings = async (newSettingItem) => {
    const { labelEng, labelKor, optionValue, filePath } = newSettingItem;
    try {
      setIsLoading(true);
      const oldSettingIndex = currentSetting?.findIndex(
        (obj) => obj?.optionKind === "Language"
      );
      // find that the old setting was exist or not
      let newSetting;
      if (oldSettingIndex !== -1) {
        newSetting = [...currentSetting];
        newSetting[oldSettingIndex] = {
          optionKind: "Language",
          labelEng,
          labelKor,
          optionValue,
          filePath
        };
      } else
        newSetting = [
          ...currentSetting,
          {
            optionKind: "Language",
            labelEng,
            labelKor,
            optionValue,
            filePath
          }
        ];
      if (newSetting !== null) {
        if (token) {
          const res = await updateSetting({
            createYn: "N",
            setItems: newSetting
          });
          if (res?.code === "200") {
            dispatch(appSettingActions.updateCurrentSetting(newSetting));
          }
        } else {
          dispatch(appSettingActions.updateCurrentSetting(newSetting));
        }
        switch (optionValue) {
          case "KOR":
            if (i18n.language !== "ko") i18n.changeLanguage("ko");
            if (window.flutter_inappwebview) {
              window.flutter_inappwebview.callHandler("handleMessage", "ko");
            }
            break;
          case "USA":
            if (i18n.language !== "en") i18n.changeLanguage("en");
            if (window.flutter_inappwebview) {
              window.flutter_inappwebview.callHandler("handleMessage", "en");
            }
            break;
          // case "VNM":
          //   if (i18n.language !== "vi") i18n.changeLanguage("vi");
          //   if (window.flutter_inappwebview) {
          //     window.flutter_inappwebview.callHandler("handleMessage", "vi");
          //   }
          //   break;
          default:
            if (i18n.language !== "en") i18n.changeLanguage("en");
            if (window.flutter_inappwebview) {
              window.flutter_inappwebview.callHandler("handleMessage", "en");
            }
        }
        localStorage.setItem("userSettings", JSON.stringify(newSetting)); // still save on local to recheck language
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <button
        type="button"
        ref={anchorRef}
        className="flag"
        onClick={handleToggle}>
        <img
          src={
            national?.filePath ||
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg"
          }
          alt="national"
        />
      </button>
      <Popper
        sx={{
          zIndex: 1302
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "bottom-end"
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {listContry?.map((option, index) => (
                    <MenuItem
                      key={option?.optionValue}
                      selected={option?.optionValue === national?.optionValue}
                      onClick={() => updateSettings(option)}>
                      <div className="flex-start-center gap-12">
                        <img
                          className="img-box-shadow img-cover block-24px br-12"
                          srcSet={option?.filePath}
                          src={option?.filePath}
                          alt=""
                        />
                        <h4>
                          {i18n?.language === "ko"
                            ? option?.labelKor || option?.labelEng
                            : option?.labelEng || option?.labelKor}
                        </h4>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default memo(ButtonSelectLanguage);
