/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  List,
  ListItemButton,
  Toolbar
} from "@mui/material";

import classNames from "classnames";
import { successAlert } from "components/alert";
import { GoToPage15px, TextAdd } from "components/buttons";
import {
  CheckboxCheckedBlueSvg,
  CheckboxUncheckedSvg,
  Edit3Svg
} from "components/icons";
import useResponsive from "hook/useResponsive";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { addInterest } from "services/InterestService";
import { getMyListPortfolio } from "services/PofolioService";
import { isIOS } from "util/CommonUtil";
import {
  Button40,
  ButtonSubmit,
  Drawer,
  FormBtnSubmit,
  ListItemText,
  Puller
} from "./styled";

function ModalMyPortfolio() {
  const { isTabletOrMobile } = useResponsive();
  const dispatch = useDispatch();
  const listPortfolio = useSelector((state) => state.portfolio.listPortfolio);
  const interestAdding = useSelector((state) => state.common.interestAdding);
  const lastModified = useSelector((state) => state?.portfolio?.lastModified);
  const { t } = useTranslation();

  const openNewPortfolio = useSelector(
    (state) => state.common.openNewPortfolio
  );

  const currentActive = useSelector((state) => state.portfolio.currentActive);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (lastModified === null) onClose();
  }, [lastModified]);

  useEffect(() => {
    if (!!interestAdding && !openNewPortfolio) getListPortfolio();
  }, [interestAdding, openNewPortfolio]);

  useEffect(() => {
    if (currentActive) setSelected(currentActive?.mkMyInterestMasterUid);
  }, [currentActive]);

  const getListPortfolio = async () => {
    try {
      setLoading(true);
      const res = await getMyListPortfolio({
        currentPage: 1,
        pageSize: 10
      });
      if (res.code === "200") {
        dispatch(portfolioActions.setListPortfolio(res?.data?.list || []));
        if (!currentActive?.mkMyInterestMasterUid)
          dispatch(
            portfolioActions.updateCurrentPortfolio(
              res?.data?.list?.filter(
                (portfolio) => portfolio?.defaultYn === "Y"
              )[0]
            )
          );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  const onClose = () => {
    dispatch(commonActions.onClosePortfolio());
  };

  const handleUpdateClick = () => {
    dispatch(commonActions.onOpenUpdatePortfolio());
    onClose();
  };

  const handleNewClick = () => {
    if (listPortfolio?.length >= 10)
      dispatch(commonActions.onOpenAddOver10Portfolio());
    else dispatch(commonActions.onOpenNewPortfolio());
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const res = await addInterest({
        mkMyInterestMasterUid: selected,
        symbolCategory3depthUid: interestAdding?.symbolCategory3depthUid,
        symbolUid: interestAdding?.symbolUid,
        symbolType: interestAdding?.symbolType
      });
      if (res.code === "200") {
        successAlert(t("info.themaAddedSuccessfully"));
        dispatch(portfolioActions.setLastModified(selected)); // save last portfolio selected to open when done
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const contentComponent = (
    <>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
          <div className="flex-start-center gap-12">
            <h2>{t("info.portfolio")}</h2>
            <div className="flex-centered gap-2 fit-height">
              <p className="fw-400 font-2 color-info fs-14 lh-18">
                {listPortfolio?.length || 0}
              </p>
              <p className="fw-400 font-2 color-info fs-12 lh-18 pb-2">/</p>
              <p className="fw-400 font-2 color-info fs-14 lh-18">10</p>
            </div>
          </div>
          <Button40 onClick={handleUpdateClick}>
            <Edit3Svg />
          </Button40>
        </Toolbar>
      </AppBar>
      <List
        style={{
          maxHeight: "60vh",
          overflow: "auto",
          padding: "8px 0"
        }}>
        <div style={{ maxHeight: "60vh" }}>
          {listPortfolio?.map((item, index) => (
            <ListItemButton
              onClick={() => setSelected(item?.mkMyInterestMasterUid)}
              sx={{
                justifyContent: "space-between",
                padding: "16px 24px"
              }}
              key={index}>
              <ListItemText>
                <div className="portfolio_title">
                  {item?.defaultYn === "Y" ? (
                    <GoToPage15px
                      btnText={item?.interestName}
                      color="var(--neutral-color-1, #11131A)"
                      bold
                      style={{ overflow: "hidden", width: "calc(100% - 16px)" }}
                    />
                  ) : (
                    <GoToPage15px
                      btnText={item?.interestName}
                      color="var(--neutral-color-1, #11131A)"
                    />
                  )}
                </div>
                <div className="interest_status">
                  {item?.defaultYn === "Y" && (
                    <h5
                      style={{ color: "var(--primary-color-1)" }}
                      className="lh-14 fw-700">
                      {t("info.myDefaultPortfolio")}
                    </h5>
                  )}
                  <h5
                    style={{ color: "var(--neutral-color-4)" }}
                    className="lh-14 fw-500">
                    {t("info.countInterest", { interest: item.rows })}
                  </h5>
                </div>
              </ListItemText>
              <Button40>
                {selected === item.mkMyInterestMasterUid ? (
                  <CheckboxCheckedBlueSvg />
                ) : (
                  <CheckboxUncheckedSvg
                    fillColor="var( --neutral-color-7)"
                    width={24}
                    height={24}
                  />
                )}
              </Button40>
            </ListItemButton>
          ))}
        </div>
      </List>
      <Divider
        style={{
          margin: "8px 0"
        }}
      />
      <Button
        onClick={handleNewClick}
        sx={{
          backgroundColor: "var(--neutral-color-9)",
          width: "100%",
          padding: "16px 24px 16px 24px",
          justifyContent: "flex-start",
          borderBottom: "8px solid white",
          textTransform: "unset"
        }}>
        <TextAdd btnText={t("button.newPortfolio")} />
      </Button>
      <div className={classNames({ "pb-16": isIOS() })}>
        <FormBtnSubmit>
          <ButtonSubmit submitType="cancel" onClick={onClose}>
            {t("button.close")}
          </ButtonSubmit>
          <ButtonSubmit submitType="save" onClick={onSave}>
            {t("button.save")}
          </ButtonSubmit>
        </FormBtnSubmit>
      </div>
    </>
  );

  if (isTabletOrMobile) {
    return (
      <Drawer
        onOpen={() => {}}
        anchor="bottom"
        open={!!interestAdding && !openNewPortfolio}
        onClose={onClose}
        sx={{
          display: !!interestAdding && !openNewPortfolio ? "block" : "none",
          zIndex: "1301 !important", // pop over modalComprehensiveSearch
          "& .MuiPaper-root": {
            borderRadius: "16px 16px 0 0"
          }
        }}>
        {contentComponent}
      </Drawer>
    );
  }
  return (
    <Dialog
      open={!!interestAdding && !openNewPortfolio}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "480px"
        }
      }}>
      {contentComponent}
    </Dialog>
  );
}

export default memo(ModalMyPortfolio);
