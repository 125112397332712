export default function CopySvg({
  fillColor = "#AFB1B8",
  width = 20,
  height = 20
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 8.08333C8.56836 8.08333 8.08333 8.56835 8.08333 9.16666V16.6667C8.08333 17.265 8.56836 17.75 9.16667 17.75H16.6667C17.265 17.75 17.75 17.265 17.75 16.6667V9.16666C17.75 8.56835 17.265 8.08333 16.6667 8.08333H9.16667ZM6.91667 9.16666C6.91667 7.92402 7.92403 6.91666 9.16667 6.91666H16.6667C17.9093 6.91666 18.9167 7.92402 18.9167 9.16666V16.6667C18.9167 17.9093 17.9093 18.9167 16.6667 18.9167H9.16667C7.92403 18.9167 6.91667 17.9093 6.91667 16.6667V9.16666Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 2.24999C3.04601 2.24999 2.77046 2.36413 2.5673 2.5673C2.36414 2.77046 2.25 3.04601 2.25 3.33333V10.8333C2.25 11.1206 2.36414 11.3962 2.5673 11.5994C2.77046 11.8025 3.04601 11.9167 3.33333 11.9167H4.16667C4.48883 11.9167 4.75 12.1778 4.75 12.5C4.75 12.8222 4.48883 13.0833 4.16667 13.0833H3.33333C2.73659 13.0833 2.1643 12.8463 1.74234 12.4243C1.32038 12.0024 1.08333 11.4301 1.08333 10.8333V3.33333C1.08333 2.73659 1.32038 2.16429 1.74234 1.74234C2.1643 1.32038 2.7366 1.08333 3.33333 1.08333H10.8333C11.4301 1.08333 12.0024 1.32038 12.4243 1.74234C12.8463 2.16429 13.0833 2.73659 13.0833 3.33333V4.16666C13.0833 4.48883 12.8222 4.74999 12.5 4.74999C12.1778 4.74999 11.9167 4.48883 11.9167 4.16666V3.33333C11.9167 3.04601 11.8025 2.77046 11.5994 2.5673C11.3962 2.36413 11.1206 2.24999 10.8333 2.24999H3.33333Z"
        fill={fillColor}
      />
    </svg>
  );
}
