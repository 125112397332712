export default function ChatIconSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.01458 21.69C2.83458 21.69 2.65458 21.62 2.51458 21.48C2.32458 21.29 2.26458 21.02 2.34458 20.76L4.15458 15.33C3.59458 14.13 3.30458 12.8 3.30458 11.48C3.30458 9.77004 3.78458 8.10004 4.68458 6.65004C5.58458 5.20004 6.86458 4.02004 8.39458 3.26004C9.65458 2.62004 11.0746 2.29004 12.4846 2.29004H13.0046C15.3046 2.42004 17.4246 3.37004 19.0246 4.97004C20.6246 6.57004 21.5746 8.70004 21.6946 10.96V11.5C21.6946 12.92 21.3646 14.35 20.7246 15.62C19.9646 17.15 18.7946 18.43 17.3346 19.33C15.8846 20.23 14.2046 20.7 12.4946 20.71C11.1246 20.71 9.84458 20.42 8.64458 19.86L3.21458 21.67C3.14458 21.69 3.06458 21.71 2.99458 21.71L3.01458 21.69ZM12.4946 3.69004C11.2946 3.69004 10.0946 3.97004 9.03458 4.51004C7.73458 5.16004 6.64458 6.15004 5.88458 7.39004C5.12458 8.62004 4.71458 10.04 4.71458 11.49C4.71458 12.7 4.99458 13.9 5.53458 14.97C5.61458 15.14 5.63458 15.33 5.57458 15.51L4.11458 19.88L8.48458 18.42C8.66458 18.36 8.85458 18.37 9.02458 18.46C10.0946 19 11.2846 19.29 12.4846 19.29C13.9646 19.29 15.3746 18.89 16.6046 18.12C17.8346 17.36 18.8346 16.27 19.4746 14.97C20.0146 13.89 20.3046 12.69 20.3046 11.48V10.98C20.2046 9.10004 19.3946 7.30004 18.0346 5.95004C16.6746 4.60004 14.8746 3.79004 12.9646 3.68004H12.5046C12.5046 3.68004 12.4946 3.68004 12.4846 3.68004L12.4946 3.69004Z"
        fill={fillColor}
      />
    </svg>
  );
}
