/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";
import URL from "./url";

class MainPageService {
  getMenu() {
    const url = URL.GET_MENU;
    return axiosClient.get(url);
  }

  getGeneralPost() {
    const url = URL.GET_GENERAL_POST;
    return axiosClient.get(url);
  }

  get5LatestPost(id) {
    const url = URL.GET_5_LATEST_POST(id);
    return axiosClient.get(url);
  }

  getDetailBoard(id) {
    const url = URL.GET_DETAIL_BOARD(id);
    return axiosClient.get(url);
  }

  getCountAllNoti() {
    const url = URL.GET_COUNT_ALL_NOTI;
    return axiosClient.get(url);
  }

  getAllAlarmList() {
    const url = URL.GET_ALL_ALARM_LIST;
    return axiosClient.get(url);
  }

  getNotReadAlarmList() {
    const url = URL.GET_NOT_READ_ALARM_LIST;
    return axiosClient.get(url);
  }

  getPostDetail(id, idBoard) {
    const url = URL.GET_POST_DETAIL(id, idBoard);
    return axiosClient.get(url);
  }

  getSettingPage() {
    const url = URL.GET_SETTING_PAGE;
    return axiosClient.get(url);
  }

  getBlockIp(signal) {
    const url = URL.GET_BLOCK_IP;
    return axiosClient.get(url, {
      signal
    });
  }

  getSliderPost(id) {
    const url = URL.GET_SLIDER_POST(id);
    return axiosClient.get(url);
  }

  checkVisitPage(body, signal) {
    const url = URL.CHECK_VISIT_PAGE;
    return axiosClient.post(url, body, {
      signal
    });
  }
}

export default new MainPageService();
