export default function ExchangeSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1997 4.91533C12.5863 4.91533 12.8997 5.22873 12.8997 5.61533V19.1627L16.4772 15.1495C16.7344 14.8609 17.1769 14.8356 17.4655 15.0928C17.7541 15.3501 17.7795 15.7925 17.5222 16.0811L12.7222 21.4657C12.529 21.6825 12.2219 21.7575 11.9505 21.6541C11.6791 21.5507 11.4997 21.2904 11.4997 20.9999V5.61533C11.4997 5.22873 11.8131 4.91533 12.1997 4.91533Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79981 17.0846C5.41321 17.0846 5.09981 16.7712 5.09981 16.3846L5.09981 2.83718L1.52234 6.85037C1.26509 7.13895 0.8226 7.16435 0.534016 6.9071C0.245433 6.64985 0.220033 6.20736 0.477285 5.91878L5.27729 0.534161C5.47055 0.317355 5.77763 0.242417 6.04904 0.345828C6.32045 0.449239 6.49981 0.709512 6.49981 0.999957L6.49981 16.3846C6.49981 16.7712 6.18641 17.0846 5.79981 17.0846Z"
        fill={fillColor}
      />
    </svg>
  );
}
