const detectDeviceType = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    if (window.flutter_inappwebview) return "APP";
    return "MOBILE";
  }
  return "PC";
};

const isBrowserTabFocused = () => !document.hidden;

const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export { detectDeviceType, isBrowserTabFocused, isIOS };
