/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import Header from "components/subHeaderMobile";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { getMemberShip } from "services/SettingService";
import { SelfScrollDiv } from "views/HeatMap/styled";
import { ModalStyled } from "./styled";

const fakeArticle2 = [
  {
    content:
      "“서비스”라 함은 구현되는 단말기(PC, TV, 휴대용 단말기등 각종 유무선 기기 포함)와 상관없이 회사가 회원에게 제공하는 모든 인터넷 서비스를 의미합니다."
  },
  {
    content:
      "“회원”이라 함은 서비스에 접속하여 이 약관에 따라 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다."
  },
  {
    content:
      "“아이디”라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인한 문자와 숫자의 조합을 의미합니다."
  },
  {
    content:
      "“비밀번호”라 함은 회원이 사용하는 아이디와 일치된 회원임을 확인하고 회원의 권익보호를 위하여 회원이 선정한 문자와 숫자 조합을 말합니다."
  },
  {
    content:
      "“서비스”라 함은 구현되는 단말기(PC, TV, 휴대용 단말기등 각종 유무선 기기 포함)와 상관없이 회사가 회원에게 제공하는 모든 인터넷 서비스를 의미합니다."
  },
  {
    content:
      "“이용계약”이라 함은 서비스를 제공받기 위하여 회사와 체결한 계약을 말합니다."
  },
  {
    content: "“해지”라 함은 회사 또는 회원이 이용계약을 해약하는 것을 말합니다."
  },
  {
    content:
      "“게시물”이라 함은 “회원”이 “서비스”를 이용함에 있어 “서비스상”에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다."
  }
];

const fakeArticle3 = [
  {
    content:
      "이 약관은 서비스 초기화면에 공지되거나 전자우편의 방법으로 회원에게 공지됨으로써 그 효력이 발생됩니다."
  },
  {
    content:
      "합리적 사유가 발생할 경우, 회사는 관계법령에 위배되지않는 범위 내에서 본 약관을 개정할 수 있습니다."
  },
  {
    content:
      "회사가 본 약관을 개정하는 경우에는 개정사유 및 적용일자를 명시하여 현행 약관과 함께 적용일자 7일전부터 적용일전일까지 제 1항의 방법으로 공지합니다. 다만, 회원의 권리 또는 의무에 관한 중요한 규정의 변경은 그 적용일자 30일전부터 적용일 이후 상당한 기간 동안 서비스 초기화면에 공지하고, 전자우편으로 통지 합니다."
  },
  {
    content:
      "회사가 전항에 따라 회원에게 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 의사표시가 없을 경우에는 변경된 약관을 승인한 것으로 봅니다."
  },
  {
    content:
      "회원은 변경된 약관에 동의하지 아니하는 경우 서비스의 이용을 중단하고 이용 계약을 해지할 수 있습니다."
  }
];

const fakeArticle6 = [
  {
    content:
      "회원이 신청 양식에 기재하는 회원정보는 이용고객의 실제 정보인 것으로 간주되고, 실제정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며 서비스 이용에 제한을 받을 수 있습니다."
  },
  {
    content:
      "회사는 회원이 등록한 정보에 대하여 본인의 정보 여부 확인 조치를 할 수 있으며, 회원은 회사의 확인 조치에 대하여 적극 협력합니다. 만일 이를 준수하지 아니할 경우 회사는 회원이 등록한 정보가 허위정보로 간주할 수 있습니다."
  },
  {
    content:
      "만 14세 미만의 아동이 서비스를 이용하기 위해서는 회사가 요구하는 법정대리인의 동의 절차를 거쳐야 합니다."
  },
  {
    content:
      "타인의 개인정보를 도용하는 등 부정한 목적과 방법으로 이용신청을 한 회원의 아이디는 사전 통지없이 삭제될 수 있으며, 당해 회원은 관계법령에 따라 처벌을 받을 수 있습니다."
  }
];

const fakeArticle8 = [
  {
    content: {
      contentMain:
        "회사는 제5조, 제6조의 규정에 의한 가입신청자의 신청에 대하여 업무 수행상 또는 기술상 지장이 없는 경우에 서비스 이용을 승낙함을 원칙으로 합니다."
    }
  },
  {
    content: {
      contentMain:
        "회사는 다음 각호에 해당하는 신청에 대하여는 승낙을 보류할 수 있습니다.",
      allContent: [
        {
          title: "서비스 제공을 위한 설비 용량에 현실적인 여유가 없는 경우"
        },
        {
          title: "서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우"
        },
        {
          title: "기타 회사가 재정적, 기술적으로 필요하다고 인정되는 경우"
        }
      ]
    }
  },
  {
    content: {
      contentMain:
        "회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나, 사후에 이용 계약을 해지 할 수 있습니다.",
      allContent: [
        {
          title:
            "이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻는 경우에는 예외로 함."
        },
        {
          title: "신청양식을 허위로 기재한 경우"
        },
        {
          title: "신청양식의 기재사항을 누락하거나 오기하여 신청하는 경우"
        },
        {
          title:
            "법령위반 또는 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하는 경우"
        },
        {
          title: "부정한 용도로 본 서비스를 이용하고자 하는 경우"
        }
      ]
    }
  }
];

const fakeArticle9 = [
  {
    content: {
      contentMain:
        "회사는 회원에 대하여 이 약관에 정하는 바에 따라 아이디를 부여합니다."
    }
  },
  {
    content: {
      contentMain:
        "아이디는 다음 각 호에 해당하는 경우에 회원의 요청 또는 회사의 직권으로 변경 또는 정지 할 수 있습니다.",
      allContent: [
        {
          title:
            "아이디가 고객의 연락처 또는 주민등록번호 등으로 등록되어 사행활 침해가 우려되는 경우"
        },
        {
          title: "타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우"
        },
        {
          title:
            "회사, 회사의 서비스 또는 서비스 운영자 등의 명칭과 동일하거나 오인 등의 우려가 있는 경우"
        },
        {
          title: "기타 합리적인 사유가 있는 경우"
        }
      ]
    }
  },
  {
    content: {
      contentMain:
        "아이디 및 비밀번호 관리 책임은 회원에게 있습니다. 이를 소홀이 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 회원에게 있으며 회사는 그에 대한 책임을 지지 않습니다."
    }
  },
  {
    content: {
      contentMain:
        "기타 이용자 개인정보 관리 및 변경 등에 관한 사항은 서비스별 안내에서 정하는 바에 의합니다."
    }
  }
];

const fakeArticle10 = [
  {
    content:
      "회사는 회사의 서비스 제공 및 보안과 관련된 설비를 지속적이고 안정적인 서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다."
  },
  {
    content:
      "회사는 서비스 제공과 관련하여 알고 있는 회원의 회원정보를 본인의 동의없이 제3자에게 제공하지 않습니다."
  },
  {
    content:
      "회사는 회원의 회원정보를 보호하기 위해 보안시스템을 구축 운영하며, “개인정보취급방침”을 공지하고 준수합니다. 또한, 회사는 “개인정보취급방침”에 따라 회원정보를 취급함에 있어 안정성 확보에 필요한 기술적 및 관리적 대책을 수립ㆍ운영합니다."
  },
  {
    content:
      "회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 회원에게 통지합니다."
  },
  {
    content:
      "회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실에 의해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상 손해에 한합니다."
  },
  {
    content:
      "회사가 제3자와의 서비스 제공계약 등을 체결하여 회원에게 서비스를 제공하는 경우 회사는 각 개별서비스에서 서비스의 제공을 위하여 제3자에게 제공되는 회원의 구체적인 회원정보를 명시하고 회원의 개별적이고 명시적인 동의를 받은 후 동의의 범위 내에서 해당 서비스의 제공 기간 동안에 한하여 회원의 개인정보를 제3자와 공유하는 등 관련 법령을 준수합니다."
  }
];

const fakeArticle11 = [
  {
    content:
      "회원은 신청양식 기재시 또는 회원정보 변경시 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 개인정보를 이용한 사실이 발견된 경우 회원은 서비스 이용과 관련한 일체의 권리를 주장할 수 없습니다."
  },
  {
    content:
      "회원은 관계법령, 약관, 서비스 이용안내 및 서비스상에 공지한 주의사항, 회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다."
  },
  {
    content:
      "회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다."
  },
  {
    content:
      "회원은 회원 아이디 및 비밀번호를 철저히 관리하여야 하며, 관리소홀, 부정사용 등에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인이 부담하며, 회사는 이에 대한 어떠한 책임도 부담하지 않습니다."
  },
  {
    content:
      "회원은 본인의 아이디 및 비밀번호를 제3자에게 이용하게 하여서는 아니되며, 회원 본인의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지하는 경우에는 즉시 비밀 번호를 변경하여야 하며, 해당 사실을 회사에 통지하고 회사가 안내하는 바에 따라야 합니다."
  },
  {
    content:
      "회원은 회사 및 제3자의 지적 재산권을 포함한 제반 권리를 침해하거나 제18조 각호에 해당하는 행위를 해서는 안됩니다."
  }
];

const fakeArticle12 = [
  {
    content:
      "서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시 중단은 서비스 초기화면을 통해 사전에 공지합니다."
  },
  {
    content:
      "회사는 긴급한 시스템 점검, 증설 및 교체, 설비의 장애, 서비스 이용의 폭주, 국가비상사태, 정전 등 부득이한 사유가 발생한 경우 사전 예고 없이 일시적으로 서비스의 전부 또는 일부를 중단할 수 있습니다."
  },
  {
    content:
      "회사는 서비스 개편 등 서비스 운영상 필요한 경우 회원에게 사전 예고한 후 서비스의 전부 또는 일부의 제공을 중단할 수 있습니다."
  }
];

const fakeArticle13 = [
  {
    content:
      " 회원이 서비스에 등록하는 게시물 등으로 인하여 본인 또는 타인에게 손해나 기타 문제가 발생하는 경우 회원은 이에 대한 책임을 지게 되며, 회사는 특별한 사정이 없는 한 이에 대하여 책임을 지지 않습니다."
  },
  {
    content:
      "회사는 다음 각호에 해당하는 게시물 등을 회원의 사전 동의 없이 임시 게시 중단, 수정, 삭제, 이동 또는 등록 거부 등의 관련 조치를 취할 수 있습니다."
  },
  {
    content:
      "다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우"
  },
  {
    content: "공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우"
  },
  {
    content: "불법복제 또는 해킹을 조장하는 내용인 경우"
  },
  {
    content: "영리를 목적으로 하는 광고인 경우"
  },
  {
    content: "범죄와 결부된다고 객관적으로 인정되는 내용인 경우"
  },
  {
    content:
      "다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우"
  },
  {
    content:
      "사적인 정치적 판단이나 종교적 견해의 내용으로 회사가 서비스 성격에 부합하지 않는다고 판단하는 경우"
  },
  {
    content:
      "회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우"
  },
  {
    content: "기타 관계법령에 위배된다고 판단되는 경우"
  },
  {
    content:
      "회사는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로 게시 중단(전송 중단)할 수 있으며, 게시 중단 요청자와 게시물 등록자 간에 소송, 합의 또는 이에 준하는 관련기관의 결정 등이 이루어져 회사에 접수된 경우 이에 따릅니다."
  },
  {
    content:
      "해당 게시물 등에 대해 임시 게시 중단이 된 경우, 게시물을 등록한 회원은 재게시(전송 재개)를 회사에 요청할 수 있으며, 게시 중단일로부터 3개월 내에 재게시를 요청하지 아니한 경우 회사는 이를 삭제할 수 있습니다."
  },
  {
    content:
      "회사는 개별서비스별로 게시물과 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 게시물(회원간 전달 포함)을 게재하여야 합니다."
  }
];

const fakeArticle14 = [
  {
    content: {
      contentMain:
        "회사가 작성한 게시물 또는 저작물에 대한 저작권과 기타 지적재산권은 회사에 귀속됩니다."
    }
  },
  {
    content: {
      contentMain:
        "회원이 서비스 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 단, 회사는 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 회원의 별도의 허락 없이 무상으로 저작권법에 규정하는 공정한 관행에 합치되게 합리적인 범위 내에서 다음과 같이 회원이 등록한 게시물을 사용할 수 있습니다.",
      allContent: [
        {
          title:
            "서비스 내에서 회원 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및 저작물성을 해치지 않는 범위 내에서의 편집 저작물 작성"
        }
      ]
    }
  }
];

const fakeArticle15 = [
  {
    content:
      "회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 전자우편, 서면, 전화, SMS등의 방법으로 회원에게 제공할 수 있습니다."
  },
  {
    content:
      "회사는 서비스의 운영과 관련하여 광고 등을 서비스 화면에 게재하거나, 전자우편, 서면, 전화, SMS등을 통하여 발송할 수 있습니다. 다만, 회사는 회원에게 전자우편, 서면, 전화, SMS 등을 발송하는 경우 수신 동의 여부를 확인하여, 수신 동의한 회원에 한하여 이를 발송합니다."
  },
  {
    content:
      "회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주간의 문제입니다. 만약, 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다."
  }
];

const fakeArticle16 = [
  {
    content:
      "회사가 회원에게 서비스를 제공할 수 있는 서비스 투자 기반의 일부는 광고게재를 통한 수익으로부터 나옵니다. 회원은 서비스 이용시 노출되는 광고게재에 대해 동의합니다."
  },
  {
    content:
      " 회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다."
  }
];

const fakeArticle17 = [
  {
    content:
      "회원은 언제든지 서비스 초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, “회사”는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다."
  },
  {
    content:
      "회원이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 “회사”가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 “회원”의 모든 데이터는 소멸됩니다."
  },
  {
    content:
      "회원이 계약을 해지하는 경우, “회원”이 작성한 “게시물” 중, 본인 계정에 등록된 게시물 일체는 삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 되어 재게시되거나, 공용게시판에 등록된 “게시물” 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다."
  },
  {
    content:
      "회원은 제3항에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개합니다."
  }
];

const fakeArticle18 = [
  {
    content: {
      contentMain:
        "회사는 회원이 서비스 이용내용에 있어서 본 약관 제11조 내용을 위반하거나, 다음 각호에 해당하는 경우 서비스 이용 제한, 초기화, 이용계약 해지 및 기타 해당 조치를 할 수 있습니다.",
      allContent: [
        {
          title:
            "회원정보에 허위 내용을 등록하거나 타인의 아이디, 비밀번호 등 기타 개인정보를 도용하는 행위 또는 사용자아이디를 타인과 거래하거나 제공하는 행위"
        },
        {
          title:
            "공공질서 및 미풍양속에 위반되는 저속, 음란한 내용 또는 타인의 명예나 프라이버시를 침해할 수 있는 내용의 정보, 문장, 도형, 사진, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위"
        },
        {
          title:
            " 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위"
        },
        {
          title:
            "회사로부터 특별한 권리를 부여 받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위"
        },
        {
          title:
            "서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위"
        },
        {
          title:
            "회사의 운영진, 직원 또는 관계자를 사칭하거나 고의로 서비스를 방해하는 등 정상적인 서비스 운영에 방해가 될 경우"
        },
        {
          title: "정보통신윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우"
        },
        {
          title:
            "약관을 포함하여 회사가 정한 제반 규정을 위반하거나 범죄와 결부된다고 객관적으로 판단되는 등 관계법령을 위반하는 행위"
        }
      ]
    }
  }
];

const fakeArticle19 = [
  {
    content:
      "회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를 배상해야 합니다."
  },
  {
    content:
      "회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보취급방침에서 정하는 내용을 위반하지 않는 한 어떠한 손해도 책임 지지 않습니다."
  }
];

const fakeArticle20 = [
  {
    content:
      "회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다."
  },
  {
    content:
      "회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다."
  },
  {
    content:
      "회사는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 이메일 주소를 부정확하게 기재해 손해가 발생한 경우 책임을 지지 않습니다."
  },
  {
    content:
      "회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 자료로 인한 손해에 대해서도 책임을 지지 않습니다."
  },
  {
    content:
      "회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않으며, 회원 상호간 및 회원과 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없고, 이로 인한 손해를 배상할 책임도 없습니다."
  },
  {
    content:
      "회사는 회원의 게시물을 등록 전에 사전심사하거나 상시적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지 아니합니다."
  }
];

const fakeArticle21 = [
  {
    content:
      "회사는 회원이 서비스 이용에 필요한 권리 및 의무 등에 관한 사항을 회원이 지정한 메일, SMS 등으로 통지 할 수 있습니다."
  },
  {
    content:
      "회사는 불특정 다수 회원에 대한 통지의 경우 서비스에 게시함으로써 개별 통지에 갈음할 수 있습니다."
  }
];

const fakeArticle22 = [
  {
    content:
      "본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에 대하여는 관습에 의합니다."
  },
  {
    content:
      "회사의 유료 서비스 이용 회원의 경우 당해 서비스와 관련해서는 서비스별 안내에 따릅니다."
  },
  {
    content:
      "회사와 회원간 발생한 분쟁에 대해 소송이 제기되는 경우 서울중앙지방법원을 관할법원으로 합니다."
  }
];

export function ContentMemberShip({ title, content, withNumber, listData }) {
  return (
    <div className="flex-col gap-16">
      <h4>{title}</h4>
      {withNumber ? (
        <>
          {content ? (
            <p className="fw-500 fs-14 lh-22 color-neutral">{content}</p>
          ) : null}
          {listData?.map((item, index) =>
            item.content.contentMain ? (
              <>
                <ContentMemberWithNumber
                  key={index}
                  index={index + 1}
                  content={item.content.contentMain}
                />
                {item.content.allContent?.map((itemList, idx) => (
                  <ContentMemberWithNumberCircle
                    key={`member_number${idx}`}
                    index={idx + 1}
                    content={itemList.title}
                  />
                ))}
              </>
            ) : (
              <ContentMemberWithNumber
                key={`member_${index}`}
                index={index + 1}
                content={item.content}
              />
            )
          )}
        </>
      ) : (
        <p className="fw-500 fs-14 lh-22 color-neutral">{content}</p>
      )}
    </div>
  );
}

export function ContentMemberWithNumber({ index, content }) {
  return (
    <p className="fw-500 fs-14 lh-22 color-neutral">
      {index}) {content}
    </p>
  );
}

export function ContentMemberWithNumberCircle({ index, content }) {
  return (
    <div className="flex gap-4">
      <div className="circle-number">
        <p className="txt">{index}</p>
      </div>
      <p className="flex fw-500 fs-14 lh-22 color-neutral">{content}</p>
    </div>
  );
}

export default function ModalMemberShip() {
  const dispatch = useDispatch();
  const openModalMemberShip = useSelector(
    (state) => state.common.openModalMemberShip
  );
  const onClose = () => {
    dispatch(commonActions.onCloseModalMemberShip());
  };
  // const [content, setContent] = useState("");

  // useEffect(() => {
  //   getContentMember();
  // }, []);

  // const getContentMember = async () => {
  //   try {
  //     // setIsLoading(true);
  //     const res = await getMemberShip();
  //     if (res?.code === "200") {
  //       setContent(res?.data[0]?.detail);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };

  return (
    <Modal
      open={openModalMemberShip}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          logo={false}
          borderBottom
          onBack={onClose}
          headerTitle={<h1>회원가입약관</h1>}
        />

        <SelfScrollDiv className="pt-32 pl-pr-20 pb-64 gap-32">
          {/* <h2>최신 수정일: 0000년 0월 00일</h2> */}
          <div className="flex-col gap-24">
            <h3>[ 제1장 총칙 ]</h3>
            <ContentMemberShip
              title="제1조 (목적)"
              content=" 이 약관은 데일리에이치㈜(이하 “회사”라 함)가 제공하는 서비스와 관련하여
        회사와 이용자(이하 “회원”이라 함) 간에 서비스 이용 조건 및 절차, 회사와
        회원간의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로
        합니다."
            />
            <ContentMemberShip
              title="제2조 (용어의 정의)"
              content="이 약관에서 사용하는 용어의 정의는 다음과 같습니다."
              listData={fakeArticle2}
              withNumber
            />
            <ContentMemberShip
              title="제3조 (이용약관의 효력 및 변경)"
              listData={fakeArticle3}
              withNumber
            />
            <ContentMemberShip
              title="제4조 (약관 외 준칙)"
              content="회사는 필요한 경우 개별 서비스에 대하여 별도의 이용약관 및 운영원칙(이하 “서비스별 안내”라 함)을 정할 수 있으며, 해당 내용이 이 약관과 상충되는 경우에는 서비스별 안내의 내용을 우선하여 적용합니다."
            />
          </div>
          <div className="pt-24 flex-col gap-24">
            <h3>[ 제2장 이용계약 체결 ]</h3>
            <ContentMemberShip
              title="제5조 (이용계약의 성립)"
              content="서비스 이용계약은 회원이 되고자 하는 자(이하 “가입신청자”)가 이 약관 및 “개인정보취급방침”에 대하여 동의를 한 다음 회사가 정한 신청양식을 작성하여 회원가입 신청을 하고 회사가 이를 승낙함으로써 성립됩니다."
            />
            <ContentMemberShip
              title="제6조 (서비스 이용 신청)"
              listData={fakeArticle6}
              withNumber
            />
            <ContentMemberShip
              title="제7조 (개인정보의 보호 및 사용)"
              content="회사는 관계법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다."
            />
            <ContentMemberShip
              title="제8조 (이용 신청의 승낙과 제한)"
              listData={fakeArticle8}
              withNumber
            />
            <ContentMemberShip
              title="제8조 (이용 신청의 승낙과 제한)"
              listData={fakeArticle9}
              withNumber
            />
          </div>
          <div className="pt-24 flex-col gap-24">
            <h3>[ 제3장 계약 당사자의 의무 ]</h3>
            <ContentMemberShip
              title="제10조 (회사의 의무)"
              listData={fakeArticle10}
              withNumber
            />
            <ContentMemberShip
              title="제11조 (회원의 의무)"
              listData={fakeArticle11}
              withNumber
            />
          </div>
          <div className="pt-24 flex-col gap-24">
            <h3>[ 제4장 서비스의 이용 ]</h3>
            <ContentMemberShip
              title="제12조 (서비스 이용 시간)"
              listData={fakeArticle12}
              withNumber
            />
            <ContentMemberShip
              title="제13조 (회원의 게시물 등)"
              listData={fakeArticle13}
              withNumber
            />
            <ContentMemberShip
              title="제14조 (게시물에 대한 저작권)"
              listData={fakeArticle14}
              withNumber
            />
            <ContentMemberShip
              title="제15조 (정보의 제공)"
              listData={fakeArticle15}
              withNumber
            />
            <ContentMemberShip
              title="제16조 (광고게재 및 광고주와의 거래)"
              listData={fakeArticle16}
              withNumber
            />
          </div>
          <div className="pt-24 flex-col gap-24">
            <h3>[ 제5장 계약 해지 및 이용 제한 ]</h3>
            <ContentMemberShip
              title="제17조 (계약 변경 및 해지)"
              listData={fakeArticle17}
              withNumber
            />
            <ContentMemberShip
              title="제18조 (서비스 이용제한)"
              listData={fakeArticle18}
              withNumber
            />
          </div>
          <div className="pt-24 flex-col gap-24">
            <h3>[ 제6장 손해배상 및 기타사항 ]</h3>
            <ContentMemberShip
              title="제19조 (손해배상)"
              listData={fakeArticle19}
              withNumber
            />
            <ContentMemberShip
              title="제20조 (면책조항)"
              listData={fakeArticle20}
              withNumber
            />
            <ContentMemberShip
              title="제21조 (통지)"
              listData={fakeArticle21}
              withNumber
            />
            <ContentMemberShip
              title="제22조 (재판권 및 준거법)"
              listData={fakeArticle22}
              withNumber
            />
          </div>
        </SelfScrollDiv>
        {/* <SelfScrollDiv>
          <pre className="pt-32 pl-pr-20 pb-64">{content}</pre>
        </SelfScrollDiv> */}
      </ModalStyled>
    </Modal>
  );
}
