import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listPopularSearch: [],
  listSearchResult: [],
  listCategory: [],
  currentCategory: "ALL",
  loading: true
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setListPopularSearch: (state, action) => {
      state.listPopularSearch = action?.payload;
    },
    clearListPopularSearch: (state) => {
      state.listPopularSearch = [];
    },
    setListSearchResult: (state, action) => {
      state.listSearchResult = action?.payload;
    },
    clearListSearchResult: (state) => {
      state.listSearchResult = [];
    },
    setListCategory: (state, action) => {
      state.listCategory = action?.payload;
    },
    clearListCategory: (state) => {
      state.listCategory = [];
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action?.payload;
    }
  }
});

// Actions
export const searchActions = searchSlice.actions;

// Reducer
const searchReducer = searchSlice.reducer;
export default searchReducer;
