import styled from "styled-components";

export const FilterCountryStyled = styled.div`
  display: flex;
  flex-grow: 1;
  height: ${(props) => (props.pc ? "32px" : "24px")};
  justify-content: space-between;
`;
export const CountryListStyled = styled.div`
  display: flex;
  height: ${(props) => (props.pc ? "32px" : "24px")};
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  }

  img.pc {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  }

  img:not(:first-child) {
    margin-left: -8px;
  }

  .h-24 {
    height: 24px;
  }

  .h-w-32 {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  h6 {
    background: #45495a;
    margin-left: -8px;
    color: #fff;
    padding: 1px 6px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
