export default function CameraIcon({ fillColor = "#9497A0" }) {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41761 0.611759C8.54744 0.41702 8.766 0.300049 9.00005 0.300049H15C15.2341 0.300049 15.4527 0.41702 15.5825 0.611759L17.3747 3.30005H21C21.7161 3.30005 22.4029 3.58451 22.9092 4.09086C23.4156 4.59721 23.7001 5.28396 23.7001 6.00005V17C23.7001 17.7161 23.4156 18.4029 22.9092 18.9092C22.4029 19.4156 21.7161 19.7001 21 19.7001H3.00005C2.28396 19.7001 1.59721 19.4156 1.09086 18.9092C0.584512 18.4029 0.300049 17.7161 0.300049 17V6.00005C0.300049 5.28396 0.584512 4.59721 1.09086 4.09086C1.59721 3.58451 2.28396 3.30005 3.00005 3.30005H6.62542L8.41761 0.611759ZM9.37468 1.70005L7.58248 4.38834C7.45266 4.58308 7.2341 4.70005 7.00005 4.70005H3.00005C2.65527 4.70005 2.32461 4.83701 2.08081 5.08081C1.83701 5.32461 1.70005 5.65527 1.70005 6.00005V17C1.70005 17.3448 1.83701 17.6755 2.08081 17.9193C2.32461 18.1631 2.65527 18.3 3.00005 18.3H21C21.3448 18.3 21.6755 18.1631 21.9193 17.9193C22.1631 17.6755 22.3 17.3448 22.3 17V6.00005C22.3 5.65527 22.1631 5.32461 21.9193 5.08081C21.6755 4.83701 21.3448 4.70005 21 4.70005H17C16.766 4.70005 16.5474 4.58308 16.4176 4.38834L14.6254 1.70005H9.37468Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.70005C10.1775 7.70005 8.70005 9.17751 8.70005 11C8.70005 12.8226 10.1775 14.3 12 14.3C13.8226 14.3 15.3 12.8226 15.3 11C15.3 9.17751 13.8226 7.70005 12 7.70005ZM7.30005 11C7.30005 8.40431 9.40431 6.30005 12 6.30005C14.5958 6.30005 16.7 8.40431 16.7 11C16.7 13.5958 14.5958 15.7 12 15.7C9.40431 15.7 7.30005 13.5958 7.30005 11Z"
        fill={fillColor}
      />
    </svg>
  );
}
