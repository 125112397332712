export default function CameraSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3838_40199)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.56318 1.95879C6.66055 1.81273 6.82447 1.72501 7.00001 1.72501H11.5C11.6755 1.72501 11.8395 1.81273 11.9368 1.95879L13.281 3.97501H16C16.5371 3.97501 17.0521 4.18835 17.4319 4.56811C17.8117 4.94788 18.025 5.46294 18.025 6.00001V14.25C18.025 14.7871 17.8117 15.3021 17.4319 15.6819C17.0521 16.0617 16.5371 16.275 16 16.275H2.50001C1.96294 16.275 1.44788 16.0617 1.06811 15.6819C0.688354 15.3021 0.475006 14.7871 0.475006 14.25V6.00001C0.475006 5.46294 0.688354 4.94788 1.06811 4.56811C1.44788 4.18835 1.96294 3.97501 2.50001 3.97501H5.21903L6.56318 1.95879ZM7.28098 2.77501L5.93683 4.79122C5.83946 4.93728 5.67554 5.02501 5.50001 5.02501H2.50001C2.24142 5.02501 1.99342 5.12773 1.81058 5.31058C1.62773 5.49342 1.52501 5.74142 1.52501 6.00001V14.25C1.52501 14.5086 1.62773 14.7566 1.81058 14.9394C1.99343 15.1223 2.24142 15.225 2.50001 15.225H16C16.2586 15.225 16.5066 15.1223 16.6894 14.9394C16.8723 14.7566 16.975 14.5086 16.975 14.25V6.00001C16.975 5.74142 16.8723 5.49342 16.6894 5.31058C16.5066 5.12773 16.2586 5.02501 16 5.02501H13C12.8245 5.02501 12.6606 4.93728 12.5632 4.79122L11.219 2.77501H7.28098Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25001 7.27501C7.8831 7.27501 6.77501 8.3831 6.77501 9.75001C6.77501 11.1169 7.8831 12.225 9.25001 12.225C10.6169 12.225 11.725 11.1169 11.725 9.75001C11.725 8.3831 10.6169 7.27501 9.25001 7.27501ZM5.72501 9.75001C5.72501 7.8032 7.3032 6.22501 9.25001 6.22501C11.1968 6.22501 12.775 7.8032 12.775 9.75001C12.775 11.6968 11.1968 13.275 9.25001 13.275C7.3032 13.275 5.72501 11.6968 5.72501 9.75001Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_3838_40199">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
