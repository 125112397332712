import styled from "styled-components";

export const NumberTab2Styled = styled.div`
  display: flex;
  flex-direction: column;
  .txt {
    display: flex;
    justify-content: end;
    padding: 0px 0px 16px 0px;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
    .line {
      border-left: 1px solid #e4e5e7;
      height: 10px;
    }
  }
  .news-check {
    width: 100%;
    padding: 16px 0px 12px 0px;
    display: flex;
    align-items: center;
    .check {
      padding: 4px 8px 4px 0;
    }
    .news {
      width: calc(100% - 32px);
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
      svg {
        width: 24px;
        height: 24px;
      }
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
