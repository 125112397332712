/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import { CircleButton56px } from "components/buttons";
import { AlertCircleSvg } from "components/icons";
import Header from "components/subHeaderMobile";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex-col h-100vh">
      <Header />
      <div className="pb-64 pl-20 pr-20">
        <div className="flex-col pt-8 flex-centered flex-1 gap-16 text-centered pr-44 pl-44">
          <CircleButton56px icon={<AlertCircleSvg />} />
          <Trans
            i18nKey="info.pageNotFound"
            parent="div"
            className="flex-col gap-8">
            <h4>페이지를 찾을 수 없어요</h4>
            <h6 className="fw-500 color-neutral">
              존재하지 않는 주소를 입력하셨거나 요청하신 페이지의 주소가 변경,
              삭제되어 찾을 수 없어요.
            </h6>
          </Trans>
          <div className="flex gap-7">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="flex-centered bg-neutral-10 pd-17-0 br-24 border-1px-neutral-7 mw-120px h-48px">
              <h5 className="lh-15">{t("button.back")}</h5>
            </button>
            <button
              type="button"
              onClick={() => navigate("/")}
              className="flex-centered bg-sell pd-17-0 br-24 mw-120px h-48px">
              <h5 className="color-neutral-10 uppercase lh-15">
                {t("button.home")}
              </h5>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
