import MainPageService from "../../services/MainPage/MainPageService";
import { GET_SETTING } from "../type/settingType";

export const getSetting = () => async (dispatch) => {
  try {
    const res = await MainPageService.getSettingPage();
    const data = res?.data?.data ?? {};
    dispatch({ type: GET_SETTING, payload: data });
  } catch (err) {
    console.log(err);
  }
};
