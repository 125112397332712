export default function MarketIconSvg({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="10" width="3" height="8" fill={fillColor} />
      <rect x="5.5" width="3" height="18" fill={fillColor} />
      <rect x="10.5" y="5" width="3" height="13" fill={fillColor} />
    </svg>
  );
}
