/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import { AppBar, Dialog, Toolbar } from "@mui/material";
import classNames from "classnames";
import { successAlert } from "components/alert";
import {
  CheckboxCheckedCircleSvg,
  CheckboxUncheckedCircleSvg
} from "components/icons";
import { InputField } from "components/textField";
import useResponsive from "hook/useResponsive";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { idRouter } from "routes/idRouter";
import { addNewPortfolio, getMyListPortfolio } from "services/PofolioService";
import { isIOS } from "util/CommonUtil";
import {
  ButtonSubmit,
  CheckMark,
  Container,
  CustomCheckbox,
  Drawer,
  FormBtnSubmit,
  Puller
} from "./styled";

function ModalNewPortfolio() {
  const { isTabletOrMobile } = useResponsive();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const openNewPortfolio = useSelector(
    (state) => state.common.openNewPortfolio
  );
  const listPortfolio = useSelector((state) => state.portfolio.listPortfolio);
  // const listHidenModal = [idRouter.recentViewDetail];

  const [checked, setChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!openNewPortfolio) setInputValue("");
  }, [openNewPortfolio]);

  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  const onClose = () => {
    dispatch(commonActions.onCloseNewPortfolio());
  };

  const handleInputChange = (props) => {
    setInputValue(props);
  };

  const onSave = async () => {
    const postData = {
      interestName: inputValue?.trim(),
      defaultYn: checked ? "Y" : "N"
    };

    try {
      setLoading(true);
      const res = await addNewPortfolio(postData);
      if (res.code === "200") {
        successAlert(t("info.themaAddedSuccessfully"));
        getListPortfolio();
        onClose();
        navigate(`/${idRouter.portfolioDetail}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const getListPortfolio = async () => {
    try {
      const res = await getMyListPortfolio({
        currentPage: 1,
        pageSize: 10
      });
      if (res.code === "200" && res.data !== "no data") {
        const newList = res?.data?.list || [];
        dispatch(portfolioActions.setListPortfolio(newList));
        dispatch(portfolioActions.updateCurrentPortfolio(newList[0]));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const contentComponent = (
    <>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
          <div className="flex-start-center gap-12">
            <h2>{t("button.newPortfolio")}</h2>
            <div className="flex-centered gap-2 fit-height">
              <p className="fw-400 font-2 color-info fs-14 lh-18">
                {listPortfolio?.length || 0}
              </p>
              <p className="fw-400 font-2 color-info fs-12 lh-18 pb-2">/</p>
              <p className="fw-400 font-2 color-info fs-14 lh-18">10</p>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Container>
        <InputField onInputChange={handleInputChange} onEnter={onSave} />
        <CustomCheckbox>
          <input
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0"
            }}>
            <CheckMark>
              {checked ? (
                <CheckboxCheckedCircleSvg />
              ) : (
                <CheckboxUncheckedCircleSvg />
              )}
            </CheckMark>
            <h3 className="fw-500">{t("info.setAsDefaultPortfolio")}</h3>
          </div>
        </CustomCheckbox>
      </Container>
      <div className={classNames({ "pb-16": isIOS() })}>
        <FormBtnSubmit>
          <ButtonSubmit submitType="cancle" onClick={onClose}>
            {t("button.close")}
          </ButtonSubmit>
          <ButtonSubmit
            disabled={!inputValue?.trim().length}
            submitType="save"
            onClick={onSave}>
            {t("button.save")}
          </ButtonSubmit>
        </FormBtnSubmit>
      </div>
    </>
  );

  if (isTabletOrMobile) {
    return (
      <Drawer
        onOpen={() => {}}
        anchor="bottom"
        open={openNewPortfolio}
        onClose={onClose}
        sx={{
          display: openNewPortfolio ? "block" : "none",
          "& .MuiPaper-root": {
            borderRadius: "16px 16px 0 0"
          }
        }}>
        {contentComponent}
      </Drawer>
    );
  }
  return (
    <Dialog
      open={openNewPortfolio}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "480px"
        }
      }}>
      {contentComponent}
    </Dialog>
  );
}

export default memo(ModalNewPortfolio);
