export default function CheckboxCheckedCircleSvg({ fillColor = "#1554F6" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={fillColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4948 6.00507C20.7681 6.27844 20.7681 6.72166 20.4948 6.99502L9.49478 17.995C9.22141 18.2684 8.7782 18.2684 8.50483 17.995L3.50483 12.995C3.23146 12.7217 3.23146 12.2784 3.50483 12.0051C3.7782 11.7317 4.22141 11.7317 4.49478 12.0051L8.9998 16.5101L19.5048 6.00507C19.7782 5.73171 20.2214 5.73171 20.4948 6.00507Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1513 5.65152C19.6199 5.18289 20.3797 5.18289 20.8483 5.65152C21.317 6.12015 21.317 6.87995 20.8483 7.34858L9.84833 18.3486C9.3797 18.8172 8.61991 18.8172 8.15128 18.3486L3.15128 13.3486C2.68265 12.8799 2.68265 12.1201 3.15128 11.6515C3.61991 11.1829 4.3797 11.1829 4.84833 11.6515L8.9998 15.803L19.1513 5.65152ZM20.1412 6.35863C20.0631 6.28052 19.9365 6.28052 19.8584 6.35863L9.35336 16.8637C9.25959 16.9574 9.13241 17.0101 8.9998 17.0101C8.8672 17.0101 8.74002 16.9574 8.64625 16.8637L4.14123 12.3586C4.06312 12.2805 3.93649 12.2805 3.85838 12.3586C3.78028 12.4367 3.78028 12.5634 3.85838 12.6415L8.85838 17.6415C8.93649 17.7196 9.06312 17.7196 9.14123 17.6415L20.1412 6.64147C20.2193 6.56337 20.2193 6.43673 20.1412 6.35863Z"
        fill="white"
      />
    </svg>
  );
}
