/* eslint-disable default-param-last */
import {
  CHANGE_PASSWORD,
  CHANGE_INFORMATION,
  EDIT_PROFILE,
  WITHDRAW,
  RESET_ALL
} from "../type/editType";

const initialState = {
  editProfile: null,
  changePassword: null,
  changeInformation: null,
  withdraw: null
};

export const editReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE: {
      return { ...state, editProfile: action.payload };
    }
    case CHANGE_PASSWORD: {
      state.changePassword = action.payload;
      return { ...state };
    }
    case CHANGE_INFORMATION: {
      state.changeInformation = action.payload;
      return { ...state };
    }

    case WITHDRAW: {
      state.withdraw = action.payload;
      return { ...state };
    }
    case RESET_ALL: {
      return {
        editProfile: null,
        changePassword: null,
        changeInformation: null,
        withdraw: null
      };
    }
    default:
      return state;
  }
};
