export default function index({ fillColor = "#9497A0" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00005 1.70005C2.28208 1.70005 1.70005 2.28208 1.70005 3.00005V17C1.70005 17.718 2.28208 18.3 3.00005 18.3H17C17.718 18.3 18.3 17.718 18.3 17V3.00005C18.3 2.28208 17.718 1.70005 17 1.70005H3.00005ZM0.300049 3.00005C0.300049 1.50888 1.50888 0.300049 3.00005 0.300049H17C18.4912 0.300049 19.7001 1.50888 19.7001 3.00005V17C19.7001 18.4912 18.4912 19.7001 17 19.7001H3.00005C1.50888 19.7001 0.300049 18.4912 0.300049 17V3.00005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50005 5.70005C6.05822 5.70005 5.70005 6.05822 5.70005 6.50005C5.70005 6.94188 6.05822 7.30005 6.50005 7.30005C6.94188 7.30005 7.30005 6.94188 7.30005 6.50005C7.30005 6.05822 6.94188 5.70005 6.50005 5.70005ZM4.30005 6.50005C4.30005 5.28502 5.28502 4.30005 6.50005 4.30005C7.71508 4.30005 8.70005 5.28502 8.70005 6.50005C8.70005 7.71508 7.71508 8.70005 6.50005 8.70005C5.28502 8.70005 4.30005 7.71508 4.30005 6.50005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5051 7.50507C13.7784 7.23171 14.2217 7.23171 14.495 7.50507L19.495 12.5051C19.7684 12.7784 19.7684 13.2217 19.495 13.495C19.2217 13.7684 18.7784 13.7684 18.5051 13.495L14 8.99L3.49502 19.495C3.22166 19.7684 2.77844 19.7684 2.50507 19.495C2.23171 19.2217 2.23171 18.7784 2.50507 18.5051L13.5051 7.50507Z"
        fill={fillColor}
      />
    </svg>
  );
}
