export default function CheckCorrectSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1792 3.04077C17.7736 3.63517 17.7736 4.59889 17.1792 5.19329L7.41266 14.9598C6.81825 15.5542 5.85454 15.5542 5.26014 14.9598L0.820801 10.5205C0.2264 9.92609 0.2264 8.96238 0.820801 8.36798C1.4152 7.77358 2.37892 7.77358 2.97332 8.36798L6.3364 11.7311L15.0267 3.04077C15.6211 2.44637 16.5848 2.44637 17.1792 3.04077Z"
        fill={fillColor}
      />
    </svg>
  );
}
