import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabIndex: 0,
  activeTab: null,
  idBoardWrite: null,
  listTabSelect: []
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setTabIndexNews: (state, action) => {
      state.tabIndex = action?.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action?.payload;
    },
    clearActiveTab: (state) => {
      state.activeTab = null;
    },
    updateListTabSelect: (state, action) => {
      state.listTabSelect = action?.payload;
    },
    clearListTabSelect: (state) => {
      state.listTabSelect = [];
    },
    setIdBoardWrite: (state, action) => {
      state.idBoardWrite = action?.payload;
    },
    clearIdBoardWrite: (state) => {
      state.idBoardWrite = null;
    }
  }
});

// Actions
export const newsActions = newsSlice.actions;

// Reducer
const newsReducer = newsSlice.reducer;
export default newsReducer;
