export default function SendNoteSvg(fillColor = "#AFB1B8") {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9166 13.5L14.5833 9.66667V1.5C14.5833 1.41667 14.5833 1.33333 14.5833 1.33333C14.5833 1.33333 14.5833 1.33333 14.5833 1.25C14.5833 1.16667 14.4999 1.16667 14.4999 1.08333C14.4166 1 14.4166 1 14.3333 1C14.2499 1 14.2499 1 14.1666 1H4.33325C4.24992 1 4.24992 1 4.16659 1C4.08325 1 4.08325 1.08333 3.99992 1.08333C3.99992 1.08333 3.91659 1.16667 3.91659 1.25C3.91659 1.25 3.91659 1.25 3.91659 1.33333C3.91659 1.41667 3.91659 1.5 3.91659 1.5V9.58333L1.24992 11.8333C1.16659 11.9167 1.08325 12.0833 1.08325 12.25C1.08325 12.4167 1.16659 12.5833 1.24992 12.6667L5.74992 17.1667C5.99992 17.4167 6.33325 17.4167 6.58325 17.1667L8.99992 14.75L13.6666 18.9167C13.9166 19.0833 14.2499 19.0833 14.4999 18.9167L18.9999 14.4167C19.0833 14.3333 19.1666 14.1667 19.1666 14C19.0833 13.75 18.9999 13.6667 18.9166 13.5ZM9.99992 5.66667L13.4166 2.75V8.66667L9.99992 5.66667ZM12.4166 2.08333L9.16658 4.91667L5.91659 2.08333H12.4166ZM5.99992 16L2.33325 12.3333L4.16659 10.75L7.91658 14.0833L5.99992 16ZM13.9166 17.6667L9.33325 13.5833C9.33325 13.5833 9.33325 13.5 9.24992 13.5C9.16658 13.4167 9.16658 13.4167 9.08325 13.4167L5.08325 9.83333L4.91659 9.66667V8.5V2.75L8.24992 5.66667L9.16658 6.41667L13.5833 10.3333L13.7499 10.4167L17.7499 13.9167L13.9166 17.6667Z"
        fill={fillColor}
      />
    </svg>
  );
}
