import styled from "styled-components";

export const NumberTab3Styled = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const ButtonStyled = styled.button`
  padding: 12px 20px;
  border-radius: 24px;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled
      ? "var(--neutral-color-8, #f4f4f4)"
      : "var(--accent-sell, #1554f6)"};
  h5 {
    text-align: center;
    color: ${({ disabled }) =>
      disabled
        ? "var(--neutral-color-5, #afb1b8)"
        : "var(--neutral-color-10, #ffffff)"};
  }
`;
