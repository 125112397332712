/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { Box, Button, Container, MenuItem } from "@mui/material";
import classNames from "classnames";
import {
  AlarmSvg,
  ChattingSvg,
  LogoFinchiveSvg,
  NoteSvg
} from "components/icons";
import SvgIcons from "components/svgIcons";
import SearchBarPC from "components/textField/searchBarPC";
import { AnimatePresence, motion } from "framer-motion";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import {
  bindHover,
  bindMenu,
  usePopupState
} from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { idRouter } from "routes/idRouter";
import MenuList from "../menu";
import ButtonSelectLanguage from "./buttonLanguage";
import { StyledStackHeader } from "./styled";

function HeaderPC() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menu = [
    {
      title: t("info.morePage.market.title"),
      children: [
        {
          title: t("info.morePage.market.children.thema"),
          onClick: () => {
            navigate("/?tab=0");
          }
        },
        {
          title: t("info.morePage.market.children.stock"),
          onClick: () => {
            navigate("/?tab=1");
          }
        },
        {
          title: t("info.morePage.market.children.indices"),
          onClick: () => {
            navigate("/?tab=2");
          }
        },
        {
          title: t("info.morePage.market.children.foreign"),
          onClick: () => {
            navigate("/?tab=3");
          }
        },
        {
          title: t("info.morePage.market.children.futures"),
          onClick: () => {
            navigate("/?tab=4");
          }
        },
        {
          title: t("info.morePage.market.children.crypto"),
          onClick: () => {
            navigate("/?tab=5");
          }
        },
        {
          title: t("info.morePage.market.children.ETF"),
          onClick: () => {
            navigate("/?tab=6");
          }
        }
      ],
      pathname: "/"
    },
    {
      title: t("info.morePage.watchList"),
      pathname: `/${idRouter.watchList}`,
      onClick: () => navigate(`/${idRouter.watchList}`)
    },
    {
      title: t("info.economicCalendar"),
      children: [
        {
          title: t("info.morePage.economic.children.thema"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=0`);
          }
        },
        {
          title: t("info.morePage.economic.children.disclosure"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=1`);
          }
        },
        {
          title: t("info.morePage.economic.children.indicator"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=2`);
          }
        },
        {
          title: t("info.morePage.economic.children.earnings"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=3`);
          }
        },
        {
          title: t("info.morePage.economic.children.dividend"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=4`);
          }
        },
        {
          title: t("info.morePage.economic.children.split"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=5`);
          }
        },
        {
          title: t("info.morePage.economic.children.IPO"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=6`);
          }
        },
        {
          title: t("info.morePage.economic.children.holiday"),
          onClick: () => {
            navigate(`/${idRouter.economicCalendar}?tab=7`);
          }
        }
        // {
        //   title: t("info.morePage.economic.children.maturity"),
        //   onClick: () => {
        //     navigate(`/${idRouter.communityApp}?tab=8`);
        //   }
        // }
      ],
      pathname: `/${idRouter.economicCalendar}`
    },
    {
      title: t("info.morePage.heatMap.title"),
      children: [
        {
          title: t("info.morePage.heatMap.children.thema"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=0`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.stock"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=1`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.indices"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=2`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.forex"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=3`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.crypto"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=4`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.future"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=5`);
          }
        },
        {
          title: t("info.morePage.heatMap.children.ETF"),
          onClick: () => {
            navigate(`/${idRouter.heatMap}?tab=6`);
          }
        }
      ],
      pathname: `/${idRouter.heatMap}`
    },
    {
      title: t("info.newsAndAnalysis"),
      children: [
        {
          title: t("info.news"),
          onClick: () => {
            navigate(`/${idRouter.newsAndAnalysis}?tab=0`);
          }
        },
        {
          title: t("info.analysis"),
          onClick: () => {
            navigate(`/${idRouter.newsAndAnalysis}?tab=1`);
          }
        },
        {
          title: t("info.report"),
          onClick: () => {
            navigate(`/${idRouter.newsAndAnalysis}?tab=2`);
          }
        }
      ],
      pathname: `/${idRouter.newsAndAnalysis}`
    },
    {
      title: t("info.morePage.community"),
      children: [
        {
          title: "HOT",
          onClick: () => {
            navigate(`/${idRouter.communityApp}?tab=0`);
          }
        },
        {
          title: "LIKE",
          onClick: () => {
            navigate(`/${idRouter.communityApp}?tab=1`);
          }
        },
        {
          title: t("info.freeBulletinBoard"),
          onClick: () => {
            navigate(`/${idRouter.communityApp}?tab=2`);
          }
        }
      ],
      pathname: `/${idRouter.communityApp}`
    }
  ];

  const [showMenu, setShowMenu] = useState(false);
  const navbarRef = useRef(null);
  const [isNavbarSticky, setIsNavbarSticky] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setShowMenu((e) => !e);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = navbarRef.current;
      if (!navbar) return;

      const containerTop = navbar.offsetTop;
      const scrollPosition = window.scrollY;

      if (scrollPosition > containerTop) {
        setIsNavbarSticky(true);
        setShowMenu(false);
      } else {
        setIsNavbarSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <StyledStackHeader>
        <Container
          disableGutters
          className="flex-between"
          style={{
            width: "1256px",
            maxWidth: "1256px" // override default
          }}>
          <Box className="flex-col gap-16 pos-relative full-width">
            <Box className="flex-center gap-32">
              <LogoFinchiveSvg fillColor="#ffffff" />
              <AnimatePresence>
                {!isNavbarSticky && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}>
                    <SearchBarPC onClick={() => setOpen(true)} readOnly />
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
            <Box
              id="navbar"
              ref={navbarRef}
              className={classNames("flex gap-32 list-tab", {
                "sticky-navbar": isNavbarSticky
              })}>
              {menu?.map((item, index) => {
                const popupState = usePopupState({
                  variant: "popover",
                  popupId: `menu-${index}`
                });
                return (
                  <React.Fragment key={index}>
                    <Button
                      aria-haspopup="true"
                      {...bindHover(popupState)}
                      onClick={item?.onClick}>
                      <h3
                        className={classNames("color-neutral-10", {
                          "active-color":
                            window?.location?.pathname === item?.pathname
                        })}>
                        {item?.title}
                      </h3>
                    </Button>
                    {!isEmpty(item?.children) && isArray(item?.children) && (
                      <HoverMenu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        disableScrollLock>
                        {item?.children?.map((subItem, subIndex) => (
                          <MenuItem
                            key={subIndex}
                            onClick={(e) => {
                              popupState.close();
                              setTimeout(() => {
                                subItem?.onClick?.();
                              }, 100);
                            }}>
                            {subItem?.title?.toUpperCase()}
                          </MenuItem>
                        ))}
                      </HoverMenu>
                    )}
                  </React.Fragment>
                );
              })}

              <AnimatePresence>
                {isNavbarSticky && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}>
                    <SearchBarPC onClick={() => setOpen(true)} readOnly />
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
          </Box>

          <Box className="flex-col gap-16">
            <Box className="flex-center gap-16 menu">
              <Box className="flex-center gap-8 pointer">
                <SvgIcons name="DefaultProfilePictureSvg" />
                <h5 className="fw-500 color-neutral-10 ellipsis">
                  닉네임이들어가요
                </h5>
                <SvgIcons name="downTriangleGraySelected" className="chevron" />
              </Box>
              <button type="button" className="bell" onClick={() => {}}>
                <AlarmSvg fillColor="#FFFFFF" />
                <span>1</span>
              </button>
              <button type="button" className="note" onClick={() => {}}>
                <NoteSvg fillColor="#FFFFFF" />
                <span>27</span>
              </button>
              <button type="button" className="chat" onClick={() => {}}>
                <ChattingSvg fillColor="#FFFFFF" />
                <span>30</span>
              </button>
              <ButtonSelectLanguage />
            </Box>
            <Box className="flex-end-center gap-24">
              <button type="button" className="fit-width">
                <h5 className="color-neutral-10">{t("button.tool")}</h5>
              </button>
              <button type="button" className="fit-width">
                <h5 className="color-neutral-10">
                  {t("info.customerService")}
                </h5>
              </button>
              <button type="button" onClick={handleClick}>
                {showMenu ? (
                  <SvgIcons name="closeMenuSvg" />
                ) : (
                  <SvgIcons name="openMenuSvg" />
                )}
              </button>
            </Box>
          </Box>
        </Container>
      </StyledStackHeader>
      <Box>{showMenu && <MenuList />}</Box>
      {/* <ModalSelectTimeFrame open={open} onClose={() => setOpen(false)} /> */}
    </>
  );
}
export default memo(HeaderPC);
