export default function LouOutSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9998 3.70005C4.65502 3.70005 4.32436 3.83701 4.08057 4.08081C3.83677 4.32461 3.6998 4.65527 3.6998 5.00005V19C3.6998 19.3448 3.83677 19.6755 4.08057 19.9193C4.32436 20.1631 4.65502 20.3 4.9998 20.3H8.9998C9.3864 20.3 9.6998 20.6135 9.6998 21C9.6998 21.3866 9.3864 21.7001 8.9998 21.7001H4.9998C4.28372 21.7001 3.59696 21.4156 3.09062 20.9092C2.58427 20.4029 2.2998 19.7161 2.2998 19V5.00005C2.2998 4.28396 2.58427 3.59721 3.09062 3.09086C3.59696 2.58451 4.28372 2.30005 4.9998 2.30005H8.9998C9.3864 2.30005 9.6998 2.61345 9.6998 3.00005C9.6998 3.38665 9.3864 3.70005 8.9998 3.70005H4.9998Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5048 6.50507C15.7782 6.23171 16.2214 6.23171 16.4948 6.50507L21.4948 11.5051C21.7681 11.7784 21.7681 12.2217 21.4948 12.495L16.4948 17.495C16.2214 17.7684 15.7782 17.7684 15.5048 17.495C15.2315 17.2217 15.2315 16.7784 15.5048 16.5051L20.0099 12L15.5048 7.49502C15.2315 7.22166 15.2315 6.77844 15.5048 6.50507Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2998 12C8.2998 11.6134 8.61321 11.3 8.9998 11.3H20.9998C21.3864 11.3 21.6998 11.6134 21.6998 12C21.6998 12.3866 21.3864 12.7 20.9998 12.7H8.9998C8.61321 12.7 8.2998 12.3866 8.2998 12Z"
        fill={fillColor}
      />
    </svg>
  );
}
