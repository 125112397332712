import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "redux/reducers/myPageReducer";

function AvatarImage({ size = "80px", borderRadius = "50%" }) {
  const dispatch = useDispatch();
  const avatarUrl = useSelector((state) => state?.myPage?.avatarUrl);
  useEffect(() => {
    dispatch(
      myPageActions.updateAvatar({
        avatarFile: null,
        avatarUrl: JSON.parse(localStorage.getItem("user"))?.imgPath
      })
    );
  }, []);

  return (
    <div
      className="full-width full-height flex-centered"
      style={{
        width: size,
        height: size,
        borderRadius
      }}>
      {avatarUrl ? (
        <img
          src={avatarUrl}
          alt="avatar"
          style={{
            width: size,
            height: size,
            borderRadius
          }}
          className="img-cover img-box-shadow"
        />
      ) : (
        <svg
          width={size || "81"}
          height={size || "80"}
          viewBox="0 0 81 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_308_15844)">
            <rect
              x="1"
              y="0.5"
              width="79"
              height="79"
              rx="39.5"
              fill="#F9F9F9"
            />
            <rect
              x="1"
              y="0.5"
              width="79"
              height="79"
              rx="39.5"
              stroke="#E4E5E7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.8332 29.9999C27.8332 22.968 33.4679 17.3333 40.4999 17.3333C47.5318 17.3333 53.1666 22.968 53.1666 29.9999C53.1666 37.0319 47.5318 42.6666 40.4999 42.6666C33.4679 42.6666 27.8332 37.0319 27.8332 29.9999Z"
              fill="#E4E5E7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.6595 47.1448C29.4919 45.3035 34.7237 43.9999 40.4999 43.9999C46.2761 43.9999 51.5078 45.3035 55.3402 47.1448C57.2527 48.0637 58.8725 49.1434 60.0388 50.3128C61.1752 51.4522 62.0554 52.8583 62.0554 54.4102C62.0554 56.0254 61.1107 57.3235 59.9107 58.286C58.7041 59.2538 57.0542 60.0441 55.1558 60.6731C51.3435 61.9364 46.1688 62.6666 40.4999 62.6666C34.8177 62.6666 29.6418 61.8733 25.833 60.5797C23.9365 59.9356 22.2959 59.1396 21.0974 58.1916C19.922 57.262 18.9443 55.994 18.9443 54.4102C18.9443 52.8583 19.8246 51.4522 20.961 50.3128C22.1273 49.1434 23.7471 48.0637 25.6595 47.1448Z"
              fill="#E4E5E7"
            />
          </g>
          <defs>
            <clipPath id="clip0_308_15844">
              <rect
                width="80"
                height="80"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
            <clipPath id="clip1_308_15844">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(59.5 58.5)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
}
export default memo(AvatarImage);
