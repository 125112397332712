const URL = Object.freeze({
  GET_LIST_SETTING: "/api/user/setting/kind",
  GET_VALUE_SETTING: "/api/user/setting/items",
  RESET_SETTING: "/api/user/setting",
  UPDATE_SETTING: "/api/user/setting",
  GET_ALL_SETTING: "/api/user/setting/select",
  GET_MEMBERSHIP: "/api/user/term/list/intro",
  GET_PRIVACY: "/api/user/term/list"
});
export default URL;
