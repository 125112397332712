import { Modal } from "@mui/material";
import { ArrowLeft } from "components/icons";
import { ButtonTab, Justify4Tabs } from "components/tabs";
import { SearchBar } from "components/textField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { ModalStyled } from "../styled";
import Analysis from "./components/Analysis";
import Events from "./components/Events";
import Interest from "./components/Interest";
import News from "./components/News";

let timeout;
const listTab = [
  { id: 0, labelKor: "종목", labelEng: "Interest" },
  { id: 1, labelKor: "이벤트", labelEng: "Events" },
  { id: 2, labelKor: "뉴스", labelEng: "News" },
  { id: 3, labelKor: "분석", labelEng: "Analysis" }
];

export default function ModalComprehensiveSearch() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [keywords, setKeywords] = useState("");
  const open = useSelector(
    (state) => state.common?.openModalComprehensiveSearch
  );
  const tabIndex = useSelector(
    (state) => state?.common.tabIndexIntegratedSearch
  );

  useEffect(() => {
    getSearch();
  }, [keywords]);

  const handleChange = (newValue) => {
    dispatch(commonActions.setTabIntegratedSearch(newValue));
  };

  const getSearch = () => {};

  const onClose = () =>
    dispatch(commonActions.onCloseModalComprehensiveSearch());

  const handleSearch = async (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => setKeywords(e), 300);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <div className="flex pd-8-20">
          <button type="button" className="btn-40px-start" onClick={onClose}>
            <ArrowLeft />
          </button>
          <SearchBar onInputChange={handleSearch} placeHolder="Search hint" />
        </div>
        <div className="flex-1 flex-col flow-y-auto hidden-scrollbar full-height">
          <div className="flex">
            <div className="border-1px-bottom-neutral-7 w-20px" />
            <Justify4Tabs style={{ padding: 0 }}>
              {listTab?.map((item, index) => (
                <ButtonTab
                  key={`customer_service_${index}`}
                  active={item?.id === +tabIndex}
                  border="bottom"
                  disableBorderTop
                  onClick={() => handleChange(item?.id)}>
                  <h3 style={{ color: "inherit" }}>
                    {i18n.language === "ko" ? item?.labelKor : item?.labelEng}
                  </h3>
                </ButtonTab>
              ))}
            </Justify4Tabs>
            <div className="border-1px-bottom-neutral-7 w-20px" />
          </div>
          {+tabIndex === 0 && <Interest keywords={keywords} />}
          {+tabIndex === 1 && <Events keywords={keywords} />}
          {+tabIndex === 2 && <News keywords={keywords} />}
          {+tabIndex === 3 && <Analysis keywords={keywords} />}
        </div>
      </ModalStyled>
    </Modal>
  );
}
