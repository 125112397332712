/* eslint-disable no-unused-vars */
import { InputAdornment, TextField } from "@mui/material";
import { SearchSvg } from "components/icons";
import SvgIcons from "components/svgIcons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchCountryStyled } from "./styled";

function SearchCountryBarPC({
  onInputChange,
  onSearch,
  placeHolder,
  hiddenDeleteButton
}) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    onInputChange(null);
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  const handleDeleteClick = () => {
    setInputValue("");
    onInputChange(null);
  };

  return (
    <SearchCountryStyled>
      <TextField
        placeholder={placeHolder || t("info.requiredSearch")}
        value={inputValue}
        onChange={handleInputChange}
        maxLength={50}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end" className="pl-20">
              <button type="button" className="btn-40px-start">
                <SvgIcons name="SearchSvg" color="#9497A0" />
              </button>
            </InputAdornment>
          ),
          endAdornment: inputValue && (
            <InputAdornment position="end" className="pr-20">
              <button
                type="button"
                className="btn-40px-end-pc"
                onClick={handleDeleteClick}>
                <SvgIcons name="close" />
              </button>
            </InputAdornment>
          )
        }}
      />
      {onSearch && (
        <button type="button" onClick={onSearch} className="flex">
          <SearchSvg />
        </button>
      )}
    </SearchCountryStyled>
  );
}

export default SearchCountryBarPC;
