import styled from "styled-components";
import { memo } from "react";
import { TextLinked } from "components/buttons";
import classNames from "classnames";

export const Section = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .swiper-slide {
    width: fit-content;
  }
  .wishListSlider {
    margin-left: inherit;
    margin-right: inherit;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .flex-grow-1 {
    flex-grow: 1;
  }
  .edit-block {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  }
`;

function HeaderSection({
  mainTitle,
  onClickTitle1,
  editTitle1,
  nodata,
  editTitle2,
  onClickTitle2,
  isPC
}) {
  return (
    <StyledHeader>
      <div className="flex-grow-1">
        {mainTitle && <h2 className={classNames({ pc: isPC })}>{mainTitle}</h2>}
      </div>
      {!nodata && (
        <div className="flex-grow-1 edit-block">
          {onClickTitle1 && (
            <TextLinked btnText={editTitle1} onClick={onClickTitle1} />
          )}
          {onClickTitle2 && (
            <TextLinked btnText={editTitle2} onClick={onClickTitle2} />
          )}
        </div>
      )}
    </StyledHeader>
  );
}

export default memo(HeaderSection);
