export default function ChartCandleOutlineSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08349 17.5L6.08349 2.5L7.25016 2.5L7.25016 17.5L6.08349 17.5Z"
        fill="#F20013"
      />
      <path
        d="M4.16666 5L9.16666 5L9.16667 15L4.16666 15L4.16666 5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.16667L5.33333 6.16667L5.33333 13.8333L8 13.8333L8 6.16667ZM4.16666 5L4.16666 15L9.16667 15L9.16666 5L4.16666 5Z"
        fill="#F20013"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7502 15.8334L12.7502 4.16669L13.9168 4.16669L13.9168 15.8334L12.7502 15.8334Z"
        fill="#1554F6"
      />
      <path
        d="M10.8333 6.25L15.8333 6.25L15.8333 13.75L10.8333 13.75L10.8333 6.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 7.41667L12 7.41667L12 12.5833L14.6667 12.5833L14.6667 7.41667ZM10.8333 6.25L10.8333 13.75L15.8333 13.75L15.8333 6.25L10.8333 6.25Z"
        fill="#1554F6"
      />
    </svg>
  );
}
