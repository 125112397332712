import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledBoxFooter = styled(Box)`
  padding: 72px 0px 144px 0px;
  .image {
    width: 220px;
    height: 125px;
  }
  .MuiGrid-root.MuiGrid-item {
    padding: 0;
  }
  .MuiGrid-root.MuiGrid-container {
    margin: 0;
    width: 100%;
  }
  .footer-links-container {
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    row-gap: 40px;
    justify-content: space-between;
  }

  .footer-links-container[language="en"]::after {
    content: "";
    flex: auto;
  }

  .footer-links-container[language="ko"]::after {
    content: none;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: fit-content;
  }

  .column-links {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
