const URL = Object.freeze({
  GET_LIST_KEY_WORD: "/api/user/key-word/list",
  REGISTER_KEY_WORD: "/api/user/key-word/create",
  DELETE_KEY_WORD: "/api/user/key-word/delete",
  // scrap
  GET_LIST_SCRAP: "/api/user/scrap/list/post-scrap",
  DELETE_SELECTED: "/api/user/scrap/delete-scrap",
  DELETE_ALL: "/api/user/scrap/delete-all",
  CREATE_SCRAP: (type) => `/api/user/scrap/${type}`,
  // change-pass
  CHANGE_PASS: "/api/auth/change-pass/change",
  // check-nickname
  CHECK_NICKNAME: "/api/auth/check/nick-name",
  // detail
  GET_USER_DETAIL: "/api/user/detail",
  // all
  GET_USER_ALL: "/api/user/all",
  // save-attachments
  SAVE_ATTACHMENT: "/api/storage/attachments",
  // change-profile
  CHANGE_PROFILE: "/api/user/change/profile",
  // point
  GET_LIST_POINT: "/api/user/point-board/list",
  // withDraw
  WITHDRAW: "/api/auth/with-drawl"
});
export default URL;
