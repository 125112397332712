/* eslint-disable no-unused-vars */
import classNames from "classnames";
import ScrapButton from "components/buttons/scrapBtn";
import SvgIcons from "components/svgIcons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { idRouter } from "routes/idRouter";
import { convertBigNumber, getTimePass } from "util/common";

function NewsItem({
  item,
  index,
  total,
  onClose = () => {},
  hiddenButton,
  hiddenHashtag,
  disableClick
}) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const {
    title,
    fileUrl,
    commentCnt,
    nickName,
    createTime,
    postId,
    hashtagList
  } = item;

  return (
    <div
      className={classNames("flex-start gap-16", {
        "pb-16  border-1px-bot-neutral-8": index !== +total - 1,
        "pt-16": index !== 0
      })}>
      <div className="flex-col gap-8 flex-1 text-align-left">
        <button
          type="button"
          className="text-align-left flex-col gap-8"
          onClick={
            disableClick
              ? () => {}
              : () => {
                  navigate(`/${idRouter.postDetail}?idPost=${postId}`, {
                    replace:
                      window.location.pathname === `/${idRouter.postDetail}`
                  });
                  onClose();
                }
          }>
          {!isEmpty(hashtagList) && isArray(hashtagList) && !hiddenHashtag && (
            <div className="flex-start-center col-gap-8 flex-wrap">
              {hashtagList.slice(0, 2).map((hastagItem, idx) => (
                <h5 key={`hastag_${index}_${idx}`} className="color-sell">
                  #{hastagItem?.hashtagText}
                </h5>
              ))}
            </div>
          )}
          <h4 className="text-ellipsis-2">{title}</h4>
        </button>
        <div className="flex-start-center gap-8">
          <div className="flex-start-baseline gap-8">
            <h6 className="fw-500">{nickName}</h6>
            {createTime && (
              <p className="caption">
                {getTimePass(createTime, i18n.language)}
              </p>
            )}
          </div>
          {!hiddenButton && (
            <>
              <button
                type="button"
                onClick={() => {
                  navigate(`/${idRouter.postDetail}?idPost=${postId}`, {
                    replace:
                      window.location.pathname === `/${idRouter.postDetail}`
                  });
                  onClose();
                }}
                className="flex-start-center gap-4">
                <SvgIcons name="commentIcon" className="h-24px" />
                <p className="caption pt-1">{convertBigNumber(commentCnt)}</p>
              </button>
              <ScrapButton item={item} />
            </>
          )}
        </div>
      </div>
      {fileUrl && (
        <img
          src={fileUrl}
          alt={item?.fileName || "error"}
          className="block-56px img-fill"
        />
      )}
    </div>
  );
}
export default memo(NewsItem);
