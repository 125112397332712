export default function SearchSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 3.70005C6.96813 3.70005 3.6998 6.96837 3.6998 11C3.6998 15.0317 6.96813 18.3 10.9998 18.3C15.0315 18.3 18.2998 15.0317 18.2998 11C18.2998 6.96837 15.0315 3.70005 10.9998 3.70005ZM2.2998 11C2.2998 6.19517 6.19493 2.30005 10.9998 2.30005C15.8047 2.30005 19.6998 6.19517 19.6998 11C19.6998 15.8049 15.8047 19.7001 10.9998 19.7001C6.19493 19.7001 2.2998 15.8049 2.2998 11Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1548 16.1551C16.4282 15.8817 16.8714 15.8817 17.1448 16.1551L21.4948 20.5051C21.7681 20.7784 21.7681 21.2217 21.4948 21.495C21.2214 21.7684 20.7782 21.7684 20.5048 21.495L16.1548 17.145C15.8815 16.8717 15.8815 16.4284 16.1548 16.1551Z"
        fill={fillColor}
      />
    </svg>
  );
}
