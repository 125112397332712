/* eslint-disable no-unused-vars */
export default function index(fillColor = "#11131A") {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74226 3.40901C2.16422 2.98705 2.73651 2.75 3.33325 2.75H9.16658C9.48875 2.75 9.74992 3.01117 9.74992 3.33333C9.74992 3.6555 9.48875 3.91667 9.16658 3.91667H3.33325C3.04593 3.91667 2.77038 4.0308 2.56722 4.23397C2.36406 4.43713 2.24992 4.71268 2.24992 5V16.6667C2.24992 16.954 2.36406 17.2295 2.56722 17.4327C2.77038 17.6359 3.04593 17.75 3.33325 17.75H14.9999C15.2872 17.75 15.5628 17.6359 15.766 17.4327C15.9691 17.2295 16.0833 16.954 16.0833 16.6667V10.8333C16.0833 10.5112 16.3444 10.25 16.6666 10.25C16.9888 10.25 17.2499 10.5112 17.2499 10.8333V16.6667C17.2499 17.2634 17.0129 17.8357 16.5909 18.2577C16.169 18.6796 15.5967 18.9167 14.9999 18.9167H3.33325C2.73651 18.9167 2.16422 18.6796 1.74226 18.2577C1.3203 17.8357 1.08325 17.2634 1.08325 16.6667V5C1.08325 4.40326 1.3203 3.83097 1.74226 3.40901Z"
        fill="#AFB1B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6666 2.14891C16.3525 2.14891 16.0512 2.27369 15.8291 2.49582L8.02666 10.2982L7.46831 12.5316L9.7017 11.9733L17.5041 4.17086C17.7262 3.94874 17.851 3.64747 17.851 3.33334C17.851 3.01921 17.7262 2.71794 17.5041 2.49582C17.282 2.27369 16.9807 2.14891 16.6666 2.14891ZM15.0041 1.67086C15.445 1.22994 16.043 0.982239 16.6666 0.982239C17.2901 0.982239 17.8882 1.22994 18.3291 1.67086C18.77 2.11178 19.0177 2.70979 19.0177 3.33334C19.0177 3.95689 18.77 4.5549 18.3291 4.99582L10.4124 12.9125C10.3377 12.9872 10.244 13.0403 10.1414 13.0659L6.80808 13.8993C6.60929 13.949 6.39901 13.8907 6.25412 13.7458C6.10923 13.6009 6.05098 13.3906 6.10068 13.1919L6.93401 9.85853C6.95966 9.75596 7.01269 9.66229 7.08745 9.58753L15.0041 1.67086Z"
        fill="#AFB1B8"
      />
    </svg>
  );
}
