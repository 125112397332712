import { toast } from "react-toastify";
import {
  AlertCircleSvg,
  AlertTriangleSvg,
  CheckboxCheckedCircleSvg
} from "components/icons";

export const successAlert = (textNotice) =>
  toast.success(textNotice, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: "bottom-alert-modal",
    icon: <CheckboxCheckedCircleSvg />,
    hideProgressBar: true,
    closeButton: false,
    autoClose: 1000,
    toastId: "success"
  });

export const warningAlert = (textNotice) => {
  toast.warning(textNotice, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: "bottom-alert-modal",
    icon: <AlertTriangleSvg fillColor="var(--secondary-color-1, #F4CB5D)" />,
    hideProgressBar: true,
    closeButton: false,
    autoClose: 1000,
    toastId: "emptyDelete"
  });
};

export const errorAPI = (textNotice) => {
  toast.error(textNotice, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: "bottom-alert-modal",
    icon: <AlertCircleSvg fillColor="red" />,
    hideProgressBar: true,
    closeButton: false,
    autoClose: 1000,
    toastId: "error"
  });
};
