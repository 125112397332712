/* eslint-disable no-unused-vars */
import { SearchSvg, XCircle } from "components/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteButton, Input, InputBox, SearchBarWrap } from "./styled";

function SearchBar({
  onInputChange,
  onSearch,
  placeHolder,
  hiddenDeleteButton
}) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue("");
    onInputChange(null);
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  const handleDeleteClick = () => {
    setInputValue("");
    onInputChange(null);
  };

  return (
    <SearchBarWrap>
      <InputBox className="_input_box">
        <Input
          type="text"
          placeholder={placeHolder || t("info.requiredSearch")}
          value={inputValue}
          onChange={handleInputChange}
          maxLength={50}
        />
      </InputBox>
      {!hiddenDeleteButton && (
        <DeleteButton
          onClick={handleDeleteClick}
          style={{ display: inputValue ? "flex" : "none" }}>
          <XCircle />
        </DeleteButton>
      )}

      {onSearch && (
        <button type="button" onClick={onSearch} className="flex">
          <SearchSvg />
        </button>
      )}
    </SearchBarWrap>
  );
}

export default SearchBar;
