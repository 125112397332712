/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import classNames from "classnames";
import { errorAPI, successAlert } from "components/alert";
import FormAlert from "components/alert/FormAlert";
import {
  CheckboxCheckedCircleSvg,
  CheckboxUncheckedCircleSvg,
  Edit3Svg,
  XCircle,
  XSvg
} from "components/icons";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { authEmail, checkMail, getCodeEmail } from "services/AuthenService";
import { ModalStyled } from "views/HeatMap/styled";
import { ButtonStyled } from "views/MyPage/component/MyPageChangeProfile/styled";
import * as Yup from "yup";
import { OutlinedInputBorder24 } from "../components/OutlinedInputBorder24";
import ModalSetUp from "./ModalSetUp";
import {
  mailExists,
  outOfTime,
  regexFailed,
  requiredCode,
  requiredEmail,
  wrongCode
} from "./auth-notice";

let interval;

export default function ModalSignUpFormik({ signUpBy, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [authenCounter, setAuthenCounter] = useState(180); // the time
  const [setUpFor, setSetUpFor] = useState(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(`Error: ${t("info.emailRegexFail")}`)
      .required(`Required: ${t("info.emailRequired")}`),
    otp: Yup.string().when("emailVerified", {
      is: true,
      then: Yup.string().required(`Required: ${t("info.otpRequired")}`)
    })
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      terms: {
        over18: "Y",
        privacyPolity: "Y",
        eventNoti: "N"
      },
      emailVerified: false
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (!values?.emailVerified) {
          formik.setErrors({ email: null });
          const res = await checkMail({ mail: values?.email });
          if (res?.data?.code === "400") {
            formik.setErrors({
              email: `Error: ${t("info.emailExists")}`
            });
            setIsLoading(false);
          } else if (res?.data?.code === "200") {
            await handleSendCode(values?.email);
            // formik.setValues((prev) => ({ ...prev, emailVerified: true }));
          } else {
            errorAPI(res?.data?.message);
          }
        } else {
          const res = await authEmail({
            email: values?.email,
            code: values.otp
          });
          if (res?.data?.code === "200") {
            setSetUpFor("password");
          } else if (res?.data?.code === "400") {
            formik.setErrors({ otp: `Error: ${t("info.otpWrong")}` });
          } else {
            errorAPI(res?.data?.message);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      formik.setSubmitting(false);
    }
  });

  useEffect(() => {
    if (
      signUpBy !== null && // modal opened
      signUpBy?.loginMethod !== "Email" && // login method is not email
      signUpBy?.email !== "" // get email from other loginMethod
    ) {
      setSetUpFor("nickName");
    }
  }, [signUpBy]);

  // Effect to handle timer expiration
  useEffect(() => {
    if (authenCounter === 0) {
      // formik.setFieldTouched("otp", true);
      formik.setErrors({ otp: `Error: ${t("info.otpOutOfTime")}` });
      return () => {
        clearInterval(interval);
      };
    }
  }, [authenCounter]);

  useEffect(() => {
    if (formik.values.emailVerified && authenCounter !== 0) {
      interval = setInterval(() => {
        setAuthenCounter((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [authenCounter, formik.values.emailVerified]);
  // Effect to clear the timer when the component unmounts

  const setIsLoading = (value) => dispatch(commonActions.setLoading(value));

  const handleSendCode = async (email) => {
    try {
      const res = await getCodeEmail({ email });
      if (res?.data?.code === "200") {
        formik.setValues((prev) => ({ ...prev, emailVerified: true }));
        setAuthenCounter(180);
        successAlert(t("notice.otpSent"));
      } else {
        errorAPI(res?.data?.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Reset timer function
  const resetTimer = () => {
    clearInterval(interval);
  };

  return (
    <Modal
      open={signUpBy !== null}
      onClose={onClose}
      aria-labelledby="modal-signup-title"
      aria-describedby="modal-signup-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          logo={false}
          headerTitle={
            <div className="flex gap-8">
              <h1>{t("button.signUp")}</h1>
              <div className="bg-neutral-8 pd-3-8 br-14">
                <h6 className="font-2 fw-400 color-neutral-2">
                  {signUpBy?.loginMethod === "Email" ? "e-mail" : "SNS"}
                </h6>
              </div>
            </div>
          }
          onBack={onClose}
          customAction={
            <button
              type="button"
              className="btn-40px-end flex-centered"
              onClick={() => {
                onClose();
                dispatch(authActions.onCloseModalRegister());
                navigate("/");
              }}>
              <XSvg fillColor="#11131A" />
            </button>
          }
          borderBottom
        />
        <div className="pb-128 flex-col gap-32">
          <form
            onSubmit={formik.handleSubmit}
            className="flex-col gap-24 pt-32 pl-20 pr-20">
            <div className="flex-col gap-12">
              <OutlinedInputBorder24
                type="text"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // onFocus={() => setCurrentFocus("email")}
                // onBlur={() => setCurrentFocus("")}
                value={formik.values.email}
                placeholder={t("info.email")}
                endAdornment={
                  formik.values.emailVerified ? (
                    <button
                      type="button"
                      className="btn-40px-end"
                      onClick={() => {
                        formik.setValues((prev) => ({
                          ...prev,
                          email: "",
                          otp: "",
                          emailVerified: false
                        }));
                        formik.setErrors({
                          email: null,
                          otp: null
                        });
                        resetTimer();
                      }}>
                      <Edit3Svg fillColor="#9497A0" />
                    </button>
                  ) : formik.values.email ? (
                    <button
                      type="button"
                      className="btn-40px-end"
                      // onFocus={() => setCurrentFocus("email")}
                      onClick={() => {
                        formik.setValues((prev) => ({
                          ...prev,
                          email: "",
                          otp: "",
                          emailVerified: false
                        }));
                        formik.setErrors({
                          email: null,
                          otp: null
                        });
                      }}>
                      <XCircle />
                    </button>
                  ) : null
                }
              />
              {formik.touched.email && formik.errors.email && (
                <FormAlert tetxtNotice={formik.errors.email} />
              )}
            </div>
            {formik.values.emailVerified && (
              <>
                <div className="flex-col gap-12">
                  <div className="flex-between">
                    <label htmlFor="otp">
                      <h5 className="fw-500 color-info">
                        {t("info.authenCode")}
                      </h5>
                    </label>
                    <button
                      type="button"
                      onClick={() => {
                        if (authenCounter === 0)
                          handleSendCode(formik.values.email);
                        else {
                          errorAPI(
                            t("notice.optSent", {
                              seconds: moment
                                .utc(authenCounter * 1000)
                                .format("mm:ss")
                            })
                          );
                        }
                      }}>
                      <h5
                        className={classNames("fw-500 text-underline", {
                          "color-neutral": authenCounter > 0,
                          "color-info": authenCounter === 0
                        })}>
                        {t("button.reSend")}
                      </h5>
                    </button>
                  </div>
                  <OutlinedInputBorder24
                    type="text"
                    id="otp"
                    name="otp"
                    onChange={formik.handleChange}
                    value={formik.values.otp}
                    fullWidth
                    // onFocus={() => setCurrentFocus("authenCode")}
                    // onBlur={() => setCurrentFocus("")}
                    placeholder={t("info.enterAuthenCode")}
                    endAdornment={
                      <div className="flex-start-center gap-4">
                        <SvgIcons name="clock" />
                        <h6 className="fw-400 font-2">
                          {moment.utc(authenCounter * 1000).format("mm:ss")}
                        </h6>
                      </div>
                    }
                  />
                  {formik.touched.otp && formik.errors.otp && (
                    <FormAlert tetxtNotice={formik.errors.otp} />
                  )}
                </div>
                <div className="flex-col gap-8">
                  <button
                    type="button"
                    onClick={() =>
                      formik.setValues((prev) => ({
                        ...prev,
                        terms: {
                          ...prev.terms,
                          over18: prev.terms.over18 === "Y" ? "N" : "Y"
                        }
                      }))
                    }
                    className="flex-start-center fit-width">
                    <div className="block-32px pd-4-8-4-0">
                      {formik.values.terms?.over18 === "Y" ? (
                        <CheckboxCheckedCircleSvg />
                      ) : (
                        <CheckboxUncheckedCircleSvg />
                      )}
                    </div>
                    <Trans i18nKey="info.requiredOver18" parent="p">
                      <span className="caption fw-500 color-neutral text-align-left text-underline" />
                      <span className="caption fw-500 color-sell text-align-left pl-4 decoration-none " />
                    </Trans>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      formik.setValues((prev) => ({
                        ...prev,
                        terms: {
                          ...prev.terms,
                          privacyPolity:
                            prev.terms.privacyPolity === "Y" ? "N" : "Y"
                        }
                      }))
                    }
                    className="flex-start-center fit-width">
                    <div className="block-32px pd-4-8-4-0">
                      {formik.values.terms?.privacyPolity === "Y" ? (
                        <CheckboxCheckedCircleSvg />
                      ) : (
                        <CheckboxUncheckedCircleSvg />
                      )}
                    </div>
                    <div>
                      <Trans
                        i18nKey="info.policyAndTerm"
                        parent="p"
                        className="caption fw-500 color-neutral text-align-left">
                        <a
                          href="#"
                          onClick={() =>
                            dispatch(commonActions.onOpenModalPrivacy())
                          }
                          className="text-underline"
                        />
                        <a
                          href="#"
                          onClick={() =>
                            dispatch(commonActions.onOpenModalMemberShip())
                          }
                          className="text-underline"
                        />
                        <span className="caption fw-500 color-sell text-align-left pl-4" />
                      </Trans>
                    </div>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      formik.setValues((prev) => ({
                        ...prev,
                        terms: {
                          ...prev.terms,
                          eventNoti: prev.terms.eventNoti === "Y" ? "N" : "Y"
                        }
                      }))
                    }
                    className="flex-start-center fit-width">
                    <div className="block-32px pd-4-8-4-0">
                      {formik.values.terms?.eventNoti === "Y" ? (
                        <CheckboxCheckedCircleSvg />
                      ) : (
                        <CheckboxUncheckedCircleSvg />
                      )}
                    </div>
                    <p className="caption fw-500 color-neutral text-align-left">
                      {t("info.noticeEvents")}
                    </p>
                  </button>
                </div>
              </>
            )}
            <ButtonStyled
              type="submit"
              disabled={
                !formik.values.emailVerified
                  ? !formik.isValid ||
                    formik.isSubmitting ||
                    !formik.values.email
                  : !formik.isValid ||
                    formik.isSubmitting ||
                    !formik.values.email ||
                    formik.values.terms.over18 !== "Y" ||
                    formik.values.terms.privacyPolity !== "Y"
              }>
              {formik.values.emailVerified ? (
                <h5>{t("button.nextStep")}</h5>
              ) : (
                <h5>{t("button.requestAuthenEmail")}</h5>
              )}
            </ButtonStyled>
          </form>
        </div>
        <ModalSetUp
          signUpBy={signUpBy}
          setUpFor={setUpFor}
          setSetUpFor={setSetUpFor}
          closeSignUp={onClose}
          email={formik.values.email}
          terms={formik.values.terms}
        />
      </ModalStyled>
    </Modal>
  );
}
