import styled from "styled-components";

export const MyPageWithDrawStyled = styled.div`
  ul {
    list-style-type: none;
  }

  ul li {
    position: relative;
    padding-left: 12px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #45495a;
  }

  ul li::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
  }
`;
