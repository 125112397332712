export default function TrendingUpSvg({ fillColor = "#AFB1B8" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5791 4.58754C19.807 4.81535 19.807 5.18469 19.5791 5.4125L11.6625 13.3292C11.4347 13.557 11.0653 13.557 10.8375 13.3292L7.08333 9.57498L1.24581 15.4125C1.01801 15.6403 0.64866 15.6403 0.420854 15.4125C0.193049 15.1847 0.193049 14.8153 0.420854 14.5875L6.67085 8.33754C6.89866 8.10974 7.26801 8.10974 7.49581 8.33754L11.25 12.0917L18.7542 4.58754C18.982 4.35974 19.3513 4.35974 19.5791 4.58754Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5834 5.00002C13.5834 4.67785 13.8445 4.41669 14.1667 4.41669H19.1667C19.4889 4.41669 19.75 4.67785 19.75 5.00002V10C19.75 10.3222 19.4889 10.5834 19.1667 10.5834C18.8445 10.5834 18.5834 10.3222 18.5834 10V5.58335H14.1667C13.8445 5.58335 13.5834 5.32219 13.5834 5.00002Z"
        fill={fillColor}
      />
    </svg>
  );
}
