export default function HeartFillSvg({ fillColor = "#F23645" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 21.83C11.8101 21.83 11.6401 21.76 11.5001 21.62L2.6701 12.79C1.5001 11.62 0.850098 10.06 0.850098 8.39995C0.850098 6.73995 1.5001 5.18995 2.6701 4.01995C3.8401 2.84995 5.3901 2.19995 7.0501 2.19995C8.7101 2.19995 10.2601 2.84995 11.4401 4.01995L12.0001 4.57995L12.5601 4.01995C13.1401 3.43995 13.8101 2.98995 14.5701 2.67995C16.0801 2.04995 17.8001 2.04995 19.3201 2.67995C20.0801 2.98995 20.7501 3.44995 21.3301 4.02995C21.9101 4.60995 22.3601 5.27995 22.6701 6.03995C22.9801 6.79995 23.1401 7.59995 23.1401 8.40995C23.1401 9.21995 22.9801 10.03 22.6701 10.78C22.3601 11.54 21.9001 12.21 21.3301 12.79L12.4901 21.63C12.3601 21.76 12.1801 21.84 11.9901 21.84L12.0001 21.83Z"
        fill={fillColor}
      />
    </svg>
  );
}
