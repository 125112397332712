/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-extra-boolean-cast */
import classNames from "classnames";
import { AlarmPlusSvg, SpeakerSvg } from "components/icons";
import { localeAndFixed } from "util/common";

export default function EconomicIndicatorsItem({ item, onClick }) {
  const haveValue = !!(item?.forecast || item?.actual || item?.previous);
  return (
    <div className="economic-schedule-item">
      <button
        type="button"
        onClick={onClick}
        className="flex-start-center gap-12"
        style={{
          width: "calc(100% - 40px)"
        }}>
        {item?.fileName ? (
          <img
            src={item?.fileName}
            alt=""
            className="economic-schedule-item-flag"
          />
        ) : (
          <SpeakerSvg />
        )}
        <div
          className="flex-col text-align-left gap-4"
          style={{
            width: "calc(100% - 36px)"
          }}>
          <h4>{item?.title}</h4>
          {haveValue && (
            <div className="flex gap-12">
              <div className="flex gap-4">
                <h6 className="fw-500 color-info">실제</h6>
                <h6
                  className={classNames("fw-500", {
                    sell: item?.actual < 0,
                    buy: item?.actual > 0,
                    natural: item?.actual === 0
                  })}>
                  {item?.actual
                    ? `${localeAndFixed(item?.actual, 2)}${item?.unit}`
                    : "-"}
                </h6>
              </div>
              <div className="flex gap-4">
                <h6 className="fw-500 color-info">예상</h6>
                <h6
                  className={classNames("fw-500", {
                    sell: item?.forecast < 0,
                    buy: item?.forecast > 0,
                    natural: item?.forecast === 0
                  })}>
                  {item?.forecast
                    ? `${localeAndFixed(item?.forecast, 2)}${item?.unit}`
                    : "-"}
                </h6>
              </div>
              <div className="flex gap-4">
                <h6 className="fw-500 color-info">이전</h6>
                <h6
                  className={classNames("fw-500", {
                    sell: item?.previous < 0,
                    buy: item?.previous > 0,
                    natural: item?.previous === 0
                  })}>
                  {item?.previous
                    ? `${localeAndFixed(item?.previous, 2)}${item?.unit}`
                    : "-"}
                </h6>
              </div>
            </div>
          )}
        </div>
      </button>
      <button type="button" className="btn-40px-end">
        <AlarmPlusSvg />
      </button>
    </div>
  );
}
