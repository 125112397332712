import { Box, Menu, SwipeableDrawer } from "@mui/material";
import styled from "styled-components";

export const ButtonPortfolio = styled.button`
  display: inline-flex;
  padding: 6px 16px;
  border-radius: 40px;
  &.plus_button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 34px;
    height: 34px;
    background-color: #f4f4f4;
    flex-shrink: 0;
    padding: 7.438px;
  }
`;
export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  padding: 8px 24px;
  form {
    display: flex;
    width: 100%;
  }
  .__delete_btn {
    width: 40px;
    height: 40px;
    padding: 12px 0 8px 16px;
    svg {
      width: 24px;
      height: 24px;
      padding: 5.3px;
      display: block;
    }
  }
  input {
    padding: 0 16px;
    height: 48px;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0;
    font-family: var(--font-1, "KoPubWorldDotum");
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
  }
`;

export const Button40 = styled.button`
  width: 40px;
  height: 40px;
  padding: 8px 0 8px 16px;
`;
export const ButtonRefresh = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e4e5e7;
  padding: 12px;
  position: absolute;
  left: 24px;
`;
export const ButtonSubmit = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding: 8.5px 24px;
  border-radius: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${(props) =>
    props.submitType === "save"
      ? "var(--interaction-success-color)"
      : "var(--neutral--color--10, #ffffff)"};
  height: 40px;
  border: ${(props) =>
    props.submitType === "save" ? "none" : "1px solid var(--neutral-color-7)"};
  color: ${(props) =>
    props.submitType === "save"
      ? "var(--neutral-color-10, #ffffff)"
      : "var(--neutral-color-1)"};
`;
export const FormBtnSubmit = styled.div`
  /* isIOS */
  width: 100%;
  display: flex;
  /* padding: 0 20px; */
  /* justify-content: flex-end; */
  justify-content: ${(props) => (props.pc ? "space-between" : "flex-end")};
  align-items: center;
  height: ${(props) => (props.isTabletOrMobile ? "80px" : "64px")};
  /* padding-right: 24px; */
  padding: ${(props) => (props.pc ? "12px 24px" : "0 20px")};
  column-gap: 8px;
  border-top: 1px solid var(--E4E5E7, #e4e5e7);
  /* padding-bottom: ${(props) => (props.isTabletOrMobile ? "16px" : 0)}; */
`;

export const Puller = styled(Box)(() => ({
  width: 32,
  height: 4,
  backgroundColor: "var(--neutral-color-7)",
  borderRadius: 3,
  position: "inherit",
  marginBottom: "16px",
  top: 8,
  left: "calc(50% - 15px)"
}));

export const Container = styled.div`
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
`;

export const CustomCheckbox = styled.label`
  display: flex;
  align-items: center;
  input {
    display: none;
  }
`;

export const CheckMark = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 4px 8px 4px 0;
  cursor: pointer;
  border-radius: 50%;
`;

export const Drawer = styled(SwipeableDrawer)(() => ({
  "& .MuiTypography-root.MuiTypography-body1, .MuiTypography-root.MuiTypography-body2":
    {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
  "& .MuiTypography-body1": {
    columnGap: "2px"
  },
  "& .MuiTypography-body2": {
    columnGap: "8px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px !important",
    borderColor: "var(--neutral-color-7, #e4e5e7)",
    borderRadius: 0
  },
  "& .MuiDivider-root": {
    borderColor: "#E4E5E7"
  },
  zIndex: "1300 !important"
}));

export const ListItemText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  .portfolio_title {
    display: flex;
    .chevron {
      padding-top: 3px;
    }
  }
  .interest_status {
    display: flex;
    column-gap: 8px;
  }
`;

// modalslider
export const ModalContent = styled.div`
  display: flex;
  padding: ${(props) => (props.PC ? "32px 24px" : "24px 24px")};
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .MuiFormControl-root.MuiTextField-root {
    .MuiInputBase-root.MuiOutlinedInput-root {
      input {
        text-align: right;
      }
    }
  }

  .MuiInputBase-input-MuiOutlinedInput-input {
    text-align: right;
  }

  input {
    text-align: right;
  }

  .container {
    width: 100%;
    height: 108px;
    .wrap {
      display: flex;
      justify-content: space-between;

      .button {
        display: flex;
        gap: 10px;
        button {
          border-radius: 20px;
          border: 1px solid #e4e5e7;
          background: #fff;
          width: 32px;
          height: 32px;
          display: flex;
          padding: 7px;
          align-items: flex-start;
        }
      }
      .slider {
        width: 215px;
        position: relative;
        left: 12px;
      }
    }
    .space_top {
      display: flex;
      gap: 44px;
      justify-content: space-between;
      position: relative;
      top: 30px;
      .MuiInputBase-input.MuiInput-input {
        text-align: center;
      }
      .MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
        border-bottom: 1px solid var(--E4E5E7, #e4e5e7);
      }
    }

    .MuiSlider-colorPrimary {
      width: 100%;
      span {
        color: #1554f6;
      }
      .MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
        background-color: #1554f6;
        border-radius: 14px;
      }
      .MuiSlider-valueLabelLabel {
        color: #fff;
      }
      .MuiSlider-rail {
        opacity: 0.15;
      }
    }
  }
`;
export const DatePickerStyled = styled.div`
  padding: 0 16px;
  .MuiDateCalendar-root {
    width: 100%;
    height: auto;
    max-height: unset;
    align-items: center;
    div:nth-child(2) {
      width: 100%;
    }
  }
  .MuiDayCalendar-weekDayLabel,
  .MuiPickersDay-dayWithMargin {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    padding-top: 4px;
    margin: 0;
  }
  .MuiPickersDay-dayWithMargin {
    color: var(--neutral-color-3, "#45495a");
    font-weight: 700;
  }
  .MuiPickersCalendarHeader-switchViewButton {
    display: none;
  }
  .Mui-selected {
    color: #fff;
  }
  .MuiDayCalendar-weekDayLabel {
    color: var(--neutral-color-4, "#9497A0");
  }
  .MuiDayCalendar-header,
  .MuiDayCalendar-weekContainer {
    gap: 10px;
    margin: 0px;
    justify-content: space-between;
  }
  .MuiPickersCalendarHeader-root {
    width: 100%;
    padding: 8px 0px;
    position: relative;
    min-height: unset;
    max-height: unset;
    margin: 0px;
    height: 56px;
  }
  .MuiDayCalendar-monthContainer {
    flex-direction: column;
    display: flex;
    gap: 16px;
    padding-bottom: 24px;
  }
  .MuiPickersCalendarHeader-labelContainer {
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: var(--neutral-color-1, "#11131A");
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .MuiIconButton-edgeEnd {
    left: 0;
    position: absolute;
    top: 8px;
  }
  .MuiPickersArrowSwitcher-spacer {
    width: 0;
  }
  .MuiIconButton-edgeStart {
    position: absolute;
    right: 0;
    top: 8px;
  }
  .MuiDayCalendar-header {
    border-bottom: 1px solid var(--F4F4F4, #f4f4f4);
    margin: 8px 0px;
  }
  .Mui-disabled {
    font-weight: 400;
  }
`;

export const DatePickerStyled2 = styled.div`
  padding: 0;
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    min-width: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  .react-calendar__navigation__label {
    flex-grow: unset !important;
    margin: 0px 32px;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
  }
  .react-calendar__navigation {
    height: unset;
    margin-bottom: 0;
    padding: 8px 0;
    justify-content: center;
  }
  .react-calendar {
    border: unset;
    width: 100%;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: unset !important;
    }
  }
  .react-calendar__navigation__label__labelText {
    color: var(--neutral-color-1, "#11131A");
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .react-calendar__month-view__weekdays {
    margin-bottom: 1px;
    border-bottom: 1px solid var(--F4F4F4, #f4f4f4);
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0px;
    flex: unset !important;
    padding: 9px 3.286px 9px 4px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    abbr {
      color: var(--neutral-color-4, "#9497A0");
      font-weight: 500;
      line-height: 22px;
      width: 36px;
      font-size: 14px;
    }
  }
  .react-calendar__tile {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: var(--font-1, "KoPubWorldDotum");
    abbr {
      height: 40px;
      padding: 9px 3.286px 9px 4px;
    }
    .date-format {
      width: 36px;
      color: var(--neutral-color-3, "#45495a");
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      margin-top: 2px;
    }
  }
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover {
    background-color: transparent !important;
    background: transparent !important;
    abbr {
      background-color: var(--primary-color-1, "#1554F6");
      border-radius: 50%;
      width: 40px;
      padding: unset;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .date-format {
      color: #fff;
    }
  }
  .react-calendar__tile--now,
  .react-calendar__navigation button {
    background-color: transparent !important;
  }
  .react-calendar__tile {
    background-color: transparent !important;
    abbr,
    .disable-date {
      font-weight: 400;
    }
  }
`;

export const FooterModalStyled = styled.div`
  display: flex;
  padding: 12px 24px;
  border-top: 1px solid var(--neutral-color-7, #e4e5e7);
  align-items: center;
  gap: 8px;
  button {
    align-items: center;
    border-radius: 20px;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: var(--neutral-color-1, #11131a);
    text-align: center;
  }
  .button-refresh {
    width: 40px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    border: 1px solid var(--neutral-color-7, #e4e5e7);
  }

  .button-cancel {
    padding: 8.5px 24px;
    border: 1px solid var(--E4E5E7, #e4e5e7);
  }

  .button-save {
    background: var(--primary-color-1, #1554f6);
    padding: 8.5px 24px;
    color: #fff;
  }

  .space {
    flex: 1;
  }
`;

export const FooterCalendarStyled = styled.div`
  display: flex;
  padding-top: 16px;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 16px;
    background: var(--FFFFFF, #fff);
    display: flex;
    padding: 4.5px 16px;
    align-items: center;
    color: var(--11131A, #11131a);
    text-align: center;
    font-weight: 700;
    line-height: 22px;
  }
`;
export const SubCategory48px = styled.div`
  height: 48px;
  padding: 9px 20px;
  padding: ${(props) => (props.pc ? "9px 24px" : "9px 20px")};
  /* color: #1554f6; */
  color: ${(props) => (props.pc ? "#9497A0" : "#1554f6")};
  h5 {
    color: inherit;
  }
`;
export const TitleToolTip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  .title-tooltip {
    display: flex;
    justify-content: space-between;
  }
`;
export const MenuDropDownStyled = styled(Menu)`
  .MuiPopover-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: var(--neutral-color-2, #373d3f);
  }
  ul,
  li {
    background-color: transparent;
    padding: 0;
  }
  h4 {
    color: var(--neutral-color-10, #ffffff);
  }
`;
export const Filter = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1600;
`;

export const ModalSelectUserStyled = styled.div`
  .function-item {
    border-top: 1px solid black;

    .MuiListItemButton-root {
      padding: 10px 24px;
      margin: 50px;
    }
  }

  .function-item:first-child {
    border-top: none;
  }

  /* overlay style of ListItemButton */
  /* ".MuiListItemButton-root {
    border: 1px solid black;
  } */

  .MuiListItemButton-root:hover {
    background-color: #f4f4f4;
  }
`;

export const ModalSearchFilterStyled = styled.div`
  .heading {
    padding: 8px 24px;
  }

  .pd-8-0-8-16 {
    padding: 8px 0 8px 16px;
  }

  .MuiFormControl-root.MuiTextField-root {
    .MuiInputBase-root.MuiOutlinedInput-root {
      input {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  input[type="text"] {
    color: #e4e5e7;
    border-radius: 24px;
    border: 1px solid #e4e5e7;
  }

  .time {
    font-size: 12px;
  }

  .period {
    display: flex;
    align-items: center;
    gap: 8px;
    ._period {
      border-radius: 2px;
      border-bottom: 1px solid var(--F4F4F4, #f4f4f4);
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 11px 0px;
      align-items: center;

      p {
        font-size: 12px;
      }

      button {
        display: inline-flex;
      }
    }
  }
`;

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: var(--neutral-color-10, #ffffff);
  .circle-number {
    display: flex;
    border: 1px solid #45495a;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 3px;
    .txt {
      margin: 0;
      padding: 0;
      font-size: 10px;
    }
  }
  .square {
    display: flex;
    align-items: center;
    gap: 5px;

    width: 10px;
    height: 9px;
    background-color: black;
    line-height: 22px;
  }
`;
