/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";

class SnsBoardService {
  getListPostByBoard(url, body) {
    return axiosClient.post(url, body);
  }

  setPostLike(url) {
    return axiosClient.get(url);
  }

  getListView(url, body) {
    return axiosClient.post(url, body);
  }
}

export default new SnsBoardService();
