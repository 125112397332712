export default function UserSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30005 7.50005C6.30005 4.33567 8.83567 1.80005 12 1.80005C15.1644 1.80005 17.7 4.33567 17.7 7.50005C17.7 10.6644 15.1644 13.2 12 13.2C8.83567 13.2 6.30005 10.6644 6.30005 7.50005ZM12 3.20005C9.60887 3.20005 7.70005 5.10887 7.70005 7.50005C7.70005 9.89123 9.60887 11.8 12 11.8C14.3912 11.8 16.3 9.89123 16.3 7.50005C16.3 5.10887 14.3912 3.20005 12 3.20005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1988 17.6295C3.80395 18.0254 3.70005 18.3215 3.70005 18.4847C3.70005 18.5796 3.76008 18.7898 4.13743 19.0883C4.50436 19.3785 5.07856 19.6732 5.85017 19.9353C7.38618 20.457 9.55701 20.8 12 20.8C14.4491 20.8 16.6204 20.4825 18.1549 19.974C18.9256 19.7187 19.4956 19.4281 19.8589 19.1367C20.2252 18.8429 20.3 18.6193 20.3 18.4847C20.3 18.3215 20.1962 18.0254 19.8013 17.6295C19.4199 17.247 18.8363 16.8444 18.0719 16.4772C16.5465 15.7442 14.4008 15.2 12 15.2C9.59933 15.2 7.45362 15.7442 5.9282 16.4772C5.1638 16.8444 4.58022 17.247 4.1988 17.6295ZM5.32189 15.2153C7.04647 14.3866 9.40077 13.8 12 13.8C14.5993 13.8 16.9536 14.3866 18.6782 15.2153C19.5388 15.6287 20.2677 16.1146 20.7926 16.6408C21.3039 17.1536 21.7001 17.7863 21.7001 18.4847C21.7001 19.2115 21.2749 19.7956 20.7349 20.2288C20.1919 20.6643 19.4495 21.0199 18.5952 21.303C16.8797 21.8715 14.551 22.2001 12 22.2001C9.44308 22.2001 7.11392 21.8431 5.39993 21.2609C4.54654 20.9711 3.80824 20.6129 3.26891 20.1863C2.74002 19.768 2.30005 19.1974 2.30005 18.4847C2.30005 17.7863 2.69615 17.1536 3.20754 16.6408C3.73238 16.1146 4.4613 15.6287 5.32189 15.2153Z"
        fill={fillColor}
      />
    </svg>
  );
}
