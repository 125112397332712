/* eslint-disable no-unused-vars */
import { Backdrop, CircularProgress } from "@mui/material";
import ChevronUpSvg from "components/icons/ChevronUpSvg";
import {
  AddOver10Portfolio,
  ModalActionComment,
  ModalRequireLogin,
  ModalSelectActionUser
} from "components/modal";
import FooterPC from "components/PC/footerPC";
import HeaderPC from "components/PC/headerPC";
import PCCenterModal from "components/PC/modal/ModalPortfolioPC";
import SearchThemeModal from "components/PC/modal/SearchThemeModal";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import "./_mainLayout.scss";

export const BodyContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 0 0 0;
  margin: auto;
  transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out;
  width: 1256px;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  width: ${({ isDrawerOpen }) =>
    isDrawerOpen ? `calc(100% - ${48 + 312}px)` : `calc(100% - ${48}px)`};
`;

function ButtonScrollToTop() {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;
      setShowScrollTop(scrollY > 120);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <button
      type="button"
      onClick={scrollToTop}
      className="btn-56px-center br-28 bg-neutral-10"
      style={{
        position: "fixed",
        bottom: 30,
        right: "max(30px, calc(50vw - 628px - 30px - 56px))",
        visibility: showScrollTop ? "visible" : "hidden",
        boxShadow: "0px 2px 4px 0px #0000001F, 0px 4px 8px 0px #00000014"
      }}>
      <ChevronUpSvg fillColor="#1554F6" />
    </button>
  );
}

export default function MainLayout({ children, socket }) {
  const openDrawer = useSelector((state) => state.common.openDrawer);
  const loading = useSelector((state) => state.common.loadingPC);

  return (
    <Suspense fallback={<> </>}>
      <Backdrop
        sx={{
          "& .MuiCircularProgress-circle": {
            stroke: "white"
          },
          color: "#fff",
          zIndex: 2000
        }}
        open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      {/* <StyledBox isDrawerOpen={openDrawer !== null}> */}
      <HeaderPC />
      <BodyContainerStyled>{children}</BodyContainerStyled>
      <ButtonScrollToTop />
      {/* <SideMenu /> */}
      <SearchThemeModal />
      <ModalRequireLogin />
      <PCCenterModal type="portfolio" />
      <ModalSelectActionUser />
      <ModalActionComment />
      <AddOver10Portfolio pc />
      <FooterPC />
      <ToastContainer limit="3" />
      {/* </StyledBox> */}
    </Suspense>
  );
}
