import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getMyListPortfolio = (params) =>
  newAxiosInstance.get(URL.PORTFOLIO + qs.stringify(params, true));

export const deletePortfolioItems = (id) =>
  newAxiosInstance.delete(`${URL.PORTFOLIO}/${id}`);

export const updatePortfolioItems = (params) =>
  newAxiosInstance.put(`${URL.PORTFOLIO}/multiple`, params);

export const updatePortfolio = (params) =>
  newAxiosInstance.put(URL.PORTFOLIO, params);

export const addNewPortfolio = (body) =>
  newAxiosInstance.post(URL.PORTFOLIO, body);
