/* eslint-disable object-shorthand */
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  List,
  TextField,
  Toolbar
} from "@mui/material";
import classNames from "classnames";
import { successAlert } from "components/alert";
import { TextAdd } from "components/buttons";
import CenterConfirmModal from "components/centerConfirmModal";
import { XSvg } from "components/icons";
import { Form, Formik } from "formik";
import useResponsive from "hook/useResponsive";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import {
  deletePortfolioItems,
  getMyListPortfolio,
  updatePortfolioItems
} from "services/PofolioService";
import { sortValue } from "util/common";
import { isIOS } from "util/CommonUtil";
import * as Yup from "yup";
import {
  ButtonSubmit,
  Drawer,
  FormBtnSubmit,
  ListItem,
  Puller
} from "./styled";

export default function ModalEditPortfolio() {
  const { isTabletOrMobile } = useResponsive();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openUpdatePortfolio = useSelector(
    (state) => state.common.openUpdatePortfolio
  );
  const listPortfolio = useSelector((state) => state.portfolio.listPortfolio);

  const [data, setData] = useState();
  const [openModal, setOpenModal] = useState();

  useEffect(() => {
    if (
      !openUpdatePortfolio ||
      !listPortfolio ||
      isEmpty(listPortfolio) ||
      !isArray(listPortfolio)
    )
      return;
    if (listPortfolio?.length) setData(listPortfolio);
    if (!listPortfolio.length) getListPortfolio();
  }, [openUpdatePortfolio, listPortfolio]);

  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  const updateMyPortfolioItems = async () => {
    try {
      setLoading(true);
      const res = await updatePortfolioItems({
        updateMultiple: data
      });
      if (res.code === "200") {
        successAlert(t("notice.modifiedPortfolio"));
        getListPortfolio();
        onClose();
      } else setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const getListPortfolio = async () => {
    try {
      setLoading(true);
      const res = await getMyListPortfolio({
        currentPage: 1,
        pageSize: 10
      });
      if (res.code === "200") {
        dispatch(portfolioActions.setListPortfolio(res?.data?.list || []));
        return res?.data?.list || [];
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => dispatch(commonActions.onCloseUpdatePortfolio());
  const onOpenNewPortfolio = () => {
    onClose();
    if (listPortfolio?.length >= 10)
      dispatch(commonActions.onOpenAddOver10Portfolio());
    else dispatch(commonActions.onOpenNewPortfolio());
  };

  const deleteMyPortfolioItems = async () => {
    if (openModal?.defaultYn === "Y") {
      setOpenModal(undefined);
    } else {
      try {
        setLoading(true);
        const res = await deletePortfolioItems(openModal.mkMyInterestMasterUid);
        if (res.code === "200") {
          successAlert(t("notice.deleteSuccess"));
          try {
            const newList = await getListPortfolio();
            const newListSorted = [...newList].sort((a, b) =>
              sortValue(b.defaultYn, a.defaultYn)
            );
            dispatch(portfolioActions.updateCurrentPortfolio(newListSorted[0]));
          } catch (e) {
            console.error(e);
          } finally {
            setOpenModal(undefined);
          }
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };
  const onChangeData = (interestName, item, setFieldValue) => {
    setData((old) =>
      old?.map((oldItem) =>
        item.mkMyInterestMasterUid === oldItem.mkMyInterestMasterUid
          ? { ...oldItem, interestName: interestName }
          : oldItem
      )
    );
    setFieldValue("interestName", interestName);
  };

  if (!openUpdatePortfolio) return null;

  const contentComponent = (
    <>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
          <div className="flex-start-center gap-12">
            <h2>{t("button.editPortfolio")}</h2>
            <div className="flex-centered gap-2 fit-height">
              <p className="fw-400 font-2 color-info fs-14 lh-18">
                {listPortfolio?.length || 0}
              </p>
              <p className="fw-400 font-2 color-info fs-12 lh-18 pb-2">/</p>
              <p className="fw-400 font-2 color-info fs-14 lh-18">10</p>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <List
        style={{
          maxHeight: "60vh",
          overflow: "auto",
          padding: "8px 0"
        }}>
        {data?.map((item, index) => (
          <ListItem key={index}>
            <Formik
              initialValues={{ interestName: "" }}
              validationSchema={Yup.object({
                interestName: Yup.string().max(50, "50자 제한")
              })}>
              {({ errors, setFieldValue }) => (
                <Form>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="interestName"
                    value={item.interestName}
                    onChange={(e) =>
                      onChangeData(e.target.value, item, setFieldValue)
                    }
                    error={errors.interestName && Boolean(errors.interestName)}
                    helperText={errors.interestName}
                  />
                  <button
                    type="button"
                    className="__delete_btn"
                    onClick={() => setOpenModal(item)}>
                    <XSvg fillColor="#F23645" />
                  </button>
                </Form>
              )}
            </Formik>
          </ListItem>
        ))}
      </List>
      <Divider
        style={{
          margin: "8px 0",
          borderColor: "var(--neutral-color-7, #e4e5e7)"
        }}
      />
      <Button
        onClick={onOpenNewPortfolio}
        sx={{
          backgroundColor: "var(--neutral-color-9)",
          width: "100%",
          padding: "16px 24px 16px 24px",
          justifyContent: "flex-start",
          borderBottom: "8px solid white"
        }}>
        <TextAdd btnText={t("button.newPortfolio")} />
      </Button>
      <div className={classNames({ "pb-16": isIOS() })}>
        <FormBtnSubmit>
          <ButtonSubmit submitType="cancel" onClick={onClose}>
            {t("button.close")}
          </ButtonSubmit>
          <ButtonSubmit
            disabled={
              !!data?.find(
                (i) => !i?.interestName || i.interestName.length > 50
              )
            }
            submitType="save"
            onClick={updateMyPortfolioItems}>
            {t("button.save")}
          </ButtonSubmit>
        </FormBtnSubmit>
      </div>
    </>
  );

  return (
    <div>
      {isTabletOrMobile ? (
        <Drawer
          onOpen={() => {}}
          anchor="bottom"
          open={openUpdatePortfolio}
          onClose={onClose}
          sx={{
            display: openUpdatePortfolio ? "block" : "none",
            "& .MuiPaper-root": {
              borderRadius: "16px 16px 0 0"
            }
          }}>
          {contentComponent}
        </Drawer>
      ) : (
        <Dialog
          open={openUpdatePortfolio}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: "480px"
            }
          }}>
          {contentComponent}
        </Dialog>
      )}

      <CenterConfirmModal
        type={openModal?.defaultYn === "Y" ? "deny" : "confirm"}
        open={openModal}
        textDeny={<h6 className="fw-500">{t("notice.canNotDeleteDefault")}</h6>}
        textNotice={
          <>
            <h4>{t("button.deletePortfolio")}</h4>
            <h6 className="fw-500">{t("info.deletePortfolio")}</h6>
          </>
        }
        onCancel={() => setOpenModal(undefined)}
        onSubmit={deleteMyPortfolioItems}
      />
    </div>
  );
}
