/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getThemaClass = (params) =>
  newAxiosInstance.get(URL.GET_CLASS_OF_THEMA + qs.stringify(params, true));

export const getIndicatorDetail = (params) =>
  newAxiosInstance.get(URL.GET_INDICATORS_DETAIL + qs.stringify(params, true));

export const getIndicatorChart = (params) =>
  newAxiosInstance.get(URL.GET_INDICATORS_CHART + qs.stringify(params, true));

export const getIndicatorHistory = (params) =>
  newAxiosInstance.get(URL.GET_INDICATORS_HISTORY + qs.stringify(params, true));

export const getCalendarDisclosure = (params) =>
  newAxiosInstance.get(
    URL.GET_CALENDAR_DISCLOSURE + qs.stringify(params, true)
  );
export const getListKind = (params) =>
  newAxiosInstance.get(URL.GET_LIST_KIND + qs.stringify(params, true));
export const getListIndicator = (params) =>
  newAxiosInstance.get(URL.GET_LIST_INDICATOR + qs.stringify(params, true));
export const getListThema = (params) =>
  newAxiosInstance.get(URL.GET_LIST_THEMA + qs.stringify(params, true));
export const getThemaDetail = (params) =>
  newAxiosInstance.get(URL.GET_THEMA_DETAIL + params);
export const getListEarning = (params) =>
  newAxiosInstance.get(URL.GET_LIST_EARNING + qs.stringify(params, true));
export const getListDividend = (params) =>
  newAxiosInstance.get(URL.GET_LIST_DIVIDEND + qs.stringify(params, true));
export const getListSplit = (params) =>
  newAxiosInstance.get(URL.GET_LIST_SPLIT + qs.stringify(params, true));
export const getListIPO = (params) =>
  newAxiosInstance.get(URL.GET_LIST_IPO + qs.stringify(params, true));
export const getListHoliday = (params) =>
  newAxiosInstance.get(URL.GET_LIST_HOLIDAY + qs.stringify(params, true));
export const getListMaturity = (params) =>
  newAxiosInstance.get(URL.GET_LIST_MATURITY + qs.stringify(params, true));
export const checkDataCalendarIndicator = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_INDICATOR + qs.stringify(params, true)
  );
export const checkDataCalendarEarning = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_EARNING + qs.stringify(params, true)
  );

export const checkDataCalendarDividend = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_DIVIDEND + qs.stringify(params, true)
  );
export const checkDataCalendarSplit = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_SPLIT + qs.stringify(params, true)
  );
export const checkDataCalendarThema = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_THEMA + qs.stringify(params, true)
  );
export const checkDataCalendarHoliday = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_HOLIDAY + qs.stringify(params, true)
  );
export const checkDataCalendarIPO = (params) =>
  newAxiosInstance.get(
    URL.CHECK_DATA_CALENDAR_IPO + qs.stringify(params, true)
  );

export const getThemaCalendarDetailById = (id) =>
  newAxiosInstance.get(URL.GET_THEMA_CALENDAR_DETAIL + id);
