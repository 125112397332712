/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHelperText, Skeleton } from "@mui/material";
import { successAlert, warningAlert } from "components/alert";
// import { TextLinked } from "components/buttons";
import styled from "styled-components";
import CenterConfirmModal from "components/centerConfirmModal";
import EmptyNotice from "components/emptyNotice";
import { XCircle, XSvg } from "components/icons";
import { OutlinedInputBorder24 } from "components/modal/components/OutlinedInputBorder24";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteKeyword,
  getListKeyword,
  registerKeyword
} from "services/MyPageService/MyPageService";

const Button = styled.button`
  display: flex;
  padding: 4px 0 4px 8px;
`;

export default function MyPageKeyword() {
  const [keyword, setKeyword] = useState("");
  const [listData, setListData] = useState([]);
  const { t } = useTranslation();
  const [openSubmitDelete, setOpenSubmitDelete] = useState(false);
  const [id, setId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getKeywordList();
  }, []);

  const handleChangeSearchTerm = (e) => {
    setKeyword(e.target.value);
    if (listData?.length >= 5) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const getKeywordList = async () => {
    try {
      setIsLoading(true);
      const res = await getListKeyword();
      if (res.code === "200") {
        setListData(res?.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async () => {
    if (!keyword.trim()) {
      warningAlert(t("info.requiredKeyword"));
    } else {
      try {
        setIsLoading(true);
        const res = await registerKeyword({
          key: keyword
        });
        if (res.code === "200") {
          successAlert(t("notice.postCreated"));
          setKeyword("");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
        getKeywordList();
      }
    }
  };

  const handleRemoveItem = async () => {
    try {
      setIsLoading(true);
      const res = await deleteKeyword(id);
      if (res.code === "200") {
        successAlert(t("notice.deleteSuccess"));
        getKeywordList();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setError(false);
    }
  };

  const handleDelete = (item) => {
    setOpenSubmitDelete(true);
    setId(item?.idKey);
  };

  return (
    <>
      <div className="flex gap-16 pl-20 pr-20">
        <OutlinedInputBorder24
          fullWidth
          value={keyword}
          placeholder="keyword hint"
          onChange={handleChangeSearchTerm}
          endAdornment={
            keyword ? (
              <button
                type="button"
                className="btn-40px-end"
                onClick={() => setKeyword("")}>
                <XCircle />
              </button>
            ) : null
          }
        />
        <button
          disabled={listData?.length >= 5}
          type="button"
          onClick={handleAdd}
          className={`flex-shrink-0 ${
            listData?.length >= 5 ? "disabled" : ""
          } text-underline`}>
          {t("info.registration")}
        </button>
      </div>
      {error ? (
        <FormHelperText sx={{ color: "#F20013" }}>
          {t("notice.errorKeyword")}
        </FormHelperText>
      ) : null}

      <div className="pt-24 pl-20 pr-20 pb-64">
        {isLoading ? (
          [...Array(listData?.length || 5)].map((_item, idx) => (
            <Skeleton
              sx={{ mb: "10px", borderRadius: "8px" }}
              key={`recent_skeleton${idx}`}
              variant="rectangular"
              animation="wave"
              height={47}
              width="100%"
            />
          ))
        ) : isEmpty(listData) ? (
          <EmptyNotice
            noticeText={t("notice.noticeRegisteredKeywords")}
            noticeText2={t("notice.noticeRegisteredKeywords1")}
          />
        ) : (
          <>
            <div className="flex-between border-1px-top-neutral-8 border-1px-bot-neutral-8 pd-9-0">
              <h6 className="fw-500 color-info">{t("info.keywords")}</h6>
              <h6 className="fw-500 color-info">
                {t("info.registrationDate")}
              </h6>
            </div>
            {listData?.map((item, index) => (
              <div
                className="pd-12-0 flex-between-center border-1px-bot-neutral-8"
                key={`listData_${index}`}>
                <h5 className="fw-500 color-neutral">{item?.key}</h5>
                <div className="flex-start-center">
                  <p className="caption">
                    {moment(item?.createdDate).format("YYYY.MM.DD")}
                  </p>
                  <Button onClick={() => handleDelete(item)}>
                    <XSvg isKeyword />
                  </Button>
                </div>
              </div>
            ))}
            <div className="text-centered pt-24">
              <p className="fw-400 font-2 caption">
                {listData?.length} / {5}
              </p>
            </div>
          </>
        )}
      </div>

      <CenterConfirmModal
        open={openSubmitDelete}
        onSubmit={() => {
          handleRemoveItem();
          setOpenSubmitDelete(false);
        }}
        onCancel={() => {
          setOpenSubmitDelete(false);
        }}
        textNotice={<h4>{t("info.confirmToDeleteKeyword")}</h4>}
        btnText={t("button.confirm")}
      />
    </>
  );
}
