export default function StarFillSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.2 8.6L11.6318 2.86798C11.7697 2.54294 12.2303 2.54294 12.3682 2.86798L14.8 8.6L21.0699 9.12249C21.4204 9.1517 21.5653 9.58643 21.3024 9.82007L16.6 14L17.9915 20.0879C18.07 20.4311 17.6989 20.7009 17.3966 20.5206L12 17.3L6.6034 20.5206C6.30112 20.7009 5.93003 20.4311 6.00847 20.0879L7.4 14L2.69758 9.82007C2.43473 9.58643 2.57965 9.1517 2.93011 9.12249L9.2 8.6Z"
        fill="#1554F6"
      />
    </svg>
  );
}
