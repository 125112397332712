/* eslint-disable no-nested-ternary */
import styled from "styled-components";

export const JustifyTabs = styled.div`
  width: 100%;
  padding: 0 20px;
  display: grid;
`;
export const Justify2Tabs = styled(JustifyTabs)`
  grid-template-columns: 1fr 1fr;
`;
export const Justify3Tabs = styled(JustifyTabs)`
  grid-template-columns: 1fr 1fr 1fr;
`;
export const Justify4Tabs = styled(JustifyTabs)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const JustifyManyTabs = styled(JustifyTabs)`
  grid-auto-flow: column;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  button {
    width: fit-content;
  }
`;

export const ButtonTab = styled.button`
  padding: ${(props) => {
    if (props.border === "top") {
      if (props.active) {
        return "10px 0 10px 0";
      }
      return "11px 0 10px 0";
    }
    if (props.border === "bottom") {
      if (props.active) {
        return props.disableBorderTop ? "12px 0 8px 0" : "11px 0 8px 0";
      }
      return props.disableBorderTop ? "12px 0 9px 0" : "11px 0 9px 0";
    }
    return "12px 0 10px 0";
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${(props) =>
    props.disableBorderTop
      ? "none"
      : props.active && props.border === "top"
      ? "2px solid var(--neutral-color-1, #11131A)"
      : "1px solid #E4E5E7"};
  border-bottom: ${(props) =>
    props.active && props.border !== "top"
      ? "2px solid var(--neutral-color-1, #11131A)"
      : "1px solid #E4E5E7"};
  h3 {
    color: ${(props) =>
      props.active
        ? "var(--neutral-color-1, #11131A)"
        : "var(--neutral-color-4, #9497A0)"};
  }
`;
