/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getRecommendList = (params) =>
  newAxiosInstance.get(URL.GET_RECOMMEND_LIST + qs.stringify(params, true));

export const searchNews = (params) =>
  newAxiosInstance.get(URL.GET_NEWS_LIST + qs.stringify(params, true));

// export const searchEvents = (params) =>
//   newAxiosInstance.get(URL.SEARCH_EVENT + qs.stringify(params, true));

// export const searchCommunity = (params) =>
//   newAxiosInstance.get(URL.GET_ANALYSIS_LIST + qs.stringify(params, true));

export const searchAnalysis = (params) =>
  newAxiosInstance.get(URL.GET_ANALYSIS_LIST + qs.stringify(params, true));

export const getTab = (params) =>
  newAxiosInstance.get(URL.GET_TAB + qs.stringify(params, true));

export const getNewsList = (params) =>
  newAxiosInstance.get(URL.GET_NEWS_LIST + qs.stringify(params, true));

export const getRecommendNews = () =>
  newAxiosInstance.get(URL.GET_RECOMMEND_NEWS);

export const getAnalysisList = (params) =>
  newAxiosInstance.get(URL.GET_ANALYSIS_LIST + qs.stringify(params, true));

export const getReportList = (params) =>
  newAxiosInstance.get(URL.GET_REPOST_LIST + qs.stringify(params, true));
