import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModalSetting: false,
  currentSetting: null,
  kindOfSelect: null,
  defaultSetting: null
};

const appSettingSlice = createSlice({
  name: "appSetting",
  initialState,
  reducers: {
    updateCurrentSetting: (state, action) => {
      state.currentSetting = action?.payload; // array of setting get from api 14-02
    },
    onOpenModalSetting: (state) => {
      state.openModalSetting = true;
    },
    onCloseModalSetting: (state) => {
      state.openModalSetting = false;
    },
    setKindOfSelect: (state, action) => {
      state.kindOfSelect = action?.payload; // open modal select
    },
    clearKindOfSelect: (state) => {
      state.kindOfSelect = null; // close modal select
    },
    updateDefaultSettings: (state, action) => {
      state.defaultSetting = action?.payload;
    }
  }
});

// Actions
export const appSettingActions = appSettingSlice.actions;

// Reducer
const appSettingReducer = appSettingSlice.reducer;
export default appSettingReducer;
