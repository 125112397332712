import styled from "styled-components";

export const NumberTab4Styled = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
  svg {
    height: 16px;
    width: 16px;
  }
  .point-list {
    padding: 16px 0px 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
    &:first-of-type {
      padding-top: 0;
    }
    .txt {
      color: #5e86f4;
    }
    .ellipsis {
      width: calc(100% - 140px);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .day {
      flex-shrink: 0;
    }
  }
`;
