/* eslint-disable no-unused-vars */
/* eslint-disable no-extra-boolean-cast */
import UserProfileService from "../services/UserProfileService/UserProfileService";
import { USER_STATE, ERROR_MAP } from "../constants";
import AuthService from "../services/auth-service";
import useError from "./useError";

const useUserVerification = () => {
  const { addError } = useError();

  const checkUserStatus = async (callback = () => {}) => {
    try {
      const res = await UserProfileService.getUserDetail();
      const profile = res?.data?.data ?? {};
      const { id, state: userState } = profile || {};
      if (!!id) {
        if (userState === USER_STATE.STOP) {
          addError(ERROR_MAP.USER_SUSPEND.MSG);
          await AuthService.logoutUser();
        } else if (userState === USER_STATE.WITHDRAWAL) {
          addError(ERROR_MAP.USER_WITHDRAWL.MSG);
          await AuthService.logoutUser();
        } else {
          callback?.();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    checkUserStatus
  };
};

export default useUserVerification;
