import React from "react";

function PageBlock(props) {
  const { ip, message } = props;
  return (
    <div>
      Your IP: {ip} {message}
    </div>
  );
}

export default PageBlock;
