/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { InputAdornment } from "@mui/material";
import classNames from "classnames";
import { successAlert } from "components/alert";
import CenterConfirmModal from "components/centerConfirmModal";
import SvgIcons from "components/svgIcons";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { addInterest } from "services/InterestService";
import {
  addNewPortfolio,
  deletePortfolioItems,
  getMyListPortfolio,
  updatePortfolioItems
} from "services/PofolioService";
import { sortValue } from "util/common";
import { PortfolioTextfieldStyle } from "../textFields/styled";
import ModalNewPortfolioPC from "./ModalNewPortfolioPC";
import { PCCenterModalStyled } from "./styled";

function PCCenterModal() {
  const { t, i18n } = useTranslation();
  const [openResgister, setOpenRegister] = useState(false);
  const dispatch = useDispatch();
  const setIsLoading = (value) => dispatch(commonActions.setLoadingPC(value));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const currentActive = useSelector((state) => state.portfolio.currentActive);
  const listPortfolio = useSelector((state) => state.portfolio.listPortfolio);
  const interestAdding = useSelector((state) => state.common.interestAdding);
  const [selected, setSelected] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [flag, setFlag] = useState(false);
  let children = null;

  useEffect(() => {
    if (interestAdding) getListPortfolio();
  }, [interestAdding]);

  useEffect(() => {
    if (currentActive) setSelected(currentActive?.mkMyInterestMasterUid);
  }, [currentActive]);

  const onCloseModal = () => {
    setIsEditMode(false);
    if (!isEditMode) {
      dispatch(commonActions.onAddInterest(null));
      dispatch(commonActions.onClosePortfolio());
    }
  };

  const onClose = () => {
    dispatch(commonActions.onAddInterest(null));
    setIsEditMode(false);
  };

  const onSave = async (values) => {
    if (!isEditMode) {
      try {
        setIsLoading(true);
        const res = await addInterest({
          mkMyInterestMasterUid: selected,
          symbolCategory3depthUid: interestAdding?.symbolCategory3depthUid,
          symbolUid:
            interestAdding?.mkStockMasterUid || interestAdding?.symbolUid,
          symbolType: interestAdding?.symbolType || "STK"
        });
        if (res.code === "200") {
          successAlert(t("info.themaAddedSuccessfully"));
          dispatch(portfolioActions.onUpdateFlag());
          dispatch(commonActions.onAddInterest(null));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return;
    }
    // filter out only the cahnged ones
    const changedValues = values?.portfolio?.filter(
      (item) =>
        listPortfolio.find(
          (portfolio) =>
            portfolio?.mkMyInterestMasterUid === item?.mkMyInterestMasterUid
        )?.interestName !== item?.interestName
    );
    // if nothing changed, close edit mode and not call api
    if (changedValues?.length === 0) {
      setIsEditMode(false);
      return;
    }
    // update the changed ones
    try {
      setIsLoading(true);
      const res = await updatePortfolioItems({
        updateMultiple: [
          ...changedValues.map((item) => ({
            ...item,
            interestName: item?.interestName?.trim() // trim white sapcea
          }))
        ]
      });
      if (res.code === "200") {
        successAlert(t("notice.modifiedPortfolio"));
        getListPortfolio(); // get new list
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    setIsEditMode(false); // after save, close edit mode
  };

  const getListPortfolio = async () => {
    try {
      const res = await getMyListPortfolio({
        currentPage: 1,
        pageSize: 10
      });
      if (res.code === "200") {
        const sortedList = res?.data?.list?.sort((a, b) =>
          a.defaultYn === "Y" ? -1 : 1
        );
        dispatch(portfolioActions.setListPortfolio(sortedList));
        if (!currentActive?.mkMyInterestMasterUid)
          dispatch(
            portfolioActions.updateCurrentPortfolio(
              res?.data?.list?.filter(
                (portfolio) => portfolio?.defaultYn === "Y"
              )[0]
            )
          );
      }
    } catch (e) {
      console.error(e);
    }
  };

  children = (
    <div className="flex-col gap-32">
      <div className="flex-between-center flex-1-auto">
        <div className="flex-center gap-8">
          <h2 className="pc">{t("info.portfolio")}</h2>
          <h6 className="caption"> {listPortfolio?.length || 0} / 10</h6>
        </div>
        {!isEditMode && (
          <div className="flex-center pd-4">
            <SvgIcons
              name="editSvg3"
              width="24px"
              height="24px"
              onClick={() => setIsEditMode(true)}
            />
          </div>
        )}
      </div>
      <div
        style={{
          height: "352px",
          overflowY: "scroll",
          paddingRight: "28px"
        }}
        className="custom-pc-scroll">
        {!isEditMode ? (
          listPortfolio?.map((item, index) => (
            <div
              key={index}
              style={{
                height: "48px"
              }}
              className={classNames(
                "flex-between-center pd-8-4-8-8 gap-16 pointer",
                {
                  "bg-selected": selected === item?.mkMyInterestMasterUid
                }
              )}
              onClick={() => setSelected(item?.mkMyInterestMasterUid)}>
              <div className="flex-between-center flex-1">
                <div className="flex-center gap-8">
                  <h5 className="pc">{item?.interestName}</h5>
                  <h6 className="caption">{`${item?.rows} ${
                    i18n.language === "en" ? "items" : "개"
                  }`}</h6>
                </div>
                {item?.defaultYn === "Y" && (
                  <h6 className="color-sell fw-500">
                    {t("info.myDefaultPortfolio")}
                  </h6>
                )}
              </div>

              <div className="pd-4">
                <SvgIcons
                  name={
                    selected === item?.mkMyInterestMasterUid
                      ? "CheckboxCheckedBlueSvg"
                      : "CheckboxUncheckedSvg"
                  }
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          ))
        ) : (
          <Formik
            name="portfolio"
            enableReinitialize
            onSubmit={onSave}
            initialValues={{
              portfolio: listPortfolio?.map((item) => ({
                ...item,
                interestName: item?.interestName || "",
                mkMyInterestMasterUid: item?.mkMyInterestMasterUid
              }))
            }}>
            {({ values, setFieldValue, handleSubmit }) => (
              <Form onSubmit={handleSubmit} id="portfolio-form">
                {values.portfolio.map((item, index) => (
                  <div
                    key={index}
                    className="flex-between-center gap-8"
                    style={{ height: "48px" }}>
                    <PortfolioTextfieldStyle
                      onClick={() => setSelected(item?.mkMyInterestMasterUid)}
                      name={`portfolio[${index}].interestName`}
                      value={item?.interestName}
                      onChange={
                        (e) =>
                          setFieldValue(
                            `portfolio[${index}].interestName`,
                            e.target.value
                          ) // Update field value
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <button
                              type="button"
                              onClick={() => {
                                // setSelected(item?.mkMyInterestMasterUid);
                                // setIsEditMode(false);
                                setFieldValue(
                                  `portfolio[${index}].interestName`,
                                  ""
                                );
                              }}>
                              <SvgIcons name="close" />
                            </button>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <button
                      type="button"
                      className={classNames("button-32px flex-centered pd-6", {
                        "bg-selected":
                          openDeleteModal?.mkMyInterestMasterUid ===
                          item?.mkMyInterestMasterUid
                      })}
                      onClick={() => {
                        setSelected(item?.mkMyInterestMasterUid);
                        setOpenDeleteModal(item);
                      }}>
                      <SvgIcons
                        name="clearIcon"
                        // onClick={() => {
                        //   setOpenDeleteModal(item?.mkMyInterestMasterUid);
                        // }}
                        color={
                          openDeleteModal?.mkMyInterestMasterUid ===
                          item?.mkMyInterestMasterUid
                            ? "#F20013"
                            : "#CFD0D2"
                        } // delete portfolio
                      />
                    </button>
                  </div>
                ))}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );

  const onOpenRegisterModal = () => {
    if (listPortfolio?.length >= 10)
      dispatch(commonActions.onOpenAddOver10Portfolio());
    else setOpenRegister(true);
  };

  const footer = (
    <div
      className="flex-between-center pd-0-32 border-1px-top-neutral-7"
      style={{ height: "64px" }}>
      <button
        type="button"
        className="flex-centered gap-8 pointer"
        onClick={onOpenRegisterModal}>
        <h4>{t("info.createNewPortfolio")}</h4>
        <SvgIcons name="PlusSvg" />
      </button>
      <div className="flex gap-8">
        <button type="button" className="cancel-btn-pc" onClick={onCloseModal}>
          <h5>{t("button.close")}</h5>
        </button>
        {isEditMode ? (
          <button type="submit" form="portfolio-form" className="submit-btn-pc">
            <h5 className="color-neutral-10">{t("button.confirm")}</h5>
          </button>
        ) : (
          <button type="button" onClick={onSave} className="submit-btn-pc">
            <h5 className="color-neutral-10">{t("button.confirm")}</h5>
          </button>
        )}
      </div>
    </div>
  );

  const deleteMyPortfolioItems = async () => {
    if (openDeleteModal?.defaultYn === "Y") {
      setOpenDeleteModal(undefined);
    } else {
      try {
        const res = await deletePortfolioItems(
          openDeleteModal?.mkMyInterestMasterUid
        );
        if (res?.code === "200") {
          successAlert(t("notice.deleteSuccess"));
          try {
            const newList = await getListPortfolio();
            const newListSorted = [...newList].sort((a, b) =>
              sortValue(b.defaultYn, a.defaultYn)
            );
            dispatch(portfolioActions.updateCurrentPortfolio(newListSorted[0]));
            setFlag(!flag);
          } catch (e) {
            console.error(e);
          } finally {
            setOpenDeleteModal(undefined);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onSubmitRegister = async (inputValue, checked) => {
    const postData = {
      interestName: inputValue?.trim(),
      defaultYn: checked
    };

    try {
      const res = await addNewPortfolio(postData);
      if (res.code === "200") {
        successAlert(t("info.themaAddedSuccessfully"));
        getListPortfolio();
        setOpenRegister(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const registerModal = useMemo(() => {
    if (openResgister) {
      return (
        <ModalNewPortfolioPC
          open={openResgister}
          onClose={() => setOpenRegister(false)}
          onSubmitRegister={onSubmitRegister}
          onCancel={() => setOpenRegister(false)}
          portfolio={listPortfolio}
        />
      );
    }
    return null;
  }, [openResgister]);

  return (
    <>
      {!openResgister && (
        <PCCenterModalStyled
          open={!!interestAdding}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <div className="flex-col bg-neutral-10 modal-content">
            <div className="flex-end-center pd-8 border-1px-bot-neutral-8">
              <button
                className="block-32px flex-centered"
                type="button"
                onClick={onClose}>
                <SvgIcons name="clearIcon" />
              </button>
            </div>
            <div className="flex-col gap-24 pd-32">{children}</div>
            {footer}
          </div>
        </PCCenterModalStyled>
      )}
      {!!openDeleteModal && (
        <CenterConfirmModal
          type={openDeleteModal?.defaultYn === "Y" ? "deny" : "confirm"}
          open={!!openDeleteModal}
          textDeny={
            <h6 className="fw-500">{t("notice.canNotDeleteDefault")}</h6>
          }
          textNotice={
            <>
              <h4>{t("button.deletePortfolio")}</h4>
              <h6 className="fw-500">{t("info.deletePortfolio")}</h6>
            </>
          }
          onCancel={() => setOpenDeleteModal(undefined)}
          onSubmit={deleteMyPortfolioItems}
        />
      )}
      {registerModal}
    </>
  );
}

export default PCCenterModal;
