export default function AlignLeftSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30005 10C2.30005 9.61345 2.61345 9.30005 3.00005 9.30005H17C17.3866 9.30005 17.7 9.61345 17.7 10C17.7 10.3866 17.3866 10.7 17 10.7H3.00005C2.61345 10.7 2.30005 10.3866 2.30005 10Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30005 6.00005C2.30005 5.61345 2.61345 5.30005 3.00005 5.30005H21C21.3866 5.30005 21.7001 5.61345 21.7001 6.00005C21.7001 6.38665 21.3866 6.70005 21 6.70005H3.00005C2.61345 6.70005 2.30005 6.38665 2.30005 6.00005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30005 14C2.30005 13.6134 2.61345 13.3 3.00005 13.3H21C21.3866 13.3 21.7001 13.6134 21.7001 14C21.7001 14.3866 21.3866 14.7 21 14.7H3.00005C2.61345 14.7 2.30005 14.3866 2.30005 14Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30005 18C2.30005 17.6134 2.61345 17.3 3.00005 17.3H17C17.3866 17.3 17.7 17.6134 17.7 18C17.7 18.3866 17.3866 18.7 17 18.7H3.00005C2.61345 18.7 2.30005 18.3866 2.30005 18Z"
        fill={fillColor}
      />
    </svg>
  );
}
