/* eslint-disable no-unused-vars */
import { AppBar, Drawer, List, ListItemButton } from "@mui/material";
import SvgIcons from "components/svgIcons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "redux/reducers/myPageReducer";
import { Puller } from "../styled";

export default function DrawerActionAvatar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openDrawer = useSelector((state) => state.myPage.openDrawerAvatar);

  const handleCloseDrawerAvatar = () =>
    dispatch(myPageActions.onCloseDrawerAvatar());

  const handleViewAvatar = () => {
    dispatch(myPageActions.onOpenDialogAvatar());
    handleCloseDrawerAvatar();
  };

  return (
    <Drawer
      anchor="bottom"
      open={openDrawer}
      onClose={handleCloseDrawerAvatar}
      sx={{
        zIndex: 1301,
        display: openDrawer ? "block" : "none",
        "& .MuiPaper-root": {
          borderRadius: "16px 16px 0 0"
        }
      }}>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
      </AppBar>
      <List
        sx={{
          overflow: "auto",
          padding: "8px 0"
        }}>
        <ListItemButton
          onClick={handleViewAvatar}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 24px",
            borderBottom: "1px solid var(--neutral-color-8, #F4F4F4)"
          }}>
          <div className="flex-centered">
            <div className="btn-40px-start">
              <SvgIcons name="eye" />
            </div>
            <h4>{t("button.viewAvatar")}</h4>
          </div>
        </ListItemButton>
        <label htmlFor="input_avatar">
          <ListItemButton
            // onClick={handleSettingAvatar}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 24px",
              borderBottom: "1px solid var(--neutral-color-8, #F4F4F4)"
            }}>
            <div className="flex-centered">
              <div className="btn-40px-start">
                <SvgIcons name="ImageSvg" />
              </div>
              <h4>{t("button.updateAvatar")}</h4>
            </div>
          </ListItemButton>
        </label>
      </List>
    </Drawer>
  );
}
