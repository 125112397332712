import { combineReducers } from "@reduxjs/toolkit";

import adminReducer from "./reducers/adminReducer";
import { agoraReducer } from "./reducers/agoraReducer";
import commonReducer from "./reducers/commonReducer";
import economicCalendarReducer from "./reducers/economicCalendar";
import { editReducer } from "./reducers/editReducer";
import interestReducer from "./reducers/interestReducer";
import { loginReducer } from "./reducers/loginReducer";
import marketReducer from "./reducers/marketReducer";
import portfolioReducer from "./reducers/portfolioReducer";
import postReducer from "./reducers/postReducer";
import recentReducer from "./reducers/recentReducer";
import searchReducer from "./reducers/searchReducer";
import { settingReducer } from "./reducers/settingReducer";
import topThemedReducer from "./reducers/topThemedReducer";
import { userReducer } from "./reducers/userReducer";
import authReducer from "./reducers/authReducer";
import myPageReducer from "./reducers/myPageReducer";
import newsReducer from "./reducers/newsReducer";
import appSettingReducer from "./reducers/appSettingReducer";
import heatmapReducer from "./reducers/heatmapReducer";

const rootReducer = combineReducers({
  adminState: adminReducer,
  loginReducer,
  user: userReducer,
  edit: editReducer,
  setting: settingReducer,
  agora: agoraReducer,
  common: commonReducer,
  portfolio: portfolioReducer,
  recent: recentReducer,
  interest: interestReducer,
  search: searchReducer,
  topThemed: topThemedReducer,
  economicCalendar: economicCalendarReducer,
  market: marketReducer,
  post: postReducer,
  auth: authReducer,
  myPage: myPageReducer,
  news: newsReducer,
  appSetting: appSettingReducer,
  heatmap: heatmapReducer
});

export default rootReducer;
