import SvgIcons from "components/svgIcons";
import PropTypes from "prop-types";
import { PCCenterModalStyled } from "./styled";

function PCCenterModal({ children, onClose, open, height }) {
  return (
    <PCCenterModalStyled
      height={height}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex-col bg-neutral-10 modal-content">
        <div className="flex-end-center pd-8 border-1px-bot-neutral-8">
          <button
            className="block-32px flex-centered"
            type="button"
            onClick={onClose}>
            <SvgIcons name="clearIcon" />
          </button>
        </div>
        <div className="flex-col gap-24 pd-32">{children}</div>
      </div>
    </PCCenterModalStyled>
  );
}

PCCenterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  height: PropTypes.number
};

PCCenterModal.defaultProps = {
  height: 592
};
export default PCCenterModal;
