/* eslint-disable default-param-last */
import { GET_USER_COUNT_ALL, RECALL_NOTICE } from "../type/userType";

const initialState = {
  user: null,
  recallNotice: 0
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_COUNT_ALL: {
      return { ...state, user: action.payload };
    }
    case RECALL_NOTICE: {
      const recallNotice = JSON.parse(JSON.stringify(state));
      return { ...state, recallNotice: ++recallNotice.recallNotice };
    }
    default:
      return state;
  }
};
