/* eslint-disable no-unused-vars */
import { Textarea } from "@mantine/core";
import * as React from "react";

export default function TextAreaWithMaxlength({
  maxlength = 4000,
  height = 160,
  setText,
  text,
  setLength,
  length
}) {
  const [message, setMessage] = React.useState(`${length}/${maxlength}byte`);
  React.useMemo(() => {
    if (length <= maxlength) {
      setMessage(`${length}/${maxlength}`);
    }
  }, [length]);

  return (
    <di
      style={{
        position: "relative"
      }}>
      <Textarea
        style={{
          border: "1px solid var(--neutral-color-7, #e4e5e7) "
        }}
        height={height}
        borderRadius={4}
        value={text}
        onChange={(event) => {
          setText(event.currentTarget.value);
          setLength(event.currentTarget.value.length);
        }}
        maxLength={maxlength}
        maxRows={10}
        minRows={6}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          bottom: "20px",
          right: "13px"
        }}>
        <span className="caption">{message}</span>
      </div>
    </di>
  );
}
