const URL = Object.freeze({
  GET_NEW_POST: "/api/user/post/list-post/new",
  GET_POST_BY_BOARD: "/api/user/post/list-by-board/new",
  GET_POST_BY_MENU: "/api/user/post/list-by-menu/new",
  GET_POST_BY_ID: "/api/user/post/detail",
  REPORT_POST: "/api/user/report-post/report",
  REPORT_USER: "/api/user/report-user/report",
  DELETE_POST: "/api/user/post/delete",
  MOVE_POST: "/api/admin/post/move",
  COPY_POST: "/api/admin/post/copy",
  GET_POST_CALENDER: "/api/user/post/list-post/calendar",
  GET_POST_LIST_BY_BOARD: (id) =>
    `/api/user/post/list-by-board/new?idBoard=${id}`,
  GET_LIST_TYPE_POST: (id) => `/api/user/tab-board/get/all?idBoard=${id}`,
  UPDATE_POST: "/api/user/post/update",
  GET_PRE_NEXT_POST: "/api/user/post/get-pre-next",
  LIKE_POST: (type) => `/api/user/post/${type}`,
  GET_POSTS_BY_AUTHOR_AND_BOARD: "/api/user/post/list-by-board/user/all",
  GET_POST_BY_HASHTAG: "/api/user/post/list-by-board/new-hashtag",
  GET_PRE_NEXT_POST_VIDEO: "/api/user/post/get-pre-next-type-post",
  GET_POST_BY_TYPEPOST: "/api/user/post/list-post/keyword"
});
export default URL;
