const URL = Object.freeze({
  GET_MAIN_DEBATE: "/api/user/community/main-debate", // 11-1
  GET_FREE_BOARD: "/api/user/community/main-free", // 11-2
  TOP_20_DISCUSSION_ROOM: "/api/user/community/debate-top20", // 11-3
  GET_ROOM_BY_PORTFOLIO: "/api/user/community/portfolio", // 11-4
  GET_FOLLOWING_POST: "/api/user/community/follow", // 11-8
  GET_DEBATE: "/api/user/community/debate", // 11-17
  GET_BLOCK: "/api/user/community/block", // 11-16
  DELETE_BLOCK: "/api/user/community/block-delete", // 11-15
  INSERT_BLOCK: "/api/user/community/block-insert", // 11-14
  GET_THEMA: "/api/user/community/theme", // 11-5
  GET_POST_DETAIL: "/api/user/post/detail", // api/user/post/detail
  GET_USER_DETAIL: "/api/user/community/user", // 11-9
  GET_POST_LIST_BY_USER_ID: "/api/user/community/user-post", // 11-10
  GET_COMMENT_LIST_BY_USER_ID: "/api/user/community/user-comment", // 11-13
  GET_USER_FOLLOWING: "/api/user/community/user-following", // 11-11
  GET_USER_FOLLOWER: "/api/user/community/user-follower", // 11-12
  REPORT_COMMENT: "/api/user/report-comment/report",
  REPORT_POST: "/api/user/report-post/report",
  FOLLOW_INSERT: "/api/user/community/follow-insert", // 11-6
  FOLLOW_DELETE: "/api/user/community/follow-delete", // 11-7
  POST_CREATE: "/api/user/post/create",
  UPDATE_POST: "/api/user/post/update",
  LIKE_POST: "/api/user/post/like",
  UNLIKE_POST: "/api/user/post/unlike",
  DELETE_COMMENT: "/api/user/comment/delete/{id}",
  DELETE_POST: "/api/user/post/delete",
  REPORT_USER: "/api/user/report-user/report",
  SCRAP_POST: "/api/user/scrap/create",
  UNSCRAP_POST: "/api/user/scrap/delete-scrap",
  GET_COMMENT_BY_POST: "/api/user/community/post-comment",
  GET_COMMENT_BY_COMMENT: "/api/user/community/parent-comment",
  CREATE_COMMENT: "/api/user/comment/create",
  EDIT_COMMENT: "/api/user/comment/edit",
  GET_BULLETIN_BOARD_LIST: "/api/user/bulltin-board/list board not pagi",
  GET_BULLETIN_BOARD_DETAIL: "/api/user/bulltin-board/detail",
  GET_COMMENT_DETAIL: "/api/user/comment/detail",
  INSERT_LIKE_COMMENT: "/api/user/community/comment-like-insert",
  DELETE_LIKE_COMMENT: "/api/user/community/comment-like-delete"
});
export default URL;
