/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { successAlert } from "components/alert";
import SvgIcons from "components/svgIcons";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { recentActions } from "redux/reducers/recentReducer";
import { addThema } from "services/HeatMapService/HeatMapService";
import { deleteInterest } from "services/InterestService";
import styled from "styled-components";

/* eslint-disable jsx-a11y/click-events-have-key-events */
const ButtonStyle = styled.button``;
function InterestButton({ item, elementClass }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { listRecent } = useSelector((state) => state?.recent);
  const token = localStorage.getItem("token");

  const handleAddToFavorite = async () => {
    if (token) {
      // if (item?.symbolType === "THM") {
      //   const res = await addThema({
      //     mkThemaMasterUid: item?.symbolUid
      //   });
      //   if (res?.code === "200") successAlert(t("notice.addSuccess"));
      // } else {
      dispatch(commonActions.onAddInterest(item));
      // }
    } else {
      dispatch(commonActions.onOpenModalRequireLogin());
    }
  };

  const handleDeleteFromFavorite = async () => {
    const res = await deleteInterest(item?.mkMyInterestDetailUid);
    if (res?.code === "200") {
      successAlert(t("notice.deleteSuccess"));
      dispatch(
        recentActions.setListRecent(
          listRecent?.map((i) => ({
            ...i,
            interestYn: item?.symbolUid === i?.symbolUid ? "N" : i?.interestYn
          }))
        )
      );
      dispatch(portfolioActions.onUpdateFlag());
    }
  };
  return (
    <ButtonStyle
      type="button"
      className={classNames("block-40px", {
        [elementClass]: elementClass,
        "btn-40px-start": !elementClass
      })}
      onClick={() => console.log(item)}>
      {item?.interestYn === "N" ? (
        <button
          type="button"
          className="interest-button"
          onClick={handleAddToFavorite}>
          <SvgIcons name="StarPlusSvg" />
        </button>
      ) : (
        <button type="button" onClick={handleDeleteFromFavorite}>
          <SvgIcons name="StarFillSvg" />
        </button>
      )}
    </ButtonStyle>
  );
}

export const InterestButtonPC = memo(InterestButton);
