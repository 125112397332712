export default function NewsSvg({ fillColor = "#AFB1B8" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.40901 1.74238C3.83097 1.32043 4.40326 1.08337 5 1.08337H11.6667C11.8214 1.08337 11.9697 1.14483 12.0791 1.25423L17.0791 6.25423C17.1885 6.36362 17.25 6.512 17.25 6.66671V16.6667C17.25 17.2634 17.0129 17.8357 16.591 18.2577C16.169 18.6797 15.5967 18.9167 15 18.9167H5C4.40326 18.9167 3.83097 18.6797 3.40901 18.2577C2.98705 17.8357 2.75 17.2634 2.75 16.6667V3.33337C2.75 2.73664 2.98705 2.16434 3.40901 1.74238ZM5 2.25004C4.71268 2.25004 4.43713 2.36418 4.23397 2.56734C4.0308 2.77051 3.91667 3.04606 3.91667 3.33337V16.6667C3.91667 16.954 4.0308 17.2296 4.23397 17.4327C4.43713 17.6359 4.71268 17.75 5 17.75H15C15.2873 17.75 15.5629 17.6359 15.766 17.4327C15.9692 17.2296 16.0833 16.954 16.0833 16.6667V6.90833L11.425 2.25004H5Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 1.08337C11.9889 1.08337 12.25 1.34454 12.25 1.66671V6.08337H16.6667C16.9889 6.08337 17.25 6.34454 17.25 6.66671C17.25 6.98887 16.9889 7.25004 16.6667 7.25004H11.6667C11.3445 7.25004 11.0834 6.98887 11.0834 6.66671V1.66671C11.0834 1.34454 11.3445 1.08337 11.6667 1.08337Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08337 10.8333C6.08337 10.5112 6.34454 10.25 6.66671 10.25H13.3334C13.6555 10.25 13.9167 10.5112 13.9167 10.8333C13.9167 11.1555 13.6555 11.4167 13.3334 11.4167H6.66671C6.34454 11.4167 6.08337 11.1555 6.08337 10.8333Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08337 14.1667C6.08337 13.8445 6.34454 13.5834 6.66671 13.5834H13.3334C13.6555 13.5834 13.9167 13.8445 13.9167 14.1667C13.9167 14.4889 13.6555 14.75 13.3334 14.75H6.66671C6.34454 14.75 6.08337 14.4889 6.08337 14.1667Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08337 7.49996C6.08337 7.17779 6.34454 6.91663 6.66671 6.91663H8.33337C8.65554 6.91663 8.91671 7.17779 8.91671 7.49996C8.91671 7.82212 8.65554 8.08329 8.33337 8.08329H6.66671C6.34454 8.08329 6.08337 7.82212 6.08337 7.49996Z"
        fill={fillColor}
      />
    </svg>
  );
}
