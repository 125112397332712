export default function ValueUpSvg({ fillColor = "#F20013" }) {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0.5L8 6.5H0L4 0.5Z" fill={fillColor} />
    </svg>
  );
}
