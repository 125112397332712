/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
import { Modal, Switch, styled } from "@mui/material";
import classNames from "classnames";
import CenterConfirmModal from "components/centerConfirmModal";
import { CountryName } from "components/countryName";
import FilterCountry from "components/filterCountry";
import ImportantSelectItem from "components/importantSelectItem";
import HeaderSection from "components/layouts/section";
import SubHeaderMobile from "components/subHeaderMobile";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { economicCalendarActions } from "redux/reducers/economicCalendar";
import { isIOS } from "util/CommonUtil";
import { ModalStyled, SelfScrollDiv } from "views/HeatMap/styled";
import ModalFilterCountry from "./ModalFilterCountry";
import ModalListOfKind from "./ModalListOfKind";
import FooterResetSubmit from "./components/FooterResetSubmit";
import WhiteFilter from "./components/WhiteFilter";

const listOfImportance = [
  {
    id: 1,
    labelKor: "낮음",
    labelEng: "LOW"
  },
  {
    id: 2,
    labelKor: "보통",
    labelEng: "NORMAL"
  },
  {
    id: 3,
    labelKor: "높음",
    labelEng: "HIGH"
  }
];
export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(10px)"
    }
  },
  "& .MuiSwitch-switchBase": {
    width: "20px",
    height: "20px",
    transform: "translateY(0)", // override default transform from mui
    padding: 1.75,
    "&.Mui-checked": {
      transform: "translate(15px, 0)", // override default transform from mui
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1554F6"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200
    }),
    color: "var(--neutral-color-10, #FFFFFF)"
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box"
  }
}));

export default function ModalEconomicCalendarFilter({ open, onClose, value }) {
  const dispatch = useDispatch();
  const [openSubmitReset, setOpenSubmitReset] = useState(false);
  const [openFilterCountry, setOpenFilterCountry] = useState(false);
  const [openFilterKind, setOpenFilterKind] = useState(false);
  const [enableFilter, setEnableFilter] = useState(null);
  const [listSelectedCountry, setListSelectedCountry] = useState([]);
  const [listSelectedImportant, setListSelectedImportant] = useState([]);
  const [listSelectedKind, setListSelectedKind] = useState([]);
  const searchData = useSelector((state) => state?.economicCalendar);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (open) loadRedux();
  }, [open]);

  useEffect(() => {
    if (typeof enableFilter === "boolean" && !enableFilter) resetDefault();
  }, [enableFilter]);

  const handleSubmit = () => {
    switch (value) {
      case "56827539D3E94748A2D8CD75CB830F5C": // indicator
        dispatch(
          economicCalendarActions.updateSearchDataIndicator({
            country: listSelectedCountry,
            importance: listSelectedImportant,
            kind: listSelectedKind,
            enableFilter
          })
        );
        break;
      case "A4C3049BD5A8412E9E02A66DD7F4CEA0": // earning
        dispatch(
          economicCalendarActions.updateSearchDataEarning({
            country: listSelectedCountry,
            kind: listSelectedKind,
            enableFilter
          })
        );
        break;
      case "7484C0B060E142139A078EEA60BD405B": // dividen
        dispatch(
          economicCalendarActions.updateSearchDataDividend({
            country: listSelectedCountry,
            kind: listSelectedKind,
            enableFilter
          })
        );
        break;
      case "27B6669ACD814B4F8FCCB474798466F0": // split
        dispatch(
          economicCalendarActions.updateSearchDataSplit({
            country: listSelectedCountry,
            kind: listSelectedKind,
            enableFilter
          })
        );
        break;
      case "1B0275B8A7AF4F6BBB2F5F387AC86AD8": // maturing
        dispatch(
          economicCalendarActions.updateSearchDataSplit({
            country: listSelectedCountry,
            kind: listSelectedKind,
            enableFilter
          })
        );
        break;
      default:
        break;
    }
  };

  const resetDefault = () => {
    if (i18n.language === "ko")
      setListSelectedCountry([
        {
          countryCodeShort: "KR",
          countryCode: "KOR",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
          labelKor: "대한민국",
          labelEng: "Republic of Korea",
          mkCountryUid: "19d0417c147811ee9382d8bbc199b2cd"
        }
      ]);
    else
      setListSelectedCountry([
        {
          countryCodeShort: "US",
          countryCode: "USA",
          mainYn: "Y",
          imageUrl:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
          labelKor: "미국",
          labelEng: "United States",
          mkCountryUid: "19d05cfb147811ee9382d8bbc199b2cd"
        }
      ]);
    setListSelectedKind([
      {
        viewOrder: 0,
        defaultValue: "Y",
        labelKor: "전체",
        labelEng: "All",
        codeValue: "ALL"
      }
    ]);
    setListSelectedImportant([
      {
        id: 0,
        labelKor: "전체",
        labelEng: "ALL"
      }
    ]);
  };

  const loadRedux = () => {
    switch (value) {
      case "56827539D3E94748A2D8CD75CB830F5C": // indicator
        setListSelectedCountry(searchData.searchDataIndicator.country);
        setListSelectedImportant(
          searchData.searchDataIndicator.importance || [
            {
              id: 0,
              labelKor: "전체",
              labelEng: "ALL"
            }
          ]
        );
        setEnableFilter(searchData.searchDataIndicator.enableFilter);
        setListSelectedKind(searchData.searchDataIndicator.kind);
        break;
      case "A4C3049BD5A8412E9E02A66DD7F4CEA0": // earning
        setListSelectedCountry(searchData.searchDataEarning.country);
        setListSelectedKind(searchData.searchDataEarning.kind);
        setEnableFilter(searchData.searchDataEarning.enableFilter);
        break;
      case "7484C0B060E142139A078EEA60BD405B": // dividen
        setListSelectedCountry(searchData.searchDataDividend.country);
        setListSelectedKind(searchData.searchDataDividend.kind);
        setEnableFilter(searchData.searchDataDividend.enableFilter);
        break;
      case "27B6669ACD814B4F8FCCB474798466F0": // split
        setListSelectedCountry(searchData.searchDataSplit.country);
        setListSelectedKind(searchData.searchDataSplit.kind);
        setEnableFilter(searchData.searchDataSplit.enableFilter);
        break;
      case "1B0275B8A7AF4F6BBB2F5F387AC86AD8": // maturing
        setListSelectedCountry(searchData.searchDataMaturity.country);
        setListSelectedKind(searchData.searchDataMaturity.kind);
        setEnableFilter(searchData.searchDataMaturity.enableFilter);
        break;
      default:
        break;
    }
  };
  const handleSelectImportant = (item) => {
    if (listSelectedImportant.map((i) => i?.id).includes(item?.id)) {
      const newListSelected = listSelectedImportant.filter(
        (i) => i?.id !== item?.id
      );
      if (newListSelected?.length === 0)
        setListSelectedImportant([
          {
            id: 0,
            labelKor: "전체",
            labelEng: "ALL"
          }
        ]);
      else setListSelectedImportant(newListSelected);
    } else {
      setListSelectedImportant((prev) =>
        [...prev, item].filter((i) => i?.id !== 0)
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <SubHeaderMobile
          headerTitle={<h1>{t("info.filter")}</h1>}
          borderBottom
          customAction={
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={enableFilter}
              onChange={() => setEnableFilter((e) => !e)}
            />
          }
          onBack={onClose}
        />
        <div className="hidden-scrollbar h-100vh flex-col gap-24 pos-relative">
          <WhiteFilter open={!enableFilter} />
          <div className="pd-0-20" style={{ paddingTop: "8px" }}>
            <CountryName name={t("info.selectCountry")} />
            <div className="pd-16-0">
              <FilterCountry
                listCountry={listSelectedCountry}
                onClick={() => setOpenFilterCountry(true)}
              />
            </div>
          </div>
          {!isEmpty(value) && value === "56827539D3E94748A2D8CD75CB830F5C" && (
            <div>
              <div className="pd-0-20">
                <CountryName name={t("info.selectImportance")} />
              </div>
              <SelfScrollDiv>
                {!isEmpty(listOfImportance) &&
                  isArray(listOfImportance) &&
                  listOfImportance.map((item, index) => {
                    return (
                      <ImportantSelectItem
                        star={item?.id}
                        key={`impotant_select_${index}`}
                        text={
                          i18n.language === "ko"
                            ? item?.labelKor
                            : item?.labelEng
                        }
                        totalStar={listOfImportance.length}
                        active={listSelectedImportant
                          .map((i) => i?.id)
                          .includes(item?.id)}
                        onClick={() => handleSelectImportant(item)}
                      />
                    );
                  })}
              </SelfScrollDiv>
            </div>
          )}
          <div className="pd-0-20">
            <CountryName name={t("info.selectType")} />
            <div className="flex-col gap-8 pd-16-0">
              <HeaderSection
                mainTitle={t("info.type")}
                editTitle1={t("info.edit")}
                onClickTitle1={() => {
                  setOpenFilterKind(true);
                }}
              />
              <span className="caption">
                {isArray(listSelectedKind) &&
                  !isEmpty(listSelectedKind) &&
                  listSelectedKind
                    .map((item) =>
                      i18n.language === "ko" ? item?.labelKor : item?.labelEng
                    )
                    .join(", ")}
              </span>
            </div>
            {/* list selected here */}
          </div>
        </div>
        <div className={classNames({ "pb-16": isIOS() })}>
          <FooterResetSubmit
            onReset={() => {
              setOpenSubmitReset(true);
            }}
            onCancle={() => {
              // return old value
              onClose();
            }}
            onSubmit={() => {
              // call api filter
              handleSubmit();
              onClose();
            }}
          />
        </div>
        <CenterConfirmModal
          type="confirm"
          open={openSubmitReset}
          onSubmit={() => {
            resetDefault();
            setOpenSubmitReset(false);
          }}
          onCancel={() => {
            setOpenSubmitReset(false);
          }}
          textNotice={
            <>
              <h4>{t("info.resetDefaultFilter.title")}</h4>
              <h6 className="fw-500">{t("info.resetDefaultFilter.content")}</h6>
            </>
          }
          btnText={t("button.cancel")}
        />
        <ModalFilterCountry
          open={openFilterCountry}
          onClose={() => {
            setOpenFilterCountry(false);
          }}
          value={value}
          listSelectedCountry={listSelectedCountry}
          setListSelectedCountry={setListSelectedCountry}
        />
        <ModalListOfKind
          open={openFilterKind}
          onClose={() => {
            setOpenFilterKind(false);
          }}
          value={value}
          listSelectedKind={listSelectedKind}
          setListSelectedKind={setListSelectedKind}
        />
      </ModalStyled>
    </Modal>
  );
}
