/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { IncorrectSvg } from "components/icons";
import { useTranslation } from "react-i18next";

const Filter = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1600;
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  background-color: var(--neutral-color-10, #ffffff);

  /* width: calc(100% - 64px); */
  width: ${(props) => (props.pc ? "376px" : "calc(100% - 64px)")};
  min-width: 311px;
  max-width: 550px;

  padding: 32px;
  flex-direction: column;
  align-items: center;

  border-radius: 2px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12),
    0px 0px 8px 0px rgba(0, 0, 0, 0.08);

  .text-notice,
  .text-warning,
  .submit-important {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    text-align: center;
    h6 {
      color: var(--neutral-color-3, #45495a);
    }
  }
  .text-warning {
    width: 80%;
    padding-top: 16px;
  }
  .submit-important {
    padding-top: 8px;
  }
`;

const ButtonSubmit = styled.button`
  width: ${(props) => {
    if (
      props.type === "deny" ||
      props.type === "afterAccept" ||
      props.type === "success"
    )
      return "100%";
    return "48%";
  }};
  height: 48px;
  text-align: center;
  border: 1px solid #e4e5e7;
  border-radius: 24px;
  background-color: ${(props) => {
    if (props.type === "accept" || props.type === "success")
      return "var(--interaction-success-color, #1554F6)";
    if (props.type === "deny" || props.type === "delete")
      return "var(--accent-buy, #F20013)";
    if (props.type === "afterAccept") return "#9497A0";
    return "var(--neutral-color-10,#FFFFFF)";
  }};
  h5 {
    padding-top: 2px; // center text korean
    /* line-height: 15px; */
    color: ${(props) => {
      if (props.type === "cancel") return "var(--neutral-color-1,#11131A)";
      return "var(--neutral-color-10,#FFFFFF)";
    }};
  }
`;

const Submit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function CenterConfirmModal({
  open,
  type,
  onSubmit,
  onCancel,
  textNotice,
  textDeny,
  textAccept,
  btnText,
  btnText2,
  pc
}) {
  const { t } = useTranslation();

  return (
    <Filter open={!!open} pc={pc}>
      {type === "confirm" ? (
        <Modal pc={pc}>
          {/* delete portfolio */}
          <div className="text-notice">{textNotice}</div>
          <Submit>
            <ButtonSubmit type="cancel" onClick={onCancel}>
              <h5 className="lh-15">{btnText || t("button.cancel")}</h5>
            </ButtonSubmit>
            <ButtonSubmit type="accept" onClick={onSubmit}>
              <h5 className="lh-15">{btnText2 || t("button.confirm")}</h5>
            </ButtonSubmit>
          </Submit>
        </Modal>
      ) : type === "deny" ? (
        <Modal pc={pc}>
          <div
            className="flex-centered block-40px border-rounded"
            style={{
              backgroundColor: "var(--interaction-error-color)"
            }}>
            <IncorrectSvg
              fillColor="var(--neutral-color-10)"
              width="13"
              height="13"
            />
          </div>
          <div className="text-warning">{textDeny}</div>
          <Submit>
            <ButtonSubmit type="deny" onClick={onSubmit}>
              <h5>{t("button.confirm")}</h5>
            </ButtonSubmit>
          </Submit>
        </Modal>
      ) : type === "afterAccept" ? (
        <Modal pc={pc}>
          <div className="text-notice">{textNotice}</div>
          {textAccept && <div className="text-warning">{textAccept}</div>}
          <Submit>
            <ButtonSubmit type="afterAccept" onClick={onSubmit}>
              <h5>{btnText}</h5>
            </ButtonSubmit>
          </Submit>
        </Modal>
      ) : type === "success" ? (
        <Modal pc={pc}>
          <div className="text-notice">{textNotice}</div>
          <Submit>
            <ButtonSubmit type="success" onClick={onSubmit}>
              <h5>{btnText}</h5>
            </ButtonSubmit>
          </Submit>
        </Modal>
      ) : (
        // when delete something relly importtant
        // or something, have colow withe and red submit
        <Modal pc={pc}>
          <div className="submit-important">{textNotice}</div>
          <Submit>
            <ButtonSubmit type="cancel" onClick={onCancel}>
              <h5>{btnText2 || t("button.cancel")}</h5>
            </ButtonSubmit>
            <ButtonSubmit type="delete" onClick={onSubmit}>
              <h5>{btnText || t("button.confirm")}</h5>
            </ButtonSubmit>
          </Submit>
        </Modal>
      )}
    </Filter>
  );
}
