export default function SpeakerSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#F4F4F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.052 7.54861C13.1935 7.6166 13.2835 7.75967 13.2835 7.91664V16.0833C13.2835 16.2403 13.1935 16.3833 13.052 16.4513C12.9105 16.5193 12.7426 16.5002 12.62 16.4022L9.81523 14.1583H7.62513C7.39961 14.1583 7.2168 13.9755 7.2168 13.75V10.25C7.2168 10.0245 7.39961 9.84164 7.62513 9.84164H9.81523L12.62 7.59778C12.7426 7.49973 12.9105 7.48061 13.052 7.54861ZM12.4668 8.76623L10.2135 10.5688C10.1411 10.6267 10.0512 10.6583 9.95846 10.6583H8.03346V13.3416H9.95846C10.0512 13.3416 10.1411 13.3732 10.2135 13.4311L12.4668 15.2337V8.76623Z"
        fill="#9497A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2348 9.6462C15.3943 9.48676 15.6528 9.4868 15.8122 9.64628C16.4356 10.2698 16.7858 11.1154 16.7858 11.9971C16.7858 12.8787 16.4356 13.7243 15.8122 14.3478C15.6528 14.5073 15.3943 14.5074 15.2348 14.3479C15.0753 14.1885 15.0752 13.9299 15.2347 13.7704C15.7049 13.3001 15.9691 12.6622 15.9691 11.9971C15.9691 11.3319 15.7049 10.694 15.2347 10.2237C15.0752 10.0642 15.0753 9.80564 15.2348 9.6462Z"
        fill="#9497A0"
      />
    </svg>
  );
}
