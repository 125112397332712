import { memo, useMemo } from "react";
/* eslint-disable arrow-body-style */
import classNames from "classnames";
import { TextLinked } from "components/buttons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { CountryListStyled, FilterCountryStyled } from "./styled";

function FilterCountry({ listCountry, onClick, pc, hideText }) {
  const { t } = useTranslation();
  const moreCountryMap = useMemo(() => {
    return listCountry.length - 3;
  }, [listCountry]);
  return (
    <FilterCountryStyled pc={pc}>
      <CountryListStyled pc={pc}>
        {!isEmpty(listCountry) &&
          isArray(listCountry) &&
          listCountry.slice(0, 3).map((item, index) => {
            return (
              <img
                className={classNames({
                  pc
                })}
                src={item?.imageUrl || item?.fileName}
                alt=""
                key={`country_${index}`}
              />
            );
          })}
        {moreCountryMap > 0 && (
          <h6
            className={classNames("fw-400 h-24", {
              "pc h-w-32": pc
            })}>
            +{moreCountryMap}
          </h6>
        )}
      </CountryListStyled>
      {!hideText && (
        <TextLinked btnText={t("info.selectCountry")} onClick={onClick} />
      )}
    </FilterCountryStyled>
  );
}
export default memo(FilterCountry);
