/* eslint-disable no-unused-vars */
import { successAlert } from "components/alert";
import SvgIcons from "components/svgIcons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { scrapPost, unscrapPost } from "services/CommunityService";
import { ButtonStyled } from "./ButtonStyled";

export default function ScrapButton({ item, pd }) {
  const dispatch = useDispatch();
  const [scrap, setScrap] = useState(item?.scrapYn);
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  useEffect(() => {
    setScrap(item?.scrapYn);
  }, [item?.scrapYn]);

  const onClick = () => {
    if (token) {
      if (scrap === "Y" || scrap === 1) {
        onUnScrap();
      } else {
        onScrap();
      }
    } else dispatch(commonActions.onOpenModalRequireLogin());
  };

  const onUnScrap = async () => {
    try {
      const res = await unscrapPost({ idPost: item?.postId });
      if (res?.code === "200") {
        setScrap("N");
        successAlert(t("notice.removeScrap"));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onScrap = async () => {
    try {
      const res = await scrapPost({ idPost: item?.postId });
      if (res?.code === "200") {
        setScrap("Y");
        successAlert(t("notice.addScrap"));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ButtonStyled type="button" className="flex-start-center" pd={pd}>
      <SvgIcons
        onClick={onClick}
        name={
          scrap === "Y" || scrap === 1 ? "bookmarkIconFill" : "bookmarkIcon"
        }
      />
    </ButtonStyled>
  );
}
