/* eslint-disable no-unused-vars */
import DefaultAvatar from "assets/img/mobile/avatar_mobile.svg";
import classNames from "classnames";
import ScrapButton from "components/buttons/scrapBtn";
import SvgIcons from "components/svgIcons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { idRouter } from "routes/idRouter";
import { convertBigNumber, getTimePass } from "util/common";

export default function AnalysisItem({
  item,
  index,
  total,
  pbClass,
  onClose = () => {}
}) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const {
    postId,
    title,
    nickName,
    createTime,
    commentCnt,
    hashtagList,
    newNew
  } = item;

  return (
    <div
      type="button"
      className={classNames("flex-start-center gap-12 text-align-left", {
        "border-1px-bot-neutral-8": index !== +total - 1,
        "pb-16": index !== +total - 1 && pbClass === "pb-16",
        "pb-12": index !== +total - 1 && pbClass === "pb-12",
        "pt-16": index !== 0
      })}>
      {newNew === "Y" && (
        <div
          style={{
            width: "6px",
            height: "6px"
          }}
          className="border-rounded bg-sell"
        />
      )}
      <div className="flex-col gap-8 flex-1">
        <button
          type="button"
          className="text-align-left flex-col gap-8"
          onClick={() => {
            navigate(`/${idRouter.postDetail}?idPost=${postId}`, {
              replace: window.location.pathname === `/${idRouter.postDetail}`
            });
            onClose();
          }}>
          {!isEmpty(hashtagList) && isArray(hashtagList) && (
            <div className="flex-start-center col-gap-8 flex-wrap">
              {hashtagList.slice(0, 2).map((hastagItem, idx) => (
                <h5 key={`hastag_${index}_${idx}`} className="color-sell">
                  #{hastagItem?.hashtagText}
                </h5>
              ))}
            </div>
          )}
          <h4 className="text-ellipsis-2">{title}</h4>
        </button>

        <div className="flex-start-center gap-8">
          <h6 className="fw-500">{nickName}</h6>
          {createTime && (
            <p className="caption pt-1">
              {getTimePass(createTime, i18n.language)}
            </p>
          )}
          <button
            type="button"
            onClick={() => {
              navigate(`/${idRouter.postDetail}?idPost=${postId}`, {
                replace: window.location.pathname === `/${idRouter.postDetail}`
              });
              onClose();
            }}
            className="flex-start-center gap-4">
            <SvgIcons name="commentIcon" className="h-24px" />
            <p className="caption pt-1">{convertBigNumber(commentCnt)}</p>
          </button>
          <ScrapButton item={item} />
        </div>
      </div>
    </div>
  );
}
