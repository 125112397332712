export default function ValueRightSvg({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8V0L6 4.00909L0 8Z" fill={fillColor} />
    </svg>
  );
}
