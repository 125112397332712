/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AppBar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu
} from "@mui/material";
import { errorAPI, successAlert } from "components/alert";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import CenterConfirmModal from "components/centerConfirmModal";
import {
  BlockSvg,
  LogOutScg,
  NoteSvg,
  ReportSvg,
  ShareSvg,
  ViewProfileSvg
} from "components/icons";
import SvgIcons from "components/svgIcons";
import useResponsive from "hook/useResponsive";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { postActions } from "redux/reducers/postReducer";
import { idRouter } from "routes/idRouter";
import { deletePost, insertBlock } from "services/CommunityService";
import { createRoom, deleteRoom } from "services/NewChatService/ChatService";
import ModalReporting from "./ModalReporting";
import { Drawer, Puller } from "./styled";

function TextNotice({ onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClick = () => {
    try {
      dispatch(postActions.onCloseModalAction());
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      navigate(`/${idRouter.reportingList}`);
    }
  };
  return (
    <Trans i18nKey="info.blockSuccess" parent="h6" className="fw-500">
      <button
        type="button"
        className="color-buy fw-500 fs-13 font-1 lh-22"
        onClick={onClick}
      />
    </Trans>
  );
}

export default function ModalSelectActionUser() {
  const { isTabletOrMobile } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state?.post?.modalOpen);
  const anchorEl = useSelector((state) => state?.post?.anchorEl);
  const userData = useSelector((state) => state?.post?.memberData);
  const isThisUser = JSON.parse(localStorage.getItem("user"));
  const { t, i18n } = useTranslation();

  const listFunction =
    location.pathname.slice(1) === idRouter.chatting
      ? [
          {
            action: "view_profile",
            text: t("button.viewDetail"),
            icon: <ViewProfileSvg fillColor="#AFB1B8" />
          },
          {
            action: "send_note",
            text: t("button.sendNote"),
            icon: <NoteSvg fillColor="#AFB1B8" />
          },
          {
            action: "block",
            text: t("button.block"),
            icon: <BlockSvg />
          },
          {
            action: "report_user",
            text: t("button.reportUser"),
            icon: <ReportSvg />
          },
          {
            action: "leave_chat",
            text: t("button.leaveChat"),
            icon: <LogOutScg fillColor="#AFB1B8" />
          }
        ]
      : userData?.id === isThisUser?.id || userData?.userId === isThisUser?.id
      ? [
          {
            action: "view_profile",
            text: t("button.viewDetail"),
            icon: <ViewProfileSvg fillColor="#AFB1B8" />
          },
          {
            action: "share",
            text: t("button.share"),
            icon: <ShareSvg fillColor="#AFB1B8" />
          },
          {
            action: "edit",
            text: t("button.edit"),
            icon: <SvgIcons name="editSvg3" />
          },
          {
            action: "delete",
            text: t("button.delete"),
            icon: <SvgIcons name="trashBin" />
          }
        ]
      : [
          {
            action: "view_profile",
            text: t("button.viewDetail"),
            icon: <ViewProfileSvg fillColor="#AFB1B8" />
          },
          {
            action: "share",
            text: t("button.share"),
            icon: <ShareSvg fillColor="#AFB1B8" />
          },
          {
            action: "send_note",
            text: t("button.sendNote"),
            icon: <NoteSvg fillColor="#AFB1B8" />
          },
          {
            action: "chat",
            text: t("button.chatting"),
            icon: <SvgIcons name="ChatSvg" />
          },
          {
            action: "block",
            text: t("button.block"),
            icon: <BlockSvg />
          },
          {
            action: "report_user",
            text: t("button.reportUser"),
            icon: <ReportSvg />
          },
          {
            action: "report_post",
            text: t("button.reportPost"),
            icon: <ReportSvg />
          }
        ];

  const idUser = userData?.id || userData?.userId;
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenConfirmToBlock, setIsOpenConfirmToBlock] = useState(false);
  const [isOpenReportingModal, setIsOpenReportingModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const setLoading = (value) => dispatch(commonActions.setLoading(value));
  const flagRedux = useSelector((state) => state?.post?.flag);
  const idPost = userData?.linkPost?.split("idPost=")[1];
  const { roomId } = userData;
  const [action, setAction] = useState(null);
  const shareObject = useSelector((state) => state?.post?.sharedObject);
  const blockFlag = useSelector((state) => state?.post?.blockFlag);
  const isEditUrl = useSelector((state) => state?.post?.isEditUrl);
  const isCurrentDetail = location.pathname.includes(idRouter.discussion);
  const backUrl = useSelector((state) => state?.post?.previousPage);

  const deleteRoomMesage = async () => {
    try {
      setLoading(true);
      const res = await deleteRoom({ roomId });
      if (res.code === "200") {
        successAlert(t("notice.successAlertLeavingChat"));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      navigate(`/${idRouter.chatList}`);
    }
  };

  const onShare = async () => {
    try {
      const options = {
        url: `${process.env.REACT_APP_CLIENT_URI}/${shareObject?.url}`,
        title: shareObject?.title
      };
      if (window.flutter_inappwebview)
        window.flutter_inappwebview.callHandler(
          "Share",
          JSON.stringify(options)
        );
      else await navigator.share(options);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectItem = (e, item) => {
    switch (item?.action) {
      case "chat":
        getRoomId();
        dispatch(postActions.onCloseModalAction());
        break;
      case "send_note":
        navigate(`/${idRouter.writeANote}?userId=${idUser}`);
        dispatch(postActions.onCloseModalAction());
        break;
      case "block":
        setIsOpenBlockModal(true);
        break;
      case "report_post":
        setAction("report_post");
        setIsOpenReportingModal(isThisUser);
        dispatch(postActions.onCloseModalAction());
        break;
      case "view_profile":
        navigate(`/${idRouter.profile}?id=${idUser}`);
        dispatch(postActions.onCloseModalAction());
        break;
      case "edit":
        if (isTabletOrMobile)
          navigate(
            `/${idRouter.writePost}?detail=${idPost || userData?.postId}`
          );
        else dispatch(postActions.onEditPost(idPost || userData?.postId));
        dispatch(postActions.onCloseModalAction());
        break;
      case "report_user":
        setAction(null);
        setIsOpenReportingModal(isThisUser);
        dispatch(postActions.onCloseModalAction());
        break;
      case "delete":
        deletePostById();
        dispatch(postActions.onCloseModalAction());
        break;
      case "leave_chat":
        dispatch(postActions.onCloseModalAction());
        setOpenDeleteModal(true);
        break;
      case "share":
        onShare();
        dispatch(postActions.onCloseModalAction());
        break;
      default:
    }
  };

  const deletePostById = async () => {
    try {
      const res = await deletePost({
        id: idPost || userData?.idPost || userData?.postId
      });
      if (res?.code === "200") {
        successAlert(t("notice.postDelete"));
        dispatch(postActions.onBlockUser(!blockFlag));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(postActions.onChangeFlag(!flagRedux));
      dispatch(postActions.onBlockUser(!blockFlag));
      if (backUrl && isCurrentDetail) {
        const url = new URL(backUrl);
        navigate(`${url.pathname}${url.search}`);
      }
    }
  };

  const afterBlockModal = () => {
    onConfirmToBlock().then(() => {
      setIsOpenBlockModal(false);
      setIsOpenConfirmToBlock(true);
    });
  };
  const onClose = () => dispatch(postActions.onCloseModalAction());

  const onConfirmToBlock = async () => {
    try {
      const res = await insertBlock({
        blockUserId: idUser
      });
      if (res?.code === "200") {
        dispatch(postActions.onBlockUser(!blockFlag));
        dispatch(postActions.onChangeBlockType("post"));
      }
    } catch (e) {
      errorAPI(t("notice.blockFailed"));
      console.error(e);
    }
  };

  const getRoomId = async () => {
    try {
      const res = await createRoom({
        idUserReceive: idUser,
        content: ""
      });
      if (res?.code === "200")
        navigate(`/${idRouter.chatting}?roomId=${res?.data?.roomId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const contentComponent = (
    <>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none"
        }}
        color="inherit">
        {isTabletOrMobile && <Puller />}
      </AppBar>
      <List
        sx={{
          ".MuiButtonBase-root.MuiListItemButton-root": {
            padding: isTabletOrMobile ? "10px 24px" : "0px 32px 0px 16px",
            height: isTabletOrMobile ? "" : "40px"
          }
        }}>
        {listFunction.map((item, index) => (
          <ListItemButton
            className="flex-start-center"
            sx={{
              borderTop:
                index !== 0 ? "1px solid var(--neutral-color-8)" : "none"
            }}
            key={`list_function_${index}`}
            onClick={(e) => handleSelectItem(e, item)}>
            <ListItemIcon className="btn-36px-start mw-none">
              {item.icon}
            </ListItemIcon>
            <ListItemText
              className="mg-none"
              disableTypography
              primary={
                isTabletOrMobile ? (
                  <h4>{item?.text}</h4>
                ) : (
                  <h6 className="fw-500 color-neutral-1">{item?.text}</h6>
                )
              }
            />
          </ListItemButton>
        ))}
      </List>
      {isTabletOrMobile && (
        <ButtonFixedCancel onBack={onClose} text={t("button.cancel")} />
      )}
    </>
  );

  return (
    <>
      {isTabletOrMobile ? (
        <Drawer
          onOpen={() => {}}
          anchor="bottom"
          open={isOpen}
          onClose={onClose}
          sx={{
            display: isOpen ? "block" : "none",
            "& .MuiPaper-root": {
              borderRadius: "16px 16px 0 0"
            }
          }}>
          {contentComponent}
        </Drawer>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          slotProps={{
            paper: {
              sx: {
                width: 198
              }
            }
          }}>
          {[...contentComponent.props.children]}
        </Menu>
      )}
      <CenterConfirmModal
        open={isOpenBlockModal}
        btnText={t("button.block")}
        textNotice={
          <>
            <h4>
              {t("notice.blockWarning", {
                blockUser:
                  userData?.nickname || userData?.nickName || "This user"
              })}
            </h4>
            <h6 className="fw-500">{t("notice.blockWarningDetail")}</h6>
          </>
        }
        onCancel={() => setIsOpenBlockModal(undefined)}
        onSubmit={afterBlockModal}
      />

      <CenterConfirmModal
        type="afterAccept"
        open={isOpenConfirmToBlock}
        textNotice={
          <>
            <h4>
              {t("info.blockSuccessTitle", {
                blockedUser:
                  userData?.nickname || userData?.nickName || "This user"
              })}
            </h4>
            <TextNotice onClose={() => setIsOpenConfirmToBlock(false)} />
          </>
        }
        onSubmit={() => {
          setIsOpenConfirmToBlock(false);
          onClose();
        }}
      />

      <CenterConfirmModal
        btnText={t("button.exit")}
        open={openDeleteModal}
        onSubmit={() => {
          deleteRoomMesage();
          setOpenDeleteModal(false);
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
        textNotice={
          <>
            <h4>{t("notice.noticeExitChat")}</h4>
            <h6 className="fw-500">{t("notice.noticeExitChat1")}</h6>
          </>
        }
      />

      <ModalReporting
        isReportPost={action === "report_post"}
        isOpen={isOpenReportingModal}
        setIsOpen={setIsOpenReportingModal}
        submit={() => {
          setIsOpenReportingModal(false); // close reporting modal
          successAlert(t("notice.reportSuccess")); // show success alert
          // onClose(); // close action modal
        }}
      />
    </>
  );
}
