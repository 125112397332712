/* eslint-disable no-unused-expressions */
import useResponsive from "hook/useResponsive";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export function ButtonFixedCancel({
  onBack,
  text,
  color = "var(--neutral-color-10, #FFFFFF)"
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isTabletOrMobile } = useResponsive();

  const backPrevious = () => {
    navigate(location.key !== "default" ? -1 : "/");
  };

  const handleClick = onBack || backPrevious;

  return (
    <button
      type="button"
      className="full-width flex-centered pd-12-0 border-1px-top-neutral-7"
      style={{
        paddingBottom: isTabletOrMobile ? "18px" : "12px",
        backgroundColor: { color }
      }}
      onClick={handleClick}>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "700",
          padding: "8.5px 24px"
        }}>
        {text || t("button.close")}
      </div>
    </button>
  );
}
