const URL = Object.freeze({
  GET_LIST_KIND: "/api/user/calendar/kind", // 12-1,
  GET_LIST_INDICATOR: "/api/user/calendar/indicators", // 12-3
  GET_INDICATORS_DETAIL: "/api/user/calendar/indicators/detail", // 12-4
  GET_INDICATORS_CHART: "/api/user/calendar/indicators/chart", // 12-5
  GET_INDICATORS_HISTORY: "/api/user/calendar/indicators/history", // 12-6
  GET_CALENDAR_DISCLOSURE: "/api/user/calendar/disclosure", // 12-7
  GET_LIST_EARNING: "/api/user/calendar/earning", // 12-9
  GET_LIST_DIVIDEND: "/api/user/calendar/dividend", // 12-10
  GET_LIST_SPLIT: "/api/user/calendar/split", // 12-11
  GET_CLASS_OF_THEMA: "/api/user/calendar/thema/class", // 12-12,
  GET_LIST_THEMA: "/api/user/calendar/thema/schedule", // 12-13
  GET_THEMA_DETAIL: "/api/user/calendar/thema/schedule/detail/", // 12-14
  GET_LIST_HOLIDAY: "/api/user/calendar/holiday", // 12-15
  GET_LIST_IPO: "/api/user/calendar/ipo", // 12-17
  GET_LIST_MATURITY: "/api/user/calendar/maturity", // 12-empty
  CHECK_DATA_CALENDAR_INDICATOR: "/api/user/calendar/indicators/daily-event", // 12-3-1
  CHECK_DATA_CALENDAR_EARNING: "/api/user/calendar/earning/daily-event", // 12-9-1
  CHECK_DATA_CALENDAR_DIVIDEND: "/api/user/calendar/dividend/daily-event", // 12-10-1
  CHECK_DATA_CALENDAR_SPLIT: "/api/user/calendar/split/daily-event", // 12-11-1
  CHECK_DATA_CALENDAR_THEMA: "/api/user/calendar/thema/schedule/daily-event", // 12-13-1
  CHECK_DATA_CALENDAR_HOLIDAY: "/api/user/calendar/holiday/daily-event", // 12-15-1
  CHECK_DATA_CALENDAR_IPO: "/api/user/calendar/ipo/daily-event", // 12-17-1

  // PC api
  GET_THEMA_CALENDAR_DETAIL: "/api/user/calendar/thema/schedule/detail/" // 12-14
});
export default URL;
