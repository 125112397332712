import styled from "styled-components";

export const EconomicCalendarStyled = styled.div`
  display: flex;
  flex-direction: column;
  .scroll {
    flex: 1;
    overflow: auto;
    padding-top: 32px;
  }
  /* .MuiTabs-flexContainer {
    padding-top: 56px;
  } */
  .MuiTabPanel-root {
    padding: 0;
  }
  .mbsc-calendar-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mbsc-calendar-button-prev,
  .mbsc-calendar-button-next {
    display: none;
  }
`;
export const EconomicScheduleLists = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .wishListSlider {
    margin: 0;
  }
  .economic-schedule-lists {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 0;
    .economic-schedule-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #f4f4f4;
      .economic-schedule-item-flag {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
      }
      h4,
      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sell {
        color: #1554f6;
      }
      .buy {
        color: var(--primary-color-2, #f23645);
      }
      .natural {
        color: var(--primary-color-3, #45495a);
      }
    }
    & {
      > div:first-of-type {
        padding-top: 0;
        border-top: none;
      }
    }
    & {
      > div:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  .calendar-and-list {
    height: calc(100vh - 201px);
    & > div {
      height: fit-content;
      max-height: 100%;
      border: 1px solid #f4f4f4;
      padding: 16px 12px 0 12px;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`;
