/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { Modal } from "@mui/material";
import { successAlert, warningAlert } from "components/alert";
import { CheckboxUncheckedCircleSvg, XSvg } from "components/icons";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import TextAreaWithMaxlength from "components/textAreaWithMaxlength";
import useResponsive from "hook/useResponsive";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { postActions } from "redux/reducers/postReducer";
import {
  reportComment,
  reportPost,
  reportUser
} from "services/CommunityService";
import { ModalStyled, SelfScrollDiv } from "views/HeatMap/styled";

export default function ModalReporting({
  isOpen,
  setIsOpen,
  isReportPost,
  isReportingComment
}) {
  const [selectedReason, setSelectedReason] = useState(null);
  const [text, setText] = useState("");
  const [length, setLength] = useState(0);
  const userData = useSelector((state) => state?.post?.memberData);
  const commentUserData = useSelector((state) => state?.post?.commentData);
  const idPost = userData?.linkPost?.split("idPost=")[1];
  const idComment = commentUserData?.commentId;
  const { t, i18n } = useTranslation();
  const [body, setBody] = useState({
    reason: "",
    title: "",
    detail: "",
    boardId: "",
    userReportId: "",
    groupUser: "SNS"
  });
  const { isTabletOrMobile } = useResponsive();
  const reportReason = useMemo(
    () => [
      { title: t("button.reportReasons.spam"), code: 1 },
      { title: t("button.reportReasons.nudeOrSexual"), code: 2 },
      { title: t("button.reportReasons.fakeNews"), code: 3 },
      { title: t("button.reportReasons.profanity"), code: 4 },
      { title: t("button.reportReasons.personalHarassment"), code: 5 },
      { title: t("button.reportReasons.other"), code: 6 }
    ],
    [i18n.language]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  useEffect(() => {
    setBody({ ...body, userReportId: userData?.userId || userData?.id });
  }, [userData]);

  useEffect(() => {
    setBody({ ...body, detail: text });
  }, [text]);

  const onSelect = (item) => {
    setSelectedReason(item?.code);
    setBody({ ...body, reason: item?.code });
  };

  const onClose = () => {
    setSelectedReason(undefined);
    setIsOpen(false);
  };

  const onSubmit = () => {
    if (selectedReason) {
      if (isReportingComment) {
        reportingComment();
      } else {
        if (isReportPost) reportingPost();
        else reportingUser();
        setText("");
        setLength(0);
      }
    } else {
      warningAlert(t("notice.reportFormRequired"));
    }
  };

  const reportingComment = async () => {
    try {
      setLoading(true);
      const res = await reportComment({ idComment }, body);
      if (res?.code === "200") {
        dispatch(postActions.onReportPost(idComment));
        successAlert(t("notice.reportCommentSuccess"));
        setSelectedReason(undefined);
        onClose();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const reportingPost = async () => {
    try {
      setLoading(true);
      const res = await reportPost({ idPost }, body);
      if (res?.code === "200") {
        dispatch(postActions.onReportPost(idPost));
        successAlert(t("notice.reportPostSuccess"));
        setSelectedReason(undefined);
        onClose();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const reportingUser = async () => {
    try {
      setLoading(true);
      const res = await reportUser(body);
      if (res?.code === "200") {
        dispatch(postActions.onReportPost(idPost));
        successAlert(t("notice.reportSuccess"));
        setSelectedReason(undefined);
        onClose();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <ModalStyled
        modalType={!isTabletOrMobile ? "pc-small" : ""}
        className="hidden-scrollbar">
        {isTabletOrMobile ? (
          <Header
            headerTitle={
              <h1>
                {isReportPost ? t("button.reportPost") : t("button.reportUser")}
              </h1>
            }
            logo={false}
            borderBottom={false}
            onBack={onClose}
            customAction={
              <button type="button" onClick={onClose}>
                <XSvg fillColor="#11131A" />
              </button>
            }
          />
        ) : (
          <div className="modal-header">
            <button
              className="close-modal__button"
              type="button"
              onClick={onClose}>
              <SvgIcons name="clearIcon" />
            </button>
          </div>
        )}
        <SelfScrollDiv
          className={classNames("hidden-scrollbar gap-24", {
            "pt-32": !isTabletOrMobile
          })}>
          {!isTabletOrMobile && (
            <h2 className="pd-0-20">
              {isReportPost ? t("button.reportPost") : t("button.reportUser")}
            </h2>
          )}
          <div className="flex-col gap-12">
            <div className="flex-start-center gap-16 pd-0-20">
              <h5 className="color-info fw-500 no-wrap min-width-40">
                {t("button.reporter")}
              </h5>
              <input
                disabled
                type="text"
                style={{
                  width: "100%"
                }}
                className="input-field"
                placeholder={userData?.nickName || userData?.nickname} // name of person who is being reported
              />
            </div>
            {isReportPost && (
              <div className="flex-start-center gap-16 pd-0-20 w-100vw">
                <h5 className="color-info fw-500 no-wrap break-all min-width-40">
                  {t("info.content")}
                </h5>
                <div className="flex-col gap-8">
                  <h4
                    className="caption fw-500 caption-text text-ellipsis-1 break-all full-width pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html:
                        userData?.detail ||
                        userData?.title ||
                        userData?.postContents
                    }}
                  />
                  <h4 className="caption">{userData?.createTime}</h4>
                </div>
              </div>
            )}
          </div>
          <div className="flex-col gap-8 pd-0-20">
            {reportReason.map((item, index) => (
              <div
                key={`reason_report_${index}`}
                className="flex-start-center gap-8">
                <button
                  type="button"
                  className="pd-4-8-4-0 block-32px"
                  onClick={() => onSelect(item)}>
                  {selectedReason === item?.code ? (
                    <CheckboxUncheckedCircleSvg fillColor="var(--color-buy, #F20013)" />
                  ) : (
                    <SvgIcons name="uncheckCircleSvg" />
                  )}
                </button>
                <h5 className="fw-500">{item?.title}</h5>
              </div>
            ))}
          </div>
          {selectedReason === 6 && (
            <div className="pd-0-20">
              <TextAreaWithMaxlength
                setText={setText}
                text={text}
                setLength={setLength}
                length={length}
              />
            </div>
          )}
          <p className="pd-0-20 caption">{t("notice.reportWarning")}</p>
        </SelfScrollDiv>
        <div
          className={classNames("gap-8 pt-12 pb-12 full-width", {
            "border-1px-top-neutral-7": isTabletOrMobile
          })}
          style={{
            textAlign: "right"
          }}>
          <button
            type="button"
            onClick={onClose}
            className="cancel-btn fs-14 fw-700">
            {t("button.cancel")}
          </button>
          <button
            type="button"
            className="report-btn mr-24 ml-8 fs-14 fw-700"
            onClick={onSubmit}>
            {t("button.reportConfirm")}
          </button>
        </div>
      </ModalStyled>
    </Modal>
  );
}
