export default function ArrowLeft({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2998 12C4.2998 11.6134 4.61321 11.3 4.9998 11.3H18.9998C19.3864 11.3 19.6998 11.6134 19.6998 12C19.6998 12.3866 19.3864 12.7 18.9998 12.7H4.9998C4.61321 12.7 4.2998 12.3866 4.2998 12Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4948 4.50507C12.7681 4.77844 12.7681 5.22166 12.4948 5.49502L5.98975 12L12.4948 18.5051C12.7681 18.7784 12.7681 19.2217 12.4948 19.495C12.2214 19.7684 11.7782 19.7684 11.5048 19.495L4.50483 12.495C4.23146 12.2217 4.23146 11.7784 4.50483 11.5051L11.5048 4.50507C11.7782 4.23171 12.2214 4.23171 12.4948 4.50507Z"
        fill={fillColor}
      />
    </svg>
  );
}
