/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import Modal from "@mui/material/Modal";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import CountryItem from "components/countryItem";
import EmptyNotice from "components/emptyNotice";
import Header from "components/subHeaderMobile";
import { SearchBar } from "components/textField";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import {
  getListCountry112,
  getListCountryStock,
  getListCountryTheme
} from "services/HeatMapService/HeatMapService";
import {
  getCurrencyList,
  getExchangeList,
  getExchangeSymbolForex,
  getStockIndices,
  getStockIndicesGroup
} from "services/MarketService/MarketService";
import {
  ModalStyled,
  SelfScrollDiv,
  SelfScrollRestrictHeight
} from "views/HeatMap/styled";
import WorldSvg from "../../assets/img/mobile/world.svg";
import { SubCategory48px } from "./styled";

let timeout;

const world = {
  mkCountryUid: 0,
  labelEng: "World",
  labelKor: "세계 주요 지수",
  imageUrl: WorldSvg,
  countryCodeShort: ""
};
function ModalSelectNation({
  isOpen,
  nation,
  setNation,
  closeModal,
  forPage,
  setIsShowFilter,
  selCurrency
}) {
  const dispatch = useDispatch();
  const [listSearched, setListSearched] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setCurrentPage(1);
  }, [searchKeywords]);

  useEffect(() => {
    if (isOpen) getListCountry();
  }, [searchKeywords, isOpen, currentPage]);

  const handleScroll = (e) => {
    const targetDiv = e.target; // The scrollable div
    const scrollHeight = targetDiv.scrollHeight;
    const currentHeight = targetDiv.scrollTop + targetDiv.clientHeight;
    if (currentHeight + 1 >= scrollHeight) {
      setCurrentPage(currentPage + 1);
    }
  };

  const setIsLoading = (value) => dispatch(commonActions.setLoading(value));
  const getListCountry = async () => {
    try {
      setIsLoading(true);
      let res = null;
      switch (forPage) {
        case "Theme":
          res = await getListCountryTheme(
            searchKeywords ? { keyword: searchKeywords } : ""
          );
          if (res?.code === "200") {
            setListSearched(res?.data || []);
          }
          break;
        case "Stock":
          res = await getListCountryStock(
            searchKeywords ? { keyword: searchKeywords } : ""
          );
          if (res?.code === "200") {
            setListSearched(res?.data || []);
          }
          break;
        case "CalculatorNationCurrency":
          res = await getCurrencyList({
            keyword: searchKeywords || "",
            selCurrency,
            currentPage,
            pageSize: 20
          });
          if (
            res?.code === "200" &&
            currentPage <= res?.data?.pagination?.totalPages
          ) {
            const myDiv = document.getElementById("myDiv");
            myDiv.addEventListener("scroll", handleScroll);
            setListSearched(
              currentPage === 1
                ? res?.data?.list
                : [...listSearched, ...res?.data?.list]
            );
          }
          break;
        case "CalculatorNationTarget":
          res = await getCurrencyList({
            keyword: searchKeywords || "",
            selCurrency,
            currentPage,
            pageSize: 20
          });
          if (res?.code === "200") {
            const myDiv = document.getElementById("myDiv");
            myDiv.addEventListener("scroll", handleScroll);
            setListSearched(
              currentPage === 1
                ? res?.data?.list
                : [...listSearched, ...res?.data?.list]
            );
          }
          break;
        case "ExchangeRate":
          res = await getExchangeList(
            searchKeywords ? { keyword: searchKeywords } : ""
          );
          if (res?.code === "200") {
            setListSearched(res?.data || []);
          }
          break;

        case "exchangeRateSymbol":
          res = await getExchangeSymbolForex(
            searchKeywords ? { keyword: searchKeywords } : ""
          );
          if (res?.code === "200") {
            setListSearched(res?.data || []);
          }
          break;

        case "MarketStock":
          res = await getStockIndicesGroup({
            language: i18n.language === "ko" ? "KO" : "EN",
            keyword: searchKeywords || ""
          });
          if (res?.code === "200") {
            setListSearched(res?.data || []);
          }
          break;
        case "MarketStockIndustry":
          res = await getStockIndices();
          if (res?.code === "200") {
            setListSearched(res?.data?.list || []);
          }
          break;
        default:
          res = await getListCountry112(
            searchKeywords
              ? {
                  languageCode: "KO",
                  keyword: searchKeywords
                }
              : {
                  languageCode: "KO"
                }
          );
          if (res?.code === "200") {
            setListSearched(res?.data || []);
          }
          break;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearch = async (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => setSearchKeywords(e?.toUpperCase()), 300);
  };
  const handleClickItemNation = (item) => {
    setNation(item);
    setIsShowFilter(true);
    setSearchKeywords(null);
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header logo={false} borderBottom={false} onBack={closeModal} />
        <SelfScrollDiv className="hidden-scrollbar">
          <div style={{ padding: "8px 20px" }}>
            <SearchBar onInputChange={handleSearch} />
          </div>
          {/* splitmodal when call real api, not use forPage, maybe. */}
          {forPage === "Exchange" && (
            <SelfScrollDiv style={{ paddingTop: "8px" }}>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                <>
                  <SubCategory48px>
                    <h5 className="fw-500">{t("info.majorCurrencies")}</h5>
                  </SubCategory48px>
                  <SelfScrollDiv>
                    {isArray(listSearched) &&
                      !isEmpty(listSearched) &&
                      listSearched.map((item, index) => (
                        <CountryItem
                          item={item}
                          key={index}
                          active={nation?.country === item?.country}
                          onClick={() => handleClickItemNation(item)}
                        />
                      ))}
                  </SelfScrollDiv>
                </>
              )}
            </SelfScrollDiv>
          )}
          {(forPage === "ExchangeRate" || forPage === "exchangeRateSymbol") && (
            <SelfScrollDiv style={{ paddingTop: "8px" }}>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                <>
                  <SubCategory48px>{t("info.majorCurrencies")}</SubCategory48px>
                  <SelfScrollDiv>
                    {!isEmpty(listSearched) &&
                      Object.keys(listSearched).map((key) => {
                        const item = {
                          ...listSearched[key],
                          countryLabelKor: listSearched[key]?.baseCurrency
                        };
                        return (
                          <CountryItem
                            item={item}
                            key={key}
                            active={nation?.baseCurrency === item?.baseCurrency}
                            onClick={() => handleClickItemNation(item)}
                          />
                        );
                      })}
                  </SelfScrollDiv>
                </>
              )}
            </SelfScrollDiv>
          )}
          {forPage === "CalculatorNationCurrency" && (
            <SelfScrollDiv style={{ paddingTop: "8px" }}>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                <>
                  <SubCategory48px>{t("info.majorCurrencies")}</SubCategory48px>
                  <SelfScrollDiv id="myDiv">
                    {isArray(listSearched) &&
                      !isEmpty(listSearched) &&
                      listSearched.map((item, index) => (
                        <CountryItem
                          item={item}
                          key={index}
                          active={nation?.currency === item?.currency}
                          onClick={() => handleClickItemNation(item)}
                        />
                      ))}
                  </SelfScrollDiv>
                </>
              )}
            </SelfScrollDiv>
          )}
          {forPage === "CalculatorNationTarget" && (
            <SelfScrollDiv style={{ paddingTop: "8px" }}>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                <>
                  <SubCategory48px>{t("info.majorCurrencies")}</SubCategory48px>
                  <SelfScrollDiv id="myDiv">
                    {isArray(listSearched) &&
                      !isEmpty(listSearched) &&
                      listSearched.map((item, index) => (
                        <CountryItem
                          item={item}
                          key={index}
                          active={nation?.currency === item?.currency}
                          onClick={() => handleClickItemNation(item)}
                        />
                      ))}
                  </SelfScrollDiv>
                </>
              )}
            </SelfScrollDiv>
          )}
          {(forPage === "ETF" || forPage === "marjorIndicies") && (
            <SelfScrollDiv style={{ paddingTop: "8px" }}>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : !searchKeywords ? (
                <>
                  {forPage === "marjorIndicies" && (
                    <CountryItem
                      item={world}
                      key="world"
                      active={
                        nation?.countryCodeShort === world?.countryCodeShort
                      }
                      onClick={() => handleClickItemNation(world)}
                    />
                  )}
                  <SubCategory48px>
                    <h5 className="fw-500">{t("info.popularMarket")}</h5>
                  </SubCategory48px>
                  <div>
                    {!isEmpty(listSearched?.list) &&
                      listSearched?.list
                        .filter((item) => item.mainYn === "Y")
                        .map((item, index) => (
                          <CountryItem
                            item={item}
                            key={index}
                            active={
                              nation?.countryCodeShort ===
                              item?.countryCodeShort
                            }
                            onClick={() => handleClickItemNation(item)}
                          />
                        ))}
                  </div>
                  <SubCategory48px className="mt-24">
                    <h5 className="fw-500">{t("info.allMarket")}</h5>
                  </SubCategory48px>
                  <SelfScrollRestrictHeight>
                    {isArray(listSearched?.list) &&
                      !isEmpty(listSearched?.list) &&
                      listSearched?.list.map((item, index) => (
                        <CountryItem
                          item={item}
                          key={index}
                          active={
                            nation?.countryCodeShort === item?.countryCodeShort
                          }
                          onClick={() => handleClickItemNation(item)}
                        />
                      ))}
                  </SelfScrollRestrictHeight>
                </>
              ) : (
                <SelfScrollDiv>
                  {isArray(listSearched?.list) &&
                    !isEmpty(listSearched?.list) &&
                    listSearched?.list.map((item, index) => (
                      <CountryItem
                        item={item}
                        key={index}
                        active={
                          nation?.countryCodeShort === item?.countryCodeShort
                        }
                        onClick={() => handleClickItemNation(item)}
                      />
                    ))}
                </SelfScrollDiv>
              )}
            </SelfScrollDiv>
          )}
          {(forPage === "Theme" || forPage === "Stock") && (
            <SelfScrollDiv>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                isArray(listSearched) &&
                !isEmpty(listSearched) &&
                listSearched.map((item, index) => (
                  <CountryItem
                    item={item}
                    key={index}
                    active={nation?.country === item?.country}
                    onClick={() => handleClickItemNation(item)}
                  />
                ))
              )}
            </SelfScrollDiv>
          )}
          {forPage === "MarketStock" && (
            <SelfScrollDiv style={{ paddingTop: "8px" }}>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                <>
                  <SubCategory48px>
                    <h5 className="fw-500">{t("info.popularMarket")}</h5>
                  </SubCategory48px>
                  <div className="flex-col">
                    {isArray(listSearched) &&
                      !isEmpty(listSearched) &&
                      listSearched
                        ?.filter((item) => item?.meta?.mainYn === "Y")
                        ?.map((item, index) => (
                          <>
                            <div className="pd-9-20">
                              <p className="caption-text lh-22 fs-13">
                                {item.meta.countryLabelKor}
                              </p>
                            </div>
                            {item?.list?.map((countryItem, idx) => (
                              <CountryItem
                                item={{
                                  ...countryItem,
                                  imageUrl: countryItem?.countryFileName
                                }}
                                key={idx}
                                active={
                                  nation?.mkIndicesMasterUid ===
                                  countryItem.mkIndicesMasterUid
                                }
                                noneBorder={idx === item.list.length - 1}
                                onClick={() =>
                                  handleClickItemNation(countryItem)
                                }
                              />
                            ))}
                          </>
                        ))}
                  </div>
                  <SubCategory48px className="mt-24">
                    <h5 className="fw-500">{t("info.allMarket")}</h5>
                  </SubCategory48px>
                  <div className="flex-col">
                    {isArray(listSearched) &&
                      !isEmpty(listSearched) &&
                      listSearched?.map((item, index) => (
                        <>
                          <div className="pd-9-20">
                            <p className="caption-text lh-22 fs-13">
                              {item.meta.countryLabelKor}
                            </p>
                          </div>
                          {item?.list?.map((countryItem, idx) => (
                            <CountryItem
                              item={{
                                ...countryItem,
                                imageUrl: countryItem?.countryFileName
                              }}
                              key={idx}
                              active={
                                nation?.mkIndicesMasterUid ===
                                countryItem.mkIndicesMasterUid
                              }
                              noneBorder={idx === item.list.length - 1}
                              onClick={() => handleClickItemNation(countryItem)}
                            />
                          ))}
                        </>
                      ))}
                  </div>
                </>
              )}
            </SelfScrollDiv>
          )}
          {forPage === "MarketStockIndustry" && (
            <SelfScrollDiv>
              {listSearched === "no data" ? (
                <EmptyNotice noticeText={t("notice.noMatchResult")} />
              ) : (
                isArray(listSearched) &&
                !isEmpty(listSearched) &&
                listSearched.map((item, index) => (
                  <CountryItem
                    item={item}
                    key={index}
                    active={
                      nation?.mkIndicesMasterUid === item.mkIndicesMasterUid
                    }
                    onClick={() => handleClickItemNation(item)}
                  />
                ))
              )}
            </SelfScrollDiv>
          )}
          <ButtonFixedCancel onBack={closeModal} />
        </SelfScrollDiv>
      </ModalStyled>
    </Modal>
  );
}

export default ModalSelectNation;
