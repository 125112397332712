/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";
import URL from "./url";

class UserProfileService {
  getUserCountNotice() {
    const url = URL.GET_USER_COUNT_NOTICE;
    return axiosClient.get(url);
  }

  getUserDetail() {
    const url = URL.GET_USER_DETAIL;
    return axiosClient.get(url);
  }

  changeProfile(body) {
    const url = URL.CHANGE_PROFILE;
    return axiosClient.put(url, body);
  }

  updateUser(body) {
    const url = URL.UPDATE_USER;
    return axiosClient.put(url, body);
  }

  checkNickName(nickname) {
    const url = URL.CHECK_NICK_NAME(nickname);
    return axiosClient.get(url);
  }

  getAuthorProfile(id) {
    const query = `?idUser=${id}`;
    const url = URL.GET_AUTHOR_PROFILE + query;
    return axiosClient.get(url);
  }

  updatePointUser(body) {
    const url = URL.UPDATE_POINT;
    return axiosClient.post(url, body);
  }

  saveAvatar(link) {
    const body = { search: link };
    const url = URL.SAVE_AVATAR2;
    return axiosClient.post(url, body);
  }

  getListAvatar() {
    const url = URL.GET_LIST_AVATAR;
    return axiosClient.get(url);
  }
}

export default new UserProfileService();
