export default function index({
  fillColor = "#11131A",
  width = "24",
  height = "24"
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2998 7.4998C6.2998 4.33543 8.83543 1.7998 11.9998 1.7998C15.1642 1.7998 17.6998 4.33543 17.6998 7.4998C17.6998 10.6642 15.1642 13.1998 11.9998 13.1998C8.83543 13.1998 6.2998 10.6642 6.2998 7.4998ZM11.9998 3.1998C9.60863 3.1998 7.6998 5.10863 7.6998 7.4998C7.6998 9.89098 9.60863 11.7998 11.9998 11.7998C14.391 11.7998 16.2998 9.89098 16.2998 7.4998C16.2998 5.10863 14.391 3.1998 11.9998 3.1998Z"
        fill={fillColor}
      />
      <path
        d="M4.19856 17.6292C3.8037 18.0251 3.6998 18.3212 3.6998 18.4844C3.6998 18.5794 3.75984 18.7896 4.13719 19.088C4.50412 19.3783 5.07831 19.673 5.84993 19.9351C7.38594 20.4568 9.55677 20.7998 11.9998 20.7998C12.0639 20.7998 12.1279 20.7996 12.1917 20.7992C12.459 21.3051 12.7966 21.7682 13.1914 22.1755C12.8008 22.1916 12.4031 22.1998 11.9998 22.1998C9.44284 22.1998 7.11367 21.8428 5.39968 21.2607C4.5463 20.9708 3.80799 20.6127 3.26867 20.1861C2.73977 19.7677 2.2998 19.1972 2.2998 18.4844C2.2998 17.7861 2.69591 17.1533 3.2073 16.6406C3.73214 16.1144 4.46105 15.6285 5.32165 15.215C7.04623 14.3864 9.40053 13.7998 11.9998 13.7998C12.3982 13.7998 12.7908 13.8136 13.1763 13.8399C12.7879 14.2435 12.4554 14.7013 12.1915 15.201C12.1278 15.2002 12.0639 15.1998 11.9998 15.1998C9.59908 15.1998 7.45338 15.744 5.92796 16.4769C5.16356 16.8442 4.57997 17.2468 4.19856 17.6292Z"
        fill={fillColor}
      />
      <path
        d="M21.135 16.308C21.3992 16.0438 21.3992 15.6155 21.135 15.3513C20.8709 15.0872 20.4425 15.0872 20.1784 15.3513L16.316 19.2137L14.8213 17.719C14.5571 17.4548 14.1288 17.4548 13.8646 17.719C13.6005 17.9832 13.6005 18.4115 13.8646 18.6757L15.8377 20.6487C16.1019 20.9129 16.5302 20.9129 16.7944 20.6487L21.135 16.308Z"
        fill={fillColor}
      />
    </svg>
  );
}
