/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getListCountry112 = (params) =>
  newAxiosInstance.get(URL.GET_COUNTRY_LIST + qs.stringify(params, true));
export const getListCountryTheme = (params) =>
  newAxiosInstance.get(URL.GET_COUNTRY_LIST_THEME + qs.stringify(params, true));
export const getListCountryStock = (params) =>
  newAxiosInstance.get(URL.GET_COUNTRY_LIST_STOCK + qs.stringify(params, true));
export const getChartHeatMapIndices = (params) =>
  newAxiosInstance.get(URL.GET_CHART_INDICECS + qs.stringify(params, true));
export const getChartHeatMapCrypto = (params) =>
  newAxiosInstance.get(URL.GET_CHART_CRYPTO + qs.stringify(params, true));
export const getChartHeatMapEtf = (params) =>
  newAxiosInstance.get(URL.GET_CHART_ETF + qs.stringify(params, true));
export const getChartHeatMapForex = (params) =>
  newAxiosInstance.get(URL.GET_CHART_FOREX + qs.stringify(params, true));
export const getChartHeatMapFutures = (params) =>
  newAxiosInstance.get(URL.GET_CHART_FUTURES + qs.stringify(params, true));
export const getTop20Themed = (params) =>
  newAxiosInstance.get(URL.GET_TOP_20_THEMED + qs.stringify(params, true));

export const getListTheme = (params) =>
  newAxiosInstance.get(URL.GET_THEME + qs.stringify(params, true));

export const getThemeStockList = (params) =>
  newAxiosInstance.get(URL.GET_THEM_STOCKLIST + qs.stringify(params, true));

export const getStockList = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_STOCKLIST + qs.stringify(params, true));

export const getSelectorList = (params) =>
  newAxiosInstance.get(URL.GET_SELECTOR_LIST + qs.stringify(params, true));

export const getListStock = (params) =>
  newAxiosInstance.get(URL.GET_LIST_STOCK + qs.stringify(params, true));

export const getThemaInterested = () =>
  newAxiosInstance.get(URL.GET_THEMA_LIST);

export const removeThema = (id) =>
  newAxiosInstance.delete(`${URL.DELETE_THEMA}${id}`);

// PC api
export const getHeatMapStock = (params) =>
  newAxiosInstance.get(URL.GET_HEATMAP_STOCK + qs.stringify(params, true));

export const getHeatmapCountry = (params) =>
  newAxiosInstance.get(URL.GET_HEATMAP_COUNTRY + qs.stringify(params, true));

export const getThemaDetail = (params) =>
  newAxiosInstance.get(URL.GET_THEMA_DETAIL + qs.stringify(params, true));

export const getCalendarThemaList = (params) =>
  newAxiosInstance.get(URL.GET_THEMA_CALENDAR + qs.stringify(params, true));

export const getThemaDetailOverview = (params) =>
  newAxiosInstance.get(URL.GET_THEMA_OVERVIEW + qs.stringify(params, true));

export const getThemaDetailSumary = (params) =>
  newAxiosInstance.get(URL.GET_THEMA_SUMARY + qs.stringify(params, true));

export const getTop100Thema = (params) =>
  newAxiosInstance.get(URL.GET_TOP100_THEMA + qs.stringify(params, true));

export const getPopularSearchThema = (params) => {
  newAxiosInstance.get(
    URL.GET_POPULAR_SEARCH_THEMA + qs.stringify(params, true)
  );
};

export const getRecentSearchThema = (params) => {
  newAxiosInstance.get(
    URL.GET_RECENT_SEARCH_THEMA + qs.stringify(params, true)
  );
};

export const addThema = (body) => newAxiosInstance.post(URL.ADD_THEMA, body);
