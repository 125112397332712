import { CheckboxCheckedBlueSvg } from "components/icons";

/* eslint-disable arrow-body-style */
export default function ImportantSelectItem({
  star,
  totalStar,
  text,
  onClick,
  active
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 20px",
        borderBottom: "1px solid var(--neutral-color-8, #F4F4F4)",
        backgroundColor: active ? "#5E86F40F" : ""
      }}>
      <div style={{ display: "flex", gap: "8px" }}>
        {[...Array(totalStar)].map((_, index) => {
          return (
            <h5
              style={{ color: index < star ? "#9497A0" : "#E4E5E7" }}
              key={`${text}_star_${index}`}>
              ★
            </h5>
          );
        })}
        <h4>{text}</h4>
      </div>
      <div
        style={{
          padding: "8px 0 8px 16px",
          width: "40px",
          height: "40px"
        }}>
        {active ? <CheckboxCheckedBlueSvg /> : " "}
      </div>
    </button>
  );
}
