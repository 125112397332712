import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openNewPortfolio: false,
  interestAdding: undefined,
  openUpdatePortfolio: false,
  openModalMemberShip: false,
  openModalExchangeCalculator: false,
  openModalPrivacy: false,
  loading: false,
  loadingPC: false,
  loadingPCGrid: {
    loadingRealTime: false,
    loadingCapacityValue: false,
    loadingIndicator: false
  },
  openAddOver10Portfolio: false,
  openModalRequireLogin: false,
  tabIndex: "0",
  activeTab: 0,
  tabIndexTrading: 0,
  tabIndexEconomic: 0,
  tabIndexForex: 0,
  tabIndexCustomerService: 0,
  tabIndexIntegratedSearch: 0,
  openModalComprehensiveSearch: false,
  submitLogout: false,
  tabIndexCommunity: "hot",
  tabIndexCommunityLike: "portfolio",
  tabIndexCommunityHot: "THM",
  tabIndexCommunityLikeFollowing: "recent",
  mkMyInterestMasterUid: undefined,
  prevPath: undefined,
  prevPath2: undefined, // level 2 to save prePath, if need more, maybe we should try history as array or something,
  fullScreen: false,
  openDrawer: null
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    onOpenNewPortfolio: (state) => {
      state.openNewPortfolio = true;
    },
    onCloseNewPortfolio: (state) => {
      state.openNewPortfolio = false;
    },
    onAddInterest: (state, action) => {
      state.interestAdding = action?.payload;
    },
    addMkMyInterestMasterUid: (state, action) => {
      state.mkMyInterestMasterUid = action?.payload;
    },
    onClosePortfolio: (state) => {
      state.interestAdding = undefined;
    },
    onOpenUpdatePortfolio: (state) => {
      state.openUpdatePortfolio = true;
    },
    onCloseUpdatePortfolio: (state) => {
      state.openUpdatePortfolio = false;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setLoadingPC: (state, action) => {
      state.loadingPC = action?.payload;
    },
    setLoadingPCGrid: (state, action) => {
      const { loadingCapacityValue, loadingIndicator, loadingRealTime } =
        action.payload;
      if (loadingCapacityValue !== undefined) {
        state.loadingPCGrid.loadingCapacityValue = loadingCapacityValue;
      }
      if (loadingIndicator !== undefined) {
        state.loadingPCGrid.loadingIndicator = loadingIndicator;
      }
      if (loadingRealTime !== undefined) {
        state.loadingPCGrid.loadingRealTime = loadingRealTime;
      }
    },
    onOpenAddOver10Portfolio: (state) => {
      state.openAddOver10Portfolio = true;
    },
    onCloseAddOver10Portfolio: (state) => {
      state.openAddOver10Portfolio = false;
    },
    onOpenModalRequireLogin: (state) => {
      state.openModalRequireLogin = true;
    },
    onCloseModalRequireLogin: (state) => {
      state.openModalRequireLogin = false;
    },
    onOpenModalExchangeCalculator: (state) => {
      state.openModalExchangeCalculator = true;
    },
    onCloseModalExchangeCalculator: (state) => {
      state.openModalExchangeCalculator = false;
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action?.payload;
    },
    setTabIntegratedSearch: (state, action) => {
      state.tabIndexIntegratedSearch = action?.payload;
    },
    setTabIndexCustomerService: (state, action) => {
      state.tabIndexCustomerService = action?.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action?.payload;
    },
    setTabIndexTrading: (state, action) => {
      state.tabIndexTrading = action?.payload;
    },
    setTabIndexEconomic: (state, action) => {
      state.tabIndexEconomic = action?.payload;
    },
    setTabIndexForex: (state, action) => {
      state.tabIndexForex = action?.payload;
    },
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    onOpenModalMemberShip: (state) => {
      state.openModalMemberShip = true;
    },
    onCloseModalMemberShip: (state) => {
      state.openModalMemberShip = false;
    },
    onOpenModalPrivacy: (state) => {
      state.openModalPrivacy = true;
    },
    onCloseModalPrivacy: (state) => {
      state.openModalPrivacy = false;
    },
    onOpenModalComprehensiveSearch: (state) => {
      state.openModalComprehensiveSearch = true;
    },
    onCloseModalComprehensiveSearch: (state) => {
      state.openModalComprehensiveSearch = false;
    },
    onLogout: (state) => {
      state.submitLogout = true;
    },
    onSubmitLogout: (state) => {
      state.submitLogout = false;
    },
    setTabIndexCommunity: (state, action) => {
      state.tabIndexCommunity = action?.payload;
    },
    setTabIndexCommunityTheme: (state, action) => {
      state.tabIndexCommunityHot = action?.payload;
    },
    setTabIndexCommunityLike: (state, action) => {
      state.tabIndexCommunityLike = action?.payload;
    },
    setTabIndexCommunityLikeFollowing: (state, action) => {
      state.tabIndexCommunityLikeFollowing = action?.payload;
    },
    setPrevPath: (state, action) => {
      state.prevPath = action?.payload;
    },
    setPrevPath2: (state, action) => {
      state.prevPath2 = action?.payload;
    },
    setFullScreen: (state, action) => {
      state.fullScreen = action?.payload;
    }
  }
});

// Actions
export const commonActions = commonSlice.actions;

// Reducer
const commonReducer = commonSlice.reducer;
export default commonReducer;
