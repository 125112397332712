/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, Toolbar } from "@mui/material";
import classNames from "classnames";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import { CheckboxCheckedBlueSvg } from "components/icons";
import { Drawer, Puller } from "components/modal/styled";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

export default function ModalReason({
  isOpen,
  onClose,
  onReasonChange,
  reason
}) {
  const { t } = useTranslation();

  const listReason = [
    {
      label: t("button.drawButton.noService"),
      codeValue: 1
    },
    {
      label: t("button.drawButton.siteSpeed"),
      codeValue: 2
    },
    {
      label: t("button.drawButton.anotherSite"),
      codeValue: 3
    },
    {
      label: t("button.drawButton.lowUsage"),
      codeValue: 4
    },
    {
      label: t("button.drawButton.benefit"),
      codeValue: 5
    },
    {
      label: t("button.drawButton.dissatisfaction"),
      codeValue: 6
    },
    {
      label: t("button.drawButton.other"),
      codeValue: 7
    }
  ];

  const handleChecked = (item) => {
    onReasonChange(item);
    onClose();
  };

  return (
    <Drawer
      onOpen={() => {}}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: isOpen ? "block" : "none",
        "& .MuiPaper-root": {
          borderRadius: "16px 16px 0 0"
        }
      }}>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
          <h2>{t("info.reasonForWithdrawal")}</h2>
        </Toolbar>
      </AppBar>
      <div className="flex-col pd-8-0">
        {isArray(listReason) && !isEmpty(listReason) && (
          <div className="flex-col">
            {listReason.map((item, index) => (
              <button
                type="button"
                key={`button_select_catergory_${index}`}
                className={classNames("flex-between-center", {
                  "bg-list-selected": reason?.codeValue === item.codeValue
                })}
                style={{
                  padding: "8px 16px 8px 24px"
                }}
                onClick={() => handleChecked(item)}>
                <h4>{item?.label}</h4>
                <div className="btn-40px-center">
                  {reason?.codeValue === item.codeValue && (
                    <CheckboxCheckedBlueSvg />
                  )}
                </div>
              </button>
            ))}
          </div>
        )}
        <ButtonFixedCancel onBack={onClose} />
      </div>
    </Drawer>
  );
}
