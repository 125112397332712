/* eslint-disable react/jsx-no-useless-fragment */
import { MorePagging } from "components/buttons";
import { CountryName } from "components/countryName";
import EmptyNotice from "components/emptyNotice";
import { SearchItem } from "components/searchItem";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { portfolioActions } from "redux/reducers/portfolioReducer";
import { recentActions } from "redux/reducers/recentReducer";
import { searchActions } from "redux/reducers/searchReducer";
import { idRouter } from "routes/idRouter";
import { getMyPopularSearch, getSearchResults } from "services/InterestService";
import { addToListRecent, getMyRecentList } from "services/RecentService";

export default function Interest({ keywords }) {
  const { listPopularSearch, listSearchResult } = useSelector(
    (state) => state?.search
  );
  const location = useLocation();
  const { t } = useTranslation();
  const { listRecent } = useSelector((state) => state?.recent);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(null);
  const currentCategory = useSelector((state) => state.search.currentCategory);
  const isLoading = useSelector((state) => state?.common.loading);
  const lastModified = useSelector((state) => state?.portfolio?.lastModified);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onGetList();
  }, [keywords]);

  useEffect(() => {
    if (lastModified) {
      dispatch(portfolioActions.setLastModified(null));
      onGetList();
    }
  }, [lastModified]);

  useEffect(() => {
    if (currentPage) getDataFromApi();
  }, [currentPage, currentCategory]);

  const setIsLoading = (value) => dispatch(commonActions.setLoading(value));

  const onClickViewMore = () => {
    setCurrentPage((e) => e + 1);
  };

  const getDataFromApi = () => {
    if (keywords) {
      getNewSearchResult();
    } else {
      getData();
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      await Promise.all([getRecentList(), getPopularSearch()]);
    } finally {
      setIsLoading(false);
    }
  };

  const getRecentList = async () => {
    try {
      const res = await getMyRecentList({
        currentPage: 1,
        pageSize: 5
      });
      if (res.code === "200") {
        const listData = res?.data?.list || [];
        dispatch(
          recentActions.setListRecent(
            currentPage === 1 ? listData : [...listRecent, ...listData]
          )
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPopularSearch = async () => {
    try {
      const res = await getMyPopularSearch({
        currentPage: 1,
        pageSize: 5
      });
      if (res.code === "200")
        dispatch(searchActions.setListPopularSearch(res?.data?.list || []));
    } catch (e) {
      console.error(e);
    }
  };

  const getNewSearchResult = async () => {
    try {
      setIsLoading(true);
      const res = await getSearchResults({
        keyword: keywords,
        symbolType: currentCategory || "ALL",
        currentPage,
        pageSize: 10
      });
      if (res.code === "200") {
        const listData = res?.data?.list || [];
        dispatch(
          searchActions.setListSearchResult(
            currentPage === 1 ? listData : [...listSearchResult, ...listData]
          )
        );
        setTotalItems(res?.data?.pagination?.totalItems || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onGetList = () => {
    if (currentPage !== 1) setCurrentPage(1);
    else getDataFromApi();
  };

  const handleClickInterest = async (item) => {
    try {
      // addToRecent
      await addToListRecent({
        symbolCategory1depthUid: item?.symbolCategory1depthUid,
        symbolCategory3depthUid: item?.symbolCategory3depthUid,
        symbolType: item?.symbolType,
        symbolUid: item?.symbolUid
      });
    } catch (e) {
      console.error(e);
    } finally {
      navigate(
        `/${idRouter.interestDetail}?symbol=${
          item?.uidCode || item?.symbol
        }&symbolType=${item?.symbolType}&symbolUid=${item?.symbolUid}`,
        {
          replace: location.pathname === `/${idRouter.interestDetail}`
        }
      );
      dispatch(commonActions.onCloseModalComprehensiveSearch());
    }
  };

  return (
    <div className="pt-8 full-height flow-y-auto flex-col flex-1 flex-start-center gap-24 hidden-scrollbar">
      {!keywords ? (
        <>
          {isArray(listRecent) && !isEmpty(listRecent) && (
            <div className="full-width pd-0-20">
              <CountryName name={t("info.recentView")} />
              {listRecent.map((item, index) => (
                <SearchItem
                  item={item}
                  key={`recent-list${index}`}
                  onClick={() => handleClickInterest(item)}
                />
              ))}
            </div>
          )}

          {isArray(listPopularSearch) && !isEmpty(listPopularSearch) && (
            <div className="full-width pl-20 pr-20 pb-40">
              <CountryName name={t("info.popularSearch")} />
              {listPopularSearch.map((item, index) => (
                <SearchItem
                  item={item}
                  key={`popular-search${index}`}
                  onClick={() => handleClickInterest(item)}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="full-width pl-20 pr-20 pb-40">
          {isArray(listSearchResult) && !isEmpty(listSearchResult) ? (
            <>
              {listSearchResult.map((item, index) => (
                <SearchItem
                  item={item}
                  key={`search-result${item.symbolCategory3depthUid}${index}`}
                  onClick={() => handleClickInterest(item)}
                />
              ))}
              <MorePagging
                status={listSearchResult.length}
                limit={totalItems}
                onClick={onClickViewMore}
              />
            </>
          ) : (
            !isLoading && (
              <EmptyNotice noticeText={t("notice.noMatchResult")} borderTop />
            )
          )}
        </div>
      )}
    </div>
  );
}
