/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-bitwise */
import i18n, { t } from "i18next";
import isArray from "lodash/isArray";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ko";
import { toast } from "react-toastify";
import { addToListRecent } from "services/RecentService";
import { FileDownload } from "./fileDownload";

export const sortValue = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};

export const period = () => [
  { id: "5M", text: t("button.period.5M") },
  { id: "15M", text: t("button.period.15M") },
  { id: "30M", text: t("button.period.30M") },
  { id: "1H", text: t("button.period.1H") },
  { id: "5H", text: t("button.period.5H") },
  { id: "1D", text: t("button.period.1D") },
  { id: "1W", text: t("button.period.1W") },
  { id: "MONTH", text: t("button.period.MONTH") }
];

export const noneDigits = [
  "KOSPI",
  "KOSDAQ",
  "N225",
  "KONEX",
  "KRX",
  "KOSDAQ GLOBAL"
];
export const fourDigits = ["FUT", "FRX"];
export const eightDigits = ["CRP"];

export const localeAndFixed = (number, digit = 0) =>
  parseFloat(+number || 0).toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: digit
  });

export const convertBigNumber = (number) => {
  const SI_SYMBOLS = ["", "K", "M", "B", "T", "P", "E"];
  const absNumber = Math.abs(number === undefined ? 0 : +number);

  // Find the appropriate SI symbol based on the magnitude
  const tier = Math.floor(Math.log10(absNumber || 1) / 3);

  // Limit decimal places to 1
  const formattedNumber = Math.round(parseFloat(absNumber / 10 ** (tier * 3)));

  // Return the formatted string with SI symbol
  return formattedNumber + SI_SYMBOLS[tier];
};

export const isTabletOrMobile = window.innerWidth < 1140;
export const calSum = (data, parameter) => {
  const sum = data.reduce(
    (accumulator, current) => accumulator + current[parameter],
    0
  );
  return sum;
};

const { languages } = navigator;
export const isKorean = languages.includes("ko") || languages.includes("ko-KR");

export const baseDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

const checkColor = (value) => {
  if (value > 0) return "#F20013";
  if (value === 0) return "#45495A";
  return "#1554F6";
};

export const convertData = (arr, sort) =>
  arr?.map((i) => {
    const [key, value] = Object.entries(i)[0];
    if (!key || !value || !i) return {};
    return {
      ...value,
      name: key,
      children: convertData(value.children, sort),
      value: sort === "changePercent" ? value?.changePercentSize : value?.value,
      valueAbsolute: value?.value, // value for trading volume
      displayValue: `${value?.changePercent.toFixed(2)}`,
      itemStyle: {
        color: checkColor(
          sort === "changePercent" ? value?.changePercent : value?.change
        ),
        borderColor: "transparent"
      }
    };
  });
// convert data from api
export const combinedObject = (arrayOfObjects) =>
  arrayOfObjects.reduce((result, object) => {
    const key = Object.keys(object)[0];
    const value = object[key];
    return { ...result, [key]: value };
  }, {});
// convert data from api
export const treemapToArray = (arrayOfObjects) =>
  arrayOfObjects.reduce((result, object) => {
    const key = Object.keys(object)[0];
    const value = object[key];
    return [...result, value];
  }, []);
// convert day, month, year
export const convert1WeekAgo = (date) =>
  moment(date).subtract(1, "weeks").format("YYYY-MM-DD");
export const convert1MonthAgo = (date) =>
  moment(date).subtract(1, "months").format("YYYY-MM-DD");
export const convert3MonthAgo = (date) =>
  moment(date).subtract(3, "months").format("YYYY-MM-DD");
// cookies
export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  if (exdays) {
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue}; ${expires}`;
  } else {
    document.cookie = `${cname}=${cvalue};`;
  }
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
// test get location of user
// const error = (err) => console.log(err);
// const sucess = (data) => {
//   const { coords } = data;
//   console.log(coords);
// };
// navigator.geolocation.getCurrentPosition(sucess, error);

export const time = () => {
  const nowInKorea = moment().utcOffset("+0900");
  return nowInKorea
    .format("YYYY.M.D HH:mm")
    .concat(" " + t("info.timeZoneKorean"));
};

export const getTabByValue = (activeTab) => {
  switch (activeTab) {
    case 0:
      return "TRADE";
    case 1:
      return "UP";
    case 2:
      return "DOWN";
    case 3:
      return "CAP";
    default:
      return "";
  }
};

export const getOrderByValue = (sortOrder) => {
  switch (sortOrder) {
    case 1:
      return "DESC";
    case -1:
      return "ASC";
    default:
      return "";
  }
};

export const getTabFutureValue = (childTabFuture) => {
  switch (childTabFuture) {
    case 0:
      return "ALL";
    case 1:
      return "ENERGY";
    case 2:
      return "METAL";
    case 3:
      return "FARM";
    case 4:
      return "FOREX";
    case 5:
      return "INDICES";
    case 6:
      return "BOND";
    default:
      return "";
  }
};

export const convertRgbToRgba = (colorRGB, opacity) => {
  const checkValidate =
    colorRGB.includes("rgb") && +opacity <= 1 && +opacity >= 0;
  // if (!checkValidate) {
  // console.log("Invalid color");
  // not neccesary some setting really using "" as defaul color
  // } else
  if (checkValidate) {
    // we was check as rgb or not in the previous line,
    // so we can disable eslint here
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [r, g, b] = colorRGB?.slice(4, -1).split(",");
    return `rgba(${+r}, ${+g}, ${+b},${opacity})`;
  }
  return "rgb(17, 19, 26)";
};

// function convertDateIOS(date) {
//   const t = date.split(/[- : T]/);
//   if (t?.length === 6)
//     return new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]).getTime();
//   return new Date(date).getTime(); // backup func, might not use
// }

// export function getTimePass(postTime, currentLanguage) {
//   if (postTime) {
//     const timeDiffer = moment.duration(
//       moment()
//         .tz("Asia/Seoul")
//         .locale(currentLanguage)
//         .diff(moment(postTime).locale(currentLanguage))
//     );
//     const textTimeDiffer = timeDiffer.locale(currentLanguage).humanize();
//     if (timeDiffer.asMinutes() < 1)
//       return currentLanguage === "ko" ? "방금" : "Just now";
//     else if (timeDiffer.asWeeks() <= 4 && timeDiffer.asWeeks() >= 1)
//       return `${Math.floor(timeDiffer.asWeeks())}${
//         currentLanguage === "ko" ? "주전" : " weeks ago"
//       }`;
//     else if (timeDiffer.locale("en").humanize().startsWith("a")) {
//       return `${textTimeDiffer.replace(
//         /^(a|an|한)\s+/i,
//         currentLanguage === "ko" ? "1" : "1 "
//       )}${currentLanguage === "ko" ? "전" : " ago"}`;
//     }
//     return `${timeDiffer.locale(currentLanguage).humanize()}${
//       currentLanguage === "ko" ? "전" : " ago"
//     }`;
//   }
//   return null;
// }
export function getTimePass(postTime, currentLanguage) {
  if (postTime) {
    const currentMoment = moment().tz("Asia/Seoul").locale(currentLanguage);
    const postMoment = moment(postTime).locale(currentLanguage);
    const timeDiffer = moment.duration(currentMoment.diff(postMoment));
    if (timeDiffer.asMonths() > 11.5) {
      return currentMoment.format("YY-MM-DD HH:mm");
    } else if (timeDiffer.asWeeks() > 4.5) {
      return `${Math.round(timeDiffer.asMonths())}${
        currentLanguage === "ko" ? "달 전" : " months ago"
      }`;
    } else if (timeDiffer.asDays() > 6.5) {
      return `${Math.round(timeDiffer.asWeeks())}${
        currentLanguage === "ko" ? "주 전" : " weeks ago"
      }`;
    } else if (timeDiffer.asHours() > 23.5) {
      return `${Math.round(timeDiffer.asDays())}${
        currentLanguage === "ko" ? "일 전" : " days ago"
      }`;
    } else if (timeDiffer.asMinutes() > 59.5) {
      return `${Math.round(timeDiffer.asHours())}${
        currentLanguage === "ko" ? "시간 전" : " hours ago"
      }`;
    } else if (timeDiffer.asMinutes() > 1) {
      return `${Math.round(timeDiffer.asMinutes())}${
        currentLanguage === "ko" ? "분 전" : " mins ago"
      }`;
    } else {
      return currentLanguage === "ko" ? "방금" : "Just now";
    }
  }
  return null;
}

export const dateFormat = "YYYY-MM-DD";

export const createDateCheck = (createDate) => {
  moment.locale(i18n.language);
  const now = moment();
  const createDateMoment = moment(createDate);

  if (now.isSame(createDateMoment, "day")) {
    if (createDateMoment.format("HH") < 12)
      return `${t("info.dateCheck.AM")} ${createDateMoment.format("HH:mm")}`;
    return `${t("info.dateCheck.PM")} ${createDateMoment.format("HH:mm")}`;
  } else if (now.subtract(1, "day").isSame(createDateMoment, "day")) {
    return t("info.dateCheck.yesterday");
  } else {
    const month = createDateMoment.format(
      i18n.language === "en" ? "MMMM" : "MM"
    );
    const day = createDateMoment.format("DD");
    return t("info.dateCheck.monthDay", { month, day });
  }
};

export const handleDownload = (urlImag, fileName) => {
  if (window.flutter_inappwebview) {
    const mobileType = navigator.userAgent.toLowerCase();
    if (
      mobileType.indexOf("iphone") > -1 ||
      mobileType.indexOf("ipad") > -1 ||
      mobileType.indexOf("ipod") > -1
    )
      toast.error("다운로드가 시작되었습니다.", {
        position: toast.POSITION.BOTTOM_CENTER,
        className: "bottom-alert-modal",
        hideProgressBar: true,
        closeButton: false,
        autoClose: 1000,
        toastId: "error"
      });
    window.flutter_inappwebview.callHandler(
      "DownloadFile",
      JSON.stringify({ urlImag, fileName })
    );
  } else {
    FileDownload(urlImag, fileName);
  }
};

export const idBB = {
  discussion: "2115f99492fa4a594958ba73c6017ece4b59",
  freeboard: "213fea31563e26f4787abf5a9f6d6eec875"
};

// Use replace() with the ^ operator to remove substrings that start with the prefix
export const removePrefix = (inputString, prefix) =>
  inputString.replace(new RegExp(`^(${prefix})`), "");

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
export const emptyMonths = Array(31)
  .fill()
  .map((_, index) => `${year}-${month}-${index + 1}`);

export const emailRegex = /\bhref="([^"]*)\S+/gi;

export const popWindowUp = (url) => {
  window.open(
    url,
    "demo",
    "_system",
    `width=${window.screen.width}
  , height=${window.screen.height}`
  );
};

export const handleContentClick = (e) => {
  e.preventDefault();
  const links = Array.from(e.currentTarget?.querySelectorAll("a"));
  links?.map((link) => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("LaunchUrl", link.href);
    } else {
      popWindowUp(link.href);
    }
    return null;
  });
};

// calculate the color for heatmap in pc
export const calculateColorBg = (changePercent, maxVal) => {
  const color1 =
    +changePercent > 0.5
      ? "rgba(242, 0, 19, 1)" // color when = 0.5
      : "rgba(21, 84, 246, 1)"; // color when = -0.5
  const color2 =
    +changePercent > 0.5
      ? "rgba(145, 0, 11, 1)" // color when = maxVal
      : "rgba(13, 50, 148, 1)"; // color when = minVal ( < -0.5 )
  const minValue = +changePercent > 0.5 ? 0.5 : -0.5;
  // Normalize value between 0 and 1
  const normalizedValue = (changePercent - minValue) / (maxVal - minValue);

  // Clamp the normalized value to 0-1 range
  const clampedValue = Math.max(0, Math.min(1, normalizedValue));

  // Split colors into rgba components
  const rgba1 = color1.match(/rgba?\((\d+), (\d+), (\d+), ([0-9.]+)\)/);
  const rgba2 = color2.match(/rgba?\((\d+), (\d+), (\d+), ([0-9.]+)\)/);

  const [r1, g1, b1, a1] = rgba1.slice(1).map(Number);
  const [r2, g2, b2, a2] = rgba2.slice(1).map(Number);

  // Calculate interpolated values for each component
  const r = Math.round(r1 + (r2 - r1) * clampedValue);
  const g = Math.round(g1 + (g2 - g1) * clampedValue);
  const b = Math.round(b1 + (b2 - b1) * clampedValue);
  const a = a1 + (a2 - a1) * clampedValue;

  // Return interpolated color string
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const customSortIcons = {
  sortAscending: () => `<svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0L8 6H0L4 0Z" fill="#45495A" />
      <path
        d="M4 16L8 10H0L4 16Z"
        fill="#CFD0D2"
      />
    </svg>`,
  sortDescending: () => `<svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0L8 6H0L4 0Z" fill="#CFD0D2" />
      <path
        d="M4 16L8 10H0L4 16Z"
        fill="#45495A"
      />
    </svg>`,
  sortUnSort: () => `<svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0L8 6H0L4 0Z" fill="#CFD0D2" />
      <path
        d="M4 16L8 10H0L4 16Z"
        fill="#CFD0D2"
      />
    </svg>`
};

export const updateArrayWithMissingData = (
  misingDataArray,
  fullDataArrayDefault
) => {
  const lackDataArray = isArray(misingDataArray) ? misingDataArray : [];
  const existingKinds = new Set(lackDataArray.map((item) => item?.optionKind));
  const missingData = fullDataArrayDefault.filter(
    (item) => !existingKinds.has(item?.optionKind)
  );
  const updatedData = [...missingData, ...lackDataArray];
  return updatedData;
};

export const bgRange = ["#EDF2FF", "#FEEBEC", "#E6FBFF", "#F1ECDC", "#FAF5FF"];
export const dateformat = "YYYY-MM-DD";

export const mkIndicesMasterUid = {
  all: "ALL",
  kospi: "B880C6B36C144022BD614506FAA3C92B",
  kosdaq: "0221F177AE8F441A8BC5E4050796C311"
};

export const handleAddToRecent = async (item) => {
  try {
    if (
      !item?.symbolUid ||
      !item?.symbolCategory1depthUid ||
      !item?.symbolCategory3depthUid ||
      !item?.symbolType
    )
      return;
    const body = {
      symbolCategory1depthUid: item?.symbolCategory1depthUid,
      symbolCategory3depthUid: item?.symbolCategory3depthUid,
      symbolType: item?.symbolType || "STK",
      symbolUid: item?.symbolUid
    };
    await addToListRecent(body);
  } catch (e) {
    console.log(e);
  }
};
