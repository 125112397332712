import styled from "styled-components";
import { Dialog } from "@mui/material";
import SvgIcons from "components/svgIcons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";

const DialogStyled = styled(Dialog)`
  z-index: 1302 !important;
  .tab-area {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--F23645, #f23645);
  }
  .text {
    color: var(--45495A, #45495a);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  .button-close {
    border-radius: 24px;
    width: 100%;
    background: var(--F23645, #f23645);
    display: flex;
    padding: 13px 0px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: var(--FFFFFF, #fff);
    text-align: center;
  }
`;

function AddOver10Portfolio({ pc }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const open = useSelector((state) => state.common.openAddOver10Portfolio);
  const onClose = () => dispatch(commonActions.onCloseAddOver10Portfolio());

  return (
    <DialogStyled
      open={open}
      sx={{
        zIndex: pc ? 1302 : undefined,
        "& .MuiPaper-root": {
          borderRadius: "2px",
          padding: "32px",
          width: 311,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "16px",
          boxShadow:
            "0px 0px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
        },
        "& .MuiPaper-root header": {
          padding: 0,
          width: "unset"
        }
      }}>
      <div className="tab-area">
        <SvgIcons name="incorrect" />
      </div>
      <div className="text">{t("notice.portfolioLimit")}</div>
      <button onClick={onClose} type="button" className="button-close">
        {t("button.confirm")}
      </button>
    </DialogStyled>
  );
}

export default AddOver10Portfolio;
