/* eslint-disable default-param-last */
import { GET_SETTING } from "../type/settingType";

const initState = {
  setting: null
};
export const settingReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SETTING:
      return { ...state, setting: action.payload };
    default:
      return state;
  }
};
