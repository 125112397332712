const SNSURL = Object.freeze({
  GET_LIST_POST_BY_BOARD: (idBoard) =>
    `/api/user/post/list-by-board/pag?idBoard=${idBoard}`,
  SET_POST_LIKE: () => "/api/user/post",
  GET_LIST_SORT_VIEW: (idBoard) =>
    `/api/user/post/list-by-board/All/popular?idBoard=${idBoard}`,
  GET_LIST_LIKE: (idBoard) =>
    `/api/user/post/list-by-board/user-like?idBoard=${idBoard}`,
  GET_LIST_CONTACT_BOARD: (idBoard) =>
    `/api/user/post/list-faq/user/all?idBoard=${idBoard}`,
  GET_LIST_NOITICE_BOARD: (idBoard) =>
    `/api/user/post/list-by-board/all/popular-sort-date?idBoard=${idBoard}`
});
export default SNSURL;
