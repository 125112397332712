export default function BookMarkSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9998 3.70005C6.65502 3.70005 6.32436 3.83701 6.08057 4.08081C5.83677 4.32461 5.6998 4.65527 5.6998 5.00005V19.6398L11.5929 15.4304C11.8363 15.2566 12.1633 15.2566 12.4067 15.4304L18.2998 19.6398V5.00005C18.2998 4.65527 18.1628 4.32461 17.919 4.08081C17.6752 3.83701 17.3446 3.70005 16.9998 3.70005H6.9998ZM5.09062 3.09086C5.59696 2.58451 6.28372 2.30005 6.9998 2.30005H16.9998C17.7159 2.30005 18.4026 2.58451 18.909 3.09086C19.4153 3.59721 19.6998 4.28396 19.6998 5.00005V21C19.6998 21.2623 19.5533 21.5025 19.3201 21.6225C19.087 21.7425 18.8063 21.7221 18.5929 21.5697L11.9998 16.8603L5.40667 21.5697C5.1933 21.7221 4.91265 21.7425 4.6795 21.6225C4.44635 21.5025 4.2998 21.2623 4.2998 21V5.00005C4.2998 4.28396 4.58427 3.59721 5.09062 3.09086Z"
        fill={fillColor}
      />
    </svg>
  );
}
