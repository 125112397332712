export default function Plus({
  height = 24,
  width = 24,
  fillColor = "#11131A"
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.30005C12.3866 4.30005 12.7 4.61345 12.7 5.00005V19C12.7 19.3866 12.3866 19.7 12 19.7C11.6134 19.7 11.3 19.3866 11.3 19V5.00005C11.3 4.61345 11.6134 4.30005 12 4.30005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30005 12C4.30005 11.6134 4.61345 11.3 5.00005 11.3H19C19.3866 11.3 19.7 11.6134 19.7 12C19.7 12.3866 19.3866 12.7 19 12.7H5.00005C4.61345 12.7 4.30005 12.3866 4.30005 12Z"
        fill={fillColor}
      />
    </svg>
  );
}
