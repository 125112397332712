export const apiUrl = `${process.env.REACT_APP_API_URI}/api`;

export const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
export const KAKAO_REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;
export const KAKAO_JAVASCRIP_API_KEY = "87e4c05dabbf33efe6b38a77ee228a7c";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBrNkVy4J7bUcmmMLAtqY3G4A_aMlghtko",
  authDomain: "daily-801ce.firebaseapp.com",
  projectId: "daily-801ce",
  storageBucket: "daily-801ce.appspot.com",
  messagingSenderId: "380317010313",
  appId: "1:380317010313:web:13cce9ac4fbd74c16bb958",
  measurementId: "G-CPSHB0T02N"
};

export const REDIRECT_URL_KAKAO = `${process.env.REACT_APP_CLIENT_URI}/kakao`;
export const REDIRECT_URL_NAVER = `${process.env.REACT_APP_CLIENT_URI}/naver`;

export const SNS_METHOD = Object.freeze({
  GOOGLE: "GOOGLE",
  APPLE: "APPLE",
  KAKAOTALK: "KAKAOTALK",
  NAVER: "NAVER"
});

export const MENU_ID = Object.freeze({
  COMMUNITY: "211e98b182979784e3ba3b03694dc2974cb",
  STOCK_MARKET: "2906bcd4b140bf468592f6336b998ab68e"
});

export const BOARD_ID = Object.freeze({
  STOCK_MARKET_SCHEDULE: "22aae8b60060054eb4b084fd4f0d4b9c59",
  FOREX_BROKERAGE: "230a8d11019ef3c43bd99876e1c4c54e6fe",
  HOW_TO_SIGN_UP: "215e4e1f6269c4a4764b0bb71a75c704d10",
  FX_ESSENTIAL_LECTURE: "227f2f93a36b0974db1bd8c7f1006def85b",
  CONTACT_US: "218ba5adfdccdbb4df0acc5bac162ec1336"
});

export const calender = [
  { text: "월", date: "", day: "" },
  { text: "화", date: "", day: "" },
  { text: "수", date: "", day: "" },
  { text: "목", date: "", day: "" },
  { text: "금", date: "", day: "" },
  { text: "토", date: "", day: "" },
  { text: "일", date: "", day: "" }
];

export const TYPE_POST = {
  DOMESTIC: "국내",
  US: "미국",
  OVERSEAS: "해외",
  EVENT: "종목"
};

export const TYPE_POST_COLOR1 = {
  DOMESTIC: "",
  US: "-red",
  OVERSEAS: "-yellow",
  EVENT: "-blue-active"
};

export const TYPE_POST_COLOR = {
  국내: "",
  미국: "-red",
  해외: "-yellow",
  종목: "-blue-active"
};

export const TYPE_ALARM = {
  MESSAGE: "쪽지",
  COMMENT_POST: "댓글",
  COMMENT_COMMENT: "댓글",
  KEYWORD: "키워드",
  ADMIN_ANSWER: "답변",
  CHAT: "채팅",
  PUSH_ADMIN: "PUSH"
};
