import styled from "styled-components";
import { Dialog, Modal } from "@mui/material";

export const ModalShareStyled = styled(Modal)`
  z-index: 1302;
  .modal-share {
    background: var(--neutral-color-10);
    border-radius: 2px;
    width: 480px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 30%;
    transform: translateY(-50%);
    color: var(--neutral-color-3);
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
    .close-modal__button {
      width: 40px;
      height: 40px;
      padding: 8px;
      display: block;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 32px 32px 48px 32px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    width: 480px;
    align-items: center;
    justify-content: center;

    svg {
      width: 40px;
      height: 40px;
    }

    .swiper {
      width: 256px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide {
      width: auto;
    }
  }

  .modal-share__input {
    display: flex;
    gap: 16px;
    width: 100%;

    svg {
      width: 10px;
      height: 10px;
    }

    input {
      width: 100%;
      border: none;
      padding: 0 16px;
      height: 40px;
      background: var(--neutral-color-9, #f9f9f9);
      color: var(--neutral-color-1);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    button {
      height: 40px;
      padding: 0 24px;
      white-space: nowrap;
      font-weight: 700;
      border: none;
      border-radius: 20px;
      background: var(--primary-color-1, #1554f6);
      color: var(--neutral-color-10);
      cursor: pointer;
    }
  }
`;

export const DialogStyled = styled(Dialog)`
  z-index: 1304;
  .tab-area {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  .text {
    color: var(--45495A, #45495a);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .button-close {
    border-radius: 24px;
    width: 100%;
    background: var(--F23645, #f23645);
    display: flex;
    padding: 13px 0px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: var(--FFFFFF, #fff);
    text-align: center;
  }
`;

export const EventDetailModalStyled = styled(Modal)`
  z-index: 1302;

  .modal-share {
    background: var(--neutral-color-10);
    border-radius: 2px;
    width: 800px;
    min-height: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: var(--neutral-color-3);
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
    .close-modal__button {
      width: 40px;
      height: 40px;
      padding: 8px;
      display: block;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 32px;
    gap: 24px;
    display: flex;
    max-height: 800px;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .body-content {
      overflow-y: auto;
      padding-right: 28px;
      max-height: 778px;
      ::-webkit-scrollbar {
        width: 4px;
        background-color: var(--neutral-color-8, #f4f4f4);
      }
      ::-webkit-scrollbar-thumb {
        background-color: var(--neutral-color-6, #cfd0d2);
      }
    }

    .underline {
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
    }

    .badge {
      width: 72px;
      min-width: 72px;
      height: 22px;
      white-space: nowrap;
      border-radius: 12px;
      background: #f4f4f4;
      /* text-align: center; */
      padding: 0 8px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .preview {
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      .detail {
        padding: 0 16px;
        background: #f4f4f4;
      }
    }

    .event-detail-content {
      a {
        color: blue;
      }
      p {
        margin-bottom: 16px;
      }
    }
  }
`;

export const PCCenterModalStyled = styled(Modal)`
  z-index: 1302;
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: ${(props) => props.height}px;
    border-radius: 4px;
  }
`;
