/* eslint-disable no-restricted-globals */
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import ApiUtil from "../util/Api";

const API_URL = `${ApiUtil.getRealApiUrl()}api/auth/`;
let newToken = "";

const register = (userName, mail, password) =>
  axios.post(`${API_URL}signUp`, {
    userName,
    mail,
    password
  });

const loginUser = async (credentials) =>
  axios
    .post(`${API_URL}auth/login`, credentials, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(async (response) => {
      const isSuccess = !!response.data.data;
      if (isSuccess) {
        const objLocalStrg = {
          autoLg: credentials.autoLogin,
          ...response.data.data.user
        };
        window.localStorage.setItem("user", JSON.stringify(objLocalStrg));
        window.localStorage.setItem(
          "joinQuizState",
          // response.data.data.joinQuizState.join_quiz_yn
          response.data.data.joinQuizState
        );
        window.localStorage.setItem(
          "token",
          response.data.data.token.accessToken
        );
        window.localStorage.setItem(
          "refresh_token",
          response.data.data.refresh_token
        );
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler(
            "LoginListener",
            objLocalStrg.mail
          );
        }
        window.localStorage.setItem("isError", false);
        window.localStorage.setItem("loginMessage", "로그인 완료되었습니다");
      } else {
        let message = "";
        if (response.data.code === "400") {
          message = "등록되지 않은 이메일과 비밀번호입니다.";
        } else {
          message = response.data.message;
        }

        window.localStorage.setItem("isError", true);
        window.localStorage.setItem("loginMessage", message);
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
    });

const logoutUser = async () => {
  if (window.flutter_inappwebview) {
    const { mail } = JSON.parse(localStorage.getItem("user"));
    window.flutter_inappwebview.callHandler("LogoutListener", mail);
  }
  localStorage.clear();
  location.reload(); // clear reducers
};

const currentUser = () => {
  const user = window.JSON.parse(localStorage.getItem("user"));
  return user;
};

const refreshToken = (refreshTk) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .post(
          `${API_URL}auth/refresh-token`,
          {
            refreshToken: refreshTk
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then((response) => {
          if (response.data.code === "200") {
            window.localStorage.setItem(
              "refresh_token",
              response.data.data.refresh_token
            );
            window.localStorage.setItem(
              "token",
              response.data.data.token.accessToken
            );
            newToken = response.data.data.token.accessToken;
            resolve(response.data.data.token.accessToken);
          }
          if (response.data.code === "400" && newToken !== "") {
            resolve(newToken);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }, 1000);
  });

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const isTokenExpired = (token) => {
  if (isEmpty(token)) return false;
  const decodedJwt = parseJwt(token);
  if (decodedJwt.exp * 1000 < Date.now()) {
    return true;
  }
  return false;
};

export default {
  register,
  loginUser,
  logoutUser,
  currentUser,
  refreshToken,
  isTokenExpired
};
