import styled from "styled-components";

export const ContentItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isPC ? "8px 0" : "16px 0")};
  border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
  &:last-of-type {
    border: none;
  }
  .left {
    display: flex;
    align-items: center;
    width: 100%;
    img {
      width: 24px;
      width: 24px;
    }
    .label {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 12px;
      height: 38px;
      h4,
      h6 {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 6px 0 6px 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
