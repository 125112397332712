export default function index({ fillColor = "#AFB1B8" }) {
  return (
    <svg
      width="8"
      height="20"
      viewBox="0 0 8 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9998 1.70005C2.65502 1.70005 2.32436 1.83701 2.08057 2.08081C1.83677 2.32461 1.6998 2.65527 1.6998 3.00005V17C1.6998 17.3448 1.83677 17.6755 2.08057 17.9193C2.32436 18.1631 2.65502 18.3 2.9998 18.3H6.9998C7.3864 18.3 7.6998 18.6135 7.6998 19C7.6998 19.3866 7.3864 19.7001 6.9998 19.7001H2.9998C2.28372 19.7001 1.59696 19.4156 1.09062 18.9092C0.584268 18.4029 0.299805 17.7161 0.299805 17V3.00005C0.299805 2.28396 0.584268 1.59721 1.09062 1.09086C1.59696 0.584512 2.28372 0.300049 2.9998 0.300049H6.9998C7.3864 0.300049 7.6998 0.613449 7.6998 1.00005C7.6998 1.38665 7.3864 1.70005 6.9998 1.70005H2.9998Z"
        fill={fillColor}
      />
    </svg>
  );
}
