export default function AlarmActiveSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26271 6.16234C8.51921 4.90584 10.2234 4.19995 12.0003 4.19995C13.7773 4.19995 15.4814 4.90584 16.7379 6.16234C17.9944 7.41883 18.7003 9.123 18.7003 10.9C18.7003 15.5454 20.0593 17.8319 20.8323 18.7624C21.1456 19.1395 21.1007 19.6092 20.9839 19.9007C20.8683 20.1893 20.5553 20.6 20.0095 20.6H3.99116C3.4454 20.6 3.13232 20.1893 3.01674 19.9007C2.89997 19.6092 2.85505 19.1395 3.16834 18.7624C3.94131 17.8319 5.30033 15.5454 5.30033 10.9C5.30033 9.123 6.00622 7.41883 7.26271 6.16234ZM12.0003 5.59995C10.5947 5.59995 9.2466 6.15834 8.25266 7.15229C7.25872 8.14623 6.70033 9.4943 6.70033 10.9C6.70033 15.2898 5.55243 17.8406 4.59448 19.2H19.4062C18.4482 17.8406 17.3003 15.2898 17.3003 10.9C17.3003 9.4943 16.7419 8.14623 15.748 7.15229C14.7541 6.15834 13.406 5.59995 12.0003 5.59995Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91919 22.1947C10.2536 22.0007 10.6819 22.1146 10.8759 22.449C10.9902 22.646 11.1542 22.8095 11.3516 22.9232C11.5489 23.0368 11.7727 23.0967 12.0004 23.0967C12.2282 23.0967 12.4519 23.0368 12.6493 22.9232C12.8466 22.8095 13.0106 22.646 13.1249 22.449C13.3189 22.1146 13.7473 22.0007 14.0817 22.1947C14.4161 22.3887 14.5299 22.817 14.3359 23.1515C14.0986 23.5606 13.7579 23.9002 13.348 24.1363C12.9382 24.3724 12.4734 24.4967 12.0004 24.4967C11.5274 24.4967 11.0627 24.3724 10.6528 24.1363C10.2429 23.9002 9.90227 23.5606 9.66493 23.1515C9.47094 22.817 9.58478 22.3887 9.91919 22.1947Z"
        fill={fillColor}
      />
      <g filter="url(#filter0_d_645_14446)">
        <circle cx="21.3302" cy="4.67" r="2.67" fill="#1554F6" />
      </g>
      <defs>
        <filter
          id="filter0_d_645_14446"
          x="16.6602"
          y="0"
          width="9.33984"
          height="9.34009"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.368627 0 0 0 0 0.52549 0 0 0 0 0.956863 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_645_14446"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_645_14446"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
