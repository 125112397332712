/* eslint-disable no-unused-vars */
import React from "react";

import { idRouter } from "./idRouter";

const Privacy = React.lazy(() => import("views/Privacy"));
const RecentViewDetail = React.lazy(() => import("views/RecentViewDetail"));
const PortfolioDetail = React.lazy(() => import("views/PortfolioDetail"));
const SearchInterest = React.lazy(() => import("views/SearchInterest"));
const WatchList = React.lazy(() => import("views/WatchList"));
const CommunityApp = React.lazy(() => import("views/Community"));
const TradingView = React.lazy(() => import("views/TradingView"));
const Tiny = React.lazy(() => import("views/Tiny"));
const HeatMap = React.lazy(() => import("views/HeatMap"));
const EconomicCalendar = React.lazy(() => import("views/EconomicCalendar"));
const EconomicCalendarPc = React.lazy(() =>
  import("viewsPC/EconomicCalendar/index")
);
const EconomicIndicatorDetail = React.lazy(() =>
  import("views/EconomicIndicatorDetail")
);

const EconomicThemaDetail = React.lazy(() =>
  import("views/EconomicThemaDetail")
);
const MorePage = React.lazy(() => import("views/MorePage"));
const Market = React.lazy(() => import("views/Market"));
const MajorCryptoCurrency = React.lazy(() =>
  import("views/MajorCryptoCurrency")
);
const Components = React.lazy(() => import("views/Components"));
const Top100Crypto = React.lazy(() => import("views/Top100Crypto"));
const TechnicalDetail = React.lazy(() => import("views/TechnicalDetail"));
const DailyPrice = React.lazy(() => import("views/DailyPrice"));
const ViewChartImage = React.lazy(() => import("views/ViewChartImage"));
const MajorIndices = React.lazy(() => import("views/MajorIndices"));
const RealTimeExchangeRate = React.lazy(() =>
  import("views/RealtimeExchangeRate")
);
const DetailPrediction = React.lazy(() => import("views/DetailPrediction"));
const TopTrader = React.lazy(() => import("views/TopTraders"));
const TraderDetail = React.lazy(() => import("views/TopTraders/TraderDetails"));
const SendNote = React.lazy(() => import("views/SendNote"));
const ReportingPage = React.lazy(() => import("views/ReportingList"));
const Profile = React.lazy(() => import("views/Profile"));
const Following = React.lazy(() => import("views/Profile/component/Follow"));
const SvgIcon = React.lazy(() => import("components/svgIcons/svgIcon"));
const WriteAPost = React.lazy(() => import("views/WriteAPost"));
const FreeBoard = React.lazy(() => import("views/FreeBoard"));
const APIError500 = React.lazy(() => import("views/APIError500"));
const MyPage = React.lazy(() => import("views/MyPage"));
const Top100HighestVolume = React.lazy(() =>
  import("views/Top100HighestVolume")
);
const TopLowestPointStock = React.lazy(() =>
  import("views/TopLowestPointStock")
);
const Top100Thema = React.lazy(() => import("views/Top100Thema"));
const Top100Subject = React.lazy(() => import("views/Top100Subject"));
const ThemeDetail = React.lazy(() => import("views/ThemeDetail"));
const Top100Industry = React.lazy(() => import("views/Top100Industry"));
const MajorEtf = React.lazy(() => import("views/MajorETF"));
const Top100Etf = React.lazy(() => import("views/Top100Etf"));
const RealTimeFuture = React.lazy(() => import("views/RealTimeFuture"));
const Top20DiscussionRoom = React.lazy(() =>
  import("views/Top20DiscussionRoom")
);
const Chatting = React.lazy(() => import("views/ChattingPage"));
const ChatList = React.lazy(() => import("views/ChatListPage"));
const Discussion = React.lazy(() => import("views/Discussion"));
const NEWCustomerService = React.lazy(() => import("views/CustomerService"));
const AppNoticeDetail = React.lazy(() => import("views/AppNoticeDetail"));
const WriteANote = React.lazy(() =>
  import("views/SendNote/components/DefaultWriteANote")
);
const Notification = React.lazy(() => import("views/Notifications"));
const MessageBox = React.lazy(() => import("views/MessageBox"));

const NewsAndAnalysis = React.lazy(() => import("views/NewsAndAnalysis"));
const News = React.lazy(() => import("views/News"));
const OverseaCompanies = React.lazy(() =>
  import("views/InfoOverseaComnpanies")
);
const PostDetail = React.lazy(() => import("views/PostDetail"));
const ThemeDebate = React.lazy(() => import("views/ThemeDebate"));
const HeatMapPC = React.lazy(() => import("viewsPC/HeatMap"));

const HomePC = React.lazy(() => import("viewsPC/Home"));

const ThemedDetailPC = React.lazy(() => import("viewsPC/ThemeDetail"));
const ThemeDetailOverView = React.lazy(() =>
  import("viewsPC/ThemeDetailOverview")
);
const InterestDetails = React.lazy(() => import("viewsPC/InterestDetails"));

const DailyQuotes = React.lazy(() => import("viewsPC/DailyQuotes"));

const dailyQuotes = {
  id: idRouter.dailyQuotes,
  path: `/${idRouter.dailyQuotes}`,
  pcComponent: DailyQuotes
};

const themeDetailOverview = {
  id: idRouter.themeDetailOverview,
  path: `/${idRouter.themeDetailOverview}`,
  pcComponent: ThemeDetailOverView
};

const themeDebate = {
  id: idRouter.themeDebate,
  path: `/${idRouter.themeDebate}`,
  component: ThemeDebate
};

const privacy = {
  id: idRouter.privacy,
  path: `/${idRouter.privacy}`,
  component: Privacy
};

const postDetail = {
  id: idRouter.postDetail,
  path: `/${idRouter.postDetail}`,
  component: PostDetail
};
const overseaCompanies = {
  id: idRouter.overseaCompanies,
  path: `/${idRouter.overseaCompanies}`,
  component: OverseaCompanies
};
const news = {
  id: idRouter.news,
  path: `/${idRouter.news}`,
  component: News
};
const newsAndAnalysis = {
  id: idRouter.newsAndAnalysis,
  path: `/${idRouter.newsAndAnalysis}`,
  component: NewsAndAnalysis
};

const messageBox = {
  id: idRouter.messageBox,
  path: `/${idRouter.messageBox}`,
  component: MessageBox
};

const notification = {
  id: idRouter.notifications,
  path: `/${idRouter.notifications}`,
  component: Notification
};

const myPage = {
  id: idRouter.myPage,
  path: `/${idRouter.myPage}`,
  component: MyPage
};

const apiError500 = {
  id: idRouter.apiError500,
  path: `/${idRouter.apiError500}`,
  component: APIError500
};

const writeAPost = {
  id: idRouter.writePost,
  path: `/${idRouter.writePost}`,
  component: WriteAPost
};

const freeBoard = {
  id: idRouter.freeBoard,
  path: `/${idRouter.freeBoard}`,
  component: FreeBoard
};

const svgIcon = {
  id: idRouter.svgIcon,
  path: `/${idRouter.svgIcon}`,
  component: SvgIcon
};

const following = {
  id: idRouter.follow,
  path: `/${idRouter.follow}`,
  component: Following
};
const profile = {
  id: idRouter.profile,
  path: `/${idRouter.profile}`,
  component: Profile
};

const reportingPage = {
  id: idRouter.reportingList,
  path: `/${idRouter.reportingList}`,
  component: ReportingPage
};

const sendNote = {
  id: idRouter.sendNote,
  path: `/${idRouter.sendNote}`,
  component: SendNote
};

const traderDetail = {
  id: idRouter.traderDetail,
  path: `/${idRouter.traderDetail}`,
  component: TraderDetail
};

const topPopular = {
  id: idRouter.topPopular,
  path: `/${idRouter.topPopular}`,
  component: Top100Crypto
};

const topTrader = {
  id: idRouter.topTrader,
  path: `/${idRouter.topTrader}`,
  component: TopTrader
};

const detailPrediction = {
  id: idRouter.detailPrediction,
  path: `/${idRouter.detailPrediction}`,
  component: DetailPrediction
};

const appNoticeDetails = {
  id: idRouter.appNoticeDetail,
  path: `/${idRouter.appNoticeDetail}`,
  component: AppNoticeDetail
};
const customerService = {
  id: idRouter.customerService,
  path: `/${idRouter.customerService}`,
  component: NEWCustomerService
};

const chatList = {
  id: idRouter.chatList,
  path: `/${idRouter.chatList}`,
  component: ChatList
};

const chatting = {
  id: idRouter.chatting,
  path: `/${idRouter.chatting}`,
  component: Chatting
};

const writeANote = {
  id: idRouter.writeANote,
  path: `/${idRouter.writeANote}`,
  component: WriteANote
};

const discussion = {
  id: idRouter.discussion,
  path: `/${idRouter.discussion}`,
  component: Discussion
};

const top20DiscussionRoom = {
  id: idRouter.top20DiscussionRoom,
  path: `/${idRouter.top20DiscussionRoom}`,
  component: Top20DiscussionRoom
};

const realTimeFuture = {
  id: idRouter.realTime,
  path: `/${idRouter.realTime}`,
  component: RealTimeFuture
};

const majorEtf = {
  id: idRouter.majorEtf,
  path: `/${idRouter.majorEtf}`,
  component: MajorEtf
};

const top100Etf = {
  id: idRouter.top100Etf,
  path: `/${idRouter.top100Etf}`,
  component: Top100Etf
};

const top100Industry = {
  id: idRouter.top100Industry,
  path: `/${idRouter.top100Industry}`,
  component: Top100Industry
};

const themeDetail = {
  id: idRouter.themeDetail,
  path: `/${idRouter.themeDetail}`,
  component: ThemeDetail,
  pcComponent: ThemedDetailPC
};

const top100Thema = {
  id: idRouter.top100Thema,
  path: `/${idRouter.top100Thema}`,
  component: Top100Thema
  // pcComponent: Top100ThemaPc
};

const top100Subject = {
  id: idRouter.top100Subject,
  path: `/${idRouter.top100Subject}`,
  component: Top100Subject
};

const topLowestPointStock = {
  id: idRouter.topLowestPointStock,
  path: `/${idRouter.topLowestPointStock}`,
  component: TopLowestPointStock
};

const top100HighestVolume = {
  id: idRouter.top100HighestVolume,
  path: `/${idRouter.top100HighestVolume}`,
  component: Top100HighestVolume
};

const realtimExchangeRate = {
  id: idRouter.realtimeExchangeRate,
  path: `/${idRouter.realtimeExchangeRate}`,
  component: RealTimeExchangeRate
};

const majorIndicies = {
  id: idRouter.majorIndices,
  path: `/${idRouter.majorIndices}`,
  component: MajorIndices
};

const viewChartImage = {
  id: idRouter.viewChartImage,
  path: `/${idRouter.viewChartImage}`,
  component: ViewChartImage
};

const component = {
  id: idRouter.components,
  path: `/${idRouter.components}`,
  component: Components
};
const dailyPrice = {
  id: idRouter.dailyPrice,
  path: `/${idRouter.dailyPrice}`,
  component: DailyPrice
};

const technicalDetail = {
  id: idRouter.technicalDetail,
  path: `/${idRouter.technicalDetail}`,
  component: TechnicalDetail
};

const tiny = {
  id: idRouter.tiny,
  path: `/${idRouter.tiny}`,
  component: Tiny
};

const morePage = {
  id: idRouter.morePage,
  path: `/${idRouter.morePage}`,
  component: MorePage
};

const majorCryptoCurrency = {
  id: idRouter.majorCryptoCurrency,
  path: `/${idRouter.majorCryptoCurrency}`,
  component: MajorCryptoCurrency
};

const top100Crypto = {
  id: idRouter.top100Crypto,
  path: `/${idRouter.top100Crypto}`,
  component: Top100Crypto
};

const economicCalendar = {
  id: idRouter.economicCalendar,
  path: `/${idRouter.economicCalendar}`,
  component: EconomicCalendar
  // pcComponent: EconomicCalendarPc
};

const economicCalendar2 = {
  id: "motconvit",
  path: "/motconvit",
  component: EconomicCalendarPc,
  pcComponent: EconomicCalendarPc
};

const economicIndicatorDetail = {
  id: idRouter.economicIndicatorDetail,
  path: `/${idRouter.economicIndicatorDetail}`,
  component: EconomicIndicatorDetail
};
const economicThemaDetail = {
  id: idRouter.economicThemaDetail,
  path: `/${idRouter.economicThemaDetail}`,
  component: EconomicThemaDetail
};

const recentViewDetail = {
  id: idRouter.recentViewDetail,
  path: `/${idRouter.recentViewDetail}`,
  component: RecentViewDetail
};

const portfolioDetail = {
  id: idRouter.portfolioDetail,
  path: `/${idRouter.portfolioDetail}`,
  component: PortfolioDetail
};

const watchList = {
  id: idRouter.watchList,
  path: `/${idRouter.watchList}`,
  component: WatchList
};

const searchInterest = {
  id: idRouter.searchInterest,
  path: `/${idRouter.searchInterest}`,
  component: SearchInterest
};

const heatMap = {
  id: idRouter.heatMap,
  path: `/${idRouter.heatMap}`,
  component: HeatMap,
  pcComponent: HeatMapPC
};

const communityApp = {
  id: idRouter.communityApp,
  path: `/${idRouter.communityApp}`,
  component: CommunityApp
};
const interestDetail = {
  id: idRouter.interestDetail,
  path: `/${idRouter.interestDetail}`,
  component: TradingView,
  pcComponent: InterestDetails
};

const homeRoute = {
  id: "homeRoute",
  path: "/",
  component: Market,
  pcComponent: HomePC
};

export const registerRoutes = [
  themeDetailOverview,
  themeDebate,
  postDetail,
  overseaCompanies,
  news,
  newsAndAnalysis,
  appNoticeDetails,
  customerService,
  apiError500,
  discussion,
  top20DiscussionRoom,
  top100Industry,
  themeDetail,
  top100Subject,
  top100Thema,
  topLowestPointStock,
  top100HighestVolume,
  component,
  economicCalendar,
  interestDetail,
  tiny,
  majorCryptoCurrency,
  top100Crypto,
  portfolioDetail,
  recentViewDetail,
  watchList,
  heatMap,
  communityApp,
  homeRoute,
  searchInterest,
  morePage,
  economicIndicatorDetail,
  economicThemaDetail,
  technicalDetail,
  dailyPrice,
  viewChartImage,
  majorIndicies,
  realtimExchangeRate,
  detailPrediction,
  majorEtf,
  top100Etf,
  topTrader,
  traderDetail,
  topPopular,
  realTimeFuture,
  sendNote,
  chatting,
  reportingPage,
  profile,
  following,
  svgIcon,
  writeAPost,
  freeBoard,
  notification,
  myPage,
  messageBox,
  writeANote,
  chatList,
  privacy,
  economicCalendar2,
  dailyQuotes
];
