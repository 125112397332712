import styled from "styled-components";

export const EmptyHistoryPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  padding: 32px;
  border-top: ${(props) => (props.borderTop ? "1px solid #f4f4f4" : "none")};
  border: ${(props) => (props.border ? "1px solid #f4f4f4" : "none")};
`;
