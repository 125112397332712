import { OutlinedInput } from "@mui/material";
import styled from "styled-components";

export const OutlinedInputBorder24 = styled(OutlinedInput)`
  padding-right: ${({ nopadding }) => (nopadding ? "20px !important" : "0px")};
  height: 48px;
  fieldset {
    height: 100%;
    border-radius: 24px;
    border: 1px solid var(--neutral-color-7, #e4e5e7);
    top: ${({ noneTop }) =>
      noneTop
        ? "0"
        : "-2.3px"}; // css by my eye, try to center the text and button inside fieldset ://
  }
  input {
    font-family: var(--font-1, "KoPubWorldDotum");
    font-size: 14px;
    font-weight: 500;
    padding: 12px 19px;
    height: 22px;
    line-height: 22px;
  }
  &.Mui-disabled {
    background-color: #f4f4f4;
    border-radius: 24px;
    padding-right: 0 !important;
    input {
      -webkit-text-fill-color: #afb1b8;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;
