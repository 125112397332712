// 어드민에서 설정하는 메뉴 타입을 정리해놓은 enum 객체입니다.
const MENU_TYPE = {
  BASIC: "BASIC",
  BULLETIN_BOARD_GROUP_MENU: "BULLETIN_BOARD_GROUP_MENU",
  STOCK_MARKET: "STOCK_MARKET",
  LINK: "LINK"
};

// 게시판 타입 구분 시 사용하는 enum 객체입니다.
const BOARD_TYPE = {
  BASIC: "BASIC",
  SNS: "SNS",
  VIDEO: "VIDEO",
  INQUIRY: "INQUIRY",
  FAQ: "FAQ"
};

// 특정 게시판에서의 5개의 최신 포스트를 가져오는 data fetch 작업을 진행하는 경우에
// 해당 게시판의 값이 필요함으로. 부득이하게 하드코딩되어있는 값입니다.
const BOARD_ID = {
  FEATURE_INTEREST_THEME_STOCKS: "2723864e67443541bfb3123ad94bf45c14",
  MARKET_ANALYST: "267e88e3183a114d1dab4cea8ad544afab",
  DAILY_REPORT: "24cb745f0b669b46a988459162dddd9ced",
  INVESTMENT_STRATEGY_ANALYST: "2896e124d18de441ddabe7a15fef1497c1",
  GENERAL_NEWS: "2357136a4a54b14e75b63c46e82587ea06",
  MENU_STOCK_NEWS: "2906bcd4b140bf468592f6336b998ab68e",
  SNS_NEWS: "29552623e706974949883955d7dfcc76de"
};

// 검색 조건 설정 시 나타날 텍스트(label)를 케이스별로 정리해놓은 enum 객체입니다.
const MAP_SEARCH_KEY = {
  TITLE_DETAIL: "제목+내용",
  TITLE: "제목",
  DETAIL: "내용",
  NICK_NAME: "닉네임",
  HASHTAG: "해시태그"
};

// 보드 클래스를 구분하는 enum 값입니다.
const DETAIL_TYPE = {
  BASIC: "BASIC",
  SNS: "SNS",
  VIDEO: "VIDEO"
};

// 에러 발생 시 커스텀 Alert을 노출시켜줍니다. 해당 Alert에 표시할 MSG와 Code를 케이스별로 정리해놓은 객체입니다.
const ERROR_MAP = {
  NO_AUTH: {
    MSG: "접근 권한이 없습니다.",
    CD: 401
  },
  DUP_REPORT: {
    MSG: "이미 신고되었습니다.",
    CD: 208
  },
  ETC_NO_INPUT: {
    MSG: "신고사유를 입력해주세요.",
    CD: 204
  },
  COMPLETE: {
    MSG: "완료",
    CD: 200
  },
  ERROR: {
    MSG: "오류",
    CD: 501
  },
  SERVER_ERROR: {
    MSG: "잠시후 다시 시도해주세요.",
    CD: 500
  },
  DELETED: {
    MSG: "삭제되었습니다",
    CD: 200
  },
  ALREADY_SCRAPPED: {
    MSG: "이미 스크랩된 게시글입니다.",
    CD: 405
  },
  ENTER_PASSWORD: {
    MSG: "비밀번호를 입력해주세요.",
    CD: 404
  },
  INVALID_EMAIL: {
    MSG: "이메일 주소를 확인해주세요.",
    CD: 400
  },
  ENTER_EMAIL: {
    MSG: "이메일을 입력해주세요.",
    CD: 404
  },
  EMAIL_IN_USE: {
    MSG: "이미 사용중인 이메일입니다.",
    CD: 409
  },
  ENTER_CD: {
    MSG: "인증번호를 입력해주세요.",
    CD: 400
  },
  RETRY_AUTH: {
    MSG: "인증을 재시도해주세요.",
    CD: 522
  },
  ENTER_NICKNAME: {
    MSG: "닉네임을 입력해 주세요.",
    CD: 404
  },
  ENTER_EMAIL_CD: {
    MSG: "인증번호를 입력해주세요.",
    CD: 404
  },
  CHECK_PW: {
    MSG: "비밀번호를 확인해주세요.",
    CD: 404
  },
  PW_NOT_MATCH: {
    MSG: "비밀번호가 일치하지 않습니다.",
    CD: 409
  },
  USER_REG_FAILED: {
    MSG: "회원가입이 실패했습니다.",
    CD: 444
  },
  URL_SAVE_CLIPBOARD: {
    MSG: "URL이 클립보드에 저장되었습니다.",
    CD: 200
  },
  INSUFFICIENT_AUTH: {
    MSG: "불충분한 권한.",
    CD: 403
  },
  KEYWORD_ALREADY_REG: {
    MSG: "이미 등록된 키워드입니다.",
    CD: 400
  },
  SELECT_POST_DEL: {
    MSG: "선택삭제할 게시물을 하나 이상 선택하세요.",
    CD: 404
  },
  INVALID_NICKNAME: {
    MSG: "사용할 수 없는 닉네임입니다.",
    CD: 400
  },
  EMAIL_PW_NOT_MATCH: {
    MSG: "이메일/비밀번호가 일치하지 않습니다.",
    CD: 409
  },
  NO_RECIPIENT: {
    MSG: "받는 사람이 없습니다.",
    CD: 404
  },
  SENT: {
    MSG: "보냄 완료.",
    CD: 200
  },
  LINK_COPIED: {
    MSG: "링크가 복사되었습니다.",
    CD: 200
  },
  DEL_MSG: {
    MSG: "삭제 완료.",
    CD: 200
  },
  INVALID_FILE: {
    MSG: "사용할 수 없는 파일 확장자입니다.",
    CD: 403
  },
  USER_SUSPEND: {
    MSG: "회원 정지중으로 로그인할 수 없습니다."
  },
  USER_DORMANCY: {
    MSG: "휴면 상태가 해제되었습니다."
  },
  USER_WITHDRAWL: {
    MSG: "탈퇴된 회원입니다."
  },
  DELETE_CHAT: {
    MSG: "대화방 나가기 완료.",
    CD: 200
  },
  VERIFY_CODE_SENT: {
    MSG: "인증번호가 전송되었습니다.",
    CD: 200
  },
  ACCOUNT_DELETED: {
    MSG: "탈퇴된 회원입니다.",
    CD: 200
  },
  UPLOAD_LINK_VIDEO: {
    MSG: "동영상 링크를 입력하세요.",
    CD: 200
  },
  INVALID_IMG: {
    MSG: "지원하지 않는 파일 확장자입니다."
  },
  BOARD_DELETED: {
    MSG: "접근할 수 없습니다."
  },
  INTEREST_NOT_SELECTED: {
    MSG: "관심분야를 한개 이상 선택해주세요.",
    CD: 404
  }
};

// 어떤 사용자의 액션에서 포인트를 얻었는지 판단 혹은 데이터를 생성 시 사용하는 케이스에 따른 문자 값입니다. (Action)
const ACTIVITY = {
  READING: "READING",
  WRITTING: "WRITTING",
  COMMENT: "COMMENT",
  DOWNLOAD: "DOWNLOAD",
  SIGNUP: "SIGNUP",
  LOGIN_DAY: "LOGIN_DAY",
  WRITE_NOTE: "WRITE_NOTE"
};

// 어떤 사용자의 액션에서 포인트를 얻었는지 판단 혹은 데이터를 생성 시 사용하는 케이스에 따른 문자 값입니다. (상세정보)
const MAP_ACTIVITY = {
  READING: "게시물 읽기",
  WRITTING: "게시글 작성",
  COMMENT: "댓글쓰기",
  DOWNLOAD: "첨부파일 다운로드",
  SIGNUP: "회원 가입",
  LOGIN_DAY: "로그인",
  WRITE_NOTE: "쪽지쓰기"
};

// 유저의 Role 분류를 enum 방식으로 처리해놓은 객체입니다.
const USER_ROLE = {
  ADMIN: "ADMIN",
  NORMAL: "NORMAL"
};

// 증시 차트 탭의 분류를 enum 방식으로 처리해놓은 객체입니다.
const STOCK_CHART_TAB = {
  tab_0: "index",
  tab_1: "raw",
  tab_2: "exchange",
  tab_3: "stock"
};

// 유저의 상태값을 enum 방식으로 처리해놓은 객체입니다.
const USER_STATE = {
  STOP: "STOP",
  NORMAL: "NORMAL",
  DORMANCY: "DORMANCY",
  WITHDRAWAL: "WITHDRAWAL"
};

const MAXIMUM_AUTHORITY = 9999;

// 금/은/동 탭 사용 시 배열에 넣어서 사용하기 위하여 해당 사용값에 알맞게 배열로 처리해둔 값입니다.
const FAVORITE_TAB = [
  {
    tab: "금메달",
    color: ""
  },
  {
    tab: "은메달",
    color: ""
  },
  {
    tab: "동메달",
    color: ""
  }
];

// 금/은/동 메달의 기준이 되는 view-count의 값입니다. 해당 값 이상일 시 해당 메달로 판단합니다.
const RANK = {
  GOLD: 150,
  SILVER: 100,
  COPPER: 50
};

export {
  MENU_TYPE,
  BOARD_TYPE,
  BOARD_ID,
  MAP_SEARCH_KEY,
  DETAIL_TYPE,
  ERROR_MAP,
  USER_ROLE,
  STOCK_CHART_TAB,
  MAXIMUM_AUTHORITY,
  ACTIVITY,
  MAP_ACTIVITY,
  USER_STATE,
  FAVORITE_TAB,
  RANK
};
