/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import classNames from "classnames";
import { isEmail } from "common/ContantComm";
import { errorAPI, successAlert } from "components/alert";
import FormAlert from "components/alert/FormAlert";
import { Edit3Svg, XCircle, XSvg } from "components/icons";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { changePass, checkCode, findPass } from "services/AuthenService";
import { ModalStyled } from "views/HeatMap/styled";
import { ButtonStyled } from "views/MyPage/component/MyPageChangeProfile/styled";
import * as Yup from "yup";
import { OutlinedInputBorder24 } from "../components/OutlinedInputBorder24";

let interval;
function FormSignUp() {
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [authenCounter, setAuthenCounter] = useState(180); // the time
  const [visible, setVisible] = useState({
    password: false,
    confirmPassword: false
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      emailVerified: false,
      passwordNew: "",
      passwordNeww: "",
      otpVerified: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Error: ${t("info.emailRegexFail")}`)
        .required(`Required: ${t("info.emailRequired")}`),
      otp: Yup.string().when("emailVerified", {
        is: true,
        then: Yup.string().required(`Required: ${t("info.otpRequired")}`)
      }),
      passwordNew: Yup.string().when("otpVerified", {
        is: true,
        then: Yup.string()
          .required(`Required: ${t("info.newPasswordRequired")}`)
          .min(8, `Error: ${t("info.limitPasswords")}`)
          .max(16, `Error: ${t("info.limitPasswords")}`)
      }),
      passwordNeww: Yup.string().when("passwordNew", {
        is: (passwordNew) => passwordNew && !formik.errors.passwordNew,
        then: Yup.string()
          .required(`Required: ${t("info.newWPasswordRequired")}`)
          .oneOf(
            [Yup.ref("passwordNew")],
            `Error: ${t("info.passwordNotMatch")}`
          )
      })
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (!formik?.values?.emailVerified) {
          await sendCode();
        } else if (!formik?.values?.otpVerified) {
          const res = await checkCode(
            `?email=${values?.email}&code=${values?.otp}`
          );
          if (res?.data?.code === "200") {
            formik.setValues((prev) => ({ ...prev, otpVerified: true }));
          } else if (res?.data?.code === "400") {
            formik.setErrors({ otp: `Error: ${t("info.otpWrong")}` });
          } else {
            errorAPI(res?.data?.message);
          }
        } else {
          const res = await changePass(
            `?email=${values?.email}&code=${values?.otp}`,
            {
              passwordNew: values?.passwordNew,
              passwordNeww: values?.passwordNeww
            }
          );
          if (res?.data?.code === "200") {
            dispatch(authActions.onCloseModalForgetPasswords());
            dispatch(authActions.onCloseModalLogin());
            dispatch(authActions.onRegisterSuccess("find-pass"));
            navigate("/");
          } else if (res?.data?.code === "400") {
            formik.setErrors({ otp: `Error: ${t("info.otpWrong")}` });
          } else {
            errorAPI(res?.data?.message);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      formik.setSubmitting(false);
    }
  });

  const sendCode = async () => {
    try {
      if (!loading) setIsLoading(true);
      if (!isEmail(formik.values.email)) {
        formik.setErrors({
          email: `Required: ${t("info.otpRequired")}`
        });
      } else {
        formik.setErrors({ email: null });
        const res = await findPass({ email: formik.values?.email });
        if (res?.data?.code === "400") {
          if (res?.data?.message.startsWith("e"))
            formik.setErrors({
              email: `Error: ${t("info.emailNotExists")}`
            });
          if (res?.data?.message.startsWith("u"))
            formik.setErrors({
              email: `Error: ${t("info.emailLinkedSocial")}`
            });
          setIsLoading(false);
        } else if (res?.data?.code === "200") {
          formik.setValues((prev) => ({ ...prev, emailVerified: true }));
          setAuthenCounter(180);
          successAlert(t("notice.otpSent"));
        } else {
          errorAPI(res?.data?.message);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to handle timer expiration
  useEffect(() => {
    if (authenCounter === 0) {
      // formik.setFieldTouched("otp", true);
      formik.setErrors({ otp: `Error: ${t("info.otpOutOfTime")}` });
      return () => {
        clearInterval(interval);
      };
    }
  }, [authenCounter]);

  useEffect(() => {
    if (formik.values.emailVerified && authenCounter !== 0) {
      interval = setInterval(() => {
        setAuthenCounter((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [authenCounter, formik.values.emailVerified]);
  // Effect to clear the timer when the component unmounts

  // Reset timer function
  const resetTimer = () => {
    clearInterval(interval);
  };

  const setIsLoading = (value) => dispatch(commonActions.setLoading(value));

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex-col gap-24 pl-20 pr-20 pt-32 pb-64">
      {!formik?.values?.otpVerified ? (
        <>
          <OutlinedInputBorder24
            type="text"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // onFocus={() => setCurrentFocus("email")}
            // onBlur={() => setCurrentFocus("")}
            value={formik.values.email}
            placeholder={t("info.email")}
            endAdornment={
              formik?.values?.emailVerified ? (
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() => {
                    formik.setValues({
                      email: "",
                      otp: "",
                      emailVerified: false
                    });
                    formik.setErrors({
                      email: null,
                      otp: null
                    });
                    resetTimer();
                  }}>
                  <Edit3Svg fillColor="#9497A0" />
                </button>
              ) : formik.values.email ? (
                <button
                  type="button"
                  className="btn-40px-end"
                  // onFocus={() => setCurrentFocus("email")}
                  onClick={() => {
                    formik.setValues({
                      email: "",
                      otp: "",
                      emailVerified: false
                    });
                    formik.setErrors({
                      email: null,
                      otp: null
                    });
                  }}>
                  <XCircle />
                </button>
              ) : null
            }
          />
          {formik.touched.email && formik.errors.email && (
            <FormAlert tetxtNotice={formik.errors.email} />
          )}
          {formik?.values?.emailVerified && (
            <div className="flex-col gap-12">
              <div className="flex-between">
                <label htmlFor="otp">
                  <h5 className="fw-500 color-info">{t("info.authenCode")}</h5>
                </label>
                <button
                  type="button"
                  onClick={() => {
                    if (authenCounter === 0) sendCode();
                    else {
                      errorAPI(
                        t("notice.optSent", {
                          seconds: moment
                            .utc(authenCounter * 1000)
                            .format("mm:ss")
                        })
                      );
                    }
                  }}>
                  <h5
                    className={classNames("fw-500 text-underline", {
                      "color-neutral": authenCounter > 0,
                      "color-info": authenCounter === 0
                    })}>
                    {t("button.reSend")}
                  </h5>
                </button>
              </div>
              <OutlinedInputBorder24
                type="text"
                id="otp"
                name="otp"
                onChange={formik.handleChange}
                value={formik.values.otp}
                fullWidth
                // onFocus={() => setCurrentFocus("authenCode")}
                // onBlur={() => setCurrentFocus("")}
                placeholder={t("info.enterAuthenCode")}
                endAdornment={
                  <div className="flex-start-center gap-4">
                    <SvgIcons name="clock" />
                    <h6 className="fw-400 font-2">
                      {moment.utc(authenCounter * 1000).format("mm:ss")}
                    </h6>
                  </div>
                }
              />
              {formik.touched.otp && formik.errors.otp && (
                <FormAlert tetxtNotice={formik.errors.otp} />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex-col gap-12">
            <label htmlFor="passwordNew">
              <h5 className="fw-500 color-neutral-8">{t("info.password")}</h5>
            </label>
            <OutlinedInputBorder24
              id="passwordNew"
              onChange={formik.handleChange}
              value={formik.values.passwordNew}
              placeholder={t("info.limitPasswords")}
              type={visible?.password ? "text" : "password"}
              endAdornment={
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() =>
                    setVisible((e) => ({ ...e, password: !e.password }))
                  }>
                  <SvgIcons name={visible.password ? "eyeOff" : "eye"} />
                </button>
              }
            />
            {formik.touched.passwordNew && formik.errors.passwordNew && (
              <FormAlert tetxtNotice={formik.errors.passwordNew} />
            )}
          </div>
          <div className="flex-col gap-12">
            <label htmlFor="passwordNeww">
              <h5 className="fw-500 color-neutral-8">
                {t("info.verifyPassword")}
              </h5>
            </label>
            <OutlinedInputBorder24
              id="passwordNeww"
              onChange={formik.handleChange}
              value={formik.values.passwordNeww}
              placeholder={t("info.reEnterPassword")}
              type={visible?.confirmPassword ? "text" : "password"}
              endAdornment={
                <button
                  type="button"
                  className="btn-40px-end"
                  onClick={() =>
                    setVisible((e) => ({
                      ...e,
                      confirmPassword: !e.confirmPassword
                    }))
                  }>
                  <SvgIcons name={visible.confirmPassword ? "eyeOff" : "eye"} />
                </button>
              }
            />
            {formik.touched.passwordNeww && formik.errors.passwordNeww && (
              <FormAlert tetxtNotice={formik.errors.passwordNeww} />
            )}
          </div>
        </>
      )}
      <ButtonStyled
        type="submit"
        disabled={
          !formik.isValid || formik.isSubmitting || !formik.values.email
        }>
        {formik?.values?.emailVerified ? (
          <h5>{t("button.nextStep")}</h5>
        ) : (
          <h5>{t("button.requestAuthenEmail")}</h5>
        )}
      </ButtonStyled>
    </form>
  );
}

export default function ModalForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const open = useSelector((state) => state.auth.openModalForgetPasswords);
  const onClose = () => dispatch(authActions.onCloseModalForgetPasswords());
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          headerTitle={<h1>{t("button.resetPassword")}</h1>}
          onBack={onClose}
          customAction={
            <button
              type="button"
              className="btn-40px-end flex-centered"
              onClick={() => {
                onClose();
                navigate("/");
              }}>
              <XSvg fillColor="#11131A" />
            </button>
          }
          borderBottom
        />
        <div className="pb-128 flex-col gap-32 flex-1">
          <FormSignUp />
        </div>
      </ModalStyled>
    </Modal>
  );
}
