/* eslint-disable no-unused-vars */
export default function SendSvg({ fillColor = "#E4E5E7" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={fillColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4472 6.20286C17.6067 6.36233 17.6067 6.62087 17.4472 6.78033L11.0306 13.197C10.8711 13.3565 10.6126 13.3565 10.4531 13.197C10.2936 13.0375 10.2936 12.779 10.4531 12.6195L16.8698 6.20286C17.0292 6.0434 17.2878 6.0434 17.4472 6.20286Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4472 6.20286C17.5582 6.31378 17.5957 6.47843 17.5439 6.62649L13.4606 18.2932C13.4053 18.4512 13.2591 18.5594 13.0918 18.5663C12.9245 18.5731 12.77 18.4771 12.702 18.3241L10.4325 13.2176L5.32599 10.9481C5.173 10.8801 5.07701 10.7256 5.08384 10.5583C5.09066 10.391 5.19891 10.2448 5.35694 10.1895L17.0236 6.10619C17.1717 6.05437 17.3363 6.09194 17.4472 6.20286ZM6.59885 10.6201L10.9077 12.5351C11.0001 12.5762 11.0739 12.65 11.115 12.7424L13.03 17.0512L16.4929 7.15717L6.59885 10.6201Z"
        fill="white"
      />
    </svg>
  );
}
