export const emailPattern = /^([a-zA-Z0-9@._-])+$/;
export const MAX_SIZE = 5242880;
export const B_TO_KB = 0.0009765625;
export const numberPattern = /[^0-9]/g;
export const isEmail = (value) =>
  /^(?=.{1,256}$)[^\s@]+@[^\s@]+\.[^\s@]+$/.test((value || "").toString());

export const formatsEditor = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  "imageBlot",
  "video",
  "formula"
];

export const modulesEditor = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, "normal"] }],
    [
      { color: [] },
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "code-block"
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
    ["link", "image", "video"],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

export const RP_CM_USER = "RP_CM_USER";
export const MAX_LENGHT_STOCK_MARKET_DATE = 8;
export const KEY_GG = "AIzaSyAd-QJZvgZ28IWergwA_Ya4dxuqH1mylbs";
