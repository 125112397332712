export default function CopyLinkSvg({ fillColor = "#AFB1B8" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8197 2.47208C11.7156 1.60683 12.9155 1.12806 14.1609 1.13888C15.4063 1.1497 16.5977 1.64926 17.4784 2.52995C18.3591 3.41063 18.8586 4.60199 18.8694 5.84743C18.8803 7.09286 18.4015 8.29272 17.5362 9.18858L17.5292 9.19588L15.0292 11.6957C15.0292 11.6958 15.0292 11.6957 15.0292 11.6957C14.5477 12.1774 13.9681 12.55 13.33 12.788C12.6918 13.0261 12.0099 13.1241 11.3305 13.0755C10.6511 13.0268 9.99017 12.8326 9.39244 12.506C8.79471 12.1795 8.2742 11.7282 7.86622 11.1827C7.67325 10.9248 7.72595 10.5592 7.98393 10.3662C8.24191 10.1733 8.60747 10.226 8.80044 10.4839C9.10822 10.8954 9.50088 11.2358 9.9518 11.4822C10.4027 11.7286 10.9014 11.8751 11.4139 11.9118C11.9264 11.9485 12.4408 11.8745 12.9222 11.6949C13.4037 11.5154 13.8408 11.2343 14.2041 10.8709L16.7005 8.37457C17.3511 7.69912 17.711 6.79548 17.7028 5.85757C17.6947 4.91803 17.3178 4.01928 16.6534 3.3549C15.989 2.69052 15.0903 2.31367 14.1508 2.3055C13.2126 2.29735 12.3087 2.65748 11.6332 3.30844L10.2029 4.73035C9.97447 4.95749 9.60512 4.95641 9.37798 4.72794C9.15084 4.49947 9.15192 4.13013 9.38039 3.90299L10.8197 2.47208Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.67 7.21197C7.30817 6.97392 7.99008 6.87589 8.66946 6.92455C9.34885 6.97321 10.0098 7.1674 10.6076 7.49397C11.2053 7.82054 11.7258 8.27184 12.1338 8.81727C12.3267 9.07525 12.274 9.44081 12.0161 9.63378C11.7581 9.82675 11.3925 9.77405 11.1996 9.51608C10.8918 9.10462 10.4991 8.76416 10.0482 8.5178C9.59727 8.27144 9.09864 8.12494 8.58612 8.08824C8.0736 8.05153 7.55918 8.12548 7.07776 8.30507C6.59633 8.48465 6.15916 8.76568 5.79589 9.12908L3.29953 11.6254C2.64894 12.3009 2.28902 13.2045 2.29717 14.1424C2.30533 15.082 2.68219 15.9807 3.34657 16.6451C4.01095 17.3095 4.90969 17.6863 5.84923 17.6945C6.78715 17.7027 7.6908 17.3427 8.36625 16.6921L9.78752 15.2709C10.0153 15.0431 10.3847 15.0431 10.6125 15.2709C10.8403 15.4987 10.8403 15.868 10.6125 16.0958L9.18031 17.528C8.28445 18.3932 7.08453 18.8719 5.83909 18.8611C4.59366 18.8503 3.4023 18.3507 2.52161 17.4701C1.64092 16.5894 1.14137 15.398 1.13055 14.1526C1.11972 12.9071 1.5985 11.7073 2.46375 10.8114L2.47079 10.8041L4.97078 8.30427C4.97075 8.30429 4.9708 8.30424 4.97078 8.30427C5.45231 7.82259 6.03186 7.45002 6.67 7.21197Z"
        fill={fillColor}
      />
    </svg>
  );
}
