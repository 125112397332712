/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import SubHeaderMobile from "components/subHeaderMobile";
import { useDispatch } from "react-redux";
import { ModalStyled } from "views/HeatMap/styled";

export default function ModalDetailElectronicDisclosure({
  open,
  onClose,
  uid
}) {
  const dispatch = useDispatch();

  const onShare = async () => {
    try {
      const options = {
        url: `https://dart.fss.or.kr/dsaf001/main.do?rcpNo=${uid}`,
        title: "전자공시",
        text: "전자공시"
      };
      if (window.flutter_inappwebview)
        window.flutter_inappwebview.callHandler(
          "Share",
          JSON.stringify(options)
        );
      else await navigator.share(options);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <SubHeaderMobile
          borderBottom
          onBack={onClose}
          onShare={onShare}
          onNotice={() => {}}
        />
        <div
          className="hidden-scrollbar full-height"
          style={{ borderBottom: "1px solid #E4E5E7" }}>
          <iframe
            title="disclosure-iframe"
            src={`https://dart.fss.or.kr/dsaf001/main.do?rcpNo=${uid}`}
            style={{
              border: "none",
              height: "100%" // write inline for override old code D:\Code\Workspace\finchive\finchive\src\components\broker\_fxEssentialClassWrite.scss
            }}
            width="100%"
            id="disclosure-iframe"
            // onLoad={handleIframeLoad}
          />
        </div>
      </ModalStyled>
    </Modal>
  );
}
