/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getMainDebate = (params) =>
  newAxiosInstance.get(URL.GET_MAIN_DEBATE + qs.stringify(params, true));

export const getFreeBoard = () => newAxiosInstance.get(URL.GET_FREE_BOARD);

export const getTop20ThemaRoom = (params) =>
  newAxiosInstance.get(URL.TOP_20_DISCUSSION_ROOM + qs.stringify(params, true));

export const getRoomByPorfolio = (params) =>
  newAxiosInstance.get(URL.GET_ROOM_BY_PORTFOLIO + qs.stringify(params, true));

export const getDebate = (params) =>
  newAxiosInstance.get(URL.GET_DEBATE + qs.stringify(params, true));

export const getFollowing = (params) =>
  newAxiosInstance.get(URL.GET_FOLLOWING_POST + qs.stringify(params, true));

export const getBlockList = (params) =>
  newAxiosInstance.get(URL.GET_BLOCK + qs.stringify(params, true));

export const getThema = (params) =>
  newAxiosInstance.get(URL.GET_THEMA + qs.stringify(params, true));

export const getPostDetail = (params) =>
  newAxiosInstance.get(URL.GET_POST_DETAIL + qs.stringify(params, true), {
    hideErrorAlert: true
  });

export const getUserDetail = (params) =>
  newAxiosInstance.get(URL.GET_USER_DETAIL + qs.stringify(params, true));

export const getPostListByUserId = (params) =>
  newAxiosInstance.get(
    URL.GET_POST_LIST_BY_USER_ID + qs.stringify(params, true)
  );

export const getCommentListByUserId = (params) =>
  newAxiosInstance.get(
    URL.GET_COMMENT_LIST_BY_USER_ID + qs.stringify(params, true)
  );

export const getUserFollowing = (params) =>
  newAxiosInstance.get(URL.GET_USER_FOLLOWING + qs.stringify(params, true));

export const getUserFollower = (params) =>
  newAxiosInstance.get(URL.GET_USER_FOLLOWER + qs.stringify(params, true));

export const reportPost = (params, body) =>
  newAxiosInstance.post(URL.REPORT_POST + qs.stringify(params, true), body);

export const insertFollow = (body) =>
  newAxiosInstance.post(URL.FOLLOW_INSERT, body);

export const deleteFollow = (data) =>
  newAxiosInstance.delete(URL.FOLLOW_DELETE, { data });

export const insertBlock = (body) =>
  newAxiosInstance.post(URL.INSERT_BLOCK, body);

export const createPost = (body) =>
  newAxiosInstance.post(URL.POST_CREATE, body);

export const updatePost = (param, body) =>
  newAxiosInstance.put(URL.UPDATE_POST + qs.stringify(param, true), body);

export const unlikePost = (params) =>
  newAxiosInstance.get(URL.UNLIKE_POST + qs.stringify(params, true));

export const likePost = (params) =>
  newAxiosInstance.get(URL.LIKE_POST + qs.stringify(params, true));

export const unlikeComment = (data) =>
  newAxiosInstance.delete(URL.DELETE_LIKE_COMMENT, { data });

export const likeComment = (body) =>
  newAxiosInstance.post(URL.INSERT_LIKE_COMMENT, body);

export const deleteComment = (param) =>
  newAxiosInstance.delete(URL.DELETE_COMMENT + qs.stringify(param, true));

export const deletePost = (data) =>
  newAxiosInstance.delete(URL.DELETE_POST + qs.stringify(data, true));

export const reportUser = (body) => newAxiosInstance.put(URL.REPORT_USER, body);

export const deleteBlock = (data) =>
  newAxiosInstance.delete(URL.DELETE_BLOCK, { data });

export const scrapPost = (params) =>
  newAxiosInstance.post(URL.SCRAP_POST + qs.stringify(params, true));

export const unscrapPost = (params) =>
  newAxiosInstance.delete(URL.UNSCRAP_POST + qs.stringify(params, true));

export const getCommentByPostId = (params) =>
  newAxiosInstance.get(URL.GET_COMMENT_BY_POST + qs.stringify(params, true));

export const getCommentByComment = (params) =>
  newAxiosInstance.get(URL.GET_COMMENT_BY_COMMENT + qs.stringify(params, true));

export const createComment = (body) =>
  newAxiosInstance.post(URL.CREATE_COMMENT, body);

export const reportComment = (params, body) =>
  newAxiosInstance.post(URL.REPORT_COMMENT + qs.stringify(params, true), body);

export const updateComment = (param, body) =>
  newAxiosInstance.put(URL.EDIT_COMMENT + qs.stringify(param, true), body);

export const getBulltinBoardList = () =>
  newAxiosInstance.get(URL.GET_BULLETIN_BOARD_LIST);

export const getDetailBulletinBoard = (params) =>
  newAxiosInstance.get(
    URL.GET_BULLETIN_BOARD_DETAIL + qs.stringify(params, true)
  );
  
export const getCommentDetailById = (params) =>
  newAxiosInstance.get(URL.GET_COMMENT_DETAIL + qs.stringify(params, true));