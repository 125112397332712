/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettingPage from "./hook/useSettingPage";
import "./i18n";
import "./main.scss";
import Routers from "./routes/Routers";
import useTabActive from "./util/useActiveTab";
import PageBlock from "./views/PageBlock";

function App() {
  const { settingPage, infoBlock, checkVisitPage, handleFetchBlockIp } =
    useSettingPage();
  const { windowIsActive } = useTabActive();
  const { title, metaTag } = settingPage;
  const { code, message, data } = infoBlock;
  const { i18n } = useTranslation();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    const { languages } = navigator;
    const isKorean = languages.includes("ko") || languages.includes("ko-KR");
    const languagesSetting = JSON.parse(localStorage.getItem("userSettings"));
    const localLanguage = languagesSetting?.find(
      (obj) => obj?.optionKind === "Language"
    );
    const defineLanguage = isKorean
      ? {
          filePath:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
          labelEng: "Korea",
          labelKor: "한국어",
          optionKind: "Language",
          optionValue: "KOR" // only need this, can remove the other param
        }
      : {
          filePath:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/US.svg",
          labelEng: "English",
          labelKor: "영어",
          optionKind: "Language",
          optionValue: "USA"
        };
    const currentLanguage = localLanguage || defineLanguage;
    switch (currentLanguage?.optionValue) {
      case "KOR":
        if (i18n.language !== "ko") i18n.changeLanguage("ko");
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("handleMessage", "ko");
        }
        break;
      case "USA":
        if (i18n.language !== "en") i18n.changeLanguage("en");
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("handleMessage", "en");
        }
        break;
      // case "VNM":
      //   if (i18n.language !== "vi") i18n.changeLanguage("vi");
      //   if (window.flutter_inappwebview) {
      //     window.flutter_inappwebview.callHandler("handleMessage", "vi");
      //   }
      //   break;
      default: // for other setting value find in local if we never setting before
        if (i18n.language !== "en" && !isKorean) {
          i18n.changeLanguage("en");
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler("handleMessage", "en");
          }
        }
        if (i18n.language !== "ko" && isKorean) {
          i18n.changeLanguage("ko");
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler("handleMessage", "ko");
          }
        }
    }
  }, []);

  useEffect(() => {
    if (metaTag) {
      const head = document.querySelector("head");
      const div = document.createElement("div");
      div.innerHTML += metaTag;
      head.append(...div.childNodes);
    }
  }, [metaTag]);

  if (windowIsActive) {
    handleFetchBlockIp();
    if (localStorage.getItem("token")) {
      checkVisitPage();
    }
  }

  if (!!code && code === "403") {
    return <PageBlock message={message} ip={data?.ip} />;
  }

  return <Routers />;
}

export default App;
