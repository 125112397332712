export default function HeatMapIconSvg({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 3H3.5V19H11.5V3Z" fill={fillColor} />
      <path d="M19.5 3H13.5V11H19.5V3Z" fill={fillColor} />
      <path d="M19.5 13H13.5V19H19.5V13Z" fill={fillColor} />
    </svg>
  );
}
