import { ChevronDownSvg } from "components/icons";

// eslint-disable-next-line no-unused-vars
export default function DropDownFilledA({ itemDisplay, onClickDropDown }) {
  return (
    <button
      type="button"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "4px 16px",
        backgroundColor: "var(--neutral-color-9, #F9F9F9)"
      }}
      onClick={onClickDropDown}>
      {itemDisplay}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          padding: "9px 0 7px 16px"
        }}>
        <ChevronDownSvg />
      </div>
    </button>
  );
}
