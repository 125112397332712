import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";
import App from "./App";
// import ErrorNoti from "./components/popup/ErrorNoti";
// import ErrorProvider from "./contexts/ErrorProvider";
import store from "./redux/store";

const GlobalStyle = createGlobalStyle`
  :root {
    --font-1: "KoPubWorldDotum";
    --font-2: 'Roboto', sans-serif;
    --font-3: 'Urbanist', sans-serif;
    // Primary color
    --primary-color-1: #1554F6; // finchive blue
    --primary-color-2: #F23645; // finchive red
    --primary-color-3: #45495A; // finchive gray

    // Secondary colors
    --secondary-color-1: #F4CB5D; // finchive yellow
    --secondary-color-1: #BBA14F; // finchive gold

    // Accent color
    --accent-buy: #F20013; // rgba(242, 0, 19, 1)
    --accent-buy-75: rgba(242, 0, 19, 0.75);
    --accent-buy-50: rgba(242, 0, 19, 0.5);
    --accent-buy-25: rgba(242, 0, 19, 0.25);
    --accent-buy-5: rgba(242, 0, 19, 0.05);
    --accent-buy-chart: #F5404E;

    --accent-sell: #1554F6; // rgba(80, 127, 248, 1)
    --accent-sell-75: rgba(80, 127, 248, 0.75);
    --accent-sell-50: rgba(80, 127, 248, 0.5);
    --accent-sell-25: rgba(80, 127, 248, 0.25);
    --accent-sell-5: rgba(80, 127, 248, 0.05);
    --accent-sell-chart: #507FF8;

    --accent-neutral: #45495A;

    // Interaction color
    --interaction-success-color: #1554F6;
    --interaction-error-color: #F23645;
    --interaction-info-color: #9497A0;

    // Neutral color
    --neutral-color-1: #11131A;
    --neutral-color-2: #373D3F;
    --neutral-color-3: #45495A;
    --neutral-color-4: #9497A0;
    --neutral-color-5: #AFB1B8;
    --neutral-color-6: #CFD0D2;
    --neutral-color-7: #E4E5E7;
    --neutral-color-8: #F4F4F4;
    --neutral-color-9: #F9F9F9;
    --neutral-color-10: #FFFFFF;

    // Safe-area-inset
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--neutral-color-1, #11131A);
    font-family: var(--font-1, "KoPubWorldDotum");
    -webkit-tap-highlight-color: transparent;
    scroll-behavior : smooth;
  }
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  svg, a, img {
    background-color: transparent;
  }
  a {
    text-decoration: none;
  }
  h1, h2, h3, h4, h5, h6 {
    background-color: transparent;
    vertical-align: middle;
  }
  h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  h4 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
  }
  h5 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
  }
  h6 {
    font-size: 13px;
    line-height: 22px;  
    font-weight: 700;
  }
  `;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <ErrorProvider> */}
    <GlobalStyle />
    <App />
    {/* <ErrorNoti /> */}
    {/* </ErrorProvider> */}
  </Provider>
);
