export default function AlertTriangleSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6755 2.59726C11.0798 2.3696 11.536 2.25 12.0001 2.25C12.4641 2.25 12.9203 2.3696 13.3247 2.59726C13.729 2.82492 14.0679 3.15295 14.3086 3.5497L14.3106 3.55303L22.7863 17.7027C23.0221 18.1109 23.1468 18.5739 23.1481 19.0453C23.1494 19.5168 23.0273 19.9803 22.7938 20.3899C22.5603 20.7995 22.2237 21.1408 21.8174 21.3799C21.411 21.619 20.9492 21.7475 20.4778 21.7527L20.4701 21.7528L3.52238 21.7527C3.05095 21.7476 2.58911 21.619 2.18278 21.3799C1.77646 21.1408 1.43981 20.7995 1.20635 20.3899C0.972889 19.9803 0.850741 19.5168 0.852061 19.0453C0.853381 18.5739 0.978123 18.111 1.21388 17.7027L1.21949 17.693L9.68956 3.55303L9.69157 3.5497C9.93223 3.15295 10.2711 2.82492 10.6755 2.59726ZM12.0001 3.65C11.7766 3.65 11.557 3.70758 11.3623 3.8172C11.1681 3.92654 11.0052 4.08398 10.8894 4.27437L10.8886 4.27578L2.42377 18.4071C2.31187 18.6026 2.25269 18.8239 2.25206 19.0492C2.25142 19.2762 2.31023 19.4994 2.42264 19.6966C2.53505 19.8938 2.69714 20.0582 2.89277 20.1733C3.08739 20.2878 3.30847 20.3496 3.53421 20.3527H20.4659C20.6917 20.3496 20.9127 20.2878 21.1074 20.1733C21.303 20.0582 21.4651 19.8938 21.5775 19.6966C21.6899 19.4994 21.7487 19.2762 21.7481 19.0492C21.7475 18.8239 21.6883 18.6026 21.5764 18.4071L13.1116 4.27578L13.1107 4.27437C12.9949 4.08398 12.8321 3.92654 12.6378 3.8172C12.4432 3.70758 12.2235 3.65 12.0001 3.65Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 8.35274C12.3867 8.35274 12.7001 8.66614 12.7001 9.05274V13.0527C12.7001 13.4393 12.3867 13.7527 12.0001 13.7527C11.6135 13.7527 11.3001 13.4393 11.3001 13.0527V9.05274C11.3001 8.66614 11.6135 8.35274 12.0001 8.35274Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 17.0527C11.0001 16.5005 11.4478 16.0527 12.0001 16.0527H12.0101C12.5624 16.0527 13.0101 16.5005 13.0101 17.0527C13.0101 17.605 12.5624 18.0527 12.0101 18.0527H12.0001C11.4478 18.0527 11.0001 17.605 11.0001 17.0527Z"
        fill={fillColor}
      />
    </svg>
  );
}
