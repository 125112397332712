export default function ChattingSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30005 2C2.30005 1.6134 2.61345 1.3 3.00005 1.3H21C21.3866 1.3 21.7001 1.6134 21.7001 2V14C21.7001 14.1857 21.6263 14.3637 21.495 14.495L17.495 18.495C17.3637 18.6262 17.1857 18.7 17 18.7H12.29L8.49502 22.495C8.29482 22.6952 7.99374 22.7551 7.73217 22.6467C7.4706 22.5384 7.30005 22.2831 7.30005 22V18.7H3.00005C2.61345 18.7 2.30005 18.3866 2.30005 18V2ZM3.70005 2.7V17.3H8.00005C8.38665 17.3 8.70005 17.6134 8.70005 18V20.3101L11.5051 17.505C11.6363 17.3737 11.8144 17.3 12 17.3H16.7101L20.3 13.7101V2.7H3.70005ZM11 6.3C11.3866 6.3 11.7 6.6134 11.7 7V11C11.7 11.3866 11.3866 11.7 11 11.7C10.6134 11.7 10.3 11.3866 10.3 11V7C10.3 6.6134 10.6134 6.3 11 6.3ZM16 6.3C16.3866 6.3 16.7 6.6134 16.7 7V11C16.7 11.3866 16.3866 11.7 16 11.7C15.6134 11.7 15.3 11.3866 15.3 11V7C15.3 6.6134 15.6134 6.3 16 6.3Z"
        fill={fillColor}
      />
    </svg>
  );
}
