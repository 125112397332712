/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import axios from "axios";
import qs from "querystringify";
import ApiUtil from "../../util/Api";
import URL from "./url";

// authen api without token
const authenAxiosClient = axios.create({
  baseURL: ApiUtil.getRealApiUrl()
});
// login
export const loginUser = (credentials) =>
  authenAxiosClient.post(URL.LOGIN, credentials, {
    headers: {
      "Content-Type": "application/json"
    }
  });
// check email was sign up or not
export const checkMail = (mail) =>
  authenAxiosClient.get(URL.CHECK_MAIL + qs.stringify(mail, true));

// send code verfify to email
export const getCodeEmail = (email) =>
  authenAxiosClient.get(URL.GET_CODE_EMAIL + qs.stringify(email, true));

// authen email with otp
export const authEmail = (params) =>
  authenAxiosClient.get(URL.AUTH_EMAIL + qs.stringify(params, true));

// check username exists
export const checkUserName = (userName) =>
  authenAxiosClient.get(URL.CHECK_USERNAME + qs.stringify(userName, true));

// check nickname exists
export const checkNickName = (userName) =>
  authenAxiosClient.get(URL.CHECK_NICKNAME + qs.stringify(userName, true));

export const signUp = (body) => authenAxiosClient.post(URL.SIGN_UP, body);

export const signUpSNS = (params, body) =>
  authenAxiosClient.post(URL.SIGN_UP_SNS + qs.stringify(params, true), body);

export const findPass = (email) =>
  authenAxiosClient.get(URL.FIND_PASSWORD + qs.stringify(email, true));

export const changePass = (params, body) =>
  authenAxiosClient.post(URL.CHANGE_BY_RESET_PASSWORD + params, body);

export const checkCode = (params) =>
  authenAxiosClient.post(URL.CHECK_CODE + params);

export const checkPassword = (params, body) =>
  authenAxiosClient.post(URL.CHECK_PASSWORD + qs.stringify(params, true), body);
