export default function XSvg({
  width = "14",
  height = "14",
  fillColor = "#AFB1B8",
  isKeyword
}) {
  return isKeyword ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4948 5.50503C18.7681 5.7784 18.7681 6.22161 18.4948 6.49498L6.49478 18.495C6.22141 18.7683 5.7782 18.7683 5.50483 18.495C5.23146 18.2216 5.23146 17.7784 5.50483 17.505L17.5048 5.50503C17.7782 5.23166 18.2214 5.23166 18.4948 5.50503Z"
        fill="#CFD0D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50483 5.50503C5.7782 5.23166 6.22141 5.23166 6.49478 5.50503L18.4948 17.505C18.7681 17.7784 18.7681 18.2216 18.4948 18.495C18.2214 18.7683 17.7782 18.7683 17.5048 18.495L5.50483 6.49498C5.23146 6.22161 5.23146 5.7784 5.50483 5.50503Z"
        fill="#CFD0D2"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.495 0.505074C13.7684 0.778441 13.7684 1.22166 13.495 1.49502L1.49502 13.495C1.22166 13.7684 0.778441 13.7684 0.505074 13.495C0.231707 13.2217 0.231707 12.7784 0.505074 12.5051L12.5051 0.505074C12.7784 0.231707 13.2217 0.231707 13.495 0.505074Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.505074 0.505074C0.778441 0.231707 1.22166 0.231707 1.49502 0.505074L13.495 12.5051C13.7684 12.7784 13.7684 13.2217 13.495 13.495C13.2217 13.7684 12.7784 13.7684 12.5051 13.495L0.505074 1.49502C0.231707 1.22166 0.231707 0.778441 0.505074 0.505074Z"
        fill={fillColor}
      />
    </svg>
  );
}
