/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { successAlert, warningAlert } from "components/alert";
import { MorePagging } from "components/buttons";
import CenterConfirmModal from "components/centerConfirmModal";
import EmptyNotice from "components/emptyNotice";
import SvgIcons from "components/svgIcons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import {
  deleteAllScrap,
  deleteScrapByPost,
  getListScrap
} from "services/MyPageService/MyPageService";
import { SelfScrollDiv } from "views/HeatMap/styled";
import { NumberTab2Styled } from "./styled";

export default function MyPageScrap() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState([]);
  const [openSubmitDelete, setOpenSubmitDelete] = useState(false);
  const [openSubmitDeleteAll, setOpenSubmitDeleteAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [listNews, setListNews] = useState([]);
  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  useEffect(() => {
    getScrapList();
  }, [currentPage]);

  const getScrapList = async () => {
    try {
      setLoading(true);
      const res = await getListScrap({
        pageSize: 10,
        pageNumber: currentPage
      });
      if (res.code === "200") {
        setListNews(
          currentPage === 1 ? res?.data : [...listNews, ...res?.data]
        );
      }
      setTotalItems(res?.pagination?.totalItems || 0);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = (id) => {
    const index = checkedItems.indexOf(id);
    if (index === -1) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((itemId) => itemId !== id));
    }
  };

  const handleDelete = async () => {
    try {
      if (isArray(checkedItems) && !isEmpty(checkedItems)) {
        setLoading(true);
        const res = await deleteScrapByPost({
          idPost: checkedItems
        });
        if (res.code === "200") {
          successAlert(t("notice.deleteSuccess"));
          await getScrapList();
        }
      } else {
        warningAlert(t("notice.warningAlertSelectItem"));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    try {
      setLoading(true);
      const res = await deleteAllScrap();
      if (res.code === "200") {
        successAlert(t("notice.deleteSuccess"));
        await getScrapList();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClickViewMore = () => setCurrentPage((e) => e + 1);

  return (
    <NumberTab2Styled className="flex-col pl-20 pr-20 flex-1 full-height flow-y-auto">
      <div className="txt">
        <h6
          className="fw-500 lh-24 color-info"
          onClick={() => setOpenSubmitDeleteAll(true)}>
          {t("button.deleteAll")}
        </h6>
        <div className="line mr-8 ml-8" />
        <h6
          className="fw-500 lh-24 color-info"
          onClick={() => setOpenSubmitDelete(true)}>
          {t("button.deleteSelection")}
        </h6>
      </div>
      {isEmpty(listNews) ? (
        <EmptyNotice noticeText={t("info.noInfo")} />
      ) : (
        <SelfScrollDiv className="hidden-scrollbar flex-1 full-height flow-y-auto pb-64 flex-col gap-8">
          {listNews?.map((item, index) => (
            <div className="news-check" key={`listnews_${index}`}>
              <div
                className="check"
                onClick={() => handleItemClick(item?.idPost)}>
                {checkedItems.includes(item.idPost) ? (
                  <SvgIcons name="SuccessSvg" />
                ) : (
                  <SvgIcons name="uncheckCircleSvg" />
                )}
              </div>
              <div className="news flex-col gap-8">
                <p className="caption lh-13 color-neutral">
                  {item?.nameBulltinBoard}
                </p>
                <h4>{item?.title}</h4>
                <div className="flex-start-center gap-8">
                  <div className="flex gap-4">
                    <img src={item?.urlAvatar} alt="사용자_이미지_썸네일" />
                    <p className="caption">{item?.nicknameUser}</p>
                  </div>
                  <p className="caption">
                    {moment(item?.createdTime).format("YYYY-MM-DD hh:mm")}
                  </p>

                  <div className="flex-centered gap-4">
                    <SvgIcons name="MessageCircleSvg" className="flex" />
                    <span className="caption">{item?.listComment?.length}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <MorePagging
            status={listNews?.length}
            limit={totalItems || 5}
            onClick={onClickViewMore}
          />
        </SelfScrollDiv>
      )}
      <CenterConfirmModal
        open={openSubmitDelete}
        onSubmit={() => {
          handleDelete();
          setOpenSubmitDelete(false);
        }}
        onCancel={() => {
          setOpenSubmitDelete(false);
        }}
        btnText={t("button.confirm")}
        textNotice={
          <>
            <h4>{t("info.confirmDelete.title")}</h4>
            <h6 className="fw-500">{t("info.confirmDelete.content")}</h6>
          </>
        }
      />
      <CenterConfirmModal
        open={openSubmitDeleteAll}
        onSubmit={() => {
          handleDeleteAll();
          setOpenSubmitDeleteAll(false);
        }}
        onCancel={() => {
          setOpenSubmitDeleteAll(false);
        }}
        btnText={t("button.confirm")}
        textNotice={
          <>
            <h4>{t("info.confirmDelete.title")}</h4>
            <h6 className="fw-500">{t("info.confirmDelete.content")}</h6>
          </>
        }
      />
    </NumberTab2Styled>
  );
}
