export default function SortSvg({ status }) {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0L8 6H0L4 0Z" fill={status ? "#45495A" : "#CFD0D2"} />
      <path
        d="M4 16L8 10H0L4 16Z"
        fill={status === false ? "#45495A" : "#CFD0D2"}
      />
    </svg>
  );
}

export function SortSvgTop20Thema({ status, order }) {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 0L8 6H0L4 0Z"
        fill={status === 1 && order === "asc" ? "#45495A" : "#CFD0D2"}
      />
      <path
        d="M4 16L8 10H0L4 16Z"
        fill={status === 1 && order === "desc" ? "#45495A" : "#CFD0D2"}
      />
    </svg>
  );
}
