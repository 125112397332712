export default function ChartArea({ fillColor = "#F20013" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1667 4.61261C19.1667 4.1042 18.5739 3.82621 18.183 4.15128L11.6356 9.59601C11.4124 9.78162 11.0883 9.78082 10.866 9.59411L7.46925 6.74082C7.24611 6.55338 6.92055 6.55338 6.69742 6.74081L1.04742 11.4868C0.9117 11.6008 0.833332 11.769 0.833332 11.9462V16.0666C0.833332 16.398 1.10196 16.6666 1.43333 16.6666H18.5667C18.898 16.6666 19.1667 16.398 19.1667 16.0666V4.61261Z"
        fill={fillColor}
      />
    </svg>
  );
}
