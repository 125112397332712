/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import {
  BrokerSvg,
  CalendarSvg,
  FollowSvg,
  GridSvg,
  HelpCircleSvg,
  MessageCircleSvg,
  NewsSvg,
  StarSvg,
  ToolSvg,
  TrendingUpSvg
} from "components/icons";
import { useTranslation } from "react-i18next";

import MenuContainer from "views/MorePage/component/menuContainer";
import { StyledMenu } from "./styled";

export default function Menu() {
  const { t } = useTranslation();
  const fakeMenuMessage = [
    { id: "HOT" },
    { id: "LIKE" },
    { id: t("info.freeBulletinBoard") }
  ];

  const fakeMenuTrend = [
    { id: 0, field: t("info.morePage.market.children.market") },
    { id: 1, field: t("info.morePage.market.children.stock") },
    { id: 2, field: t("info.morePage.market.children.foreign") },
    { id: 3, field: t("info.morePage.market.children.futures") },
    { id: 4, field: t("info.morePage.market.children.crypto") },
    { id: 5, field: t("info.morePage.market.children.ETF") }
  ];

  const fakeMenuHeatmap = [
    { id: 0, field: t("info.morePage.heatMap.children.thema") },
    { id: 1, field: t("info.morePage.heatMap.children.stock") },
    { id: 2, field: t("info.morePage.heatMap.children.indices") },
    { id: 3, field: t("info.morePage.heatMap.children.forex") },
    { id: 4, field: t("info.morePage.heatMap.children.crypto") },
    { id: 5, field: t("info.morePage.heatMap.children.future") },
    { id: 6, field: t("info.morePage.heatMap.children.ETF") }
  ];

  const fakeMenuCalendar = [
    { id: 0, field: t("info.morePage.economic.children.indicator") },
    { id: 1, field: t("info.morePage.economic.children.disclosure") },
    { id: 2, field: t("info.morePage.economic.children.thema") },
    { id: 3, field: t("info.morePage.economic.children.earnings") },
    { id: 4, field: t("info.morePage.economic.children.dividend") },
    { id: 5, field: t("info.morePage.economic.children.split") },
    { id: 6, field: t("info.morePage.economic.children.IPO") },
    { id: 7, field: t("info.morePage.economic.children.holiday") }
    // { id: 8, field: t("info.morePage.economic.children.maturity") }
  ];

  const fakeMenuNews = [
    { field: t("info.news"), id: 0 },
    { field: t("info.analysis"), id: 1 },
    { field: t("info.report"), id: 2 }
  ];

  const fakeMenuHelp = [
    { field: "FAQ", id: 0 },
    { field: t("info.notice"), id: 1 },
    { field: "Q&A", id: 2 }
  ];

  const fakeMenuTool = [{ id: t("info.morePage.tool.children") }];

  const fakeMenuFollow = [
    {
      id: "내 관심 목록",
      field: t("info.morePage.myPage.children.myWatchList")
    },
    { id: 0, field: t("info.morePage.myPage.children.keyword") },
    {
      id: "퀴즈결과",
      field: t("info.morePage.myPage.children.quiz")
    },
    { id: 3, field: t("info.morePage.myPage.children.scrap") },
    { id: 4, field: t("info.morePage.myPage.children.settingProfile") },
    { id: 5, field: t("info.morePage.myPage.children.point") },
    // { id: "퀴즈결과", field: "회원정보 수정" },
    { id: 6, field: t("info.morePage.myPage.children.password") }
  ];
  return (
    <StyledMenu>
      <Grid
        style={{ maxWidth: "1144px" }}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={3} className="flex-grid">
          <MenuContainer
            fakeMenu={fakeMenuTrend}
            icon={<TrendingUpSvg />}
            text={t("info.morePage.market.title")}
          />
          <MenuContainer
            icon={<StarSvg fillColor="#AFB1B8" />}
            text={t("info.menu.watchList")}
            noneBorder
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <MenuContainer
            fakeMenu={fakeMenuHeatmap}
            icon={<GridSvg fillColor="#AFB1B8" width={20} height={20} />}
            text={t("info.morePage.heatMap.title")}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <MenuContainer
            fakeMenu={fakeMenuCalendar}
            icon={<CalendarSvg fillColor="#AFB1B8" width={20} height={20} />}
            text={t("info.economicCalendar")}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <MenuContainer
            icon={<NewsSvg />}
            text={t("info.newsAndAnalysis")}
            fakeMenu={fakeMenuNews}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <MenuContainer
            fakeMenu={fakeMenuTool}
            icon={<ToolSvg fillColor="#AFB1B8" />}
            text={t("info.morePage.tool.title")}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3} className="flex-grid">
          <MenuContainer
            icon={<MessageCircleSvg />}
            text={t("info.menu.community")}
            fakeMenu={fakeMenuMessage}
          />
          <MenuContainer
            icon={<BrokerSvg />}
            text={t("info.companyInformation")}
            noneBorder
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <MenuContainer
            fakeMenu={fakeMenuFollow}
            icon={<FollowSvg fillColor="#AFB1B8" width={20} height={20} />}
            text={t("info.morePage.myPage.title")}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <MenuContainer
            icon={<HelpCircleSvg fillColor="#AFB1B8" width={20} height={20} />}
            text={t("info.customerService")}
            fakeMenu={fakeMenuHelp}
          />
        </Grid>
      </Grid>
    </StyledMenu>
  );
}
