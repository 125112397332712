import { CheckboxCheckedBlueSvg } from "components/icons";
import { RankingListItemStyled } from "components/rankingListItem/styled";
import { memo } from "react";
import { useTranslation } from "react-i18next";

function RankingListItem2({ item, active, onClick }) {
  const { i18n } = useTranslation();
  return (
    <RankingListItemStyled type="button" onClick={onClick} active={active}>
      <div className="ranking-list-item">
        <div className="ranking-list-content">
          <h4>
            {i18n.language === "ko"
              ? item?.labelKor || item?.labelEng
              : item?.labelEng || item?.labelKor}
          </h4>
        </div>
      </div>
      {active && <CheckboxCheckedBlueSvg />}
    </RankingListItemStyled>
  );
}
export default memo(RankingListItem2);
