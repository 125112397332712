export default function RankingIconSvg() {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7469 27.275L24 32V0H0V32L11.2531 27.275C11.7324 27.0821 12.2676 27.0821 12.7469 27.275Z"
        fill="#BBA14F"
      />
    </svg>
  );
}
