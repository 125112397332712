import styled from "styled-components";

export const SearchBarWrap = styled.div`
  background-color: white;
  padding: 8px 24px;
  border-radius: 24px;
  height: 48px;
  width: ${(props) => (props.active ? "100%" : "384px")};

  input {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const SearchCountryStyled = styled.div`
  background-color: white;
  height: 48px;
  width: ${(props) => (props.active ? "100%" : "384px")};
  display: flex;
  flex-grow: 1;

  .MuiOutlinedInput-input {
    padding: 0;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 0;
    fieldset {
      border: none;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    border-radius: 24px;
    border: 1px solid #e4e5e7;

    input {
      border: none;
      display: flex;
      padding-right: 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      line-height: 22px;
      height: 48px;
      &::placeholder {
        color: #afb1b8;
      }
    }
  }
`;
