/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
import { Modal } from "@mui/material";
import { warningAlert } from "components/alert";
import { TextLinked } from "components/buttons";
import RankingListItem2 from "components/rankingListItem2";
import SubHeaderMobile from "components/subHeaderMobile";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { getListKind } from "services/EconomicCalendarService";
import { ModalStyled, SelfScrollDiv } from "views/HeatMap/styled";
import FooterResetSubmit from "./components/FooterResetSubmit";

function ModalListOfKind({
  open,
  onClose,
  listSelectedKind,
  setListSelectedKind,
  value
}) {
  const dispatch = useDispatch();
  const [kindList, setKindList] = useState([]);
  const [listSelected, setListSelected] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      getKindList();
      setListSelected(listSelectedKind);
    }
  }, [open]);

  const getKindList = async () => {
    try {
      setIsLoading(true);
      let codeGroupId = "";
      switch (value) {
        case "56827539D3E94748A2D8CD75CB830F5C":
          codeGroupId = "INDICATOR_KIND";
          break;
        case "E20C3E24BDCF495EA9B8BBB11C4A9FC5":
          codeGroupId = "DISCLOSURE_KIND";
          break;
        case "A4C3049BD5A8412E9E02A66DD7F4CEA0": // earning
          codeGroupId = "SECTOR_DEPTH_1";
          break;
        case "7484C0B060E142139A078EEA60BD405B": // dividen
          codeGroupId = "SECTOR_DEPTH_1";
          break;
        case "27B6669ACD814B4F8FCCB474798466F0": // split
          codeGroupId = "SECTOR_DEPTH_1";
          break;
        case "1B0275B8A7AF4F6BBB2F5F387AC86AD8":
          codeGroupId = "MATURITY_KIND";
          break;
        default:
          break;
      }
      const res = await getListKind({ codeGroupId });
      if (res.code === "200") {
        setKindList(res?.data?.list || []);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));
  const handleSelect = (item) => {
    const { codeValue } = item;
    if (
      listSelected.find((obj) => obj?.codeValue === codeValue) !== undefined
    ) {
      setListSelected((e) => e.filter((a) => a?.codeValue !== codeValue));
    } else if (codeValue === "ALL") {
      setListSelected([item]);
    } else if (
      listSelected.find((obj) => obj?.codeValue === "ALL") !== undefined
    ) {
      setListSelected([item]);
    } else setListSelected((prevSelected) => [...prevSelected, item]);
  };

  const resetAll = () => {
    setListSelected([
      {
        viewOrder: 0,
        defaultValue: "Y",
        labelKor: "전체",
        labelEng: "All",
        codeValue: "ALL"
      }
    ]);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <SubHeaderMobile
          headerTitle={<h1>{t("info.category")}</h1>}
          borderBottom
          customAction={
            <TextLinked onClick={resetAll} btnText={t("button.deSelect")} />
          }
          onBack={onClose}
        />
        <SelfScrollDiv className="hidden-scrollbar h-100vh">
          <SelfScrollDiv style={{ paddingTop: "8px" }}>
            {!isEmpty(kindList) &&
              isArray(kindList) &&
              kindList.map((item, index) => {
                return (
                  <RankingListItem2
                    item={item}
                    key={index}
                    active={
                      listSelected.find(
                        (obj) => obj?.codeValue === item?.codeValue
                      ) !== undefined
                    }
                    onClick={() => handleSelect(item)}
                  />
                );
              })}
          </SelfScrollDiv>
        </SelfScrollDiv>
        <FooterResetSubmit
          onCancle={() => {
            setListSelected(listSelectedKind);
            onClose();
          }}
          onSubmit={() => {
            if (listSelected.length === 0) {
              warningAlert(t("notice.warningAlertSelectItem"));
            } else {
              setListSelectedKind(listSelected);
              onClose();
            }
          }}
        />
      </ModalStyled>
    </Modal>
  );
}

export default memo(ModalListOfKind);
