export default function StarSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.2 8.6L12 2L14.8 8.6L22 9.2L16.6 14L18.2 21L12 17.3L5.8 21L7.4 14L2 9.2L9.2 8.6Z"
        stroke={fillColor}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
}
