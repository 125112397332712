/* eslint-disable no-unused-vars */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getNickName = (params) =>
  newAxiosInstance.get(URL.SEARCH_NICK_NAME + qs.stringify(params, true));

export const getListRoom = (params) =>
  newAxiosInstance.get(URL.GET_LIST_ROOM + qs.stringify(params, true));

export const deleteRoom = (params) =>
  newAxiosInstance.delete(URL.DELETE_MESSAGE + qs.stringify(params, true));

export const getRoom = (params) =>
  newAxiosInstance.get(URL.GET_ROOM + qs.stringify(params, true));

export const checkRoom = (params) =>
  newAxiosInstance.get(URL.CHECK_ROOM + qs.stringify(params, true));

export const createRoom = (body) =>
  newAxiosInstance.post(URL.CREATE_ROOM, body);

export const attachmentsChat = (body) =>
  newAxiosInstance.post(URL.ATTACHMENT_CHAT, body, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
