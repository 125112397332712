/* eslint-disable no-unused-vars */
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Modal, Tab } from "@mui/material";
import Header from "components/subHeaderMobile";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "redux/reducers/myPageReducer";
import ChangePassword from "../ChangePassword";
import MyPageChangeProfile from "../MyPageChangeProfile";
import MyPageKeyword from "../MyPageKeyword";
import MyPagePoint from "../MyPagePoint";
import MyPageScrap from "../MyPageScrap";
import MyPageWithDraw from "../MyPageWithDraw";
import { ModalStyled } from "./styled";

export default function ModalSettingMyPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultListTab = [
    {
      id: 0,
      title: t("info.morePage.myPage.children.keyword")
    },
    {
      id: 3,
      title: t("info.morePage.myPage.children.scrap")
    },
    {
      id: 4,
      title: t("info.profileSetting")
    },
    {
      id: 5,
      title: t("info.morePage.myPage.children.point")
    },
    // {
    //   id: 6,
    //   title: "회원정보 수정"
    // },
    {
      id: 6,
      title: t("info.morePage.myPage.children.password")
    },
    {
      id: 8,
      title: t("button.accountClosure")
    }
  ];
  const tabIndex = useSelector((state) => state?.myPage.tabIndex);

  const openSettingModal = useSelector(
    (state) => state.myPage.openSettingModal
  );

  useEffect(() => {
    dispatch(
      myPageActions.updateAvatar({
        avatarFile: null,
        avatarUrl: JSON.parse(localStorage.getItem("user"))?.imgPath
      })
    );
  }, [openSettingModal, tabIndex]);

  const handleChange = (_, newValue) => {
    dispatch(myPageActions.setTabIndex(newValue));
  };

  const onClose = () => dispatch(myPageActions.onCloseSettingModal());

  return (
    <Modal
      open={openSettingModal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          logo={false}
          borderBottom
          onBack={onClose}
          headerTitle={<h1>{t("button.setting")}</h1>}
        />
        <TabContext value={tabIndex.toString()}>
          <div className="hidden-scrollbar flex-col flex-1 full-height flow-y-auto gap-32">
            <TabList
              variant="scrollable"
              scrollButtons={false}
              className="pt-32"
              onChange={handleChange}
              TabIndicatorProps={{
                hidden: true
              }}>
              {defaultListTab?.map((item, index) => (
                <Tab
                  key={`Tab_${index}`}
                  label={<h5 style={{ color: "inherit" }}>{item?.title}</h5>}
                  value={item?.id.toString()}
                />
              ))}
              <div className="last-padding"> </div>
            </TabList>
            <div
              className="hidden-scrollbar flex-1 full-height flow-y-auto flex-col"
              id="view-scroll">
              {defaultListTab?.map((item, index) => (
                <TabPanel
                  key={`tab_panel${index}`}
                  value={item?.id.toString()}
                  className="full-height">
                  {tabIndex === "0" && <MyPageKeyword />}
                  {tabIndex === "3" && <MyPageScrap />}
                  {tabIndex === "4" && <MyPageChangeProfile />}
                  {tabIndex === "5" && <MyPagePoint />}
                  {/* {tabIndex === "6" && <PersonalInformationSetting />} */}
                  {tabIndex === "6" && <ChangePassword />}
                  {tabIndex === "8" && <MyPageWithDraw />}
                </TabPanel>
              ))}
            </div>
          </div>
        </TabContext>
      </ModalStyled>
    </Modal>
  );
}
