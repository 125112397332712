/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { successAlert } from "components/alert";
import CenterConfirmModal from "components/centerConfirmModal";
import { OutlinedInputBorder24 } from "components/modal/components/OutlinedInputBorder24";
import SvgIcons from "components/svgIcons";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import {
  getDetail,
  withDrawAccount
} from "services/MyPageService/MyPageService";
import authService from "services/auth-service";
import ModalReason from "./modal/ModalReason";
import { MyPageWithDrawStyled } from "./styled";

export default function MyPageWithDraw() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reason, setReason] = useState({
    label: t("info.reasonForWithdrawal"),
    codeValue: 0
  });
  const setLoading = (value) => dispatch(commonActions.setLoading(value));
  const handleReasonChange = (selectedReason) => {
    setReason(selectedReason);
  };
  const [openModal, setOpenModal] = useState(false);
  const [openSubmitDelete, setOpenSubmitDelete] = useState(false);
  const [visible, setVisible] = useState({
    password: false,
    confirmPassword: false
  });

  useEffect(() => {
    if (reason.codeValue === 7 && reason.label !== "") {
      setReason((prev) => ({ ...prev, label: "" }));
    }
  }, [reason?.codeValue]);

  useEffect(() => {
    getUserDetail();
  }, []);

  const onSubmit = async (values) => {
    try {
      // setLoading(true);
      const res = await withDrawAccount({
        email: formik.values.email,
        password: formik.values.password,
        reason: reason?.label
      });
      if (res.code === "200") {
        await authService.logoutUser();
        successAlert(t("notice.withDrawSuccess"));
        navigate("/");
      }
    } catch (e) {
      console.error(e);
    } finally {
      // setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit
  });

  const getUserDetail = async () => {
    try {
      setLoading(true);
      const res = await getDetail();
      if (res.code === "200") {
        formik.setFieldValue("email", res?.data?.mail);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleInputClick = () => {
    setOpenModal((e) => !e);
  };

  return (
    <MyPageWithDrawStyled className="pl-20 pr-20 pb-64">
      <div className="flex-col gap-32">
        <form className="flex-col gap-12">
          <OutlinedInputBorder24
            name="email"
            value={formik.values.email}
            disabled
          />
          <OutlinedInputBorder24
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder={t("info.limitPasswords")}
            type={visible?.password ? "text" : "password"}
            endAdornment={
              <button
                type="button"
                className="btn-40px-end"
                onClick={() =>
                  setVisible((e) => ({ ...e, password: !e.password }))
                }>
                <SvgIcons name={visible.password ? "eyeOff" : "eye"} />
              </button>
            }
          />
          <OutlinedInputBorder24
            name="withdraw_reason"
            value={reason?.label}
            onChange={(e) => {
              if (reason?.codeValue === 7)
                setReason((prev) => ({ ...prev, label: e.target.value }));
            }}
            onClick={reason?.codeValue === 7 ? () => {} : handleInputClick}
            placeholder={
              reason?.codeValue === 7 ? t("button.reportReasons.other") : ""
            }
            endAdornment={
              <button
                type="button"
                className="btn-40px-end"
                onClick={reason?.codeValue === 7 ? handleInputClick : () => {}}>
                <SvgIcons name="arrowDown" />
              </button>
            }
          />
          <div className="pt-12">
            <button
              type="button"
              disabled={
                reason?.label?.length === 0 ||
                formik.values.password?.length === 0
              }
              className={classNames("pd-12-20 br-24 full-width", {
                "bg-neutral-8":
                  reason?.label?.length === 0 ||
                  formik.values.password?.length === 0,
                "bg-buy":
                  reason?.label?.length > 0 &&
                  formik.values.password?.length > 0
              })}
              onClick={() => setOpenSubmitDelete(true)}>
              <h5
                className={classNames({
                  "color-neutral-5":
                    reason?.label?.length === 0 ||
                    formik.values.password?.length === 0,
                  "color-neutral-10":
                    reason?.label?.length > 0 &&
                    formik.values.password?.length > 0
                })}>
                {t("button.accountClosure")}
              </h5>
            </button>
          </div>
        </form>
        <ul className="flex-col gap-12">
          <li>{t("info.infoWithDraw.infoRetrieve")}</li>
          <li>{t("info.infoWithDraw.infoProtection")}</li>
          <li>{t("info.infoWithDraw.lostPoint")}</li>
          <li>{t("info.infoWithDraw.postAndComment")}</li>
          <li>{t("info.infoWithDraw.requestRemovePost")}</li>
        </ul>
        <ModalReason
          isOpen={openModal}
          onClose={handleInputClick}
          onReasonChange={handleReasonChange}
          reason={reason}
        />
        <CenterConfirmModal
          open={openSubmitDelete}
          onSubmit={() => {
            onSubmit();
            setOpenSubmitDelete(false);
          }}
          onCancel={() => {
            setOpenSubmitDelete(false);
          }}
          btnText={t("button.confirm")}
          textNotice={
            <>
              <h4>{t("notice.noticeWithDraw")}</h4>
              <h6 className="fw-500">
                {t("notice.noticeWithDraw1")}
                <br />
                {t("notice.noticeWithDraw2")}
              </h6>
            </>
          }
        />
      </div>
    </MyPageWithDrawStyled>
  );
}
