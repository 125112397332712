const URL = Object.freeze({
  LOGIN: "/api/auth/auth/login",
  REFRESH_TOKEN: "/api/auth/auth/refresh-token",
  CHANGE_BY_RESET_PASSWORD: "/api/auth/change-pass",
  CHECK_CODE: "/api/auth/check-code",
  CHANGE_PASSWORD: "/api/auth/change-pass/change",
  CHECK_TOKEN: "/api/auth/check-token",
  CHECK_MAIL: "/api/auth/check/mail",
  CHECK_NICKNAME: "/api/auth/check/nick-name",
  CHECK_USERNAME: "/api/auth/check/user-name",
  GET_CODE_EMAIL: "/api/user/auth-mail/getCode",
  AUTH_EMAIL: "/api/auth/email",
  FIND_PASSWORD: "/api/auth/find-pass",
  RESET_PASSWORD: "/api/auth/reset-pass",
  SNS_LOGIN: "/api/auth/signin/social",
  SIGN_UP: "/api/auth/signUp",
  SIGN_UP_SNS: "/api/auth/signup/social",
  WITH_DRAWL: "/api/auth/with-drawl",
  CHECK_PASSWORD: "/api/auth/check-password"
});
export default URL;
