import styled from "styled-components";

export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  svg {
    width: 32px !important;
    height: 32px !important;
    padding: ${({ pd }) => pd || "4px"};
    display: flex;
    align-items: center;
  }
`;
