export default function MessageCircleSvg({ fillColor = "#AFB1B8" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.51215 18.075C2.36215 18.075 2.21215 18.0167 2.09548 17.9C1.93715 17.7417 1.88715 17.5167 1.95382 17.3L3.46215 12.775C2.99548 11.775 2.75382 10.6667 2.75382 9.56666C2.75382 8.14166 3.15382 6.74999 3.90382 5.54166C4.65382 4.33333 5.72048 3.34999 6.99548 2.71666C8.04548 2.18333 9.22882 1.90833 10.4038 1.90833H10.8372C12.7538 2.01666 14.5205 2.80833 15.8538 4.14166C17.1872 5.47499 17.9788 7.24999 18.0788 9.13333V9.58333C18.0788 10.7667 17.8038 11.9583 17.2705 13.0167C16.6372 14.2917 15.6622 15.3583 14.4455 16.1083C13.2372 16.8583 11.8372 17.25 10.4122 17.2583C9.27048 17.2583 8.20382 17.0167 7.20382 16.55L2.67882 18.0583C2.62048 18.075 2.55382 18.0917 2.49548 18.0917L2.51215 18.075ZM10.4122 3.07499C9.41215 3.07499 8.41215 3.30833 7.52882 3.75833C6.44548 4.29999 5.53715 5.12499 4.90382 6.15833C4.27048 7.18333 3.92882 8.36666 3.92882 9.57499C3.92882 10.5833 4.16215 11.5833 4.61215 12.475C4.67882 12.6167 4.69548 12.775 4.64548 12.925L3.42882 16.5667L7.07048 15.35C7.22048 15.3 7.37882 15.3083 7.52048 15.3833C8.41215 15.8333 9.40382 16.075 10.4038 16.075C11.6372 16.075 12.8122 15.7417 13.8372 15.1C14.8622 14.4667 15.6955 13.5583 16.2288 12.475C16.6788 11.575 16.9205 10.575 16.9205 9.56666V9.14999C16.8372 7.58332 16.1622 6.08333 15.0288 4.95833C13.8955 3.83333 12.3955 3.15833 10.8038 3.06666H10.4205C10.4205 3.06666 10.4122 3.06666 10.4038 3.06666L10.4122 3.07499Z"
        fill={fillColor}
      />
    </svg>
  );
}
