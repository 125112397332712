import styled from "styled-components";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 24px;
    padding-right: 24px;
    background: var(--neutral-color-10, #ffffff);
    fieldset {
      border: var(--color-neutral-7, #e4e5e7) 1px solid;
      border-radius: 24px;
    }
  }
  .MuiInputBase-input {
    height: 15px;
    padding-left: 24px;
  }
  .MuiInputAdornment-root div {
    display: flex;
    align-items: center;
  }
`;

export const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  border-radius: 20px;
  height: 48px;
  background-color: var(--neutral-color-9);
  padding: 8px 0px;
`;

export const Input = styled.input`
  width: 100px;
  height: 100%;
  background-color: var(--neutral-color-9);
  border: none;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0px 8px 16px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
`;

export const PortfolioTextfieldStyle = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 24px;
    padding-right: 24px;
    background: var(--neutral-color-10, #ffffff);
    fieldset {
      border: var(--color-neutral-7, #e4e5e7) 1px solid;
      border-radius: 24px;
    }
  }

  input {
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
  }

  .MuiInputBase-input {
    height: 15px;
    padding-left: 24px;
  }
  .MuiInputAdornment-root div {
    display: flex;
    align-items: center;
  }
`;
