export default function ChevronUpSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50507 0.505074C6.77844 0.231707 7.22166 0.231707 7.49502 0.505074L13.495 6.50507C13.7684 6.77844 13.7684 7.22166 13.495 7.49502C13.2217 7.76839 12.7784 7.76839 12.5051 7.49502L7.00005 1.99L1.49502 7.49502C1.22166 7.76839 0.778441 7.76839 0.505074 7.49502C0.231707 7.22166 0.231707 6.77844 0.505074 6.50507L6.50507 0.505074Z"
        fill={fillColor}
      />
    </svg>
  );
}
