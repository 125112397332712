import { Dialog } from "@mui/material";
import AvatarImage from "components/userAvatar/AvatarImage";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "redux/reducers/myPageReducer";

export default function DialogAvatar() {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state?.myPage?.openDialogAvatar);
  const handleCloseAvatar = () => {
    dispatch(myPageActions.onCloseDialogAvatar());
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseAvatar}
      sx={{
        display: openDialog ? "block" : "none",
        "& .MuiDialog-container.MuiDialog-scrollPaper": {
          width: "100%",
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
            {
              width: "100%",
              height: "fit-content",
              background: "transparent",
              boxShadow: "none"
            }
        }
      }}>
      <AvatarImage size="100%" borderRadius={0} />
    </Dialog>
  );
}
