import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabIndex: "0",
  openSettingModal: false,
  openDrawerAvatar: false,
  openDialogAvatar: false,
  avatarFile: undefined,
  avatarUrl: JSON.parse(localStorage.getItem("user"))?.imgPath || null
};

const myPageSlice = createSlice({
  name: "myPage",
  initialState,
  reducers: {
    setTabIndex: (state, action) => {
      state.tabIndex = action?.payload;
    },
    onOpenSettingModal: (state) => {
      state.openSettingModal = true;
    },
    onCloseSettingModal: (state) => {
      state.openSettingModal = false;
    },
    onOpenDrawerAvatar: (state) => {
      state.openDrawerAvatar = true;
    },
    onCloseDrawerAvatar: (state) => {
      state.openDrawerAvatar = false;
    },
    onOpenDialogAvatar: (state) => {
      state.openDialogAvatar = true;
    },
    onCloseDialogAvatar: (state) => {
      state.openDialogAvatar = false;
    },
    updateAvatar: (state, action) => {
      if (action?.payload?.avatarUrl)
        state.avatarUrl = action?.payload?.avatarUrl;
      if (action?.payload?.avatarFile)
        state.avatarFile = action?.payload?.avatarFile;
    }
  }
});

// Actions
export const myPageActions = myPageSlice.actions;

// Reducer
const myPageReducer = myPageSlice.reducer;
export default myPageReducer;
