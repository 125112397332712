/* eslint-disable no-unused-vars */
import { CheckboxCheckedBlueSvg } from "components/icons";
import { memo } from "react";
import { useTranslation } from "react-i18next";

function CategoryItem({ item, active, onClick }) {
  const { i18n } = useTranslation();
  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 20px",
        borderBottom: "1px solid var(--neutral-color-8, #F4F4F4)"
      }}>
      <h4>
        {i18n.language === "ko" ? item?.themaLabelKor : item?.themaLabelEng}
      </h4>

      <div style={{ padding: "8px 0 8px 16px", width: "40px", height: "40px" }}>
        {active ? <CheckboxCheckedBlueSvg /> : " "}
      </div>
    </button>
  );
}
export default memo(CategoryItem);
