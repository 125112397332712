/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */
import DefaultAvatar from "assets/img/mobile/avatar_mobile.svg";
import { MorePagging } from "components/buttons";
import SvgIcons from "components/svgIcons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { getListPoint } from "services/MyPageService/MyPageService";
import { localeAndFixed } from "util/common";
import { SelfScrollDiv } from "views/HeatMap/styled";
import { NumberTab4Styled } from "./styled";

export default function MyPagePoint() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listPoint, setListPoint] = useState();
  const [totalPoint, setTotalPoint] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const setLoading = (value) => dispatch(commonActions.setLoading(value));
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getPointList();
  }, [currentPage]);

  const getPointList = async () => {
    try {
      setLoading(true);
      const res = await getListPoint({ pageNumber: currentPage, pageSize: 10 });
      if (res.code === "200") {
        setListPoint(
          currentPage === 1
            ? res?.data?.data
            : [...listPoint, ...res?.data?.data]
        );
        setTotalItems(res?.pagination?.totalItems || 0);
        setTotalPoint(res?.data?.countSumPoint);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClickViewMore = () => setCurrentPage((e) => e + 1);

  const formatPoint = (point) => {
    if (point > 0) return `+${point}`;
    else return `${point}`;
  };

  return (
    <NumberTab4Styled className="pl-20 pr-20 pb-64 flow-y-auto hidden-scrollbar full-height flex-1">
      <SelfScrollDiv className="hidden-scrollbar full-height flex-col gap-32">
        <div>
          <div className="pd-16 flex-between bg-neutral-9">
            <div className="flex gap-8">
              <img
                src={
                  user?.imgPath === null || user?.imgPath === ""
                    ? DefaultAvatar
                    : user?.imgPath
                }
                alt="사용자_이미지_썸네일"
              />
              <div className="flex-centered gap-2">
                <h4>{user?.nickname}</h4>
                <SvgIcons name="next2Icon" className="flex-centered" />
              </div>
            </div>
            <div className="flex-centered gap-8">
              <h6 className="fw-500 lh-20 color-info">
                {t("info.pointsHeld")}
              </h6>
              <h1 className="italic font-2">{localeAndFixed(totalPoint, 0)}</h1>
            </div>
          </div>
        </div>
        <SelfScrollDiv className="hidden-scrollbar">
          {listPoint?.map((item, index) => (
            <div className="point-list" key={`listpoint_${index}`}>
              <h5 className="fw-500 color-neutral ellipsis">{item.action}</h5>
              <div className="flex-centered gap-12">
                <h3 className="fs-14 font-2 txt mt-2">
                  {formatPoint(item?.point)}
                </h3>
                <p className="caption fw-400 day font-2">
                  {moment(item?.createdDate).format("YYYY-MM-DD hh:mm")}
                </p>
              </div>
            </div>
          ))}
          <MorePagging
            status={listPoint?.length}
            limit={totalItems}
            onClick={onClickViewMore}
          />
        </SelfScrollDiv>
      </SelfScrollDiv>
    </NumberTab4Styled>
  );
}
