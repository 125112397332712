/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import ApiUtil from "../util/Api";
import authService from "./auth-service";

let token = localStorage.getItem("token");
let refreshPromise = null;

const { refreshToken, isTokenExpired } = authService;

const axiosInstance = axios.create({
  baseURL: ApiUtil.getRealApiUrl(),
  headers: {
    Authorization: !isEmpty(token) ? `Bearer ${token}` : ""
  }
});

axiosInstance.interceptors.request.use(
  async (req) => {
    const currentRefreshToken = localStorage.getItem("refresh_token");
    const tokenNew = localStorage.getItem("token");
    if (isEmpty(tokenNew)) {
      token = tokenNew;
      req.headers.Authorization = "";
      return req;
    }
    if (!isEmpty(token) && !isEmpty(currentRefreshToken)) {
      if (isTokenExpired(token)) {
        refreshPromise = refreshPromise || refreshToken(currentRefreshToken);
        const newToken = await refreshPromise;
        refreshPromise = null;
        token = newToken;
      }
      req.headers.Authorization = `Bearer ${token}`;
    } else if (isEmpty(token)) {
      if (!isEmpty(tokenNew)) {
        req.headers.Authorization = `Bearer ${tokenNew}`;
      }
    }

    return req;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
