import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authReducer";
import { getUserProfile } from "../common/FuncComm";
import { ERROR_MAP, USER_ROLE } from "../constants";
import { getOpenLogin } from "../redux/actions/loginAction";
import useError from "./useError";
import useResponsive from "./useResponsive";

function useCheckPermission() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = getUserProfile();
  const { authority = 0, id: idUser, userRole } = userProfile;
  const { addError } = useError();
  const { isTabletOrMobile } = useResponsive();

  const checkPermission = ({
    boardAuthority = 0,
    idWriter = 0,
    secret = false,
    callback = () => {},
    subCallback = () => {},
    checkAdminRole = false,
    messageError = ""
  }) => {
    if (boardAuthority > 0) {
      if (!isEmpty(userProfile)) {
        const isNotWriter = idWriter !== null ? idUser !== idWriter : true;
        const isNotAdmin = userRole !== USER_ROLE.ADMIN;
        const shouldIncludeCheckAdminRole = checkAdminRole
          ? isNotWriter && isNotAdmin
          : isNotWriter;
        const canViewSecretPost = secret
          ? secret && (!isNotWriter || authority >= 8)
          : true;

        const condition = secret
          ? !canViewSecretPost
          : boardAuthority > authority && shouldIncludeCheckAdminRole;

        if (condition) {
          if (!messageError) {
            addError(ERROR_MAP.NO_AUTH.MSG, ERROR_MAP.NO_AUTH.CD);
          } else {
            addError(messageError);
          }
          subCallback?.();
        } else {
          callback?.();
        }
      } else if (!isTabletOrMobile) {
        dispatch(getOpenLogin());
        subCallback?.();
      } else {
        navigate("/login");
        subCallback?.();
      }
    } else if (secret) {
      const isNotWriter = idWriter !== null ? idUser !== idWriter : true;
      const canViewSecretPost = secret
        ? secret && (!isNotWriter || authority >= 8)
        : true;
      if (!canViewSecretPost) {
        if (!messageError) {
          addError(ERROR_MAP.NO_AUTH.MSG, ERROR_MAP.NO_AUTH.CD);
        } else {
          addError(messageError);
        }
        subCallback?.();
      } else {
        callback?.();
      }
    } else {
      callback?.();
    }
  };

  const handleCheckPermisionMobile = (callback = () => {}) => {
    const TOKEN = localStorage.getItem("token");
    if (!TOKEN) {
      const buttonId = Date.now();
      localStorage.setItem(
        "previousUrlFunc",
        window.location.pathname + window.location.search
      );
      localStorage.setItem("clickedButton", buttonId);

      dispatch(authActions.onOpenModalLogin());
    } else callback();
  };

  return {
    checkPermission,
    handleCheckPermisionMobile
  };
}

export default useCheckPermission;
