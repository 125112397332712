/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, OutlinedInput, Toolbar } from "@mui/material";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import DropDownFilledA from "components/dropDownFilledA";
import Flag from "components/flag";
import { ExchangeSvg } from "components/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "redux/reducers/commonReducer";
import { getCalculatorResult } from "services/MarketService/MarketService";
import ModalSelectNation from "./ModalSelectNation";
import { Drawer, Puller } from "./styled";

export default function ModalExchangeCalculator({ enableSwapping = true }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currency, setCurrency] = useState();
  const [target, setTarget] = useState();
  const [dataCalculator, setDataCalculator] = useState();
  const [openModalNationCurrency, setOpenModalNationCurrency] = useState(false);
  const [openModalNationTarget, setOpenModalNationTarget] = useState(false);
  const [searchDataCurrency, setSearchDataCurrency] = useState({
    currency: "BTC",
    labelEng: "Bitcoin",
    labelKor: "Bitcoin",
    baseFileName: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
  });
  const [searchDataTarget, setSearchDataTarget] = useState({
    currency: "KRW",
    labelEng: "Korean Won",
    labelKor: "대한민국 원",
    baseFileName:
      "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg"
  });
  const currencyFromNation = searchDataCurrency.currency;
  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));
  const isOpen = useSelector(
    (state) => state.common.openModalExchangeCalculator
  );
  const onClose = () => {
    dispatch(commonActions.onCloseModalExchangeCalculator());
    setCurrency("");
    setTarget("");
  };

  useEffect(() => {
    getResultCalculator();
  }, [searchDataCurrency, searchDataTarget]);

  const handleChangeCurrency = (values, flag) => {
    if (validateCurrency(values)) {
      const newValue = +values.floatValue || 0;
      const currentRate = dataCalculator?.rate * 10e8;
      if (flag && newValue) {
        setCurrency(parseFloat(newValue.toFixed(8)));
        setTarget(parseFloat(((newValue * currentRate) / 10e8).toFixed(8)));
      } else {
        setTarget(parseFloat(newValue.toFixed(8)));
        setCurrency(parseFloat(((newValue * 10e8) / currentRate).toFixed(8)));
      }
    }
  };
  const validateCurrency = (values) => {
    const { floatValue } = values;
    return (+floatValue || 0) <= 10e15 && (+floatValue || 0) >= -10e15;
  };

  const handleModalNationCurrency = () => {
    setOpenModalNationCurrency((e) => !e);
  };

  const handleModalNationTarget = () => {
    setOpenModalNationTarget((e) => !e);
  };

  const getResultCalculator = async () => {
    try {
      setIsLoading(true);
      const res = await getCalculatorResult({
        baseCurrency: searchDataCurrency?.currency,
        targetCurrency: searchDataTarget?.currency,
        amount: currency || "1"
      });
      if (res.code === "200") {
        setDataCalculator(res?.data);
        setTarget(res?.data?.amount);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onExchangeTwoCurrency = () => {
    setSearchDataCurrency((prevState) => ({
      ...prevState,
      ...searchDataTarget
    }));
    setSearchDataTarget((prevState) => ({
      ...prevState,
      ...searchDataCurrency
    }));
  };

  return (
    <Drawer
      onOpen={() => {}}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: isOpen ? "block" : "none",
        "& .MuiPaper-root": {
          borderRadius: "16px 16px 0 0"
        },
        "& img": {
          width: "24px",
          height: "24px",
          objectFit: "cover",
          borderRadius: "50%",
          background: "var(--neutral-color-7,#E4E5E7)",
          boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.50)"
        },

        "& input": {
          textAlign: "end"
        }
      }}>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
          <div className="h2">{t("button.calculator")}</div>
          {enableSwapping && (
            <button
              type="button"
              className="flex-center gap-8"
              onClick={onExchangeTwoCurrency}>
              <ExchangeSvg />
            </button>
          )}
        </Toolbar>
      </AppBar>
      <div className="flex-col gap-24 pd-8-24">
        <div className="flex-col gap-12">
          <DropDownFilledA
            itemDisplay={
              <div className="flex-center gap-12">
                {searchDataCurrency?.baseFileName ? (
                  <Flag
                    item={{
                      baseFileName: searchDataCurrency?.baseFileName
                    }}
                  />
                ) : (
                  <div className="block-24px"> </div>
                )}
                <h4>{searchDataCurrency?.currency}</h4>
                <p className="caption">{searchDataCurrency?.labelKor}</p>
              </div>
            }
            onClickDropDown={handleModalNationCurrency}
          />
          <NumericFormat
            value={currency}
            onValueChange={(values) => handleChangeCurrency(values, true)}
            customInput={OutlinedInput}
            type="tel" //
            inputProps={{
              "aria-label": "input",
              style: {
                textAlign: "right",
                fontWeight: "700",
                fontSize: "16px",
                padding: "12px 16px",
                border: "2px"
              }
            }}
            decimalScale={8}
            isAllowed={validateCurrency}
            thousandSeparator
            valueIsNumericString
          />
          <div className="flex-between">
            <h6 className="lh-20 fw-500 color-info">
              1 {searchDataCurrency?.currency} = {dataCalculator?.rate}{" "}
              {searchDataTarget?.currency}
            </h6>
            <div className="flex gap-2">
              <h6 className="fw-500 color-info">1</h6>
              <h6 className="fw-500 color-info">
                {searchDataCurrency?.currency}
              </h6>
            </div>
          </div>
        </div>
        <h1 className="text-centered fs-50">=</h1>
        <div className="flex-col gap-12">
          <DropDownFilledA
            itemDisplay={
              <div className="flex-center gap-12">
                {searchDataTarget?.baseFileName ? (
                  <Flag
                    item={{
                      baseFileName: searchDataTarget?.baseFileName
                    }}
                  />
                ) : (
                  <div className="block-24px"> </div>
                )}
                <h4>{searchDataTarget?.currency}</h4>
                <p className="caption">{searchDataTarget?.labelKor}</p>
              </div>
            }
            onClickDropDown={handleModalNationTarget}
          />
          <NumericFormat
            value={target}
            onChange={(values) => handleChangeCurrency(values, false)}
            disabled
            customInput={OutlinedInput}
            type="tel"
            inputProps={{
              "aria-label": "input",
              style: {
                textAlign: "right",
                fontWeight: "700",
                fontSize: "16px",
                padding: "12px 16px",
                border: "2px"
              }
            }}
            decimalScale={8}
            isAllowed={validateCurrency}
            thousandSeparator
            valueIsNumericString
          />
          <div className="flex-between">
            <h6 className="lh-20 fw-500 color-info">
              {" "}
              1 {searchDataTarget.currency} ={" "}
              {Math.round(1 / dataCalculator?.rate)}{" "}
              {searchDataCurrency.currency}
            </h6>
            <div className="flex gap-2">
              <h6 className="fw-500 color-info">{dataCalculator?.rate}</h6>
              <h6 className="fw-500 color-info">
                {dataCalculator?.currencyUnitKor}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <ModalSelectNation
        isOpen={openModalNationCurrency}
        closeModal={handleModalNationCurrency}
        forPage="CalculatorNationCurrency"
        nation={searchDataCurrency}
        setIsShowFilter={() => {}}
        setNation={(item) => {
          setSearchDataCurrency((prevState) => ({
            ...prevState,
            ...item
          }));
        }}
        selCurrency={searchDataTarget.currency}
      />
      <ModalSelectNation
        isOpen={openModalNationTarget}
        closeModal={handleModalNationTarget}
        forPage="CalculatorNationTarget"
        nation={searchDataTarget}
        setIsShowFilter={() => {}}
        setNation={(item) => {
          setSearchDataTarget((prevState) => ({
            ...prevState,
            ...item
          }));
        }}
        selCurrency={currencyFromNation}
      />
      <ButtonFixedCancel onBack={onClose} text={t("button.cancel")} />
    </Drawer>
  );
}
