/* eslint-disable no-unused-vars */
import styled from "@emotion/styled";
import EmptyNotice from "components/emptyNotice";
import Flag from "components/flag";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postActions } from "redux/reducers/postReducer";
import {
  getPopularList,
  getRecentList
} from "services/MarketService/MarketService";
import { handleAddToRecent } from "util/common";
import { InterestButtonPC } from "../button/interestButton";
import SearchBarPC from "../textFields/searchBarPc";
import PCCenterModal from "./PCCenterModal";

const Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    background-color: var(--accent-sell-5, rgba(80, 127, 248, 0.05));
  }

  .interest-button:hover {
    svg path {
      fill: #9497a0 !important;
    }
  }
`;

function RecentSearchItem({ item, onClick, i18n }) {
  const { labelEng, labelKor, codeLabelEng, codeLabelKor, fileName, symbol } =
    item;
  return (
    <Styled className="flex-between-center">
      <button
        type="button"
        className="flex-start-center gap-12 pd-8-4-8-8"
        onClick={onClick}>
        <Flag item={{ baseFileName: fileName }} />
        <h5 className="lh-14 text-hover-accent-sell">
          {i18n?.language === "en"
            ? labelEng || labelKor
            : labelKor || labelEng}
        </h5>
      </button>
      <div className="flex-center gap-16">
        <div className="flex-center gap-8">
          <h6 className="caption">{symbol}</h6>
          <div
            style={{
              width: 1,
              height: 14,
              backgroundColor: "#E4E5E7"
            }}
          />
          <h6 className="caption">
            {i18n?.language === "en"
              ? codeLabelEng || codeLabelKor
              : codeLabelKor || codeLabelEng}
          </h6>
        </div>
        <InterestButtonPC item={item} elementClass="pd-4" />
      </div>
    </Styled>
  );
}

function SearchThemeModal() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const keyword = params.get("search");
  const openModalThemaSearch = useSelector(
    (state) => state?.post?.openModalThemaSearch
  );
  const token = localStorage.getItem("token");
  const flag = useSelector((state) => state?.portfolio?.flag);
  const [recentList, setRecentList] = useState([]);
  const [popularList, setPopularList] = useState([]);

  useEffect(() => {
    getRecentListData();
    getPopularListData();
  }, [flag]);

  const onClose = () => dispatch(postActions.onCloseModalThemaSearch());

  useEffect(() => {
    if (openModalThemaSearch) {
      if (token) getRecentListData();
      getPopularListData();
    }
  }, [openModalThemaSearch, flag]);

  const onSearch = (input) => {
    navigate(`/?tab=0&search=${input}`);
    onClose();
  };

  const getRecentListData = async () => {
    try {
      const res = await getRecentList({
        currentPage: 1,
        pageSize: 5
      });
      if (res?.code === "200") setRecentList(res?.data);
      else setRecentList([]);
    } catch (e) {
      console.error(e);
    }
  };

  const getPopularListData = async () => {
    try {
      const res = await getPopularList({
        currentPage: 1,
        pageSize: 5
      });
      if (res?.code === "200") setPopularList(res?.data);
      else setPopularList([]);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PCCenterModal open={openModalThemaSearch} onClose={onClose}>
      <SearchBarPC
        placeholder={t("info.searchModalThema.placeholder")}
        onSearch={onSearch}
        currentValue={keyword || ""}
      />
      <div
        className="overflow custom-pc-scroll pr-24"
        style={{
          maxHeight: 408,
          overflowY: "auto",
          overflowX: "hidden"
        }}>
        <div className="flex-col gap-24">
          {token && (
            <div className="flex-col gap-8">
              <h6 className="fw-500 pl-8">{t("info.recentView")}</h6>
              <div className="flex-col pd-4-4-4-0">
                {recentList?.list?.length > 0 ? (
                  recentList?.list?.slice(0, 4)?.map((item) => (
                    <RecentSearchItem
                      i18n={i18n}
                      key={item?.symbol}
                      item={item}
                      onClick={() => {
                        handleAddToRecent(item);
                        onSearch(
                          item?.labelEng ||
                            item?.labelKor ||
                            item?.codeLabelEng ||
                            item?.codeLabelKor
                        );
                      }}
                    />
                  ))
                ) : (
                  <EmptyNotice />
                )}
              </div>
            </div>
          )}
          <div className="flex-col gap-8">
            <h6 className="fw-500 pl-8">{t("info.popularSearch")}</h6>
            <div className="flex-col pd-4-4-4-0">
              {popularList?.list?.length > 0 ? (
                popularList?.list?.slice(0, 4)?.map((item) => (
                  <RecentSearchItem
                    i18n={i18n}
                    key={item?.symbol}
                    item={item}
                    onClick={() => {
                      handleAddToRecent(item);
                      onSearch(
                        item?.labelEng ||
                          item?.labelKor ||
                          item?.codeLabelEng ||
                          item?.codeLabelKor
                      );
                    }}
                  />
                ))
              ) : (
                <EmptyNotice />
              )}
            </div>
          </div>
        </div>
      </div>
    </PCCenterModal>
  );
}
PCCenterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SearchThemeModal;
