/* eslint-disable no-unused-vars */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const registerKeyword = (body) =>
  newAxiosInstance.post(URL.REGISTER_KEY_WORD, body);

export const getListKeyword = (key) =>
  newAxiosInstance.post(`${URL.GET_LIST_KEY_WORD}`);

export const deleteKeyword = (id) =>
  newAxiosInstance.delete(`${URL.DELETE_KEY_WORD}/${id}`);

// scrap
export const getListScrap = (params) =>
  newAxiosInstance.post(URL.GET_LIST_SCRAP + qs.stringify(params, true));

export const deleteScrapByPost = (params) =>
  newAxiosInstance.delete(URL.DELETE_SELECTED + qs.stringify(params, true));

export const deleteAllScrap = (params) =>
  newAxiosInstance.delete(URL.DELETE_ALL + qs.stringify(params, true));
// point
export const getListPoint = (params) =>
  newAxiosInstance.get(URL.GET_LIST_POINT + qs.stringify(params, true));
// change-pass
export const changePassword = (body) =>
  newAxiosInstance.post(URL.CHANGE_PASS, body);
// check-nickname
export const checkNickname = (params) =>
  newAxiosInstance.get(URL.CHECK_NICKNAME + qs.stringify(params, true));
// detail
export const getDetail = (params) =>
  newAxiosInstance.get(URL.GET_USER_DETAIL + qs.stringify(params, true));
// all
export const getAll = (params) =>
  newAxiosInstance.get(URL.GET_USER_ALL + qs.stringify(params, true));
// change-profile
export const updateProfile = (body) =>
  newAxiosInstance.put(URL.CHANGE_PROFILE, body);
// save-attachments
export const saveAttachments = (body) =>
  newAxiosInstance.post(URL.SAVE_ATTACHMENT, body, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
// withDraw
export const withDrawAccount = (params) =>
  newAxiosInstance.post(URL.WITHDRAW + qs.stringify(params, true));
