/* eslint-disable no-unused-vars */
import { InputAdornment } from "@mui/material";
import {
  CheckboxCheckedCircleSvg,
  CheckboxUncheckedCircleSvg
} from "components/icons";
import SvgIcons from "components/svgIcons";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PortfolioTextfieldStyle } from "../textFields/styled";
import PCCenterModal from "./PCCenterModal";

function ModalNewPortfolioPC({
  open,
  onClose,
  onSubmitRegister,
  onCancel,
  portfolio
}) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState("N");
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  return (
    <PCCenterModal
      open={open}
      onClose={() => {
        onClose();
      }}
      height={318}>
      <div className="flex-col bg-neutral-10 modal-content">
        <div className="flex-end-center pd-8 border-1px-bot-neutral-8">
          <button
            className="block-32px flex-centered"
            type="button"
            onClick={onClose}>
            <SvgIcons name="clearIcon" />
          </button>
        </div>
        <div className="flex-col gap-24 pd-32">
          <div className="flex-center gap-8">
            <h2 className="pc">{t("info.portfolio")}</h2>
            <h6 className="caption"> {portfolio?.length || 0} / 10</h6>
          </div>
          <div className="flex-col gap-16">
            <PortfolioTextfieldStyle
              placeholder={t("info.portfolioNameRequired")}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button
                      type="button"
                      onClick={() => setInputValue("")}
                      className="flex-centered">
                      <SvgIcons name="close" />
                    </button>
                  </InputAdornment>
                )
              }}
              variant="outlined"
              size="small"
              fullWidth
            />
            <div className="flex-center gap-8">
              <button
                type="button"
                onClick={() => setChecked(checked === "N" ? "Y" : "N")}>
                {checked === "N" ? (
                  <CheckboxUncheckedCircleSvg />
                ) : (
                  <CheckboxCheckedCircleSvg />
                )}
              </button>
              <h3 className="fw-500">{t("info.setAsDefaultPortfolio")}</h3>
            </div>
          </div>
        </div>
        <div className="flex-end-center gap-8" style={{ padding: "12px 32px" }}>
          <button type="button" className="cancel-btn-pc" onClick={onCancel}>
            {t("button.close")}
          </button>
          <button
            type="button"
            className="submit-btn-pc"
            onClick={() => onSubmitRegister(inputValue, checked)}>
            {t("button.save")}
          </button>
        </div>
      </div>
    </PCCenterModal>
  );
}
PCCenterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ModalNewPortfolioPC;
