/* eslint-disable camelcase */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import classNames from "classnames";
import { errorAPI, successAlert } from "components/alert";
import {
  CheckboxCheckedCircleSvg,
  CheckboxUncheckedCircleSvg,
  XCircle
} from "components/icons";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup
} from "firebase/auth";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { loginUser } from "services/AuthenService";
import { ModalStyled } from "views/HeatMap/styled";
import { setDataAfterLogin } from "../../../common/FuncComm";
import { ERROR_MAP, USER_STATE } from "../../../constants";
import {
  KAKAO_REST_API_KEY,
  NAVER_CLIENT_ID,
  REDIRECT_URL_KAKAO,
  REDIRECT_URL_NAVER,
  SNS_METHOD
} from "../../../contexts/Constants";
import useMain from "../../../hook/useMain";
import useSettingPage from "../../../hook/useSettingPage";
import useUserVerification from "../../../hook/useUserVerification";
import AuthService from "../../../services/AuthService/AuthService";
import { OutlinedInputBorder24 } from "../components/OutlinedInputBorder24";
import ModalForgetPassword from "./ModalForgetPassword";

export default function ModalLoginMobile() {
  const open = useSelector((state) => state.auth.openModalLogin);
  const state = "login";
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchInfoUser } = useMain();
  const { checkVisitPage } = useSettingPage();
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState();
  const { checkUserStatus } = useUserVerification();
  // const [currentFocus, setCurrentFocus] = useState(null);
  const [autoLogin, setAutoLogin] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const { t, i18n } = useTranslation();

  const stableKaKao = useRef();
  const stableNaver = useRef();

  const isPcOrIos = useMemo(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      return true;
    }

    const mobile = /iphone|ipad|ipod|android/i.test(userAgent);

    return !mobile;
  }, [navigator.userAgent]);

  useEffect(() => {
    window.addEventListener("message", nativeCheck);
    return () => {
      window.removeEventListener("message", nativeCheck);
    };
  }, []);

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const onClose = () => {
    dispatch(authActions.onCloseModalLogin());
    localStorage.removeItem("previousUrlFunc");
    localStorage.removeItem("clickedButton");
  };

  const handleLogin = async (credentials) => {
    try {
      setIsLoading(true);
      const res = await loginUser(credentials);
      const status = res?.data;
      if (status?.code === "400") errorAPI(t("notice.wrongLoginInfo"));
      else {
        const { data, message } = status;
        if (!isEmpty(data)) {
          const { user, joinQuizState, token, refresh_token, popup } = data;
          if (user?.id) {
            if (user?.state === USER_STATE.STOP) {
              errorAPI(t("notice.accountSuspended"));
              localStorage.clear(); // not required, need recheck
            } else if (user?.state === USER_STATE.WITHDRAWAL) {
              errorAPI(t("notice.retiredAccount"));
              localStorage.clear(); // not required, need recheck
            } else {
              window.localStorage.setItem(
                "user",
                JSON.stringify({
                  autoLg: autoLogin,
                  ...user
                })
              );
              window.localStorage.setItem("joinQuizState", joinQuizState);
              window.localStorage.setItem("token", token?.accessToken);
              window.localStorage.setItem("refresh_token", refresh_token);
              if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler(
                  "LoginListener",
                  user?.mail
                );
              }
              checkVisitPage();
              if (!!popup) {
                errorAPI(t("notice.accountInactive"));
              }
              const clickedButton =
                window.localStorage.getItem("clickedButton");
              const previousUrlFunc =
                window.localStorage.getItem("previousUrlFunc");
              fetchInfoUser();
              if (clickedButton && previousUrlFunc) {
                navigate(previousUrlFunc, { replace: true });
                localStorage.removeItem("clickedButton");
                localStorage.removeItem("previousUrlFunc");
              } else {
                navigate("/");
                successAlert(t("notice.loginCompelete"));
              }
              onClose();
            }
          }
        } else {
          errorAPI(message);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const currentToken = window.localStorage.getItem("token");
    if (currentUser !== null && currentToken !== null) {
      errorAPI(t("notice.alreadyLogin"));
    } else {
      handleLogin({
        userName: email,
        password,
        autoLogin
      });
    }
  };

  const nativeCheck = async (e) => {
    if (e.data.type) {
      await flutterLoginCallback(e, state);
    } else {
      await getResultId(e);
    }
  };

  const flutterLoginCallback = async (e, kind) => {
    if (kind === state) {
      const { type, accessToken: token } = e.data;
      switch (type) {
        case "naver": {
          const loginMethod = SNS_METHOD.NAVER;
          try {
            const res = await AuthService.loginSns(token, loginMethod);
            if (res?.data?.data) {
              const data = res?.data?.data;
              const { popup } = data || {};
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              checkUserStatus(async () => {
                checkVisitPage();
                navigate("/");
                onClose();
                successAlert(t("notice.loginCompelete"));
                if (!!popup) {
                  errorAPI(t("notice.accountInactive"));
                }
              });
            } else if (res?.data?.code === "400") {
              errorAPI(t("notice.accountNotFound"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.log("error naver", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        case "google": {
          const loginMethod = SNS_METHOD.GOOGLE;
          try {
            const res = await AuthService.loginSns(token, loginMethod);
            if (res?.data?.data) {
              const data = res?.data?.data;
              const { popup } = data || {};
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              checkUserStatus(async () => {
                checkVisitPage();
                navigate("/");
                onClose();
                successAlert(t("notice.loginCompelete"));
                if (!!popup) {
                  errorAPI(t("notice.accountInactive"));
                }
              });
            } else if (res?.data?.code === "400") {
              errorAPI(t("notice.accountNotFound"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.log("error gg", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        case "kakao": {
          const loginMethod = SNS_METHOD.KAKAOTALK;
          try {
            const res = await AuthService.loginSns(token, loginMethod);
            if (res?.data?.data) {
              const data = res?.data?.data;
              const { popup } = data || {};
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              checkUserStatus(async () => {
                checkVisitPage();
                navigate("/");
                onClose();
                successAlert(t("notice.loginCompelete"));
                if (!!popup) {
                  errorAPI(t("notice.accountInactive"));
                }
              });
            } else if (res?.data?.code === "400") {
              errorAPI(t("notice.accountNotFound"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.log("error kakao", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        case "apple": {
          const loginMethod = SNS_METHOD.APPLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.loginSns(token, loginMethod);
            if (res?.data?.data) {
              const data = res?.data?.data;
              const { popup } = data || {};
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              checkUserStatus(async () => {
                checkVisitPage();
                onClose();
                successAlert(t("notice.loginCompelete"));
                if (!!popup) {
                  errorAPI(t("notice.accountInactive"));
                }
              });
            } else if (res?.data?.code === "400") {
              errorAPI(t("notice.accountNotFound"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.log("error ap", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const getResultId = async (e) => {
    if (e?.data?.source?.sns === "naver" && e?.data?.source?.type === "login") {
      const token = e?.data?.access_token;
      if (stableNaver.current !== token) {
        stableNaver.current = token;
        const loginMethod = SNS_METHOD.NAVER;
        try {
          const res = await AuthService.loginSns(token, loginMethod);
          if (res?.data?.data) {
            const data = res?.data?.data;
            const { popup } = data || {};
            setDataAfterLogin(
              data.user,
              data.token.accessToken,
              data.refresh_token
            );
            checkUserStatus(async () => {
              checkVisitPage();
              navigate("/");
              onClose();
              successAlert(t("notice.loginCompelete"));
              if (!!popup) {
                errorAPI(t("notice.accountInactive"));
              }
            });
          } else if (res?.data?.code === "400") {
            errorAPI(t("notice.accountNotFound"));
          } else {
            const { message = t("notice.error") } = res?.data || {};
            errorAPI(message);
          }
        } catch (error) {
          console.log("error naver", error);
          errorAPI(t("notice.error"));
        }
      }
    }
    if (e?.data?.source?.sns === "kakao" && e?.data?.source?.type === "login") {
      const code = e?.data.code;
      if (stableKaKao.current !== code) {
        stableKaKao.current = code;
        const body = new URLSearchParams({
          code,
          redirectUri: REDIRECT_URL_KAKAO,
          grant_type: "authorization_code",
          client_id: KAKAO_REST_API_KEY
        });
        fetch("https://kauth.kakao.com/oauth/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body,
          redirect: "follow"
        })
          .then(async (res) => {
            const { access_token: token } = await res.json();
            if (token) {
              const loginMethod = SNS_METHOD.KAKAOTALK;
              try {
                const resData = await AuthService.loginSns(token, loginMethod);
                if (resData?.data?.data) {
                  const data = resData?.data?.data;
                  const { popup } = data || {};
                  setDataAfterLogin(
                    data.user,
                    data.token.accessToken,
                    data.refresh_token
                  );
                  checkUserStatus(async () => {
                    checkVisitPage();
                    navigate("/");
                    onClose();
                    successAlert(t("notice.loginCompelete"));
                    if (!!popup) {
                      errorAPI(t("notice.accountInactive"));
                    }
                  });
                } else if (resData?.data?.code === "400") {
                  errorAPI(t("notice.accountNotFound"));
                } else {
                  const { message = t("notice.error") } = resData?.data || {};
                  errorAPI(message);
                }
              } catch (error) {
                console.log("error kakao", error);
                errorAPI(t("notice.error"));
              }
            }
          })
          .catch((error) => {
            console.log("error login kakao", error);
          });
      }
    }
  };

  const loginWithNaver = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "naver");
    } else {
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=${NAVER_CLIENT_ID}&state=${state}&redirect_uri=${REDIRECT_URL_NAVER}`;
      window.open(url, "AuthCheck", "width=500, height=550");
    }
  };

  const loginWithKakao = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "kakao");
    } else {
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${REDIRECT_URL_KAKAO}&response_type=code&state=${state}`;
      window.open(url, "AuthCheck", "width=500, height=550");
    }
  };

  const loginWithGoogle = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "google");
    } else {
      const provider = new GoogleAuthProvider();
      provider
        .addScope("email")
        .addScope("https://www.googleapis.com/auth/plus.login")
        .addScope("https://www.googleapis.com/auth/plus.me")
        .addScope("https://www.googleapis.com/auth/userinfo.email")
        .addScope("https://www.googleapis.com/auth/userinfo.profile")
        .addScope("https://www.googleapis.com/auth/user.phonenumbers.read");

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;
          const token = user.accessToken;
          const loginMethod = SNS_METHOD.GOOGLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.loginSns(token, loginMethod);
            if (res?.data?.data) {
              const data = res?.data?.data;
              const { popup } = data || {};
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              checkUserStatus(async () => {
                checkVisitPage();
                navigate("/");
                onClose();
                successAlert(t("notice.loginCompelete"));
                if (!!popup) {
                  errorAPI(t("notice.accountInactive"));
                }
              });
            } else if (res?.data?.code === "400") {
              errorAPI(t("notice.accountNotFound"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            errorAPI(t("notice.error"));
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    }
  };

  const loginWithApple = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "apple");
    } else {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;
          const token = user.accessToken;
          const loginMethod = SNS_METHOD.APPLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.loginSns(token, loginMethod);
            if (res?.data?.data) {
              const data = res?.data?.data;
              const { popup } = data || {};
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              checkUserStatus(async () => {
                checkVisitPage();
                navigate("/");
                onClose();
                successAlert(t("notice.loginCompelete"));
                if (!!popup) {
                  errorAPI(t("notice.accountInactive"));
                }
              });
            } else if (res?.data?.code === "400") {
              errorAPI(t("notice.accountNotFound"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.log("error ap", error);
            errorAPI(t("notice.error"));
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-login-title"
      aria-describedby="modal-login-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          headerTitle={<h1>{t("button.login")}</h1>}
          onBack={onClose}
          flag
          onSetting={() => {}}
          borderBottom
        />
        <div className="flex-col gap-32 flex-1 flow-y-auto">
          <div className="flex-col gap-24 pt-32 pl-20 pr-20">
            <div className="flex-col gap-12">
              <OutlinedInputBorder24
                id="outlined-input-email"
                onChange={(e) => setEmail(e.target.value)}
                // onFocus={() => setCurrentFocus("email")}
                // onBlur={() => setCurrentFocus("")}
                value={email}
                placeholder={t("info.email")}
                endAdornment={
                  email ? (
                    // && currentFocus === "email"
                    <button
                      type="button"
                      className="btn-40px-end"
                      // onFocus={() => setCurrentFocus("email")}
                      onClick={() => setEmail("")}>
                      <XCircle />
                    </button>
                  ) : null
                }
              />
              <OutlinedInputBorder24
                id="outlined-input-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                // onFocus={() => setCurrentFocus("password")}
                // onBlur={() => setCurrentFocus("")}
                placeholder={t("info.password")}
                type={visiblePassword ? "text" : "password"}
                endAdornment={
                  password ? (
                    <button
                      type="button"
                      className="btn-40px-end"
                      onClick={() => setVisiblePassword((e) => !e)}>
                      <SvgIcons name={visiblePassword ? "eyeOff" : "eye"} />
                    </button>
                  ) : null
                }
              />
            </div>
            <div className="flex-col">
              <button
                type="button"
                className={classNames("pd-12-20 br-24", {
                  "bg-neutral-8": isEmpty(email) || isEmpty(password),
                  "bg-sell": !isEmpty(email) && !isEmpty(password)
                })}
                onClick={() => {
                  if (!isEmpty(email) && !isEmpty(password)) handleSubmit();
                }}>
                <h5
                  className={classNames({
                    "color-neutral-5": isEmpty(email) || isEmpty(password),
                    "color-neutral-10": !isEmpty(email) && !isEmpty(password)
                  })}>
                  {t("button.login")}
                </h5>
              </button>
              <button
                type="button"
                onClick={() => setAutoLogin((e) => !e)}
                className="flex-start-center fit-width pt-8">
                <div className="block-32px pd-4-8-4-0">
                  {autoLogin ? (
                    <CheckboxCheckedCircleSvg />
                  ) : (
                    <CheckboxUncheckedCircleSvg />
                  )}
                </div>
                <h5 className="fw-500 color-neutral">
                  {t("button.autoLogin")}
                </h5>
              </button>
            </div>
            <button
              type="button"
              className="flex-centered"
              onClick={() =>
                dispatch(authActions.onOpenModalForgetPasswords())
              }>
              <h5 className="fw-500 color-neutral text-underline">
                {t("button.forgetPassword")}
              </h5>
            </button>
          </div>
          <div className="pd-8-20">
            <div className="bg-neutral-7 h-1px" />
          </div>
          <div className="pl-20 pr-20 flex-start-center flex-col gap-24">
            <h5 className="fw-500 color-neutral">{t("button.loginWithSNS")}</h5>
            <div className="flex-centered gap-16">
              <button
                type="button"
                className="h-48px"
                onClick={() => loginWithNaver()}>
                <SvgIcons name="snsNaver" />
              </button>
              <button
                type="button"
                className="h-48px"
                onClick={
                  // () => {}
                  () => loginWithKakao()
                }>
                <SvgIcons name="snsKakao" />
              </button>
              {isPcOrIos ? (
                <button
                  type="button"
                  className="h-48px"
                  onClick={
                    // () => {}
                    () => loginWithApple()
                  }>
                  <SvgIcons name="snsApple" />
                </button>
              ) : null}
              <button
                type="button"
                className="h-48px"
                onClick={
                  // () => {}
                  () => loginWithGoogle()
                }>
                <SvgIcons name="snsGoogle" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex-centered gap-8 pd-21-24 border-1px-top-neutral-7">
          <h5 className="fw-500">{t("button.dontHaveAccount")}</h5>
          <button
            type="button"
            onClick={() => {
              onClose();
              dispatch(authActions.onOpenModalRegister());
            }}>
            <h5 className="color-sell fw-500 text-underline">
              {t("button.signUp")}
            </h5>
          </button>
        </div>
        <ModalForgetPassword />
      </ModalStyled>
    </Modal>
  );
}
