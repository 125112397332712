export default function StarPlusSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6034 20.5206C6.30112 20.7009 5.93003 20.4311 6.00847 20.0879L7.4 14L2.69758 9.82007C2.43473 9.58643 2.57965 9.1517 2.93011 9.12249L9.2 8.6L11.6318 2.86798C11.7697 2.54294 12.2303 2.54294 12.3682 2.86798L14.8 8.6L21.0598 9.12165C21.4117 9.15098 21.5557 9.58873 21.2899 9.8213L18.8 12H18C14.9 12 12.4 14.3 12 17.3L6.6034 20.5206ZM17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
        fill="#CFD0D2"
      />
    </svg>
  );
}
