import UserProfileService from "../../services/UserProfileService/UserProfileService";
import { GET_USER_COUNT_ALL, RECALL_NOTICE } from "../type/userType";

export const getUserCountAll = () => async (dispatch) => {
  try {
    if (window.localStorage.getItem("token")) {
      const res = await UserProfileService.getUserCountNotice();
      const data = res?.data?.data ?? {};
      dispatch({
        type: GET_USER_COUNT_ALL,
        payload: data
      });
    } else {
      dispatch({
        type: GET_USER_COUNT_ALL,
        payload: {}
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const recallNotice = () => (dispatch) => {
  console.log(dispatch);
  dispatch({ type: RECALL_NOTICE });
  dispatch(getUserCountAll());
};
