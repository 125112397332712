const URL = Object.freeze({
  GET_USER_COUNT_NOTICE: "/api/user/all",
  GET_USER_DETAIL: "/api/user/detail",
  CHANGE_PROFILE: "/api/user/change/profile",
  UPDATE_USER: "/api/user/update",
  CHECK_NICK_NAME: (nickname) =>
    `/api/auth/check/nick-name?nickName=${nickname}`,
  GET_AUTHOR_PROFILE: "/api/user/detail/user-author",
  UPDATE_POINT: "/api/user/update-point-user-action",
  SAVE_AVATAR: "/api/user/user-avatar/create",
  SAVE_AVATAR2: "/api/user/user-avatar/create-body",
  GET_LIST_AVATAR: "/api/user/user-avatar/list"
});
export default URL;
