const getRealUrl = () => {
  let apiUrl = `${window.location.protocol}//${window.location.hostname}`;
  apiUrl = `${apiUrl}/`;
  return apiUrl;
};

const getRealApiUrl = () => `${process.env.REACT_APP_API_URI}/`;

const refreshPage = () => {
  window.location.reload(false);
};

export default {
  getRealUrl,
  getRealApiUrl,
  refreshPage
};
