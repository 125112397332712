export default function AlertCircleSvg({ fillColor = "#9497A0" }) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4998 1.7C6.36356 1.7 2.1998 5.86375 2.1998 11C2.1998 16.1363 6.36356 20.3 11.4998 20.3C16.6361 20.3 20.7998 16.1363 20.7998 11C20.7998 5.86375 16.6361 1.7 11.4998 1.7ZM0.799805 11C0.799805 5.09056 5.59036 0.300003 11.4998 0.300003C17.4093 0.300003 22.1998 5.09056 22.1998 11C22.1998 16.9095 17.4093 21.7 11.4998 21.7C5.59036 21.7 0.799805 16.9095 0.799805 11Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4998 6.3C11.8864 6.3 12.1998 6.6134 12.1998 7V11C12.1998 11.3866 11.8864 11.7 11.4998 11.7C11.1132 11.7 10.7998 11.3866 10.7998 11V7C10.7998 6.6134 11.1132 6.3 11.4998 6.3Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4998 15C10.4998 14.4477 10.9475 14 11.4998 14H11.5098C12.0621 14 12.5098 14.4477 12.5098 15C12.5098 15.5523 12.0621 16 11.5098 16H11.4998C10.9475 16 10.4998 15.5523 10.4998 15Z"
        fill={fillColor}
      />
    </svg>
  );
}
