/* eslint-disable no-extra-boolean-cast */
/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";
import URL from "./url";

class PostService {
  getNewPost(hot = false) {
    const query = `?hot=${hot}`;
    const url = URL.GET_NEW_POST + query;
    return axiosClient.get(url);
  }

  getPostByBoard(idBoard) {
    const query = `?idBoard=${idBoard}`;
    const url = URL.GET_POST_BY_BOARD + query;
    return axiosClient.get(url);
  }

  getPostByMenu(idMenu) {
    const query = `?idMenu=${idMenu}`;
    const url = URL.GET_POST_BY_MENU + query;
    return axiosClient.get(url);
  }

  getDetailpost(idPost, isInc = false) {
    const query = `?id=${idPost}&isIncreview=${isInc}`;
    const url = URL.GET_POST_BY_ID + query;
    return axiosClient.get(url);
  }

  reportUser(body = {}) {
    const url = URL.REPORT_USER;
    return axiosClient.put(url, body);
  }

  reportPost(idPost, body = {}) {
    const query = `?idPost=${idPost}`;
    const url = URL.REPORT_POST + query;
    return axiosClient.post(url, body);
  }

  deletePost(idPost) {
    const query = `?id=${idPost}`;
    const url = URL.DELETE_POST + query;
    return axiosClient.delete(url);
  }

  movePost(idPost, idBoard) {
    const query = `?id=${idPost}&idBoard=${idBoard}`;
    const url = URL.MOVE_POST + query;
    return axiosClient.post(url);
  }

  copyPost(idPost, idBoard) {
    const query = `?id=${idPost}&idBoard=${idBoard}`;
    const url = URL.COPY_POST + query;
    return axiosClient.post(url);
  }

  getPostCalender(idBoard) {
    const query = `?idBoard=${idBoard}`;
    const url = URL.GET_POST_CALENDER + query;
    return axiosClient.get(url);
  }

  getListPostByBoard(id) {
    const url = URL.GET_POST_LIST_BY_BOARD(id);
    return axiosClient.get(url);
  }

  getListTpPost(id) {
    const url = URL.GET_LIST_TYPE_POST(id);
    return axiosClient.get(url);
  }

  updatePost(id, body) {
    const url = `${URL.UPDATE_POST}?id=${id}`;
    return axiosClient.put(url, body);
  }

  getPreNextPost(idPost, isInc = false) {
    const url = `${URL.GET_PRE_NEXT_POST}?idPost=${idPost}&isIncreview=${isInc}`;
    return axiosClient.get(url);
  }

  likePost(idPost, type) {
    const url = `${URL.LIKE_POST(type)}?idPost=${idPost}`;
    return axiosClient.get(url);
  }

  getPostByAuthorAndBoard(idBoard, idUser, pageNumber, pageSize) {
    const query = `?idBoard=${idBoard}&idUser=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const url = URL.GET_POSTS_BY_AUTHOR_AND_BOARD + query;
    return axiosClient.post(url);
  }

  getPostByHashTag(idBoard, hashtag) {
    const query = `?idBoard=${idBoard}&hashtag=${hashtag}`;
    const url = URL.GET_POST_BY_HASHTAG + query;
    return axiosClient.get(url);
  }

  getPreNextPostVideo(idPost, isInc = false) {
    const url = `${URL.GET_PRE_NEXT_POST_VIDEO}?idPost=${idPost}&isIncreview=${isInc}`;
    return axiosClient.get(url);
  }

  getPostByTypePost(url, body) {
    return axiosClient.post(url, body);
  }

  getAllPostByHashTag(url, body) {
    return axiosClient.post(url, body);
  }
}

export default new PostService();
