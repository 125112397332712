/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getCryptoAll = (params) =>
  newAxiosInstance.get(URL.GET_CRYPTO_ALL + qs.stringify(params, true));

export const getCryptoRealtime = (params) =>
  newAxiosInstance.get(URL.GET_CRYPTO_REALTIME + qs.stringify(params, true));

export const getCryptoMain = (params) =>
  newAxiosInstance.get(URL.GET_CRYPTO_MAIN + qs.stringify(params, true));

export const getCurrencyList = (params) =>
  newAxiosInstance.get(URL.GET_CURRENCY_LIST + qs.stringify(params, true));

export const getExchangeList = (params) =>
  newAxiosInstance.get(URL.GET_EXCHANGE_LIST + qs.stringify(params, true));

export const getCalculatorResult = (params) =>
  newAxiosInstance.get(URL.GET_CALCULATOR_RESULT + qs.stringify(params, true));

// indices
export const getIndicesMain = (params) =>
  newAxiosInstance.get(URL.GET_INDICES_MAIN + qs.stringify(params, true));

export const getIndicesAll = (params) =>
  newAxiosInstance.get(URL.GET_INDICES_ALL + qs.stringify(params, true));

export const getIndicesRealtimeMain = (params) =>
  newAxiosInstance.get(
    URL.GET_INDICES_REALTIME_MAIN + qs.stringify(params, true)
  );

export const getIndicesRealtime = (params) =>
  newAxiosInstance.get(URL.GET_INDICES_REALTIME + qs.stringify(params, true));

// etf

export const getEtfMain = (params) =>
  newAxiosInstance.get(URL.GET_ETF_MAIN + qs.stringify(params, true));

export const getEtfAll = (params) =>
  newAxiosInstance.get(URL.GET_ETF_ALL + qs.stringify(params, true));

export const getEtfRealtimeMain = (params) =>
  newAxiosInstance.get(URL.GET_ETF_REALTIME_MAIN + qs.stringify(params, true));

export const getEtfRealtime = (params) =>
  newAxiosInstance.get(URL.GET_ETF_REALTIME + qs.stringify(params, true));

// future

export const getFutureMain = (params) =>
  newAxiosInstance.get(URL.GET_FUTURE_MAIN + qs.stringify(params, true));

export const getFutureAll = (params) =>
  newAxiosInstance.get(URL.GET_FUTURE_ALL + qs.stringify(params, true));

export const getFutureRealtimeMain = (params) =>
  newAxiosInstance.get(
    URL.GET_FUTURE_REALTIME_MAIN + qs.stringify(params, true)
  );

export const getFutureRealtime = (params) =>
  newAxiosInstance.get(URL.GET_FUTURE_REALTIME + qs.stringify(params, true));

// stock
export const getStockIndicesGroup = (params) =>
  newAxiosInstance.get(
    URL.GET_STOCK_INDICES_GROUP + qs.stringify(params, true)
  );

export const getStockIndices = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_INDICES + qs.stringify(params, true));

export const getStockRealtime = (params) => {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return newAxiosInstance.get(
    URL.GET_STOCK_REALTIME + qs.stringify(validParams, true)
  );
};

export const getStockUnderValue = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_UNDERVALUE + qs.stringify(params, true));

export const getStockThema = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_THEMA + qs.stringify(params, true));

export const getStockThemaStock = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_THEMA_STOCK + qs.stringify(params, true));

export const getStockInvestor = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_INVESTOR + qs.stringify(params, true));

export const getStockThemaWithMk = (mkThemaMasterUid) =>
  newAxiosInstance.get(`${URL.GET_STOCK_THEMA}/${mkThemaMasterUid}`);

export const getStockSector = (params) => {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return newAxiosInstance.get(
    URL.GET_STOCK_SECTOR + qs.stringify(validParams, true)
  );
};

export const getStockSectorWithCode = (params) =>
  newAxiosInstance.get(
    URL.GET_STOCK_SECTOR_DETAIL + qs.stringify(params, true)
  );

export const getStockSectorStock = (params) => {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return newAxiosInstance.get(
    URL.GET_STOCK_SECTOR_STOCK + qs.stringify(validParams, true)
  );
};

export const getStockFair = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_FAIR + params);

export const getStockSummary = (params) =>
  newAxiosInstance.get(URL.GET_SUMMARY_STOCK + params);

export const getStockAbout = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_ABOUT + params);

export const getStockDividend = (params) =>
  newAxiosInstance.get(`${URL.GET_STOCK_DIVIDEND}/${params}`);

export const getStockEarning = (params) =>
  newAxiosInstance.get(`${URL.GET_STOCK_EARNING}/${params}`);

export const getStockDividendList = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_DIVIDEND + qs.stringify(params, true));

export const getStockIncome = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_INCOME + qs.stringify(params, true));

export const getStockBalance = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_BALANCE + qs.stringify(params, true));

export const getStockCashflow = (params) =>
  newAxiosInstance.get(URL.GET_STOCK_CASHFLOW + qs.stringify(params, true));

export const getExchangeSymbolForex = (params) =>
  newAxiosInstance.get(URL.GET_EXCHANGE_SYMBOL + qs.stringify(params, true));

// pc

export const getStockRealtimePc = (params) => {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return newAxiosInstance.get(
    URL.GET_STOCK_PC_REALTIME + qs.stringify(validParams, true)
  );
};
// PC api

// stock PC

export const getStockInvestorList = (params) =>
  newAxiosInstance.get(
    URL.GET_STOCK_INVESTOR_LIST + qs.stringify(params, true)
  );

export const getInvestorTrend = (params) =>
  newAxiosInstance.get(URL.GET_INVESTOR_TREND + qs.stringify(params, true));

export const getDailyNetValue = (params) =>
  newAxiosInstance.get(URL.GET_DAILY_NET_VALUE + qs.stringify(params, true));

export const getSectorStatus = (params) =>
  newAxiosInstance.get(URL.GET_SECTOR_STATUS + qs.stringify(params, true));

export const getSectorDetail = (params) =>
  newAxiosInstance.get(URL.GET_SECTOR_DETAIL + qs.stringify(params, true));

export const getMainStocksBySector = (params) =>
  newAxiosInstance.get(
    URL.GET_MAIN_STOCKS_BY_SECTOR + qs.stringify(params, true)
  );

export const getStockCapacityValue = (params) =>
  newAxiosInstance.get(
    URL.GET_STOCK_PC_CAPACITYVALUE + qs.stringify(params, true)
  );

export const getInvestorWarning = (params) =>
  newAxiosInstance.get(URL.GET_INVESTOR_WARNING + qs.stringify(params, true));

export const getRecentList = (params) =>
  newAxiosInstance.get(URL.GET_RECENT_LIST + qs.stringify(params, true));

export const getPopularList = (params) =>
  newAxiosInstance.get(URL.GET_POPULAR_LIST + qs.stringify(params, true));

export const getSearchThemaResult = (params) =>
  newAxiosInstance.get(
    URL.GET_SEARCH_THEMA_RESULT + qs.stringify(params, true)
  );

export const getSearchThemaSymbol = (params) =>
  newAxiosInstance.get(
    URL.GET_SEARCH_THEMA_SYMBOL + qs.stringify(params, true)
  );
