export default function ChevronRightSvg({ fillColor = "#45495A" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13668 3.66989C6.31893 3.48764 6.61441 3.48764 6.79665 3.66989L10.7966 7.66989C10.9789 7.85213 10.9789 8.14761 10.7966 8.32985L6.79665 12.3299C6.61441 12.5121 6.31893 12.5121 6.13668 12.3299C5.95444 12.1476 5.95444 11.8521 6.13668 11.6699L9.8067 7.99987L6.13668 4.32985C5.95444 4.14761 5.95444 3.85213 6.13668 3.66989Z"
        fill={fillColor}
      />
    </svg>
  );
}
