export default function ChartIndicatorSvg({ fillColor = "#11131A" }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4288_9134)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4033 2.66389C18.5889 2.88664 18.5588 3.21768 18.3361 3.4033L11.2111 9.34081C11.0164 9.50305 10.7336 9.50305 10.5389 9.34081L7.12499 6.49589L1.83609 10.9033C1.61334 11.0889 1.28229 11.0588 1.09667 10.8361C0.911051 10.6133 0.941147 10.2823 1.16389 10.0967L6.78889 5.40917C6.98359 5.24693 7.26639 5.24693 7.46108 5.40917L10.875 8.25409L17.6639 2.59667C17.8866 2.41105 18.2177 2.44115 18.4033 2.66389Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.975006 14.775C0.975006 14.4851 1.21006 14.25 1.50001 14.25H12C12.29 14.25 12.525 14.4851 12.525 14.775C12.525 15.0649 12.29 15.3 12 15.3H1.50001C1.21006 15.3 0.975006 15.0649 0.975006 14.775Z"
          fill={fillColor}
        />
        <path
          d="M18.6143 12.4606C18.7952 12.2866 18.7952 12.0045 18.6143 11.8305C18.4334 11.6565 18.1401 11.6565 17.9592 11.8305L15.3143 14.3744L14.2908 13.3899C14.1099 13.2159 13.8166 13.2159 13.6357 13.3899C13.4548 13.5639 13.4548 13.846 13.6357 14.02L14.9868 15.3195C15.1677 15.4935 15.461 15.4935 15.6419 15.3195L18.6143 12.4606Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4288_9134">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
