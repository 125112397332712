/* eslint-disable react/jsx-no-useless-fragment */
import SvgIcons from "components/svgIcons";
import { removePrefix } from "util/common";

export default function FormAlert({ tetxtNotice }) {
  return (
    <>
      {tetxtNotice ? (
        <div className="flex-start-center gap-8">
          {tetxtNotice?.toString()?.startsWith("Required: ") && (
            <>
              <SvgIcons name="alertCircle" className="svg-24px" />
              <p className="caption color-neutral-1 lh-18">
                {removePrefix(tetxtNotice, "Required: ")}
              </p>
            </>
          )}
          {tetxtNotice?.toString()?.startsWith("Error: ") && (
            <>
              <SvgIcons name="redAlertCircle" className="svg-24px" />
              <p className="caption color-neutral-1 lh-18">
                {removePrefix(tetxtNotice, "Error: ")}
              </p>
            </>
          )}
        </div>
      ) : null}
    </>
  );
}
