import { createSlice } from "@reduxjs/toolkit";
// import { baseDate } from "util/common";

const initialState = {
  // top20Themed: [],
  // top1Themed: null,
  // activeThemed: 0,
  // activeTab: true,
  // searchData: {
  //   sortBy: "changePercent",
  //   changePercent: "",
  //   baseDate: baseDate(),
  //   volume: "",
  //   value: "",
  //   country: "",
  //   mkThemaMasterUid: ""
  // },
  searchDataPC: {
    keyword: "",
    country: {
      country: "KR",
      fileName:
        "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
      countryLabelKor: "대한민국",
      countryLabelEng: "Republic of Korea"
    }
  }
};

const topThemedSlice = createSlice({
  name: "topThemed",
  initialState,
  reducers: {
    // setTop20Themed: (state, action) => {
    //   state.top20Themed = action?.payload;
    //   state.top1Themed = action?.payload[0];
    // },
    // clearTop20Themed: (state) => {
    //   state.top20Themed = [];
    // },
    // setActiveThemed: (state, action) => {
    //   state.activeThemed = action?.payload;
    // },
    // clearActiveThemed: (state) => {
    //   state.activeThemed = null;
    // },
    // changeActiveTab: (state, action) => {
    //   state.activeTab = action?.payload;
    //   state.searchData.sortBy = action?.payload ? "changePercent" : "value";
    // },
    // updateSearchData: (state, action) => {
    //   state.searchData = { ...state.searchData, ...action?.payload };
    // },
    updateSearchDataPC: (state, action) => {
      state.searchDataPC = { ...state.searchDataPC, ...action?.payload };
    },
    resetSeacrhDataPC: (state) => {
      state.searchDataPC = {
        keyword: "",
        country: {
          country: "KR",
          fileName:
            "https://finchive-resource.s3.ap-northeast-2.amazonaws.com/img/country/KR.svg",
          countryLabelKor: "대한민국",
          countryLabelEng: "Republic of Korea"
        }
      };
    }
  }
});

// Actions
export const topThemedActions = topThemedSlice.actions;

// Reducer
const topThemedReducer = topThemedSlice.reducer;
export default topThemedReducer;
