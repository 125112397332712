export default function ChatAppIconSvg({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 10.5417C20.0032 11.7516 19.7205 12.9451 19.175 14.025C18.5282 15.3191 17.5339 16.4076 16.3035 17.1685C15.0731 17.9295 13.6551 18.3328 12.2083 18.3334C10.9985 18.3365 9.80494 18.0538 8.725 17.5084L3.5 19.25L5.24167 14.025C4.69619 12.9451 4.41351 11.7516 4.41667 10.5417C4.41723 9.09497 4.82056 7.67697 5.58149 6.44653C6.34242 5.21609 7.4309 4.2218 8.725 3.57503C9.80494 3.02955 10.9985 2.74687 12.2083 2.75003H12.6667C14.5773 2.85544 16.382 3.66189 17.735 5.01498C19.0881 6.36807 19.8946 8.17271 20 10.0834V10.5417Z"
        fill={fillColor}
      />
    </svg>
  );
}
