/* eslint-disable no-restricted-syntax */
import { MorePagging } from "components/buttons";
import EmptyNotice from "components/emptyNotice";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { idRouter } from "routes/idRouter";
import { getListIndicator } from "services/EconomicCalendarService";
import EconomicIndicatorsItem from "views/EconomicCalendar/components/EconomicIndicator/EconomicIndicatorsItem";
import ModalDetailElectronicDisclosure from "views/EconomicCalendar/components/ElectronicDisclosure/Modal/ModalDetailElectronicDisclosure";
import { EconomicScheduleLists } from "views/EconomicCalendar/styled";

/* eslint-disable no-unused-vars */
export default function Events({ keywords }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listDate, setListDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [uidDetail, setUidDetail] = useState(null);
  const loading = useSelector((state) => state.common.loading);

  useEffect(() => {
    getIndicatorList();
  }, [currentPage, keywords]);

  const setLoading = (value) => dispatch(commonActions.setLoading(value));

  const viewMore = () => {
    setCurrentPage((e) => e + 1);
  };

  const getIndicatorList = async () => {
    try {
      setLoading(true);
      const res = await getListIndicator({
        currentPage,
        pageSize: 20,
        keyword: keywords,
        kind: "ALL",
        country: "ALL",
        baseDate: moment().format("YYYY-MM-DD"),
        important: "ALL"
      });
      if (res.code === "200") {
        const listData = res?.data?.list || [];
        setListDate(currentPage === 1 ? listData : [...listDate, ...listData]);
        setTotalItems(res?.data?.pagination?.totalItems || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flow-hidden pt-8">
      <EconomicScheduleLists className="flex-1 flex-col pl-20 pr-20 pb-40 full-height flow-y-auto hidden-scrollbar">
        {isArray(listDate) && !isEmpty(listDate) ? (
          <div className="economic-schedule-lists">
            {listDate?.map((item, index) => (
              <EconomicIndicatorsItem
                key={`indicator_item_${index}`}
                item={item}
                onClick={() => {
                  dispatch(commonActions.setPrevPath(window.location.pathname));
                  dispatch(commonActions.onCloseModalComprehensiveSearch());
                  navigate(
                    `/${idRouter.economicIndicatorDetail}?event=${
                      item?.event
                    }&baseDate=${moment(item?.dateTime)?.format(
                      "YYYY-MM-DD"
                    )}&id=${item?.id}`
                  );
                }}
              />
            ))}
          </div>
        ) : (
          !loading && (
            <div>
              <EmptyNotice
                noticeText={
                  keywords
                    ? t("notice.noMatchResult")
                    : t("notice.searchEventRequired")
                }
              />
            </div>
          )
        )}
        <ModalDetailElectronicDisclosure
          open={uidDetail !== null}
          onClose={() => setUidDetail(null)}
          uid={uidDetail}
        />
        <MorePagging
          status={20 * currentPage}
          limit={totalItems || 0}
          onClick={viewMore}
        />
      </EconomicScheduleLists>
    </div>
  );
}
