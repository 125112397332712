import {
  GET_CHECK_OPEN,
  GET_CHECK_CLOSE,
  GET_CHECK_AUTO_LOGIN,
  GET_CHECK_DEFAULT_AUTO_LOGIN
} from "../type/loginType";
import { recallNotice } from "./userAction";

export const getOpenLogin = () => (dispatch) => {
  dispatch({
    type: GET_CHECK_OPEN
  });
};

export const getCloseLogin = () => (dispatch) => {
  dispatch({
    type: GET_CHECK_CLOSE
  });
  dispatch(recallNotice());
};

export const getCheckAutoLogin = () => (dispatch) => {
  dispatch({
    type: GET_CHECK_AUTO_LOGIN
  });
};

export const getCheckDefaultAutoLogin = () => (dispatch) => {
  dispatch({
    type: GET_CHECK_DEFAULT_AUTO_LOGIN
  });
};
