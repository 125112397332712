import styled from "styled-components";
import {
  ChatAppIconSvg,
  HeatMapIconSvg,
  MarketIconSvg,
  MoreNavBarIconSvg,
  WatchListIconSvg
} from "components/icons";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { commonActions } from "redux/reducers/commonReducer";
import { idRouter } from "routes/idRouter";

const Nav = styled.nav`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #f4f4f4;
  width: 100%;
  padding: 11px 0;
  padding-bottom: ${(props) =>
    props.isIOS && window.flutter_inappwebview ? "20px" : "11px"};
  height: ${(props) =>
    props.isIOS && window.flutter_inappwebview ? "73px" : "64px"};
  background-color: #ffffff;
  z-index: 100;
`;
const NavItem = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  p {
    color: ${(props) => (props.bold ? "#11131A" : "#9497A0")};
  }
`;
const NavItemImageWrapper = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function NavBarMobile() {
  const currentLocation = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userAgent } = navigator;
  const isIOS = /iP(ad|od|hone)/i.test(userAgent);

  const listHideNavBar = [
    idRouter.searchInterest,
    idRouter.tiny,
    idRouter.morePage,
    idRouter.economicIndicatorDetail,
    idRouter.technicalDetail,
    idRouter.viewChartImage,
    idRouter.majorIndices,
    idRouter.top100Crypto,
    idRouter.majorCryptoCurrency,
    idRouter.realtimeExchangeRate,
    idRouter.topLowestPointStock,
    idRouter.themeDetail,
    idRouter.top100HighestVolume,
    idRouter.top100Industry,
    idRouter.top100Thema,
    idRouter.top100Subject,
    idRouter.interestDetail,
    idRouter.topTrader,
    idRouter.traderDetail,
    idRouter.realTime,
    idRouter.topPopular,
    idRouter.top20DiscussionRoom,
    idRouter.discussion,
    idRouter.sendNote,
    idRouter.chatting,
    idRouter.discussion,
    idRouter.reportingList,
    idRouter.profile,
    idRouter.follow,
    idRouter.writePost,
    idRouter.freeBoard,
    idRouter.apiError500,
    idRouter.notifications,
    idRouter.messageBox,
    idRouter.writeANote,
    idRouter.appNoticeDetail,
    idRouter.customerService,
    idRouter.myPage,
    idRouter.svgIcon,
    idRouter.postDetail,
    idRouter.reportDetail,
    idRouter.dailyPrice,
    idRouter.top100Etf,
    idRouter.majorEtf,
    idRouter.themeDebate,
    idRouter.privacy,
    idRouter.chatList,
    idRouter.economicThemaDetail,
    idRouter.detailPrediction,
    idRouter.toastCalendar,
    idRouter.heatMapLineChart,
    idRouter.heatMapTable,
    idRouter?.multilineChart
  ];

  if (listHideNavBar.includes(currentLocation.pathname.slice(1))) return null;

  const onMarket = () => {
    dispatch(commonActions.setTabIndex("0"));
    navigate("/");
  };

  const onHeatmap = () => {
    dispatch(commonActions.setTabIndex("0"));
    navigate(`/${idRouter.heatMap}`);
  };

  return (
    <Nav isIOS={isIOS}>
      <NavItem bold={currentLocation.pathname === "/"} onClick={onMarket}>
        <NavItemImageWrapper>
          <MarketIconSvg
            fillColor={currentLocation.pathname === "/" ? "#11131A" : "#CFD0D2"}
          />
        </NavItemImageWrapper>
        <p className="caption">{t("info.menu.market")}</p>
      </NavItem>
      <NavItem
        bold={
          currentLocation.pathname === `/${idRouter.watchList}` ||
          currentLocation.pathname === `/${idRouter.recentViewDetail}` ||
          currentLocation.pathname === `/${idRouter.portfolioDetail}`
        }
        onClick={() => navigate(`/${idRouter.watchList}`)}>
        <NavItemImageWrapper>
          <WatchListIconSvg
            fillColor={
              currentLocation.pathname === `/${idRouter.watchList}` ||
              currentLocation.pathname === `/${idRouter.recentViewDetail}` ||
              currentLocation.pathname === `/${idRouter.portfolioDetail}`
                ? "#11131A"
                : "#CFD0D2"
            }
          />
        </NavItemImageWrapper>
        <p className="caption">{t("info.menu.watchList")}</p>
      </NavItem>
      <NavItem
        bold={currentLocation.pathname === `/${idRouter.heatMap}`}
        onClick={onHeatmap}>
        <NavItemImageWrapper>
          <HeatMapIconSvg
            fillColor={
              currentLocation.pathname === `/${idRouter.heatMap}`
                ? "#11131A"
                : "#CFD0D2"
            }
          />
        </NavItemImageWrapper>
        <p className="caption">{t("info.menu.heatMap")}</p>
      </NavItem>
      <NavItem
        bold={currentLocation.pathname === `/${idRouter.communityApp}`}
        onClick={() => navigate(`/${idRouter.communityApp}`)}>
        <NavItemImageWrapper>
          <ChatAppIconSvg
            fillColor={
              currentLocation.pathname === `/${idRouter.communityApp}`
                ? "#11131A"
                : "#CFD0D2"
            }
          />
        </NavItemImageWrapper>
        <p className="caption">{t("info.menu.community")}</p>
      </NavItem>
      <NavItem
        bold={currentLocation.pathname === `/${idRouter.morePage}`}
        onClick={() => navigate(`/${idRouter.morePage}`)}>
        <NavItemImageWrapper>
          <MoreNavBarIconSvg
            fillColor={
              currentLocation.pathname === `/${idRouter.morePage}`
                ? "#11131A"
                : "#CFD0D2"
            }
          />
        </NavItemImageWrapper>
        <p className="caption">{t("info.menu.other")}</p>
      </NavItem>
    </Nav>
  );
}

export default memo(NavBarMobile);
