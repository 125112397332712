const URL = Object.freeze({
  GET_COUNTRY_LIST: "/api/user/code/country",
  GET_COUNTRY_LIST_THEME: "/api/user/heatmap/thema/country",
  GET_COUNTRY_LIST_STOCK: "/api/user/heatmap/stock/country",
  GET_CHART_INDICECS: "/api/user/heatmap/indices",
  GET_CHART_CRYPTO: "/api/user/heatmap/crypto",
  GET_CHART_ETF: "/api/user/heatmap/etf",
  GET_CHART_FOREX: "/api/user/heatmap/forex",
  GET_CHART_FUTURES: "/api/user/heatmap/futures",
  GET_TOP_20_THEMED: "/api/user/heatmap/thema/top20",
  GET_THEME: "/api/user/heatmap/thema",
  GET_THEM_STOCKLIST: "/api/user/heatmap/thema/stock-list",
  GET_STOCK_STOCKLIST: "/api/user/heatmap/stock/stock-list",
  GET_TOP_STOCK: "/api/user/heatmap/stock/stocklist",
  GET_SELECTOR_LIST: "/api/user/heatmap/stock/sector",
  GET_LIST_STOCK: "/api/user/heatmap/stock",
  // GET_THEMA_LIST: "/api/user/heatmap/interest",
  ADD_THEMA: "/api/user/heatmap/interest",
  // DELETE_THEMA: "/api/user/heatmap/interest/",
  // PC api
  GET_HEATMAP_STOCK: "/api/user/heatmap/stock",
  GET_HEATMAP_COUNTRY: "/api/user/stock/indices-group",
  GET_THEMA_DETAIL: "/api/user/heatmap/thema/thema-detail",
  GET_THEMA_CALENDAR: "/api/user/calendar/pc/thema/dataSearch", // 10-3-1
  GET_THEMA_OVERVIEW: "/api/user/thema/thema-detail", // 15-03
  GET_THEMA_SUMARY: "/api/user/thema/thema-detail-summary", // 15-02
  GET_TOP100_THEMA: "/api/user/thema", // 15-01
  GET_POPULAR_SEARCH_THEMA: "/api/user/thema/thema-popular-list",
  GET_RECENT_SEARCH_THEMA: "/api/user/thema/thema-recent-list"
});
export default URL;
