/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import { SIGN_IN_ADMIN, SIGN_OUT_ADMIN } from "../actions/adminAction";

const initState = {
  adminId: "admin",
  adminPass: "123456",
  isLoggedIn: localStorage.getItem("isLoggedInAdmin")
};

const onSignin = (state) => ({
  ...state,
  isLoggedIn: true
});

const onSignout = (state) => ({
  ...state,
  isLoggedIn: false
});

export default function adminReducer(state = initState, action) {
  switch (action.type) {
    case SIGN_IN_ADMIN:
      return onSignin(state);
    case SIGN_OUT_ADMIN:
      return onSignout(state);
    default:
      return state;
  }
}
