/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AppBar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu
} from "@mui/material";
import { errorAPI, successAlert } from "components/alert";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import CenterConfirmModal from "components/centerConfirmModal";
import {
  BlockSvg,
  NoteSvg,
  ReportSvg,
  ShareSvg,
  ViewProfileSvg
} from "components/icons";
import SvgIcons from "components/svgIcons";
import useResponsive from "hook/useResponsive";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postActions } from "redux/reducers/postReducer";
import { idRouter } from "routes/idRouter";
import { deleteComment, insertBlock } from "services/CommunityService";
import { createRoom } from "services/NewChatService/ChatService";
import ModalReporting from "./ModalReporting";
import { Drawer, Puller } from "./styled";

function TextNotice({ onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClick = () => {
    try {
      dispatch(postActions.onCloseModalAction());
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      navigate(`/${idRouter.reportingList}`);
    }
  };
  return (
    <Trans i18nKey="info.blockSuccess" parent="h6" className="fw-500">
      <button
        type="button"
        className="color-buy fw-500 fs-13 font-1 lh-22"
        onClick={onClick}
      />
    </Trans>
  );
}

export default function ModalActionComment() {
  const { isTabletOrMobile } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentUrl = window.location.href;
  const notNavigate =
    currentUrl.includes(`${idRouter.postDetail}`) ||
    currentUrl.includes(`${idRouter.discussion}`);
  const isOpen = useSelector((state) => state?.post?.commentOpen);
  const userData = useSelector((state) => state?.post?.commentData);
  const isThisUser = JSON.parse(localStorage.getItem("user"));
  const idUser = userData?.id || userData?.userId;
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenConfirmToBlock, setIsOpenConfirmToBlock] = useState(false);
  const [isOpenReportingModal, setIsOpenReportingModal] = useState(false);
  const idPost = userData?.postId || userData?.linkPost?.split("idPost=")[1];
  const flagRedux = useSelector((state) => state?.post?.flag);
  const shareObject = useSelector((state) => state?.post?.sharedObject);
  const blockFlag = useSelector((state) => state?.post?.blockFlag);
  const anchorEl = useSelector((state) => state?.post?.anchorEl);
  const listFunction =
    userData?.id === isThisUser?.id || userData?.userId === isThisUser?.id
      ? [
          {
            action: "share",
            text: t("button.share"),
            icon: <ShareSvg fillColor="#AFB1B8" />
          },
          {
            action: "edit",
            text: t("button.edit"),
            icon: <SvgIcons name="editSvg3" />
          },
          {
            action: "delete",
            text: t("button.delete"),
            icon: <SvgIcons name="trashBin" />
          }
        ]
      : [
          {
            action: "view_profile",
            text: t("button.viewDetail"),
            icon: <ViewProfileSvg fillColor="#AFB1B8" />
          },
          {
            action: "share",
            text: t("button.share"),
            icon: <ShareSvg fillColor="#AFB1B8" />
          },
          {
            action: "send_note",
            text: t("button.sendNote"),
            icon: <NoteSvg fillColor="#AFB1B8" />
          },
          {
            action: "chat",
            text: t("button.chatting"),
            icon: <SvgIcons name="ChatSvg" />
          },
          {
            action: "block",
            text: t("button.block"),
            icon: <BlockSvg />
          },
          {
            action: "report",
            text: t("button.report"),
            icon: <ReportSvg />
          }
        ];

  const onShare = async () => {
    try {
      const options = {
        url: `${process.env.REACT_APP_CLIENT_URI}/${shareObject?.url}`,
        title: shareObject?.title
      };
      if (window.flutter_inappwebview)
        window.flutter_inappwebview.callHandler(
          "Share",
          JSON.stringify(options)
        );
      else await navigator.share(options);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectItem = (e, item) => {
    switch (item?.action) {
      case "chat":
        getRoomId();
        dispatch(postActions.onCloseCommentModal());
        break;
      case "send_note":
        navigate(`/${idRouter.writeANote}?userId=${idUser}`);
        dispatch(postActions.onCloseCommentModal());
        break;
      case "block":
        setIsOpenBlockModal(true);
        break;
      case "report":
        setIsOpenReportingModal(isThisUser);
        dispatch(postActions.onCloseCommentModal());
        break;
      case "view_profile":
        if (
          currentUrl.includes(
            `/${idRouter.discussion}` ||
              currentUrl.includes(`/${idRouter.postDetail}`)
          )
        )
          navigate(`/${idRouter.profile}?id=${idUser}`);
        else navigate(`/${idRouter.discussion}?idPost=${idPost}`);

        dispatch(postActions.onCloseCommentModal());
        break;
      case "edit":
        Promise.all([
          dispatch(postActions.onCloseCommentModal()),
          dispatch(postActions.onEditComment(userData?.commentId))
        ]).then(() => {
          if (!notNavigate)
            navigate(
              `/${idRouter.discussion}?idPost=${idPost}&commentId=${userData?.commentId}`
            );
        });
        break;
      case "report_user":
        setIsOpenReportingModal(isThisUser);
        dispatch(postActions.onCloseCommentModal());
        break;
      case "delete":
        onDeleteComment();
        dispatch(postActions.onCloseCommentModal());
        break;
      case "share":
        onShare();
        dispatch(postActions.onCloseCommentModal());
        break;
      default:
    }
  };

  const getRoomId = async () => {
    try {
      const res = await createRoom({
        idUserReceive: idUser,
        content: ""
      });
      if (res?.code === "200")
        navigate(`/${idRouter.chatting}?roomId=${res?.data?.roomId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteComment = async () => {
    try {
      const res = await deleteComment({
        id: userData.commentId || userData.id
      });
      if (res?.code === "200") {
        successAlert(t("notice.deleteSuccess"));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(postActions.onChangeFlag(!flagRedux));
      dispatch(postActions.onSelectTarget(undefined));
    }
  };

  const afterBlockModal = () => {
    onConfirmToBlock().then(() => {
      setIsOpenBlockModal(false);
      setIsOpenConfirmToBlock(true);
    });
  };
  const onClose = () => dispatch(postActions.onCloseCommentModal());

  const onConfirmToBlock = async () => {
    try {
      const res = await insertBlock({
        blockUserId: idUser
      });
      if (res?.code === "200") {
        dispatch(postActions.onBlockUser(!blockFlag));
        dispatch(postActions.onChangeBlockType("comment"));
      }
    } catch (e) {
      errorAPI(t("notice.blockFailed"));
      console.error(e);
    }
    setIsOpenConfirmToBlock(false);
    onClose();
  };

  const contentComponent = (
    <>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none"
        }}
        color="inherit">
        {isTabletOrMobile && <Puller />}
      </AppBar>
      <List
        sx={{
          ".MuiButtonBase-root.MuiListItemButton-root": {
            padding: isTabletOrMobile ? "10px 24px" : "0px 32px 0px 16px",
            height: isTabletOrMobile ? "" : "40px"
          }
        }}>
        {listFunction.map((item, index) => (
          <ListItemButton
            className="flex-start-center"
            sx={{
              borderTop:
                index !== 0 ? "1px solid var(--neutral-color-8)" : "none"
            }}
            key={`list_function_${index}`}
            onClick={(e) => handleSelectItem(e, item)}>
            <ListItemIcon className="btn-36px-start mw-none">
              {item.icon}
            </ListItemIcon>
            <ListItemText
              className="mg-none"
              disableTypography
              primary={
                isTabletOrMobile ? (
                  <h4>{item?.text}</h4>
                ) : (
                  <h6 className="fw-500 color-neutral-1">{item?.text}</h6>
                )
              }
            />
          </ListItemButton>
        ))}
      </List>
      {isTabletOrMobile && (
        <ButtonFixedCancel onBack={onClose} text={t("button.cancel")} />
      )}
    </>
  );

  return (
    <>
      {isTabletOrMobile ? (
        <Drawer
          onOpen={() => {}}
          anchor="bottom"
          open={isOpen}
          onClose={onClose}
          sx={{
            display: isOpen ? "block" : "none",
            "& .MuiPaper-root": {
              borderRadius: "16px 16px 0 0"
            }
          }}>
          {contentComponent}
        </Drawer>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          slotProps={{
            paper: {
              sx: {
                width: 198
              }
            }
          }}>
          {[...contentComponent.props.children]}
        </Menu>
      )}

      <CenterConfirmModal
        open={isOpenBlockModal}
        btnText={t("button.block")}
        textNotice={
          <>
            <h4>
              {t("notice.blockWarning", {
                blockUser:
                  userData?.nickname || userData?.nickName || "This user"
              })}
            </h4>
            <h6 className="fw-500">{t("notice.blockWarningDetail")}</h6>
          </>
        }
        onCancel={() => setIsOpenBlockModal(undefined)}
        onSubmit={afterBlockModal}
      />

      <CenterConfirmModal
        type="afterAccept"
        open={isOpenConfirmToBlock}
        textNotice={
          <>
            <h4>
              {t("info.blockSuccessTitle", {
                blockedUser:
                  userData?.nickname || userData?.nickName || "This user"
              })}
            </h4>
            <TextNotice onClose={() => setIsOpenConfirmToBlock(false)} />
          </>
        }
        onSubmit={() => {
          setIsOpenConfirmToBlock(false);
          onClose();
        }}
      />

      <ModalReporting
        isReportingComment
        isOpen={isOpenReportingModal}
        setIsOpen={setIsOpenReportingModal}
        submit={() => {
          setIsOpenReportingModal(false); // close reporting modal
          successAlert(t("notice.reportSuccess")); // show success alert
          onClose(); // close action modal
        }}
      />
    </>
  );
}
