/* eslint-disable no-nested-ternary */
import HoldImageSvg from "components/icons/HoldImageSvg";
import { FlagBox } from "./styled";

export default function Flag({ item }) {
  const src = item?.baseFileName || item?.targetFileName;
  return (
    <FlagBox>
      {item?.symbolType !== "FRX" ? (
        <div className="flags_box">
          {src ? (
            <img src={src} alt="" className="_flag_one" />
          ) : (
            <HoldImageSvg className="_flag_one" />
          )}
        </div>
      ) : (
        <div className="flags_box">
          {item?.baseFileName ? (
            <img src={item?.baseFileName} alt="" className="_flag_two_front" />
          ) : (
            <HoldImageSvg className="_flag_two_front" />
          )}
          {item?.targetFileName ? (
            <img src={item?.targetFileName} alt="" className="_flag_two_back" />
          ) : (
            <HoldImageSvg className="_flag_two_back" />
          )}
        </div>
      )}
    </FlagBox>
  );
}
