import styled from "styled-components";
import { Stack } from "@mui/material";

export const StyledStackHeader = styled(Stack)`
  background-color: #11131a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0px 16px 0px;
  .header {
  }
  .header-left {
  }
  .header-right {
  }

  .menu {
    button {
      position: relative;
      display: flex;
      padding: 8px;
      svg {
        width: 24px;
        height: 24px;
      }
      span {
        width: 19px;
        height: 19px;
        background: radial-gradient(
          160.9% 160.9% at 15.32% 93.4%,
          #00d3f7 0%,
          #1554f6 100%
        );
        border-radius: 50%;
        font-weight: 400;
        font-size: 10px;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        position: absolute;
        right: 2px;
        top: 3px;
      }
    }

    .flag {
      padding: 8px 0 8px 8px;
      flex-shrink: 0;
      /* border-radius: 50%; */
      overflow: hidden;
      img {
        display: block;
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
      }
    }

    .chevron {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 80px;
    }
  }

  .list-tab {
    button {
      padding: 0;
      min-width: 30px;
    }
    .active-color {
      color: #00d3f7;
    }
  }

  .sticky-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #11131a;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1200;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
`;
