// import { Input } from "@mui/material";
import { XCircle } from "components/icons";
import SvgIcons from "components/svgIcons";
import { useState } from "react";
import { DeleteButton } from "../searchBar/styled";
import { SearchBarWrap } from "./styled";

export default function SearchBarPC({
  onClick,
  isActive,
  readOnly = false,
  onInputChange
}) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  const handleDeleteClick = () => {
    setInputValue("");
    onInputChange(null);
  };

  return (
    <SearchBarWrap
      onClick={onClick}
      active={isActive}
      className="flex-start-center">
      <input
        placeholder="테마/종목/이벤트"
        readOnly={readOnly}
        value={inputValue}
        onChange={handleInputChange}
      />
      {inputValue ? (
        <DeleteButton onClick={handleDeleteClick}>
          <XCircle />
        </DeleteButton>
      ) : (
        <SvgIcons name="searchSvgGradient" />
      )}
    </SearchBarWrap>
  );
}
