const URL = Object.freeze({
  GET_RECOMMEND_LIST: "/api/user/common/recommend-news-community",
  SEARCH_NEWS: "/api/user/search/news",
  SEARCH_COMMUNITY: "/api/user/search/community",
  SEARCH_EVENT: "/api/user/search/event",
  SEARCH_ANALYSIS: "/api/user/search/analysis",
  GET_TAB: "/api/user/bulltin-board/detail",
  GET_NEWS_LIST: "/api/user/community/news", // 11-23
  GET_RECOMMEND_NEWS: "/api/user/community/recommend-user-news", // 11-26
  GET_ANALYSIS_LIST: "/api/user/community/analysis", // 11-24
  GET_REPOST_LIST: "/api/user/community/report" // 11-25
});
export default URL;
