/* eslint-disable no-unused-vars */
export default function index(fillColor = "#11131A") {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89613 2.16438C9.2331 1.97467 9.61327 1.875 9.99998 1.875C10.3867 1.875 10.7669 1.97467 11.1038 2.16438C11.4408 2.3541 11.7232 2.62746 11.9237 2.95809L11.9254 2.96086L18.9885 14.7522C19.185 15.0924 19.2889 15.4782 19.29 15.8711C19.2911 16.264 19.1893 16.6503 18.9947 16.9916C18.8002 17.3329 18.5197 17.6173 18.181 17.8166C17.8424 18.0158 17.4576 18.1229 17.0647 18.1273L17.0583 18.1273L2.93523 18.1273C2.54238 18.123 2.15751 18.0158 1.8189 17.8166C1.4803 17.6173 1.19976 17.3329 1.00521 16.9916C0.810659 16.6503 0.70887 16.264 0.70997 15.8711C0.71107 15.4782 0.815021 15.0925 1.01148 14.7523L1.01616 14.7442L8.07455 2.96086L8.07623 2.95809C8.27678 2.62746 8.55916 2.3541 8.89613 2.16438ZM9.99998 3.04167C9.81379 3.04167 9.63074 3.08965 9.4685 3.181C9.30665 3.27212 9.17096 3.40331 9.07444 3.56198L9.07373 3.56315L2.01973 15.3393C1.92648 15.5022 1.87716 15.6866 1.87663 15.8744C1.8761 16.0635 1.92511 16.2495 2.01879 16.4139C2.11246 16.5782 2.24753 16.7151 2.41056 16.8111C2.57275 16.9065 2.75697 16.958 2.9451 16.9606H17.0549C17.243 16.958 17.4272 16.9065 17.5894 16.8111C17.7524 16.7151 17.8875 16.5782 17.9812 16.4139C18.0748 16.2495 18.1239 16.0635 18.1233 15.8744C18.1228 15.6866 18.0735 15.5022 17.9802 15.3393L10.9262 3.56315L10.9255 3.56198C10.829 3.40331 10.6933 3.27212 10.5315 3.181C10.3692 3.08965 10.1862 3.04167 9.99998 3.04167Z"
        fill="#AFB1B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99998 6.96062C10.3221 6.96062 10.5833 7.22179 10.5833 7.54395V10.8773C10.5833 11.1995 10.3221 11.4606 9.99998 11.4606C9.67781 11.4606 9.41664 11.1995 9.41664 10.8773V7.54395C9.41664 7.22179 9.67781 6.96062 9.99998 6.96062Z"
        fill="#AFB1B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16664 14.2106C9.16664 13.7504 9.53974 13.3773 9.99998 13.3773H10.0083C10.4685 13.3773 10.8416 13.7504 10.8416 14.2106C10.8416 14.6709 10.4685 15.044 10.0083 15.044H9.99998C9.53974 15.044 9.16664 14.6709 9.16664 14.2106Z"
        fill="#AFB1B8"
      />
    </svg>
  );
}
