/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import axios from "axios";
import { errorAPI } from "components/alert";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import ApiUtil from "../util/Api";
import authService from "./auth-service";

let token = localStorage.getItem("token");
let refreshPromise = null;

const { refreshToken, isTokenExpired } = authService;

const newAxiosInstance = axios.create({
  baseURL: ApiUtil.getRealApiUrl(),
  headers: {
    Authorization: !isEmpty(token) ? `Bearer ${token}` : ""
  }
});

newAxiosInstance.interceptors.request.use(
  async (req) => {
    const currentRefreshToken = localStorage.getItem("refresh_token");
    const tokenNew = localStorage.getItem("token");
    if (isEmpty(tokenNew)) {
      token = tokenNew;
      req.headers.Authorization = "";
      return req;
    }
    if (!isEmpty(token) && !isEmpty(currentRefreshToken)) {
      if (isTokenExpired(token)) {
        refreshPromise = refreshPromise || refreshToken(currentRefreshToken);
        const newToken = await refreshPromise;
        refreshPromise = null;
        token = newToken;
      }
      req.headers.Authorization = `Bearer ${token}`;
    } else if (isEmpty(token)) {
      if (!isEmpty(tokenNew)) {
        req.headers.Authorization = `Bearer ${tokenNew}`;
      }
    }

    return req;
  },
  (error) => Promise.reject(error)
);
newAxiosInstance.interceptors.response.use(
  async (res) => {
    const resp = res.data;
    if (resp.code !== "200") {
      if (!res.config?.hideErrorAlert) {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          className: "bottom-alert-modal",
          hideProgressBar: true,
          closeButton: false,
          autoClose: 1000,
          toastId: "error"
        });
      }
    }
    return resp;
  },
  (error) => {
    // if (error.response?.data?.code === "500") {
    // return redirect(`/${idRouter.apiError500}`);
    // const event = new Event("serverError");
    // window.dispatchEvent(event);
    // }
    errorAPI(error?.response?.data?.message || error?.message); // just using when debug
    return Promise.reject(error?.response?.data);
  }
);

export default newAxiosInstance;
