export default function SuccessSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#1554F6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4948 6.00501C20.7681 6.27838 20.7681 6.7216 20.4948 6.99496L9.49478 17.995C9.22141 18.2683 8.7782 18.2683 8.50483 17.995L3.50483 12.995C3.23146 12.7216 3.23146 12.2784 3.50483 12.005C3.7782 11.7316 4.22141 11.7316 4.49478 12.005L8.9998 16.51L19.5048 6.00501C19.7782 5.73165 20.2214 5.73165 20.4948 6.00501Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1513 5.65146C19.6199 5.18283 20.3797 5.18283 20.8483 5.65146C21.317 6.12009 21.317 6.87989 20.8483 7.34852L9.84833 18.3485C9.3797 18.8171 8.61991 18.8171 8.15128 18.3485L3.15128 13.3485C2.68265 12.8799 2.68265 12.1201 3.15128 11.6515C3.61991 11.1828 4.3797 11.1828 4.84833 11.6515L8.9998 15.8029L19.1513 5.65146ZM20.1412 6.35857C20.0631 6.28046 19.9365 6.28046 19.8584 6.35857L9.35336 16.8636C9.25959 16.9574 9.13241 17.01 8.9998 17.01C8.8672 17.01 8.74002 16.9574 8.64625 16.8636L4.14123 12.3586C4.06312 12.2805 3.93649 12.2805 3.85838 12.3586C3.78028 12.4367 3.78028 12.5633 3.85838 12.6414L8.85838 17.6414C8.93649 17.7195 9.06312 17.7195 9.14123 17.6414L20.1412 6.64141C20.2193 6.5633 20.2193 6.43667 20.1412 6.35857Z"
        fill="white"
      />
    </svg>
  );
}
