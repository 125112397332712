/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import { errorAPI } from "components/alert";
import FormAlert from "components/alert/FormAlert";
import { XCircle, XSvg } from "components/icons";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import AvatarUser from "components/userAvatar";
import { apiUrl } from "contexts/Constants";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authReducer";
import { commonActions } from "redux/reducers/commonReducer";
import { myPageActions } from "redux/reducers/myPageReducer";
import { checkNickName, signUp, signUpSNS } from "services/AuthenService";

import { useTranslation } from "react-i18next";
import { ModalStyled } from "views/HeatMap/styled";
import { OutlinedInputBorder24 } from "../components/OutlinedInputBorder24";

let timeout;

export default function ModalSetUp({
  signUpBy,
  setUpFor,
  setSetUpFor,
  closeSignUp,
  email,
  terms
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const avatarFile = useSelector((state) => state.myPage.avatarFile);
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState({
    password: false,
    confirmPassword: false
  });
  const [textNotice, setTextNotice] = useState(null);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    setVerified(false);
    if (userName.length > 12) {
      setTextNotice(`Error: ${t("info.nickNameLimit")}`);
    } else if (userName.length > 0) {
      if (!userName.trim()) {
        setTextNotice(`Error: ${t("info.requiredNickName")}`);
      } else {
        clearTimeout(timeout);
        timeout = setTimeout(() => handleCheckNickName(), 300);
      }
    }
  }, [userName]);

  useEffect(() => {
    if (signUpBy?.imageUrl) {
      dispatch(
        myPageActions.updateAvatar({
          avatarUrl: signUpBy?.imageUrl
        })
      );
    }
  }, [signUpBy]);

  const setIsLoading = (status) => dispatch(commonActions.setLoading(status));

  const onClose = () => {
    if (signUpBy?.loginMethod === "Email") {
      if (setUpFor === "password") {
        setSetUpFor(null);
      } else {
        setSetUpFor("password");
      }
    } else {
      setSetUpFor(null); // close modal setup, need close modal signup
      closeSignUp();
    }
  };

  const handleCheckNickName = async () => {
    try {
      const res = await checkNickName({ nickName: userName.trim() });
      const { data } = res || {};
      if (data.code === "400") {
        setTextNotice(`Error: ${t("info.sameNickName")}`);
      }
      if (data.code === "200") {
        setVerified(true);
        setTextNotice(null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpoadImage = async () => {
    if (avatarFile) {
      try {
        const form = new FormData();
        form.append("attachments", avatarFile);
        const response = await axios.post(
          `${apiUrl}/storage/attachments`,
          form,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );
        if (response.status === 200) {
          return response?.data?.data[0]?.url;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      return null;
    }
  };

  const handleSignUp = async (imgUrl) => {
    try {
      const res = await signUp({
        nickName: userName.trim(),
        mail: email,
        password,
        userName,
        phone: "",
        imgPath: imgUrl,
        termsAgreeYn: terms?.over18 || "Y",
        privacyAgreeYn: terms?.privacyPolity || "Y",
        eventAgreeYn: terms?.eventNoti || "N"
      });
      if (res?.data?.code === "200") onSuccess();
      else {
        errorAPI(res?.data?.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSignUpSNS = async (imgUrl) => {
    try {
      const res = await signUpSNS(
        {
          idToken: encodeURIComponent(signUpBy?.token),
          loginMethod: signUpBy?.loginMethod,
          signUp: true
        },
        {
          phone: "",
          name: userName.trim(),
          imgPath: imgUrl
        }
      );
      if (res?.data?.code === "200" || !isEmpty(res?.data?.data?.user))
        onSuccess();
      else {
        errorAPI(res?.data?.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSave = async () => {
    if (verified) {
      try {
        setIsLoading(true);
        const imgUrl = await handleUpoadImage();
        if (signUpBy?.loginMethod === "Email") {
          await handleSignUp(imgUrl);
        } else {
          await handleSignUpSNS(imgUrl || signUpBy?.imageUrl);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSuccess = () => {
    setSetUpFor(null);
    closeSignUp();
    dispatch(authActions.onCloseModalRegister());
    navigate("/");
    dispatch(authActions.onUpdateUserName(userName));
    dispatch(authActions.onRegisterSuccess("register"));
    dispatch(
      myPageActions.updateAvatar({
        avatarFile: null,
        avatarUrl: null
      })
    );
  };

  return (
    <Modal
      open={setUpFor !== null}
      onClose={onClose}
      aria-labelledby="modal-setup-title"
      aria-describedby="modal-setup-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          logo={false}
          headerTitle={
            <h1>
              {setUpFor === "password" && t("info.passwordSetting")}
              {setUpFor === "nickName" && t("info.profileSetting")}
            </h1>
          }
          onBack={onClose}
          customAction={
            <button
              type="button"
              className="btn-40px-end flex-centered"
              onClick={() => {
                onClose();
                dispatch(authActions.onCloseModalRegister());
                navigate("/");
              }}>
              <XSvg fillColor="#11131A" />
            </button>
          }
          borderBottom
        />
        <div className="pb-128 flex-col gap-32 flex-1">
          <div className="flex-col gap-24 pt-32 pl-20 pr-20">
            {setUpFor === "password" && (
              <>
                <div className="flex-col gap-12">
                  <label htmlFor="outlined-input-password">
                    <h5 className="fw-500 color-neutral-8">
                      {t("info.password")}
                    </h5>
                  </label>
                  <OutlinedInputBorder24
                    id="outlined-input-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder={t("info.limitPasswords")}
                    type={visible?.password ? "text" : "password"}
                    endAdornment={
                      <button
                        type="button"
                        className="btn-40px-end"
                        onClick={() =>
                          setVisible((e) => ({ ...e, password: !e.password }))
                        }>
                        <SvgIcons name={visible.password ? "eyeOff" : "eye"} />
                      </button>
                    }
                  />
                </div>
                <div className="flex-col gap-12">
                  <label htmlFor="outlined-input-confirm-password">
                    <h5 className="fw-500 color-neutral-8">
                      {t("info.verifyPassword")}
                    </h5>
                  </label>
                  <OutlinedInputBorder24
                    id="outlined-input-confirm-password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    placeholder={t("info.reEnterPassword")}
                    type={visible?.confirmPassword ? "text" : "password"}
                    endAdornment={
                      <button
                        type="button"
                        className="btn-40px-end"
                        onClick={() =>
                          setVisible((e) => ({
                            ...e,
                            confirmPassword: !e.confirmPassword
                          }))
                        }>
                        <SvgIcons
                          name={visible.confirmPassword ? "eyeOff" : "eye"}
                        />
                      </button>
                    }
                  />
                </div>
                <button
                  type="button"
                  className={classNames("pd-12-20 br-24", {
                    "bg-neutral-8":
                      isEmpty(password) || isEmpty(confirmPassword),
                    "bg-sell": !isEmpty(password) && !isEmpty(confirmPassword)
                  })}
                  onClick={() => {
                    if (isEmpty(password) || isEmpty(confirmPassword)) {
                      errorAPI(t("info.emptyPassword"));
                    } else if (password === confirmPassword) {
                      if (password.length >= 8 && password.length <= 12)
                        setSetUpFor("nickName");
                      else {
                        errorAPI(t("info.limitPasswords"));
                      }
                    } else {
                      errorAPI(t("info.passwordNotMatch"));
                    }
                  }}>
                  <h5
                    className={classNames({
                      "color-neutral-5":
                        isEmpty(password) || isEmpty(confirmPassword),
                      "color-neutral-10":
                        !isEmpty(password) && !isEmpty(confirmPassword)
                    })}>
                    {t("button.confirm")}
                  </h5>
                </button>
              </>
            )}
            {setUpFor === "nickName" && (
              <>
                <AvatarUser />
                <div className="flex-col gap-12">
                  <div className="flex-between">
                    <label htmlFor="outlined-input-nickname">
                      <h5 className="fw-500 color-info">
                        {t("info.nickName")}
                      </h5>
                    </label>
                    <h6 className="fw-500 color-neutral">
                      {userName.length}
                      <span className="fw-300 fs-10 lh-20 color-neutral">
                        /
                      </span>
                      12
                    </h6>
                  </div>
                  <OutlinedInputBorder24
                    id="outlined-input-nickname"
                    onChange={(e) => setUserName(e.target.value)}
                    // onFocus={() => setCurrentFocus("email")}
                    // onBlur={() => handleCheckUserName()}
                    value={userName}
                    placeholder={t("info.requiredNickName")}
                    endAdornment={
                      userName ? (
                        // && currentFocus === "email"
                        <button
                          type="button"
                          className="btn-40px-end"
                          onClick={() => {
                            setUserName("");
                            setTextNotice(null);
                          }}>
                          <XCircle />
                        </button>
                      ) : null
                    }
                  />
                  {textNotice !== null && (
                    <FormAlert tetxtNotice={textNotice} />
                  )}
                </div>
                <button
                  type="button"
                  className={classNames("pd-12-20 br-24", {
                    "bg-neutral-8": !verified,
                    "bg-sell": verified
                  })}
                  onClick={onSave}>
                  <h5
                    className={classNames({
                      "color-neutral-5": !verified,
                      "color-neutral-10": verified
                    })}>
                    {t("button.save")}
                  </h5>
                </button>
              </>
            )}
          </div>
        </div>
      </ModalStyled>
    </Modal>
  );
}
