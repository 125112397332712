/* eslint-disable class-methods-use-this */
import axiosClient from "../axiosClient";

class VideoBoardService {
  getListType(url) {
    return axiosClient.get(url);
  }

  getListVideo(url, body) {
    return axiosClient.post(url, body);
  }
}

export default new VideoBoardService();
