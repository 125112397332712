export default function index({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.70005C6.8638 2.70005 2.70005 6.8638 2.70005 12C2.70005 17.1363 6.8638 21.3 12 21.3C17.1363 21.3 21.3 17.1363 21.3 12C21.3 6.8638 17.1363 2.70005 12 2.70005ZM1.30005 12C1.30005 6.0906 6.0906 1.30005 12 1.30005C17.9095 1.30005 22.7001 6.0906 22.7001 12C22.7001 17.9095 17.9095 22.7001 12 22.7001C6.0906 22.7001 1.30005 17.9095 1.30005 12Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65083 15.5662C9.41564 16.1224 10.5572 16.7 12 16.7C13.4429 16.7 14.5845 16.1224 15.3493 15.5662C15.733 15.2871 16.0296 15.008 16.232 14.7969C16.3335 14.691 16.4121 14.6013 16.4669 14.536C16.4943 14.5033 16.5159 14.4766 16.5314 14.457L16.5503 14.4329L16.5563 14.425L16.5585 14.4222L16.5593 14.421C16.5595 14.4208 16.56 14.42 16 14L16.56 14.42C16.792 14.1108 16.7293 13.672 16.42 13.4401C16.1111 13.2083 15.6728 13.2707 15.4407 13.5792C15.4406 13.5793 15.4408 13.5791 15.4407 13.5792L15.4338 13.588C15.4261 13.5977 15.413 13.614 15.3945 13.636C15.3576 13.68 15.2994 13.7466 15.2212 13.8282C15.0642 13.9921 14.8296 14.213 14.5258 14.4339C13.9156 14.8777 13.0572 15.3 12 15.3C10.9429 15.3 10.0845 14.8777 9.47427 14.4339C9.17052 14.213 8.93589 13.9921 8.77888 13.8282C8.70066 13.7466 8.64254 13.68 8.60557 13.636C8.5871 13.614 8.57398 13.5977 8.56634 13.588L8.55902 13.5787L8.55974 13.5796C8.3277 13.2707 7.88919 13.2082 7.58005 13.4401C7.27077 13.672 7.20809 14.1108 7.44005 14.42L7.99986 14.0002C7.44005 14.42 7.43987 14.4198 7.44005 14.42L7.44078 14.421L7.44165 14.4222L7.44381 14.425L7.44984 14.4329L7.46867 14.457C7.48422 14.4766 7.50577 14.5033 7.5332 14.536C7.58802 14.6013 7.66663 14.691 7.76809 14.7969C7.97045 15.008 8.26708 15.2871 8.65083 15.5662ZM8.55902 13.5787C8.55849 13.578 8.55884 13.5784 8.55902 13.5787V13.5787Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00005 9.00005C8.00005 8.44776 8.44776 8.00005 9.00005 8.00005H9.01005C9.56233 8.00005 10.01 8.44776 10.01 9.00005C10.01 9.55233 9.56233 10 9.01005 10H9.00005C8.44776 10 8.00005 9.55233 8.00005 9.00005Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.00005C14 8.44776 14.4478 8.00005 15 8.00005H15.01C15.5623 8.00005 16.01 8.44776 16.01 9.00005C16.01 9.55233 15.5623 10 15.01 10H15C14.4478 10 14 9.55233 14 9.00005Z"
        fill={fillColor}
      />
    </svg>
  );
}
