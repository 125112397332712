import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledMenu = styled(Box)`
  padding: 40px 0px 56px 0px;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  /* padding-right: ${(props) => (props.active ? "17px" : "none")}; */
  z-index: 1200;
  border-bottom: 1px solid #afb1b8;
  .flex-grid {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;
