/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

// 전체삭제
export const deleteRecentItems = (itemlist) =>
  newAxiosInstance.delete(
    URL.DELETE_ALL_LIST +
      qs.stringify({ action: "ITEMS", item_list: itemlist }, true)
  );
export const deleteAllRecent = () =>
  newAxiosInstance.delete(`${URL.DELETE_ALL_LIST}?action=ALL&item_list=0`);

// 삭제
export const addToListRecent = (body) => {
  if (localStorage.getItem("token"))
    return newAxiosInstance.post(URL.ADD_TO_LIST, body);
  return null; // may be required login here
};

// 목록
export const getMyRecentList = (params) =>
  newAxiosInstance.get(URL.GET_MY_RECENT_LIST + qs.stringify(params, true));
