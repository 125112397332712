export default function MinimizeSvg({ fillColor = "#45495A" }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97501 10.5C2.97501 10.2101 3.21006 9.97501 3.50001 9.97501H8.00001C8.28996 9.97501 8.52501 10.2101 8.52501 10.5V15C8.52501 15.29 8.28996 15.525 8.00001 15.525C7.71006 15.525 7.47501 15.29 7.47501 15V11.025H3.50001C3.21006 11.025 2.97501 10.79 2.97501 10.5Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.47501C11.29 2.47501 11.525 2.71006 11.525 3.00001V6.97501H15.5C15.79 6.97501 16.025 7.21006 16.025 7.50001C16.025 7.78996 15.79 8.02501 15.5 8.02501H11C10.7101 8.02501 10.475 7.78996 10.475 7.50001V3.00001C10.475 2.71006 10.7101 2.47501 11 2.47501Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6212 1.87878C16.8263 2.0838 16.8263 2.41621 16.6212 2.62124L11.3712 7.87124C11.1662 8.07626 10.8338 8.07626 10.6288 7.87124C10.4237 7.66621 10.4237 7.3338 10.6288 7.12878L15.8788 1.87878C16.0838 1.67375 16.4162 1.67375 16.6212 1.87878Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37124 10.1288C8.57626 10.3338 8.57626 10.6662 8.37124 10.8712L3.12124 16.1212C2.91621 16.3263 2.5838 16.3263 2.37878 16.1212C2.17375 15.9162 2.17375 15.5838 2.37878 15.3788L7.62878 10.1288C7.8338 9.92375 8.16621 9.92375 8.37124 10.1288Z"
        fill={fillColor}
      />
    </svg>
  );
}
