/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import useResponsive from "hook/useResponsive";
import MobileLayout from "newLayout";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { io } from "socket.io-client";
import { Suspense } from "react";
import PCLayout from "../layouts/MainLayout";

import Kakao from "../components/auth/Kakao";
import Naver from "../components/auth/Naver";
import PageNotFound from "../views/PageNotFound";
import { registerRoutes } from "./index";

const socket = io(process.env.REACT_APP_SOCKET_URI, {
  transports: ["websocket"],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 5
});

const emptyComponent = (props) => {
  return null;
};

const childRoutes = (routes) => {
  const { isTabletOrMobile } = useResponsive();
  return routes.map(({ path, id, component, pcComponent }, index) => {
    // 해당 id 들은 Layout을 사용하지 않는 페이지들로 따로 분기처리해두었습니다.
    const listSpecialRowter = ["messageBrowser", "chatBrowser", "profileUser"];
    const LayoutComponent =
      isTabletOrMobile || window?.flutter_inappwebview
        ? MobileLayout
        : PCLayout;
    const Component = isTabletOrMobile
      ? component || emptyComponent
      : pcComponent || emptyComponent;
    return listSpecialRowter?.includes(id) ? (
      <Route
        key={index}
        path={path}
        exact
        element={
          <Suspense fallback={<> </>}>
            <Component socket={socket} />
          </Suspense>
        }
      />
    ) : (
      <Route
        key={index}
        path={path}
        exact
        element={
          <Suspense fallback={<> </>}>
            <LayoutComponent socket={socket}>
              <Component socket={socket} />
            </LayoutComponent>
          </Suspense>
        }
      />
    );
  });
};

function Routers() {
  return (
    <Router>
      <Routes>
        {childRoutes(registerRoutes)}
        <Route
          path="*"
          element={<PageNotFound />} // For Page Not Found
        />
        <Route path="/naver" element={<Naver />} />
        <Route path="/kakao" element={<Kakao />} />
      </Routes>
    </Router>
  );
}

export default Routers;
