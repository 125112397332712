/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import qs from "querystringify";
import newAxiosInstance from "../newAxiosClient";
import URL from "./url";

export const getListSetting = () => newAxiosInstance.get(URL.GET_LIST_SETTING); // 14-01

export const resetListSetting = () =>
  newAxiosInstance.delete(URL.RESET_SETTING); // 14-04

export const updateSetting = (body) =>
  newAxiosInstance.post(URL.UPDATE_SETTING, body); // 14-03

export const getValueSetting = (kind) =>
  newAxiosInstance.get(URL.GET_VALUE_SETTING + qs.stringify(kind, true)); // 14-01-01

export const getAllSetting = () => newAxiosInstance.get(URL.GET_ALL_SETTING); // 14-02

export const getMemberShip = (params) =>
  newAxiosInstance.get(URL.GET_MEMBERSHIP + qs.stringify(params, true));

export const getPrivacy = () => newAxiosInstance.get(URL.GET_PRIVACY);
