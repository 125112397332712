export default function ValueDownSvg({ fillColor = "#1554F6" }) {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6L8 0H0L4 6Z" fill={fillColor} />
    </svg>
  );
}
