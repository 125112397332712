import styled from "styled-components";

export const InputBarWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 8px 0px;
`;

export const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: var(--neutral-color-9);

  padding: 0px 4px 0px 12px;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: var(--neutral-color-9);
  border: none;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-color: var(--neutral-color-9);
`;
