export default function WatchListIconSvg({ fillColor = "#CFD0D2" }) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2499 1.83325L14.0824 7.57159L20.4166 8.49742L15.8333 12.9616L16.9149 19.2683L11.2499 16.2891L5.58492 19.2683L6.66659 12.9616L2.08325 8.49742L8.41742 7.57159L11.2499 1.83325Z"
        fill={fillColor}
      />
    </svg>
  );
}
