/* eslint-disable no-unused-vars */
import { Modal } from "@mui/material";
import { setDataAfterLogin } from "common/FuncComm";
import { errorAPI, successAlert } from "components/alert";
import { XSvg } from "components/icons";
import Header from "components/subHeaderMobile";
import SvgIcons from "components/svgIcons";
import {
  FIREBASE_CONFIG,
  KAKAO_REST_API_KEY,
  NAVER_CLIENT_ID,
  REDIRECT_URL_KAKAO,
  REDIRECT_URL_NAVER,
  SNS_METHOD
} from "contexts/Constants";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup
} from "firebase/auth";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authReducer";
import AuthService from "services/AuthService/AuthService";
import { ModalStyled } from "views/HeatMap/styled";
import { ERROR_MAP } from "../../../constants";
import ModalSignUpFormik from "./ModalSignUpFormik";

const app = initializeApp(FIREBASE_CONFIG);

export default function ModalRegisterMobile() {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const open = useSelector((state) => state.auth.openModalRegister);
  const onClose = () => dispatch(authActions.onCloseModalRegister());
  const [signUpBy, setSignUpBy] = useState(null);
  const state = "signup";
  const stableKaKao = useRef();
  const stableNaver = useRef();
  const isPcOrIos = useMemo(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      return true;
    }

    const mobile = /iphone|ipad|ipod|android/i.test(userAgent);

    return !mobile;
  }, [navigator.userAgent]);

  const nativeCheck = async (e) => {
    if (e.data.type) {
      await flutterLoginCallback(e, state);
    } else {
      await getResultId(e);
    }
  };

  const flutterLoginCallback = async (e, kind) => {
    if (kind === state) {
      const { type, accessToken: token } = e.data;
      switch (type) {
        case "naver": {
          const loginMethod = SNS_METHOD.NAVER;
          try {
            const res = await AuthService.signUpSns2(token, loginMethod);
            if (res?.data?.data && res?.data?.code === "200") {
              const data = res?.data?.data;
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              onClose();
              successAlert(t("notice.loginCompelete"));
              navigate("/");
              // } else if (res?.data?.code === "400") {
              // alert("이미 가입된 이메일입니다.");
            } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
              setSignUpBy({ loginMethod, token, email: res?.data?.data?.mail });
            } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
              errorAPI(t("notice.emailUsed"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.error("error naver", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        case "google": {
          const loginMethod = SNS_METHOD.GOOGLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.signUpSns2(token, loginMethod);
            if (res?.data?.data && res?.data?.code === "200") {
              const data = res?.data?.data;
              // if was register => login
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              onClose();
              successAlert(t("notice.loginCompelete"));
              navigate("/");
              // }
              // else if (res?.data?.code === "400") {
              // alert("이미 가입된 이메일입니다.");
            } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
              setSignUpBy({ loginMethod, token, email: res?.data?.data?.mail });
            } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
              errorAPI(t("notice.emailUsed"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.error("error gg", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        case "kakao": {
          const loginMethod = SNS_METHOD.KAKAOTALK;
          try {
            const res = await AuthService.signUpSns2(token, loginMethod);
            if (res?.data?.data && res?.data?.code === "200") {
              const data = res?.data?.data;
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              onClose();
              successAlert(t("notice.loginCompelete"));
              navigate("/");
              // }
              // else if (resData?.data?.code === "400") {
              // alert("이미 가입된 이메일입니다.");
            } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
              setSignUpBy({ loginMethod, token, email: res?.data?.data?.mail });
            } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
              errorAPI(t("notice.emailUsed"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.error("error kakao", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        case "apple": {
          const loginMethod = SNS_METHOD.APPLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.signUpSns2(token, loginMethod);
            if (res?.data?.data && res?.data?.code === "200") {
              const data = res?.data?.data;
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              onClose();
              successAlert(t("notice.loginCompelete"));
              navigate("/");
            } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
              setSignUpBy({ loginMethod, token, email: res?.data?.data?.mail });
            } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
              errorAPI(t("notice.emailUsed"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.error("error ap", error);
            errorAPI(t("notice.error"));
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const getResultId = async (e) => {
    if (
      e?.data?.source?.sns === "naver" &&
      e?.data?.source?.type === "signup"
    ) {
      const token = e?.data?.access_token;
      if (stableNaver.current !== token) {
        stableNaver.current = token;
        const loginMethod = SNS_METHOD.NAVER;
        try {
          const res = await AuthService.signUpSns2(token, loginMethod);
          if (res?.data?.data && res?.data?.code === "200") {
            const data = res?.data?.data;
            setDataAfterLogin(
              data.user,
              data.token.accessToken,
              data.refresh_token
            );
            onClose();
            successAlert(t("notice.loginCompelete"));
            navigate("/");
            // } else if (res?.data?.code === "400") {
            // alert("이미 가입된 이메일입니다.");
          } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
            setSignUpBy({ loginMethod, token, email: res?.data?.data?.mail });
          } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
            errorAPI(t("notice.emailUsed"));
          } else {
            const { message = t("notice.error") } = res?.data || {};
            errorAPI(message);
          }
        } catch (error) {
          console.error("error naver", error);
          errorAPI(t("notice.error"));
        }
      }
    }
    if (
      e?.data?.source?.sns === "kakao" &&
      e?.data?.source?.type === "signup"
    ) {
      const code = e?.data.code;
      if (stableKaKao.current !== code) {
        stableKaKao.current = code;
        const body = new URLSearchParams({
          code,
          redirectUri: REDIRECT_URL_KAKAO,
          grant_type: "authorization_code",
          client_id: KAKAO_REST_API_KEY
        });
        fetch("https://kauth.kakao.com/oauth/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body,
          redirect: "follow"
        })
          .then(async (res) => {
            const { access_token: token } = await res.json();
            if (token) {
              const loginMethod = SNS_METHOD.KAKAOTALK;
              try {
                const resData = await AuthService.signUpSns2(
                  token,
                  loginMethod
                );
                if (resData?.data?.data && resData?.data?.code === "200") {
                  const data = resData?.data?.data;
                  setDataAfterLogin(
                    data.user,
                    data.token.accessToken,
                    data.refresh_token
                  );
                  onClose();
                  successAlert(t("notice.loginCompelete"));
                  navigate("/");
                  // }
                  // else if (resData?.data?.code === "400") {
                  // alert("이미 가입된 이메일입니다.");
                } else if (
                  resData?.data?.code === "411" &&
                  resData?.data?.data?.mail
                ) {
                  setSignUpBy({
                    loginMethod,
                    token,
                    email: resData?.data?.data?.mail
                  });
                } else if (
                  resData?.data?.code === "411" &&
                  !resData?.data?.data?.mail
                ) {
                  errorAPI(t("notice.emailUsed"));
                } else {
                  const { message = t("notice.error") } = res?.data || {};
                  errorAPI(message);
                }
              } catch (error) {
                console.error("error kakao", error);
                errorAPI(t("notice.error"));
              }
            }
          })
          .catch((error) => {
            console.error("error login kakao", error);
          });
      }
    }
  };

  const loginWithNaver = (e) => {
    e.preventDefault();
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "naver");
    } else {
      const clientSecret = "W9zjmNHEpi";
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=${NAVER_CLIENT_ID}&state=${state}&client_secret=${clientSecret}&redirect_uri=${REDIRECT_URL_NAVER}`;
      window.open(
        url,
        "AuthCheck",
        `width=540, height=${window.screen.height}`
      );
    }
  };

  const loginWithKakao = (e) => {
    e.preventDefault();
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "kakao");
    } else {
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${REDIRECT_URL_KAKAO}&response_type=code&state=${state}`;
      window.open(
        url,
        "AuthCheck",
        `width=540, height=${window.screen.height}`
      );
    }
  };

  function loginWithGoogle(e) {
    e.preventDefault();
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "google");
    } else {
      const provider = new GoogleAuthProvider();
      provider
        .addScope("email")
        .addScope("https://www.googleapis.com/auth/plus.login")
        .addScope("https://www.googleapis.com/auth/plus.me")
        .addScope("https://www.googleapis.com/auth/userinfo.email")
        .addScope("https://www.googleapis.com/auth/userinfo.profile")
        .addScope("https://www.googleapis.com/auth/user.phonenumbers.read");

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const { user } = result;
          const token = user.accessToken;
          const loginMethod = SNS_METHOD.GOOGLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.signUpSns2(token, loginMethod);
            if (res?.data?.data && res?.data?.code === "200") {
              const data = res?.data?.data;
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              onClose();
              successAlert(t("notice.loginCompelete"));
              navigate("/");
              // }
              // else if (res?.data?.code === "400") {
              // alert("이미 가입된 이메일입니다.");
            } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
              setSignUpBy({
                loginMethod,
                token,
                email: res?.data?.data?.mail,
                imageUrl: user?.photoURL
              });
            } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
              errorAPI(t("notice.emailUsed"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.error("error gg", error);
            errorAPI(t("notice.error"));
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // const { email } = error.customData;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    }
  }

  function loginWithApple(e) {
    e.preventDefault();
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("SocialLogin", "apple");
    } else {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const { user } = result;
          const token = user.accessToken;
          const loginMethod = SNS_METHOD.APPLE;
          // Call server API to get access token & refresh token
          try {
            const res = await AuthService.signUpSns2(token, loginMethod);
            if (res?.data?.data && res?.data?.code === "200") {
              const data = res?.data?.data;
              setDataAfterLogin(
                data.user,
                data.token.accessToken,
                data.refresh_token
              );
              onClose();
              successAlert(t("notice.loginCompelete"));
              navigate("/");
              // } else if (res?.data?.code === "400") {
              // alert("이미 가입된 이메일입니다.");
            } else if (res?.data?.code === "411" && res?.data?.data?.mail) {
              setSignUpBy({
                loginMethod,
                token,
                email: res?.data?.data?.mail
              });
            } else if (res?.data?.code === "411" && !res?.data?.data?.mail) {
              errorAPI(t("notice.emailUsed"));
            } else {
              const { message = t("notice.error") } = res?.data || {};
              errorAPI(message);
            }
          } catch (error) {
            console.error("error ap", error);
            errorAPI(t("notice.error"));
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // const { email } = error.customData;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    }
  }

  useEffect(() => {
    window.addEventListener("message", nativeCheck);
    return () => {
      window.removeEventListener("message", nativeCheck);
    };
  }, []);

  const onCloseSignUp = () => setSignUpBy(null);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-register-title"
      aria-describedby="modal-register-description">
      <ModalStyled className="hidden-scrollbar">
        <Header
          logo={false}
          headerTitle={<h1>{t("button.signUp")}</h1>}
          onBack={onClose}
          customAction={
            <button
              type="button"
              className="btn-40px-end flex-centered"
              onClick={() => {
                onClose();
                navigate("/");
              }}>
              <XSvg fillColor="#11131A" />
            </button>
          }
          borderBottom
        />
        <div className="flex-col gap-32 flex-1 flow-y-auto">
          <div className="flex-col gap-12 pt-32 pr-20 pl-20">
            <button
              type="button"
              style={{ backgroundColor: "#03C75A" }}
              className="h-48px br-24 flex-start-center pos-relative"
              onClick={loginWithNaver}>
              <SvgIcons name="snsNaver" className="h-48px" />
              <h5 className="color-neutral-10 transform-center">
                {t("button.signUpWithNaver")}
              </h5>
            </button>
            <button
              type="button"
              style={{ backgroundColor: "#FEE500" }}
              className="h-48px br-24 flex-start-center pos-relative"
              onClick={loginWithKakao}>
              <SvgIcons name="snsKakao" className="h-48px" />
              <h5 className="transform-center">
                {t("button.signUpWithKakao")}
              </h5>
            </button>
            {isPcOrIos && (
              <button
                type="button"
                style={{ backgroundColor: "#45495A" }}
                className="h-48px br-24 flex-start-center pos-relative"
                onClick={loginWithApple}>
                <SvgIcons name="snsApple" className="h-48px" />
                <h5 className="color-neutral-10 transform-center">
                  {t("button.signUpWithApple")}
                </h5>
              </button>
            )}
            <button
              type="button"
              style={{ backgroundColor: "#F4F5F8" }}
              className="h-48px br-24 flex-start-center pos-relative"
              onClick={loginWithGoogle}>
              <SvgIcons name="snsGoogle" className="h-48px" />
              <h5 className="transform-center">
                {t("button.signUpWithGoogle")}
              </h5>
            </button>
          </div>
          <div className="pd-8-20">
            <div
              style={{
                height: "1px",
                backgroundColor: "var(--neutral-color-7, #e4e5e7)"
              }}
            />
          </div>
          <div className="flex-col gap-24 flex-start-center pl-20 pr-20">
            <div className="flex-col flex-start-center">
              <h5 className="fw-500 color-neutral-8 pre-wrap text-centered">
                {t("info.signUpFree")}
              </h5>
            </div>
            <button
              type="button"
              className="pd-12-20 br-24 bg-sell full-width"
              onClick={() =>
                setSignUpBy({
                  loginMethod: "Email",
                  token: "",
                  email: ""
                })
              }>
              <h5 className="color-neutral-10">
                {t("button.signUpWithEmail")}
              </h5>
            </button>
          </div>
        </div>
        <div className="flex-centered gap-8 pd-21-24 border-1px-top-neutral-7">
          <h5 className="fw-500">{t("button.alreadyHaveAccount")}</h5>
          <button
            type="button"
            onClick={() => {
              onClose();
              dispatch(authActions.onOpenModalLogin());
            }}>
            <h5 className="color-sell fw-500 text-underline">
              {t("button.login")}
            </h5>
          </button>
        </div>
        {signUpBy !== null && (
          <ModalSignUpFormik signUpBy={signUpBy} onClose={onCloseSignUp} />
        )}
      </ModalStyled>
    </Modal>
  );
}
