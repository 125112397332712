export default function CalendarSvg({ fillColor = "#AFB1B8", width, height }) {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1665 3.66667C3.5682 3.66667 3.08317 4.15169 3.08317 4.75V16.4167C3.08317 17.015 3.5682 17.5 4.1665 17.5H15.8332C16.4315 17.5 16.9165 17.015 16.9165 16.4167V4.75C16.9165 4.15169 16.4315 3.66667 15.8332 3.66667H4.1665ZM1.9165 4.75C1.9165 3.50736 2.92386 2.5 4.1665 2.5H15.8332C17.0758 2.5 18.0832 3.50736 18.0832 4.75V16.4167C18.0832 17.6593 17.0758 18.6667 15.8332 18.6667H4.1665C2.92386 18.6667 1.9165 17.6593 1.9165 16.4167V4.75Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3332 0.833336C13.6553 0.833336 13.9165 1.0945 13.9165 1.41667V4.75C13.9165 5.07217 13.6553 5.33334 13.3332 5.33334C13.011 5.33334 12.7498 5.07217 12.7498 4.75V1.41667C12.7498 1.0945 13.011 0.833336 13.3332 0.833336Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6665 0.833336C6.98867 0.833336 7.24984 1.0945 7.24984 1.41667V4.75C7.24984 5.07217 6.98867 5.33334 6.6665 5.33334C6.34434 5.33334 6.08317 5.07217 6.08317 4.75V1.41667C6.08317 1.0945 6.34434 0.833336 6.6665 0.833336Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9165 8.08334C1.9165 7.76117 2.17767 7.5 2.49984 7.5H17.4998C17.822 7.5 18.0832 7.76117 18.0832 8.08334C18.0832 8.4055 17.822 8.66667 17.4998 8.66667H2.49984C2.17767 8.66667 1.9165 8.4055 1.9165 8.08334Z"
        fill={fillColor}
      />
    </svg>
  );
}
