import styled from "styled-components";

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: var(--neutral-color-10, #ffffff);
  .MuiTabs-flexContainer {
    gap: 8px;
    align-items: center;
    padding: 0 20px;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    display: inline-flex;
    padding: 8.5px 16px;
    border-radius: 40px;
    align-items: center;
    column-gap: 8px;
    white-space: nowrap;
  }
  .MuiTabPanel-root {
    padding: 0;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: var(--neutral-color-10, #ffffff);
    background-color: var(--neutral-color-2, #373d3f);
  }

  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    color: var(--neutral-color-4, #9497a0);
    background-color: var(--neutral-color-9, #f9f9f9);
  }
  .MuiTabs-root {
    border-bottom: unset;
    min-height: 39px;
  }
  .MuiButtonBase-root.MuiTab-root {
    /* min-height: fit-content; */
    min-height: 39px;
    height: fit-content;
  }
  .economic-empty {
    border: 1px solid #f4f4f4;
  }
`;
