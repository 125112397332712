/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, Skeleton, Toolbar } from "@mui/material";
import { ButtonFixedCancel } from "components/bottomFixedCancel";
import { CheckboxCheckedBlueSvg } from "components/icons";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { searchActions } from "redux/reducers/searchReducer";
import { getCategoryList } from "services/InterestService";
import classNames from "classnames";
import { Drawer, Puller } from "./styled";

export default function ModalFilterPortfolio({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const currentCategory = useSelector(
    (state) => state.search.currentCategory || "ALL"
  );
  const listCategory = useSelector((state) => state.search.listCategory);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getListCategory();
  }, []);

  const getListCategory = async () => {
    try {
      setIsLoading(true);
      const res = await getCategoryList({
        categoryKind: "COMMON",
        codeGroupId: "SYMBOL_TYPE"
      });
      if (res.code === "200") {
        const resdata = await res.data;
        if (resdata !== "no data") {
          dispatch(searchActions.setListCategory(resdata.list));
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChecked = (item) => {
    dispatch(searchActions.setCurrentCategory(item.codeValue));
  };
  return (
    <Drawer
      onOpen={() => {}}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: isOpen ? "block" : "none",
        "& .MuiPaper-root": {
          borderRadius: "16px 16px 0 0"
        }
      }}>
      <AppBar
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: "1px solid var(--neutral-color-8)"
        }}
        color="inherit">
        <Puller />
        <Toolbar sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
          <h2>{t("info.filter")}</h2>
        </Toolbar>
      </AppBar>
      <div className="flex-col pd-8-0">
        {!isLoading ? (
          <div className="flex-col">
            {isArray(listCategory) &&
              !isEmpty(listCategory) &&
              listCategory.map((item, index) => (
                <button
                  type="button"
                  key={`button_select_catergory_${index}`}
                  className={classNames("flex-between-center", {
                    "bg-list-selected": currentCategory === item.codeValue
                  })}
                  style={{
                    padding: "8px 16px 8px 24px"
                  }}
                  onClick={() => handleChecked(item)}>
                  <h4>
                    {i18n.language === "ko"
                      ? item.labelKor || item.labelEng
                      : item.labelEng || item.labelKor}
                  </h4>
                  <div className="btn-40px-center">
                    {currentCategory === item.codeValue && (
                      <CheckboxCheckedBlueSvg />
                    )}
                  </div>
                </button>
              ))}
            <ButtonFixedCancel onBack={onClose} />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            {[...Array(5)].map((_item, index) => (
              <Skeleton
                sx={{ mb: "10px", borderRadius: "8px", p: "10px" }}
                key={index}
                variant="rectangular"
                animation="wave"
                height={74}
                width="100%"
              />
            ))}
          </div>
        )}
      </div>
    </Drawer>
  );
}
